import { Options } from "currency.js";
import { Currency, Locale } from "./types";

export const defaultCurrency = "DKK";

export const currencySymbolMap: { [P in Currency]: string } = {
  DKK: "kr",
  EUR: "€"
};

export const localeCurrencyMap: { [P in Locale]: Options } = {
  da: {
    separator: ".",
    decimal: ",",
    pattern: "# !",
    negativePattern: "-# !"
  },
  en: {
    separator: ",",
    decimal: ".",
    pattern: "! #",
    negativePattern: "! -#"
  }
};
