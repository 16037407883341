import NavigationForPaymentsCustomer from "~components/connected/app/Navigation/NavigationForPaymentsCustomer";
import NavigationForPropertiesCustomer from "~components/connected/app/Navigation/NavigationForPropertiesCustomer";
import { useUser } from "~hooks";

const Navigation = () => {
  const { isPaymentsCustomer } = useUser();

  if (isPaymentsCustomer) {
    return <NavigationForPaymentsCustomer />;
  }

  return <NavigationForPropertiesCustomer />;
};

export default Navigation;
