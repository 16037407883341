import { t } from "@lingui/macro";

import { getYear } from "date-fns";

import FieldCore, { Props as FieldCoreProps } from "../FieldCore";

export type Props = Omit<FieldCoreProps, "type" | "placeholder">;

export const Year = ({ min, max, ...rest }: Props) => {
  const thisYear = getYear(new Date());
  const minNumber = typeof min === "string" ? parseInt(min, 10) : min;
  const maxNumber = typeof max === "string" ? parseInt(max, 10) : max;
  const minYear = minNumber || thisYear;
  const maxYear = maxNumber || minYear + 31;
  const numYears = maxYear - minYear;
  const numbers = Array.from(Array(numYears).keys());
  const years = numbers.map((n) => n + minYear);
  return (
    <FieldCore type="select" placeholder={t`Choose year`} {...rest}>
      {years.map((year) => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}
    </FieldCore>
  );
};

export default Year;
