import { useEffect, useState } from "react";

import Router from "next/router";

function updateUrlWithParam(
  url: string,
  param: { key: string; value?: string }
) {
  const { key, value } = param;
  const [path, search] = url.split("?");
  const params = new URLSearchParams(search);

  if (value) {
    params.set(key, value);
  } else {
    params.delete(key);
  }

  const paramsString = params.toString();
  const searchQuery = paramsString.length > 0 ? `?${paramsString}` : "";
  return `${path}${searchQuery}`;
}

function useQueryParam<Value>(
  key: string,
  options: {
    initialValue?: Value;
    persistWhileMounted?: boolean;
  } = { persistWhileMounted: false }
) {
  const { initialValue, persistWhileMounted } = options;
  const [value, setValue] = useState<Value | undefined | "">(initialValue);

  // We want to carry over the query params from the current page to the next when navigating.
  useEffect(() => {
    const params = Router.asPath.split("?")[1];
    if (params) {
      const val = new URLSearchParams(`?${params}`).get(key);
      // @ts-ignore FIXME not sure how to type this properly
      setValue(val);
    } else {
      // setValue(undefined);
    }
  }, [Router.asPath]);

  useEffect(() => {
    if (!persistWhileMounted) {
      return;
    }

    const handleRouteChange = (url: string) => {
      const { replace, pathname, asPath } = Router;
      if (url.indexOf(key) === -1 && value) {
        if (asPath.indexOf("?") !== -1) {
          replace(pathname, `${asPath}&${key}=${value}`, { shallow: true });
        } else {
          replace(pathname, `${asPath}?${key}=${value}`, { shallow: true });
        }
      }
    };

    Router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      Router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [value]);

  const onSetValue = (value: Value | undefined) => {
    const { asPath, replace, pathname } = Router;
    // @ts-ignore FIXME not sure how to type this properly
    const updatedUrl = updateUrlWithParam(asPath, { key, value });
    if (asPath !== updatedUrl) {
      replace(pathname, updatedUrl);
    }
    setValue(value);
  };

  return [value, onSetValue] as const;
}

export default useQueryParam;
