import styled from "styled-components";

import {
  baseUnit,
  danger500,
  primary500,
  success500,
  white,
} from "../../tokens";
import { variants } from "../../utils";

import { Status } from "./types";

const backgroundColor = variants("status", {
  default: { default: white },
  info: { default: primary500 },
  error: { default: danger500 },
  success: { default: success500 },
});

type InnerProps = {
  status: Status;
};
const Notification = styled.div<InnerProps>`
  box-sizing: border-box;
  overflow: auto;
  background-color: ${backgroundColor};
  padding: ${baseUnit / 1.5}px ${baseUnit}px;
  display: flex;
  align-items: center;
  min-height: 44px;

  &:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

export default Notification;
