import { t } from "@lingui/macro";

import FieldCore, { Props as FieldCoreProps } from "../FieldCore";

const validations = {
  DK: {
    pattern: {
      // taken from https://rgxdb.com/r/335G4K1S
      value: /^(?:[1-24-9]\d{3}|3[0-8]\d{2})$/,
      message: t`Please provide a Danish postal code (e.g. 1234)`,
    },
  },
  NL: {
    pattern: {
      value: /^[1-9][0-9]{3} (?!sa|sd|ss)[a-z]{2}$/i,
      message: t`Please provide a Dutch postal code (e.g. 1234 AB)`,
    },
  },
  DE: {
    pattern: {
      value: /^(?:\d{5})$/,
      message: t`Please provide a German postal code (e.g. 12345)`,
    },
  },
};

export type Props = Omit<FieldCoreProps, "type" | "validation"> & {
  countryCode: "DK" | "NL" | "DE";
};
const PostalCode = ({ countryCode, ...rest }: Props) => {
  const validation = validations[countryCode];
  return <FieldCore type="text" validation={validation} {...rest} />;
};

export default PostalCode;
