import styled from "styled-components";

import { MenuSection, white } from "~components/copied_from_shared/pui";

import Company from "./Company";
import { Company as CompanyType } from "./types";

const Wrapper = styled.div`
  max-height: 50vh;
  background: ${white};
  width: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: auto;
`;

export type Props = {
  companies: CompanyType[];
  selectedCompanyId?: string;
  onChangeCompany: (id: string) => void;
};
const ViewAs = ({ onChangeCompany, selectedCompanyId, companies }: Props) => {
  return (
    <Wrapper>
      <MenuSection>
        {companies.map((company) => {
          return (
            <Company
              key={company.id}
              company={company}
              isActive={company.id === selectedCompanyId}
              onChangeCompany={onChangeCompany}
            />
          );
        })}
      </MenuSection>
    </Wrapper>
  );
};

export default ViewAs;
