import { useState } from "react";

import { TypeSafeApiRoute } from "next-type-safe-routes";
import useSWR, { SWRConfiguration } from "swr";

import { HttpError, NotFoundError, UnauthorizedError } from "~errors";
import { fetch, getRequestUrl, reportError } from "~utils";

import useUser from "./useUser";
import { useNotifications } from ".";

import { isDevelopment } from "~constants";

type UpdateStatus = "updating" | "failed" | "idle";
type DeleteStatus = "deleting" | "failed" | "idle";
type PostStatus = "creating" | "failed" | "idle";
export type ResourceStatus =
  | "uninitialized"
  | "pending"
  | "not-found"
  | "get-error"
  | "idle"
  | "validating"
  | "deleting"
  | "delete-failed"
  | "updating"
  | "update-failed"
  | "creating"
  | "post-failed";

type ResourceStatusProps = {
  route: TypeSafeApiRoute | null;
  data?: any;
  error?: any;
  updateStatus: UpdateStatus;
  isValidating: boolean;
  deleteStatus: DeleteStatus;
  postStatus: PostStatus;
};
function getResourceStatus({
  route,
  data,
  error,
  updateStatus,
  isValidating,
  deleteStatus,
  postStatus,
}: ResourceStatusProps): ResourceStatus {
  if (!route) {
    return "uninitialized";
  } else if (error) {
    return error instanceof HttpError && error instanceof NotFoundError
      ? "not-found"
      : "get-error";
  } else if (!data) {
    return "pending";
  } else if (updateStatus === "updating") {
    return "updating";
  } else if (updateStatus === "failed") {
    return "update-failed";
  } else if (deleteStatus === "deleting") {
    return "deleting";
  } else if (deleteStatus === "failed") {
    return "delete-failed";
  } else if (postStatus === "creating") {
    return "creating";
  } else if (postStatus === "failed") {
    return "post-failed";
  } else if (isValidating) {
    return "validating";
  }

  return "idle";
}

type NotificationMessages = {
  success: string;
  error: string;
  onSuccess?: () => void;
  onError?: () => void;
};

function useResource<Data, SaveableData = undefined>(
  typeSafeRoute: TypeSafeApiRoute | [TypeSafeApiRoute, RequestInit] | null,
  swrConfig: SWRConfiguration = {}
) {
  const [route, requestInit] =
    typeSafeRoute instanceof Array ? typeSafeRoute : [typeSafeRoute, undefined];
  const { viewAsUser, authUser } = useUser();
  const [updateStatus, setUpdateStatus] = useState<UpdateStatus>("idle");
  const [deleteStatus, setDeleteStatus] = useState<DeleteStatus>("idle");
  const [postStatus, setPostStatus] = useState<PostStatus>("idle");
  const notifications = useNotifications();
  const requestUserId = viewAsUser.id;
  const authorizedUserId = authUser.id;
  const path = route
    ? getRequestUrl({ route, requestUserId, authorizedUserId })
    : null;
  const { data, error, mutate, isValidating } = useSWR<Data, any>(
    path ? [path, requestInit] : null,
    fetch,
    {
      onErrorRetry: (error, key, option, revalidate, { retryCount }) => {
        const count = retryCount || 0;
        if (count >= 10) return;
        if (error instanceof NotFoundError) {
          return;
        }
        if (error instanceof UnauthorizedError) {
          return;
        }
        setTimeout(() => revalidate({ retryCount: count + 1 }), 3000);
      },
      refreshInterval: isDevelopment ? undefined : 3000,
      ...swrConfig,
    }
  );

  const status = getResourceStatus({
    route,
    data,
    error,
    updateStatus,
    isValidating,
    deleteStatus,
    postStatus,
  });

  const pushErrorNotification = (text: string, err: any, payload?: any) => {
    const message = err.messages?.length ? err.messages.join(". ") : null;
    notifications.push({
      type: "error",
      text,
      errorDetails: {
        code: err.code,
        requestId: err.requestId,
        reason: message || err.reason,
        payload: payload || {},
        endpoint: path || "",
      },
    });
  };

  const post = async (
    newData: SaveableData | Data,
    messages: NotificationMessages
  ) => {
    if (!path) {
      throw new Error("useResource: path not specified");
    }
    try {
      setPostStatus("creating");
      const responseData = await fetch(path, {
        method: "POST",
        body: JSON.stringify(newData),
      });
      mutate(); // tell swr to revalidate
      notifications.push({ type: "success", text: messages.success });
      messages.onSuccess && messages.onSuccess();
      setPostStatus("idle");
      return responseData;
    } catch (err: any) {
      reportError(err);
      messages.onError && messages.onError();
      setPostStatus("failed");
      pushErrorNotification(messages.error, err, newData);
    }
  };

  const put = async (
    newData: SaveableData | Data,
    messages: NotificationMessages
  ) => {
    if (!path) {
      throw new Error("useResource: path not specified");
    }
    try {
      setUpdateStatus("updating");
      const responseData = await fetch(path, {
        method: "PUT",
        body: JSON.stringify(newData),
      });
      mutate(); // tell swr to revalidate
      notifications.push({ type: "success", text: messages.success });
      messages.onSuccess && messages.onSuccess();
      setUpdateStatus("idle");
      return responseData;
    } catch (err: any) {
      reportError(err);
      setUpdateStatus("failed");
      messages.onError && messages.onError();
      pushErrorNotification(messages.error, err, newData);
    }
  };

  // Use to *modify* the resource
  const patch = async (
    newData: SaveableData | Partial<Data>,
    messages: NotificationMessages
  ) => {
    if (!path) {
      throw new Error("useResource: path not specified");
    }

    try {
      setUpdateStatus("updating");
      const responseData = await fetch(path, {
        method: "PATCH",
        body: JSON.stringify(newData),
      });
      data && mutate({ ...data, ...newData });
      notifications.push({ type: "success", text: messages.success });
      messages.onSuccess && messages.onSuccess();
      setUpdateStatus("idle");
      return responseData;
    } catch (err: any) {
      reportError(err);
      setUpdateStatus("failed");
      messages.onError && messages.onError();
      pushErrorNotification(messages.error, err, newData);
    }
  };

  const remove = async (messages: NotificationMessages) => {
    if (!path) {
      throw new Error("useResource: path not specified");
    }
    try {
      setDeleteStatus("deleting");
      const responseData = await fetch(path, { method: "DELETE" });
      notifications.push({ type: "success", text: messages.success });
      messages.onSuccess && messages.onSuccess();
      setDeleteStatus("idle");
      return responseData;
    } catch (err: any) {
      reportError(err);
      setDeleteStatus("failed");
      messages.onError && messages.onError();
      pushErrorNotification(messages.error, err);
    }
  };

  return [
    data,
    {
      post,
      put,
      patch,
      remove,
      resourceStatus: status,
      error,
      mutate,
    },
  ] as const;
}

export default useResource;
