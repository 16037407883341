import Button, { Props as ButtonProps } from "../../Button";

type SubmitButton = ButtonProps;
const SubmitButton = ({ children, look = "primary", ...rest }: ButtonProps) => (
  <Button {...rest} look={look} type="submit">
    {children}
  </Button>
);

export default SubmitButton;
