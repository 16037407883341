import styled from "styled-components";

import { baseUnit } from "~components/copied_from_shared/pui";

export const pageMaxWidth = 1100;
export const pagePaddingHorizontal = baseUnit * 2;

const Wrapper = styled.div`
  max-width: ${pageMaxWidth}px;
  margin: 0 auto;
  padding: ${baseUnit * 8}px ${pagePaddingHorizontal}px;
`;

const Inner = styled.div`
  display: flex;
`;

const Content = styled.div`
  flex: 1;
`;

type Props = {
  children: any;
};
const Page = ({ children }: Props) => (
  <Wrapper>
    <Inner>
      <Content>{children}</Content>
    </Inner>
  </Wrapper>
);

export default Page;
