import styled from "styled-components";

import Link from "~components/connected/Link";
import {
  baseUnit,
  Button,
  danger500,
  pampas300,
  Paragraph,
} from "~components/copied_from_shared/pui";
import Loader from "~components/Loader";
import Logo from "~components/Logo";

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  > *:not(:last-child) {
    margin-bottom: ${baseUnit * 2}px;
  }
`;

const LogoutWrapper = styled.div`
  position: absolute;
  top: ${baseUnit}px;
  right: ${baseUnit}px;
`;

type Props = {
  isError?: boolean;
};
const AppLoader = ({ isError }: Props) => {
  return (
    <Wrapper>
      <Logo fill={isError ? danger500 : pampas300} />
      {isError ? (
        <>
          <Paragraph look="negative">
            There was a problem loading the application.
          </Paragraph>
          <LogoutWrapper>
            <Link to="/api/auth/logout">
              <Button>Logout</Button>
            </Link>
          </LogoutWrapper>
        </>
      ) : (
        <Loader />
      )}
    </Wrapper>
  );
};

export default AppLoader;
