import { cloneElement, forwardRef, ReactElement } from "react";

import {
  Menu as ReakitMenu,
  MenuButton as ReakitMenuButton,
  useMenuState as useReakitMenuState,
} from "reakit/Menu";
import styled from "styled-components";

import { baseUnit, white } from "../../tokens";

import { Provider } from "./context";

export const borderRadius = "6px";

export const defaultOptions = { gutter: baseUnit, loop: true };

const Wrapper = styled.div`
  display: flex;
`;

const PuiMenu = styled(ReakitMenu)`
  z-index: 999;
  background-color: ${white};
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  display: inline-flex;
  flex-direction: column;
  border-radius: ${borderRadius};
  align-items: flex-start;
`;

type Props = {
  trigger: ReactElement;
  children: any;
  ariaLabel: string;
  state?: any;
};
function Menu(
  { trigger, children, ariaLabel, state, ...rest }: Props,
  ref: any
) {
  const menu = state || useReakitMenuState(defaultOptions);

  return (
    <Wrapper ref={ref} {...rest}>
      <ReakitMenuButton {...menu} {...trigger.props}>
        {(disclosureProps) => cloneElement(trigger, disclosureProps)}
      </ReakitMenuButton>
      <PuiMenu {...menu} aria-label={ariaLabel}>
        <Provider value={{ ...menu }}>{children}</Provider>
      </PuiMenu>
    </Wrapper>
  );
}

export const useMenuState = useReakitMenuState;
export default forwardRef(Menu);
