import { t } from "@lingui/macro";

import FieldCore, { Props as FieldCoreProps } from "../FieldCore";

const validations = {
  DK: {
    pattern: {
      value:
        /^((baghuset|mezz|mezzanin|parterre|st|kl|\d+)\.?)?( *)((mf|tv|th|\d+)\.?)?( *)$/,
      message: t`Please provide a Danish floor/door number.`,
    },
  },
  NL: {
    pattern: {
      value: /^[^,]+$/,
      message: t`Please provide a value without comma.`,
    },
  },
  DE: {
    pattern: {
      value: /^[^,]+$/,
      message: t`Please provide a value without comma.`,
    },
  },
};

export type Props = Omit<FieldCoreProps, "type"> & {
  countryCode: "DK" | "NL" | "DE";
};

export const FloorDoor = ({ countryCode, ...rest }: Props) => {
  const validation = validations[countryCode];
  return (
    <FieldCore
      {...rest}
      type="text"
      validation={validation}
      placeholder={t`Enter floor/door`}
    ></FieldCore>
  );
};

export default FloorDoor;
