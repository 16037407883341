import styled from "styled-components";

import { fontSmoothing } from "../../mixins";
import {
  baseUnit,
  borderColorDiscreet,
  fontFamilySans,
  fontWeightBold,
  textColor,
} from "../../tokens";

import { menuItemPaddingHorizontal } from "./MenuItem";

const Wrapper = styled.div`
  width: 100%;

  &:not(:last-child) {
    border-bottom: 1px solid ${borderColorDiscreet};
  }
`;

const Label = styled.div`
  ${fontSmoothing};
  font-family: ${fontFamilySans};
  font-weight: ${fontWeightBold};
  background-color: ${borderColorDiscreet};
  font-size: 13px;
  padding: 5px ${menuItemPaddingHorizontal}px;
  width: 100%;
  margin-top: ${baseUnit / 2}px;
  color: ${textColor};
`;

type Props = {
  children: any;
  label?: string;
};
const MenuSection = ({ children, label }: Props) => {
  if (label) {
    return (
      <>
        <Label>{label}</Label>
        <Wrapper>{children}</Wrapper>
      </>
    );
  }

  return <Wrapper>{children}</Wrapper>;
};

export default MenuSection;
