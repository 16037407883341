import { Feature, Features, UserRole } from "./types";

export const getUserHasRequiredRole = (
  feature: Feature,
  userRoles: UserRole[]
) => {
  return !!feature.enabledForUserRoles.filter((x) => userRoles.includes(x))
    .length;
};

export const getFeaturesAvailableForUser = (
  features: Features,
  userRoles: UserRole[]
): Features => {
  const availablefeatures: { [key: string]: Feature } = {};

  Object.keys(features).forEach((featureName) => {
    const feature = features[featureName];
    if (getUserHasRequiredRole(feature, userRoles)) {
      availablefeatures[featureName] = feature;
    }
  });

  return availablefeatures;
};
