import { t } from "@lingui/macro";

import FieldCore, { Props as FieldCoreProps } from "../FieldCore";

const validation = {
  pattern: {
    value: /^\d{14}$/,
    message: t`Please provide a Danish BBAN number. These are exactly 14 digits.`,
  },
};

export type Props = Omit<FieldCoreProps, "type" | "validation">;
const BBAN = ({ ...rest }: Props) => {
  return <FieldCore type="text" validation={validation} {...rest} />;
};

export default BBAN;
