import { t } from "@lingui/macro";

import styled from "styled-components";

import {
  baseUnit,
  borderColor,
  fontStack,
  textColorDiscreet,
  Tooltip,
} from "~components/copied_from_shared/pui";
import { useCopyToClipboard } from "~hooks";

export const Wrapper = styled.span<{ fontSize?: string }>`
  ${fontStack};
  cursor: copy;
  display: inline-flex;
  font-weight: normal;
  border: 1px solid ${borderColor};
  margin-left: ${baseUnit}px;
  color: ${textColorDiscreet};
  border-radius: 6px;
  padding: ${baseUnit / 3}px ${baseUnit}px;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "0.85em")};
`;

type Props = {
  children: string;
  fontSize?: string;
};
const Identifier = ({ children, fontSize }: Props) => {
  const [showDidCopyToClipboard, onCopyToClipboard] = useCopyToClipboard();
  return (
    <Tooltip title={showDidCopyToClipboard ? t`Copied!` : t`Copy to clipboard`}>
      <Wrapper
        tabIndex={0}
        role="button"
        fontSize={fontSize}
        onClick={(e: any) => {
          e.stopPropagation();
          onCopyToClipboard(children);
        }}
      >
        {children}
      </Wrapper>
    </Tooltip>
  );
};

export default Identifier;
