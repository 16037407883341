import styled from "styled-components";

import { fontStack } from "../../../mixins";
import { baseUnit, blue100, pampas100 } from "../../../tokens";

type LiProps = {
  isHighlighted?: boolean;
  isSelected?: boolean;
};
const MenuItem = styled.li<LiProps>`
  ${fontStack};
  font-size: 15px;
  background-color: ${({ isHighlighted, isSelected }: any) => {
    if (isHighlighted) {
      return pampas100;
    } else if (isSelected) {
      return blue100;
    }
    return "initial";
  }};
  padding: 0 ${baseUnit}px;
  line-height: 40px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: ${pampas100};
  }
`;

export default MenuItem;
