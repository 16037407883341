import { useState } from "react";

import NumberFormat from "react-number-format";

import { TextField } from "~components/copied_from_shared/pui";

import FieldCore, { Props as FieldCoreProps } from "../FieldCore";

const SquareMetersField = ({ ...rest }) => {
  return <TextField postfix="m²" {...rest} />;
};

const Input = ({ onChange, value, ...rest }: any) => {
  const [localValue, setLocalValue] = useState<number | undefined>(
    parseFloat(value)
  );

  return (
    <NumberFormat
      {...rest}
      value={localValue}
      customInput={SquareMetersField}
      onValueChange={(target) => {
        setLocalValue(target.floatValue);
        onChange(target.floatValue ? `${target.floatValue} m²` : "");
      }}
      decimalScale={1}
      allowNegative={false}
    />
  );
};

export type Props = Omit<FieldCoreProps, "type">;

const Money = (props: Props) => {
  return <FieldCore {...props} as={Input} />;
};

export default Money;
