import { t } from "@lingui/macro";

import Downshift from "downshift";

import {
  ClearSelectionButton,
  Id,
  Li,
  Text,
  Ul,
  Wrapper,
} from "./StyledComponents";

function highlightLetters(value: string, inputValue: string) {
  const letters = value.split("");
  let markup = "";
  letters.forEach((l) => {
    if (inputValue.toLowerCase().indexOf(l.toLowerCase()) !== -1) {
      markup += `<strong>${l}</strong>`;
    } else {
      markup += l;
    }
  });

  return <span dangerouslySetInnerHTML={{ __html: markup }} />;
}

type ItemProps = {
  item: any;
  index: number;
  highlightedIndex: number | null;
  selectedItem: any;
  getItemProps: any;
  inputValue: string;
  dataTestId?: string;
};
const Item = ({
  item,
  index,
  highlightedIndex,
  selectedItem,
  getItemProps,
  inputValue,
  dataTestId,
}: ItemProps) => {
  const props = getItemProps({ key: item.value, index, item });
  const isSelected = selectedItem && selectedItem.value === item.value;
  return (
    <Li
      isHighlighted={highlightedIndex === index}
      isSelected={isSelected}
      data-test-id={dataTestId}
      {...props}
    >
      <Text>{highlightLetters(item.value, inputValue)}</Text>
      <Id>{highlightLetters(item.id, inputValue)}</Id>
    </Li>
  );
};

type ItemsProps = {
  items: any;
  inputValue: string | null;
  highlightedIndex: number | null;
  selectedItem: any;
  getItemProps: any;
};
const Items = ({
  items,
  inputValue,
  getItemProps,
  selectedItem,
  highlightedIndex,
}: ItemsProps) => {
  return items.map((item: any, index: any) => (
    <Item
      key={item.id}
      item={item}
      index={index}
      inputValue={inputValue || ""}
      highlightedIndex={highlightedIndex}
      selectedItem={selectedItem}
      getItemProps={getItemProps}
      dataTestId={"li-landlord-downshift-menu-item-" + item.id}
    />
  ));
};

const handleFocus = (e: any) => e.target.select();

export type Props = {
  onStateChange: (search: any) => void;
  onChange: (item: any) => void;
  inputValue?: string;
  Input: any;
  items: {
    value?: string;
  }[];
};

const AutoComplete = ({
  Input,
  items,
  onChange,
  inputValue,
  onStateChange,
  ...rest
}: Props) => (
  <Downshift
    onStateChange={onStateChange}
    onChange={onChange}
    inputValue={inputValue}
    itemToString={(item) => item?.value || ""}
  >
    {(downshiftProps) => {
      const {
        getInputProps,
        getMenuProps,
        getRootProps,
        isOpen,
        openMenu,
        clearSelection,
        ...itemsProps
      } = downshiftProps;
      const { inputValue } = itemsProps;
      const menuProps = getMenuProps();
      const rootProps = getRootProps({} as any, { suppressRefError: true });
      const inputProps = getInputProps({
        onFocus: (e: any) => {
          handleFocus(e);
          inputValue && openMenu();
        },
      });
      return (
        <Wrapper>
          <div
            style={{ display: "inline-block", position: "relative" }}
            {...rootProps}
          >
            <Input
              placeholder={t`Type in company name`}
              {...rest}
              {...inputProps}
            />
            {inputValue && (
              <ClearSelectionButton onClick={() => clearSelection()} />
            )}
          </div>
          <Ul {...menuProps} data-test-id="ul-landlord-downshift-menu">
            {isOpen ? <Items items={items} {...itemsProps} /> : null}
          </Ul>
        </Wrapper>
      );
    }}
  </Downshift>
);

export default AutoComplete;
