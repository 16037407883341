import { createContext } from "react";

import { Notice } from "~types/notice";

export type ContextType = {
  notices: Notice[];
};

export const Context = createContext<ContextType>({
  notices: [],
});

export const Provider = Context.Provider;
