import { forwardRef } from "react";

import styled, { css } from "styled-components";

import {
  backgroundColorInput,
  backgroundColorInputFilled,
  borderColorInput,
  borderColorInputFocus,
  borderColorInputHover,
  primary500,
  sizeCheckboxAndRadio,
  support500,
  textColorPlaceholder,
} from "../../../tokens";
import { variant, variants } from "../../../utils";

const backgroundColorEnabled = variant(primary500, { support: support500 });

const backgroundColor = variants("filled", {
  default: { default: backgroundColorInput },
  filled: { default: backgroundColorInputFilled },
});

type InputProps = {
  filled: "filled" | "default";
  readOnly?: boolean;
};
const Input = styled.input<InputProps>`
  appearance: none;
  position: relative;
  box-sizing: border-box;
  color: transparent;
  background-color: ${backgroundColor};
  border: 1px solid ${borderColorInput};
  border-radius: 3px;
  height: ${sizeCheckboxAndRadio}px;
  width: ${sizeCheckboxAndRadio}px;
  min-height: ${sizeCheckboxAndRadio}px;
  min-width: ${sizeCheckboxAndRadio}px;
  cursor: pointer;
  margin: 0;

  &:focus {
    border-color: ${borderColorInputFocus};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover:enabled {
    color: ${textColorPlaceholder};
    border-color: ${borderColorInputHover};
  }

  &:active:enabled,
  &:checked {
    background-color: ${backgroundColorEnabled};
    border-color: ${backgroundColorEnabled};

    &::after {
      content: "";
      top: 0;
      left: 5px;
      position: absolute;
      display: block;
      transform: rotate(45deg);
      height: 14px;
      width: 7px;
      border-bottom: 2px solid white;
      border-right: 2px solid white;
    }
  }

  ${({ readOnly }) =>
    readOnly &&
    css`
      pointer-events: none;
      background-color: transparent !important;
      border-color: transparent !important;

      &::after {
        content: "";
        border-color: transparent !important;
        border-bottom: 2px solid transparent;
        width: 12px;
        display: block;
        top: 8px;
        left: 3px;
        position: absolute;
      }
    `}
`;

export type Props = {
  id?: string;
  required?: boolean;
  defaultChecked?: boolean;
  onChange?: (e: any) => void;
  filled?: boolean;
  checked?: boolean;
  readOnly?: boolean;
};

/**
 * Use the `Checkbox` component together with the `CheckboxGroup`
 * component to present multiple items and require users to
 * pick only one thing from a list.
 */
const Checkbox = ({ filled, checked, readOnly, ...rest }: Props, ref: any) => {
  return (
    <Input
      {...rest}
      readOnly={readOnly}
      checked={checked}
      type="checkbox"
      filled={filled ? "filled" : "default"}
      ref={ref}
    />
  );
};

export default forwardRef(Checkbox);
