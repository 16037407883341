import { useUser } from "~hooks";

import Menu from "./Menu";

function MenuContainer() {
  const { authUser } = useUser();
  return <Menu user={authUser} />;
}

export default MenuContainer;
