export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Query = {
  __typename?: "Query";
  paperContract?: Maybe<PaperContract>;
  fetchDocuments: Array<Document>;
  tenanciesForPaperContract: Array<TenancyIdAndAddress>;
  listParagraph11Clauses: Array<Paragraph11LibraryClause>;
  listParagraph11ClausesByIds: Array<Paragraph11LibraryClause>;
  tenancyArchivability: TenancyArchivability;
  portfolio?: Maybe<Array<Person>>;
  companies?: Maybe<Array<Person>>;
  shouldCustomerTakeSurvey: CustomerSurveyStatus;
};

export type QueryFetchFutureChargeDatesArgs = {
  leaseId: Scalars["String"];
};

export type QueryPaperContractArgs = {
  paperContractId: Scalars["String"];
};

export type QueryFetchDocumentsArgs = {
  documentIds: Array<Scalars["String"]>;
};

export type QueryTenanciesForPaperContractArgs = {
  ownerId: Scalars["String"];
};

export type QueryListParagraph11ClausesArgs = {
  ownerId: Scalars["String"];
};

export type QueryListParagraph11ClausesByIdsArgs = {
  ownerId: Scalars["String"];
  paragraph11Ids: Array<Scalars["String"]>;
};

export type QueryTenancyArchivabilityArgs = {
  tenancyId: Scalars["String"];
};

export type QueryPortfolioArgs = {
  personId: Scalars["String"];
};

export type QueryShouldCustomerTakeSurveyArgs = {
  customerId: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  addCharge: NewChargeResponse;
  createTenancy: NewTenancyResponse;
  createPaperContractOnLease: PaperContractId;
  removePaperContractFromLease?: Maybe<PaperContractId>;
  updatePaperContractData?: Maybe<PaperContract>;
  markPaperContractSigned?: Maybe<PaperContractSigned>;
  markPaperContractAsReadyToSign: PaperContractReadyToSign;
  deleteDocument: Scalars["String"];
  registerProactiveFeedback: ProactiveFeedback;
  registerSurveyFeedback: ProactiveFeedback;
  registerSurveyDismissed: SurveyDismissed;
  createNewParagraph11Clause: NewParagraph11ClauseCreated;
  archiveTenancy: ArchivedTenancy;
  archiveParagraph11Clause: ArchiveParagraph11ClauseResponse;
  assignPersonsToPortfolio: Scalars["String"];
  updatePerson: Person;
  editParagraph11Clause: Paragraph11ClauseUpdated;
};

export type MutationAddChargeArgs = {
  leaseId?: InputMaybe<Scalars["String"]>;
  charge: SavableCharge;
};

export type MutationCreateTenancyArgs = {
  ownerId: Scalars["String"];
  address: TenancyAddress;
};

export type MutationCreatePaperContractOnLeaseArgs = {
  leaseId: Scalars["String"];
  paperContractType: Scalars["String"];
};

export type MutationRemovePaperContractFromLeaseArgs = {
  leaseId: Scalars["String"];
};

export type MutationUpdatePaperContractDataArgs = {
  paperContractId: Scalars["String"];
  paperContractData: PaperContractDk1TemplateInput;
};

export type MutationMarkPaperContractSignedArgs = {
  paperContractId: Scalars["String"];
  documentId: Scalars["String"];
};

export type MutationMarkPaperContractAsReadyToSignArgs = {
  paperContractId: Scalars["String"];
  handledByProper: Scalars["Boolean"];
  renderingState: RenderingStateInput;
  signee?: InputMaybe<PaperContractSigneeInput>;
};

export type MutationDeleteDocumentArgs = {
  documentId: Scalars["String"];
};

export type MutationRegisterProactiveFeedbackArgs = {
  customerId: Scalars["String"];
  companyId: Scalars["String"];
  satisfaction: Satisfaction;
  comment?: InputMaybe<Scalars["String"]>;
};

export type MutationRegisterSurveyFeedbackArgs = {
  customerId: Scalars["String"];
  companyId: Scalars["String"];
  satisfaction?: InputMaybe<Satisfaction>;
  disappointment?: InputMaybe<Disappointment>;
  primaryBenefit?: InputMaybe<Scalars["String"]>;
  nextFeatures?: InputMaybe<Scalars["String"]>;
};

export type MutationRegisterSurveyDismissedArgs = {
  customerId: Scalars["String"];
};

export type MutationCreateNewParagraph11ClauseArgs = {
  newClause: NewParagraph11ClauseInput;
};

export type MutationArchiveTenancyArgs = {
  tenancyId: Scalars["String"];
};

export type MutationArchiveParagraph11ClauseArgs = {
  paragraph11ClauseId: Scalars["String"];
};

export type MutationAssignPersonsToPortfolioArgs = {
  companyIds: Array<Scalars["String"]>;
  portfolioAlias?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdatePersonArgs = {
  input: UpdatePersonInput;
};

export type MutationEditParagraph11ClauseArgs = {
  updatedClause: UpdatedParagraph11ClauseInput;
};

export type TenancyAddress = {
  street: Scalars["String"];
  number: Scalars["String"];
  floor?: InputMaybe<Scalars["String"]>;
  door?: InputMaybe<Scalars["String"]>;
  suite?: InputMaybe<Scalars["String"]>;
  postal: Scalars["String"];
  city: Scalars["String"];
  country: Scalars["String"];
};

export type PaperContractDk1TemplateInput = {
  isSublet?: InputMaybe<Scalars["Boolean"]>;
  moveInDate?: InputMaybe<Scalars["String"]>;
  taxAndFeesDate?: InputMaybe<Scalars["String"]>;
  rent?: InputMaybe<RentInput>;
  deposit?: InputMaybe<AdvancePaymentInput>;
  prepaidRent?: InputMaybe<AdvancePaymentInput>;
  firstMonthPayment?: InputMaybe<FirstMonthPaymentInput>;
  tenancyDetails?: InputMaybe<TenancyDetailsInput>;
  tenants?: InputMaybe<Array<InputMaybe<TenantInput>>>;
  amenities?: InputMaybe<AmenitiesInput>;
  tenancyUsedFor?: InputMaybe<Scalars["String"]>;
  heating?: InputMaybe<HeatingUtilityInput>;
  water?: InputMaybe<WaterUtilityInput>;
  electricity?: InputMaybe<ElectricityUtilityInput>;
  cooling?: InputMaybe<CoolingUtilityInput>;
  tv?: InputMaybe<TvUtilityInput>;
  internet?: InputMaybe<InternetUtilityInput>;
  other1?: InputMaybe<OtherContributionsInput>;
  other2?: InputMaybe<OtherContributionsInput>;
  hasMoveInReport?: InputMaybe<Scalars["Boolean"]>;
  maintenance?: InputMaybe<MaintenanceInput>;
  inventory?: InputMaybe<InventoryInput>;
  residentsRepresentative?: InputMaybe<ResidentsRepresentativeInput>;
  petAllowed?: InputMaybe<Scalars["Boolean"]>;
  hasHouseRules?: InputMaybe<Scalars["Boolean"]>;
  paragraph10?: InputMaybe<Scalars["String"]>;
  paragraph11Terms?: InputMaybe<Array<Paragraph11TermInput>>;
  attachmentIds?: InputMaybe<Array<Scalars["String"]>>;
  paragraph11Clauses: Array<Paragraph11ClauseInput>;
  paragraph11Ids?: InputMaybe<Array<Scalars["String"]>>;
};

export type PaperContractSigneeInput = {
  email: Scalars["String"];
  name: Scalars["String"];
};

export type RentInput = {
  amount?: InputMaybe<Scalars["String"]>;
  paymentInterval?: InputMaybe<RentPaymentInterval>;
  dayOfInterval?: InputMaybe<Scalars["Int"]>;
};

export type ResidentsRepresentativeInput = {
  amount?: InputMaybe<Scalars["String"]>;
  hasResidentsRepresentative?: InputMaybe<Scalars["Boolean"]>;
};

export type AdvancePaymentInput = {
  interval?: InputMaybe<AdvancePaymentInterval>;
  dueDate?: InputMaybe<Scalars["String"]>;
};

export type FirstMonthPaymentInput = {
  rent?: InputMaybe<Scalars["String"]>;
  consumption?: InputMaybe<ConsumptionAmountsInput>;
  daysLeftTotalDaysRatio?: InputMaybe<Scalars["Float"]>;
  isRentCustom: Scalars["Boolean"];
  isConsumptionCustom: Scalars["Boolean"];
  other1?: InputMaybe<OneTimeChargeInput>;
  other2?: InputMaybe<OneTimeChargeInput>;
  other3?: InputMaybe<OneTimeChargeInput>;
};

export type ConsumptionAmountsInput = {
  heating?: InputMaybe<Scalars["String"]>;
  water?: InputMaybe<Scalars["String"]>;
  electricity?: InputMaybe<Scalars["String"]>;
  cooling?: InputMaybe<Scalars["String"]>;
  tv?: InputMaybe<Scalars["String"]>;
  internet?: InputMaybe<Scalars["String"]>;
  other1?: InputMaybe<Scalars["String"]>;
  other2?: InputMaybe<Scalars["String"]>;
  residentsRepresentative?: InputMaybe<Scalars["String"]>;
};

export type OneTimeChargeInput = {
  amount?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
};

export type InternationalAddressInput = {
  street?: InputMaybe<Scalars["String"]>;
  postalCode?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
};

export type AddressInput = {
  street?: InputMaybe<Scalars["String"]>;
  postalCode?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Country>;
};

export type TenancyDetailsInput = {
  numberOfRooms?: InputMaybe<Scalars["Int"]>;
  tenancyType?: InputMaybe<TenancyType>;
  customTenancyType?: InputMaybe<Scalars["String"]>;
  area?: InputMaybe<Scalars["Float"]>;
  businessArea?: InputMaybe<Scalars["Float"]>;
};

export type TenantInput = {
  name?: InputMaybe<Scalars["String"]>;
  address?: InputMaybe<InternationalAddressInput>;
  cvr?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  isCompany?: InputMaybe<Scalars["Boolean"]>;
  language?: InputMaybe<TenantLanguage>;
};

export type AmenitiesInput = {
  accessToCommonLaundry?: InputMaybe<Scalars["Boolean"]>;
  accessToCommonYard?: InputMaybe<Scalars["Boolean"]>;
  accessToBikeParking?: InputMaybe<Scalars["Boolean"]>;
  accessToLoftOrCellarRoom?: InputMaybe<Scalars["Boolean"]>;
  loftOrCellarRoomNumber?: InputMaybe<Scalars["String"]>;
  accessToGarage?: InputMaybe<Scalars["Boolean"]>;
  garageNumber?: InputMaybe<Scalars["String"]>;
  accessToOtherAmenity?: InputMaybe<Scalars["Boolean"]>;
  other?: InputMaybe<Scalars["String"]>;
};

export type HeatingUtilityInput = {
  amount?: InputMaybe<Scalars["String"]>;
  paidAs?: InputMaybe<UtilityPaymentModality>;
  accountingYearStart?: InputMaybe<Scalars["String"]>;
  landlordProvidedType?: InputMaybe<LandLordProvidedHeatingInput>;
  tenancyHeating?: InputMaybe<TenancyHeatingInput>;
};

export type LandLordProvidedHeatingInput = {
  electricity?: InputMaybe<Scalars["Boolean"]>;
  districtHeatingOrNaturalGas?: InputMaybe<Scalars["Boolean"]>;
  oil?: InputMaybe<Scalars["Boolean"]>;
  other?: InputMaybe<Scalars["Boolean"]>;
  otherType?: InputMaybe<Scalars["String"]>;
};

export type TenancyHeatingInput = {
  electricity?: InputMaybe<Scalars["Boolean"]>;
  districtHeatingOrNaturalGas?: InputMaybe<Scalars["Boolean"]>;
  oil?: InputMaybe<Scalars["Boolean"]>;
  gas?: InputMaybe<Scalars["Boolean"]>;
  other?: InputMaybe<Scalars["Boolean"]>;
  otherType?: InputMaybe<Scalars["String"]>;
};

export type WaterUtilityInput = {
  amount?: InputMaybe<Scalars["String"]>;
  paidAs?: InputMaybe<UtilityPaymentModality>;
  accountingYearStart?: InputMaybe<Scalars["String"]>;
  allocatedOnIndividualMeters?: InputMaybe<Scalars["Boolean"]>;
};

export type ElectricityUtilityInput = {
  amount?: InputMaybe<Scalars["String"]>;
  paidAs?: InputMaybe<UtilityPaymentModality>;
  accountingYearStart?: InputMaybe<Scalars["String"]>;
  allocatedOnIndividualMeters?: InputMaybe<Scalars["Boolean"]>;
};

export type CoolingUtilityInput = {
  amount?: InputMaybe<Scalars["String"]>;
  paidAs?: InputMaybe<UtilityPaymentModality>;
  accountingYearStart?: InputMaybe<Scalars["String"]>;
  allocatedOnIndividualMeters?: InputMaybe<Scalars["Boolean"]>;
};

export type TvUtilityInput = {
  amount?: InputMaybe<Scalars["String"]>;
  providingEntity?: InputMaybe<TvProvidingEntity>;
};

export type InternetUtilityInput = {
  amount?: InputMaybe<Scalars["String"]>;
  providedByLandlord?: InputMaybe<Scalars["Boolean"]>;
};

export type OtherContributionsInput = {
  amount?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type MaintenanceInput = {
  amount?: InputMaybe<Scalars["String"]>;
  landlordIsResponsible?: InputMaybe<Scalars["Boolean"]>;
};

export type InventoryInput = {
  hasStove?: InputMaybe<Scalars["Boolean"]>;
  hasFridge?: InputMaybe<Scalars["Boolean"]>;
  hasFreezer?: InputMaybe<Scalars["Boolean"]>;
  hasDishwasher?: InputMaybe<Scalars["Boolean"]>;
  hasWashingMachine?: InputMaybe<Scalars["Boolean"]>;
  hasTumbleDryer?: InputMaybe<Scalars["Boolean"]>;
  hasExhaust?: InputMaybe<Scalars["Boolean"]>;
  hasElectricConvectors?: InputMaybe<Scalars["Boolean"]>;
  electricConvectorsCount?: InputMaybe<Scalars["Int"]>;
  hasWaterHeaters?: InputMaybe<Scalars["Boolean"]>;
  waterHeatersCount?: InputMaybe<Scalars["Int"]>;
  hasOther1?: InputMaybe<Scalars["Boolean"]>;
  hasOther2?: InputMaybe<Scalars["Boolean"]>;
  hasOther3?: InputMaybe<Scalars["Boolean"]>;
  other1?: InputMaybe<Scalars["String"]>;
  other2?: InputMaybe<Scalars["String"]>;
  other3?: InputMaybe<Scalars["String"]>;
};

export type Paragraph11ClauseInput = {
  name?: InputMaybe<Scalars["String"]>;
  content?: InputMaybe<Scalars["String"]>;
};

export type Paragraph11TermInput = {
  id: Scalars["String"];
  title: Scalars["String"];
  content: Scalars["String"];
};

export type RenderingStateInput = {
  paragraph10?: InputMaybe<ExtraInfoParagraph>;
  paragraph11Part1?: InputMaybe<ExtraInfoParagraph11>;
  paragraph11Part2?: InputMaybe<ExtraInfoParagraph11>;
};

export type ExtraInfoParagraph = {
  isOverflowing: Scalars["Boolean"];
};

export type ExtraInfoParagraph11 = {
  isOverflowing: Scalars["Boolean"];
  clausesIndexes: Array<Scalars["Int"]>;
};

export type SavableCharge = {
  amount: Scalars["String"];
  chargeDate: Scalars["String"];
  description: Scalars["String"];
  descriptionText?: InputMaybe<Scalars["String"]>;
  count?: InputMaybe<Scalars["Int"]>;
  until?: InputMaybe<Scalars["String"]>;
};

export type UpdatePersonInput = {
  personId: Scalars["String"];
  portfolio?: InputMaybe<Scalars["String"]>;
};

export type NewParagraph11ClauseInput = {
  title: Scalars["String"];
  content: Scalars["String"];
  ownerId?: InputMaybe<Scalars["String"]>;
};

export type UpdatedParagraph11ClauseInput = {
  id: Scalars["String"];
  title: Scalars["String"];
  content: Scalars["String"];
};

export type NewChargeResponse = {
  __typename?: "NewChargeResponse";
  leaseId: Scalars["String"];
  chargeId: Scalars["String"];
};

export type NewTenancyResponse = {
  __typename?: "NewTenancyResponse";
  buildingId: Scalars["String"];
  unitId: Scalars["String"];
  tenancyId: Scalars["String"];
  leaseId: Scalars["String"];
};

export type FutureChargeDates = {
  __typename?: "FutureChargeDates";
  leaseId: Scalars["String"];
  futureChargeDates: Array<Maybe<FutureChargeDate>>;
};

export type FutureChargeDate = {
  __typename?: "FutureChargeDate";
  key: Scalars["String"];
  chargeDate: Scalars["String"];
};

export type PaperContractSigned = {
  __typename?: "PaperContractSigned";
  paperContract?: Maybe<PaperContract>;
};

export type PaperContractReadyToSign = {
  __typename?: "PaperContractReadyToSign";
  paperContractId: Scalars["String"];
  isValid: Scalars["Boolean"];
  validationResult?: Maybe<ValidationResult>;
};

export type ValidationResult = {
  __typename?: "ValidationResult";
  errors: Array<ValidationError>;
};

export type ValidationError = {
  __typename?: "ValidationError";
  reference: Scalars["String"];
  requirement: Scalars["String"];
  invalidValue?: Maybe<Scalars["String"]>;
};

export type PaperContract = {
  __typename?: "PaperContract";
  id: Scalars["String"];
  contractType: Scalars["String"];
  state: PaperContractState;
  ownerRef: Scalars["String"];
  leaseRef: Scalars["String"];
  documentId?: Maybe<Scalars["String"]>;
  handledByProper: Scalars["Boolean"];
  createdAt: Scalars["String"];
  modifiedAt: Scalars["String"];
  data?: Maybe<PaperContractDk1Template>;
};

export type TenancyIdAndAddress = {
  __typename?: "TenancyIdAndAddress";
  tenancyId: Scalars["String"];
  address: Address;
};

export enum TenantLanguage {
  En = "en",
  Da = "da",
}

export enum PaperContractState {
  Draft = "Draft",
  ReadyToSign = "ReadyToSign",
  PdfGenerated = "PdfGenerated",
  AwaitingSignature = "AwaitingSignature",
  Signed = "Signed",
}

export enum TvProvidingEntity {
  Landlord = "Landlord",
  Tenant = "Tenant",
  None = "None",
}

export type PaperContractDk1Template = {
  __typename?: "PaperContractDk1Template";
  rentalNumber?: Maybe<Scalars["String"]>;
  isSublet?: Maybe<Scalars["Boolean"]>;
  moveInDate?: Maybe<Scalars["String"]>;
  taxAndFeesDate?: Maybe<Scalars["String"]>;
  bankAccount?: Maybe<BankAccount>;
  rent?: Maybe<Rent>;
  deposit?: Maybe<AdvancePayment>;
  prepaidRent?: Maybe<AdvancePayment>;
  firstMonthPayment?: Maybe<FirstMonthPayment>;
  owner?: Maybe<Owner>;
  address?: Maybe<Address>;
  tenancyDetails?: Maybe<TenancyDetails>;
  tenants?: Maybe<Array<Maybe<Tenant>>>;
  amenities?: Maybe<Amenities>;
  tenancyUsedFor?: Maybe<Scalars["String"]>;
  heating?: Maybe<HeatingUtility>;
  water?: Maybe<WaterUtility>;
  electricity?: Maybe<ElectricityUtility>;
  cooling?: Maybe<CoolingUtility>;
  tv?: Maybe<TvUtility>;
  internet?: Maybe<InternetUtility>;
  other1?: Maybe<OtherContributions>;
  other2?: Maybe<OtherContributions>;
  hasMoveInReport?: Maybe<Scalars["Boolean"]>;
  maintenance?: Maybe<Maintenance>;
  inventory?: Maybe<Inventory>;
  residentsRepresentative?: Maybe<ResidentsRepresentative>;
  petAllowed?: Maybe<Scalars["Boolean"]>;
  hasHouseRules?: Maybe<Scalars["Boolean"]>;
  paragraph10?: Maybe<Scalars["String"]>;
  paragraph11Terms?: Maybe<Array<Paragraph11Term>>;
  attachmentIds?: Maybe<Array<Scalars["String"]>>;
  paragraph11Ids?: Maybe<Array<Scalars["String"]>>;
  paragraph11Clauses?: Maybe<Array<Paragraph11Clause>>;
};

export type ResidentsRepresentative = {
  __typename?: "ResidentsRepresentative";
  amount?: Maybe<Scalars["String"]>;
  hasResidentsRepresentative?: Maybe<Scalars["Boolean"]>;
};

export type BankAccount = {
  __typename?: "BankAccount";
  regNumber?: Maybe<Scalars["String"]>;
  accountNumber?: Maybe<Scalars["String"]>;
  bankName?: Maybe<Scalars["String"]>;
};

export type Rent = {
  __typename?: "Rent";
  amount?: Maybe<Scalars["String"]>;
  paymentInterval?: Maybe<RentPaymentInterval>;
  dayOfInterval?: Maybe<Scalars["Int"]>;
};

export enum RentPaymentInterval {
  Monthly = "Monthly",
  Quarterly = "Quarterly",
}

export type AdvancePayment = {
  __typename?: "AdvancePayment";
  interval?: Maybe<AdvancePaymentInterval>;
  dueDate?: Maybe<Scalars["String"]>;
  displayAmount?: Maybe<Scalars["String"]>;
};

export enum AdvancePaymentInterval {
  None = "None",
  OneMonth = "OneMonth",
  TwoMonths = "TwoMonths",
  ThreeMonths = "ThreeMonths",
}

export type FirstMonthPayment = {
  __typename?: "FirstMonthPayment";
  rent?: Maybe<Scalars["String"]>;
  consumption?: Maybe<ConsumptionAmounts>;
  isRentCustom: Scalars["Boolean"];
  isConsumptionCustom: Scalars["Boolean"];
  daysLeftTotalDaysRatio?: Maybe<Scalars["Float"]>;
  other1?: Maybe<OneTimeCharge>;
  other2?: Maybe<OneTimeCharge>;
  other3?: Maybe<OneTimeCharge>;
};

export type ConsumptionAmounts = {
  __typename?: "ConsumptionAmounts";
  heating?: Maybe<Scalars["String"]>;
  water?: Maybe<Scalars["String"]>;
  electricity?: Maybe<Scalars["String"]>;
  cooling?: Maybe<Scalars["String"]>;
  tv?: Maybe<Scalars["String"]>;
  internet?: Maybe<Scalars["String"]>;
  other1?: Maybe<Scalars["String"]>;
  other2?: Maybe<Scalars["String"]>;
  residentsRepresentative?: Maybe<Scalars["String"]>;
};

export type OneTimeCharge = {
  __typename?: "OneTimeCharge";
  amount?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
};

export type Owner = {
  __typename?: "Owner";
  name?: Maybe<Scalars["String"]>;
  address?: Maybe<Address>;
  cvr?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
};

export type InternationalAddress = {
  __typename?: "InternationalAddress";
  street?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
};

export type Address = {
  __typename?: "Address";
  street?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Country>;
};

export enum Country {
  Dk = "DK",
}

export type TenancyDetails = {
  __typename?: "TenancyDetails";
  numberOfRooms?: Maybe<Scalars["Int"]>;
  tenancyType?: Maybe<TenancyType>;
  customTenancyType?: Maybe<Scalars["String"]>;
  area?: Maybe<Scalars["Float"]>;
  businessArea?: Maybe<Scalars["Float"]>;
};

export enum TenancyType {
  Apartment = "Apartment",
  Room = "Room",
  CooperativeHousing = "CooperativeHousing",
  Condominium = "Condominium",
  Villa = "Villa",
  TownHouse = "TownHouse",
  Other = "Other",
}

export type Tenant = {
  __typename?: "Tenant";
  name?: Maybe<Scalars["String"]>;
  address?: Maybe<InternationalAddress>;
  cvr?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  isCompany?: Maybe<Scalars["Boolean"]>;
  language?: Maybe<TenantLanguage>;
};

export type Amenities = {
  __typename?: "Amenities";
  accessToCommonLaundry?: Maybe<Scalars["Boolean"]>;
  accessToCommonYard?: Maybe<Scalars["Boolean"]>;
  accessToBikeParking?: Maybe<Scalars["Boolean"]>;
  accessToLoftOrCellarRoom?: Maybe<Scalars["Boolean"]>;
  loftOrCellarRoomNumber?: Maybe<Scalars["String"]>;
  accessToGarage?: Maybe<Scalars["Boolean"]>;
  garageNumber?: Maybe<Scalars["String"]>;
  accessToOtherAmenity?: Maybe<Scalars["Boolean"]>;
  other?: Maybe<Scalars["String"]>;
};

export type HeatingUtility = {
  __typename?: "HeatingUtility";
  amount?: Maybe<Scalars["String"]>;
  paidAs?: Maybe<UtilityPaymentModality>;
  accountingYearStart?: Maybe<Scalars["String"]>;
  landlordProvidedType?: Maybe<LandLordProvidedHeating>;
  tenancyHeating?: Maybe<TenancyHeating>;
};

export type LandLordProvidedHeating = {
  __typename?: "LandLordProvidedHeating";
  electricity?: Maybe<Scalars["Boolean"]>;
  districtHeatingOrNaturalGas?: Maybe<Scalars["Boolean"]>;
  oil?: Maybe<Scalars["Boolean"]>;
  other?: Maybe<Scalars["Boolean"]>;
  otherType?: Maybe<Scalars["String"]>;
};

export type TenancyHeating = {
  __typename?: "TenancyHeating";
  electricity?: Maybe<Scalars["Boolean"]>;
  districtHeatingOrNaturalGas?: Maybe<Scalars["Boolean"]>;
  oil?: Maybe<Scalars["Boolean"]>;
  gas?: Maybe<Scalars["Boolean"]>;
  other?: Maybe<Scalars["Boolean"]>;
  otherType?: Maybe<Scalars["String"]>;
};

export enum UtilityPaymentModality {
  IncludedInRent = "IncludedInRent",
  PaidSeparatelyWithRent = "PaidSeparatelyWithRent",
  PaidDirectlyToProvider = "PaidDirectlyToProvider",
}

export enum HeatingType {
  Electricity = "Electricity",
  DistrictHeatingOrNaturalGas = "DistrictHeatingOrNaturalGas",
  OilHeating = "OilHeating",
  Gas = "Gas",
  Other = "Other",
}

export type WaterUtility = {
  __typename?: "WaterUtility";
  amount?: Maybe<Scalars["String"]>;
  paidAs?: Maybe<UtilityPaymentModality>;
  accountingYearStart?: Maybe<Scalars["String"]>;
  allocatedOnIndividualMeters?: Maybe<Scalars["Boolean"]>;
};

export type ElectricityUtility = {
  __typename?: "ElectricityUtility";
  amount?: Maybe<Scalars["String"]>;
  paidAs?: Maybe<UtilityPaymentModality>;
  accountingYearStart?: Maybe<Scalars["String"]>;
  allocatedOnIndividualMeters?: Maybe<Scalars["Boolean"]>;
};

export type CoolingUtility = {
  __typename?: "CoolingUtility";
  amount?: Maybe<Scalars["String"]>;
  paidAs?: Maybe<UtilityPaymentModality>;
  accountingYearStart?: Maybe<Scalars["String"]>;
  allocatedOnIndividualMeters?: Maybe<Scalars["Boolean"]>;
};

export type TvUtility = {
  __typename?: "TvUtility";
  amount?: Maybe<Scalars["String"]>;
  providingEntity?: Maybe<TvProvidingEntity>;
};

export type InternetUtility = {
  __typename?: "InternetUtility";
  amount?: Maybe<Scalars["String"]>;
  providedByLandlord?: Maybe<Scalars["Boolean"]>;
};

export type OtherContributions = {
  __typename?: "OtherContributions";
  amount?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Maintenance = {
  __typename?: "Maintenance";
  amount?: Maybe<Scalars["String"]>;
  landlordIsResponsible?: Maybe<Scalars["Boolean"]>;
  depositDate?: Maybe<Scalars["String"]>;
};

export type Inventory = {
  __typename?: "Inventory";
  hasStove?: Maybe<Scalars["Boolean"]>;
  hasFridge?: Maybe<Scalars["Boolean"]>;
  hasFreezer?: Maybe<Scalars["Boolean"]>;
  hasDishwasher?: Maybe<Scalars["Boolean"]>;
  hasWashingMachine?: Maybe<Scalars["Boolean"]>;
  hasTumbleDryer?: Maybe<Scalars["Boolean"]>;
  hasExhaust?: Maybe<Scalars["Boolean"]>;
  hasElectricConvectors?: Maybe<Scalars["Boolean"]>;
  electricConvectorsCount?: Maybe<Scalars["Int"]>;
  hasWaterHeaters?: Maybe<Scalars["Boolean"]>;
  waterHeatersCount?: Maybe<Scalars["Int"]>;
  hasOther1?: Maybe<Scalars["Boolean"]>;
  hasOther2?: Maybe<Scalars["Boolean"]>;
  hasOther3?: Maybe<Scalars["Boolean"]>;
  other1?: Maybe<Scalars["String"]>;
  other2?: Maybe<Scalars["String"]>;
  other3?: Maybe<Scalars["String"]>;
};

export type Paragraph11Clause = {
  __typename?: "Paragraph11Clause";
  name?: Maybe<Scalars["String"]>;
  content?: Maybe<Scalars["String"]>;
};

export type Paragraph11Term = {
  __typename?: "Paragraph11Term";
  id: Scalars["String"];
  title: Scalars["String"];
  content: Scalars["String"];
};

export type LeaseIdWithPaperContractId = {
  __typename?: "LeaseIdWithPaperContractId";
  leaseId: Scalars["String"];
  paperContractId: Scalars["String"];
};

export type LeaseId = {
  __typename?: "LeaseId";
  leaseId: Scalars["String"];
};

export type PaperContractId = {
  __typename?: "PaperContractId";
  paperContractId: Scalars["String"];
};

export type Document = {
  __typename?: "Document";
  documentId: Scalars["String"];
  createdBy: Scalars["String"];
  createdAt: Scalars["String"];
  fileName: Scalars["String"];
  sizeInBytes: Scalars["String"];
  contentType: Scalars["String"];
  visibility: DocumentVisibility;
};

export type NewParagraph11ClauseCreated = {
  __typename?: "NewParagraph11ClauseCreated";
  paragraph11ClauseId: Scalars["String"];
  title: Scalars["String"];
  content: Scalars["String"];
};

export type Paragraph11ClauseUpdated = {
  __typename?: "Paragraph11ClauseUpdated";
  id: Scalars["String"];
};

export type Paragraph11LibraryClause = {
  __typename?: "Paragraph11LibraryClause";
  id: Scalars["String"];
  title: Scalars["String"];
  content: Scalars["String"];
  isProper: Scalars["Boolean"];
  isArchived: Scalars["Boolean"];
};

export enum DocumentVisibility {
  Visible = "Visible",
  Deleted = "Deleted",
}

export enum Satisfaction {
  VeryLow = "VeryLow",
  Low = "Low",
  Medium = "Medium",
  High = "High",
  VeryHigh = "VeryHigh",
}

export type ProactiveFeedback = {
  __typename?: "ProactiveFeedback";
  customerId: Scalars["String"];
  satisfaction: Satisfaction;
  comment?: Maybe<Scalars["String"]>;
};

export enum Disappointment {
  NotSad = "NotSad",
  LittleSad = "LittleSad",
  VerySad = "VerySad",
}

export type SurveyFeedback = {
  __typename?: "SurveyFeedback";
  customerId: Scalars["String"];
  satisfaction?: Maybe<Satisfaction>;
  disappointment?: Maybe<Disappointment>;
  primaryBenefit?: Maybe<Scalars["String"]>;
  nextFeatures?: Maybe<Scalars["String"]>;
};

export type SurveyDismissed = {
  __typename?: "SurveyDismissed";
  customerId: Scalars["String"];
};

export type CustomerSurveyStatus = {
  __typename?: "CustomerSurveyStatus";
  shouldTakeSurvey: Scalars["Boolean"];
};

export type TenancyArchivability = {
  __typename?: "TenancyArchivability";
  archivable: Scalars["Boolean"];
  reasons?: Maybe<CannotArchiveReasons>;
};

export type CannotArchiveReasons = {
  __typename?: "CannotArchiveReasons";
  alreadyArchived: Scalars["Boolean"];
  activeLeases: Array<ActiveLease>;
};

export type ActiveLease = {
  __typename?: "ActiveLease";
  leaseAlias: Scalars["String"];
  tenantName: Scalars["String"];
};

export type ArchivedTenancy = {
  __typename?: "ArchivedTenancy";
  tenancyId: Scalars["String"];
  archivedAt: Scalars["String"];
};

export type ArchiveParagraph11ClauseResponse = {
  __typename?: "ArchiveParagraph11ClauseResponse";
  paragraph11ClauseId: Scalars["String"];
};

export type Person = {
  __typename?: "Person";
  personId: Scalars["String"];
  name: Scalars["String"];
  mobile?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  language?: Maybe<Scalars["String"]>;
  companyEmail?: Maybe<Scalars["String"]>;
  portfolio?: Maybe<Scalars["String"]>;
};
