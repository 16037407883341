import { useEffect, useState } from "react";

import { add, isPast } from "date-fns";
import { ResponseJson } from "src/pages/api/reports";

import { Provider } from "~contexts/reports";
import { useResource } from "~hooks";

import { AwaitedReport, Report } from "./types";

const POLL_INTERVAL = 1000; // every 1 second
const TIMEOUT = { minutes: 2 };

const Reports = ({ children }: any) => {
  const [reports, setReports] = useState<AwaitedReport[]>([]);
  const [shouldPoll, setShouldPoll] = useState(reports.length > 0);
  const [documents, setDocuments] = useState<ResponseJson>([]);

  useResource<ResponseJson>(reports.length ? "/api/reports" : null, {
    refreshInterval: shouldPoll ? POLL_INTERVAL : 0,
    onSuccess: setDocuments,
  });

  const onAwaitReport = (report: AwaitedReport) => {
    setReports([...reports, report]);
  };

  const onReportDownloaded = (documentId: string) => {
    setReports(reports.filter((r) => r.documentId !== documentId));
  };

  const allReports: Report[] = reports.map((report) => {
    const document = documents.find((d) => d.documentId === report.documentId);
    if (document) {
      return {
        ...report,
        status: "ready",
        downloadUrl: document.downloadUrl,
      };
    } else if (isTimedOut(report)) {
      return { ...report, status: "timeout" };
    } else {
      return { ...report, status: "pending" };
    }
  });

  const hasPendingReports =
    allReports.filter((r) => r.status === "pending").length > 0;

  useEffect(() => {
    setShouldPoll(hasPendingReports);
  }, [hasPendingReports]);

  return (
    <Provider
      value={{ reports: allReports, onAwaitReport, onReportDownloaded }}
    >
      {children}
    </Provider>
  );
};

const isTimedOut = (report: AwaitedReport): boolean => {
  const timeOutAt = add(report.queuedAt, TIMEOUT);
  return isPast(timeOutAt);
};

export default Reports;
