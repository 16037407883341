import unfetch from "isomorphic-unfetch";

import {
  BadGatewayError,
  HttpClientError,
  HttpServerError,
  InternalServerError,
  NetworkUnreachableError,
  NotFoundError,
  UnauthorizedError,
} from "~errors";

async function fetch(url: string, options?: RequestInit) {
  let response;

  try {
    response = await unfetch(url, options);
  } catch {
    throw new NetworkUnreachableError();
  }

  const contentType = response.headers.get("content-type");
  if (contentType && ~contentType.indexOf("application/json")) {
    return handleJsonResponse(response, url);
  } else {
    return handleTextResponse(response, url);
  }
}

async function handleJsonResponse(response: Response, url: string) {
  const json = await response.json();

  if (response.ok) {
    return json;
  }

  console.error(`Error fetching ${url}`);

  handleErrors(response, json);
}

async function handleTextResponse(response: Response, url: string) {
  const text = await response.text();

  if (response.ok) {
    return text;
  }

  console.error(`Error fetching ${url}`);

  let json;
  try {
    json = JSON.parse(text);
  } catch (err) {
    console.error(`Response could not be transformed to JSON: ${text}.`);
    json = {};
  }

  handleErrors(response, json);
}

// In order to allow specific actions for different types of errors,
// we throw custom error types depending on the HTTP staus code of the
// response. For example, we don't want to capture BadGatewayErrors or
// NotFoundErrors in our error monitoring system.
function handleErrors(response: Response, json: any) {
  if (response.status === 404) {
    throw new NotFoundError();
  } else if (response.status === 401) {
    throw new UnauthorizedError();
  } else if (response.status >= 400 && response.status < 500) {
    throw HttpClientError.fromHttpStatusCode(response.status)
      .setCode(json.code)
      .setReason(json.reason)
      .setMessages(json.messages)
      .setRequestId(json.requestId);
  } else if (response.status === 502) {
    throw new BadGatewayError();
  } else if (response.status === 500) {
    throw new InternalServerError();
  } else {
    throw HttpServerError.fromHttpStatusCode(response.status)
      .setCode(json.code)
      .setReason(json.reason)
      .setMessages(json.messages)
      .setRequestId(json.requestId);
  }
}

export default fetch;
