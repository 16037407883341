import { forwardRef } from "react";

import { MoreHorizontal } from "../../icons";
import Menu, { MenuItem, MenuSection } from "../Menu";

import TdAction from "./TdAction";

export const TdActionMenuItem = MenuItem;
export const TdActionMenuSection = MenuSection;

type Props = {
  children: any;
  tooltip: string;
  state?: any;
};
const TdActionMenu = ({ children, tooltip, state }: Props, ref: any) => (
  <Menu
    ariaLabel="Table actions"
    ref={ref}
    state={state}
    trigger={<TdAction tooltip={tooltip} Icon={MoreHorizontal} />}
  >
    {children}
  </Menu>
);

export default forwardRef(TdActionMenu);
