import { useContext } from "react";

import { Context } from "~contexts/notifications";

const useNotifications = () => {
  const notifications = useContext(Context);
  return notifications;
};

export default useNotifications;
