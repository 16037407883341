import { orderBy } from "lodash";

import { useUser } from "~hooks";

import CompanySelector from "./CompanySelector";

const CompanySelectorContainer = () => {
  const { authUser, viewAsUser } = useUser();

  const sortedCompanies = orderBy(authUser.canViewAs, ["name"]);

  return (
    <CompanySelector companies={sortedCompanies} selectedCompany={viewAsUser} />
  );
};

export default CompanySelectorContainer;
