import { useState } from "react";

import Fuse from "fuse.js";

const options = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["value"],
};

type SearchResult = { value: string };

type fuzzySearchArgs = {
  fuse: any;
  items: SearchResult[];
  term?: string;
};
function fuzzySearch({ fuse, items, term }: fuzzySearchArgs): SearchResult[] {
  const result = fuse.search(`${term}`);
  return term ? result : items;
}

function useFuse(items: SearchResult[]) {
  const [term, search] = useState("");
  const fuse = new Fuse(items, options);
  const result = fuzzySearch({ items, term, fuse });
  return { result, search };
}

export default useFuse;
