import { useState } from "react";

import Fuse, { FuseOptions } from "fuse.js";

const defaultOptions = {
  shouldSort: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
};

function fuzzySearch({ fuse, data, term }: any) {
  const items = fuse.search(`${term}`);
  return term ? items : data;
}

function useFuzzySearch<T>(
  data: T[],
  keys: string[],
  options?: FuseOptions<T>
) {
  const [term, setTerm] = useState("");
  const allOptions = options
    ? { ...defaultOptions, ...options }
    : defaultOptions;
  const fuse = new Fuse(data, { ...allOptions, keys });
  const items: T[] = fuzzySearch({ data, term, fuse });
  const reset = () => setTerm("");
  return { items, search: setTerm, term, reset };
}

export default useFuzzySearch;
