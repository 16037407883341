import * as React from "react";

import styled from "styled-components";

import { Heading } from "../../components";
import { baseUnit, textColor } from "../../tokens";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: ${textColor};
    flex: 0 0 20px;
    height: 20px;
    margin-right: ${baseUnit}px;
  }
`;

export const Title = ({ children }: any) => (
  <Heading size="small">{children}</Heading>
);

type Props = {
  children: React.ReactNode;
  Icon?: any;
};

const CardHeaderTitle = ({ children, Icon, ...rest }: Props) => {
  return (
    <Wrapper {...rest}>
      {Icon && <Icon />}
      <Title>{children}</Title>
    </Wrapper>
  );
};

export default CardHeaderTitle;
