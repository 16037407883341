import { Plural } from "@lingui/macro";
import { Trans } from "@lingui/macro";

import styled from "styled-components";

import {
  baseUnit,
  Notification,
  NotificationAction,
  NotificationActions,
  NotificationMessage,
} from "~components/copied_from_shared/pui";
import { boxShadow } from "~components/copied_from_shared/pui/components/Card/Card";
import { useRouter } from "~hooks";
import { getElementStackOrder } from "~utils";

import { Notification as NotificationType } from "../Providers/Notifications";
import { Report } from "../Providers/Reports";

import ErrorDetailsDialog from "./ErrorDetails";
import ReportNotification from "./ReportNotification";

const zIndex = getElementStackOrder("notifications");
type NotificationContainerProps = {
  isPaperContract: boolean;
};
const Wrapper = styled.ul<NotificationContainerProps>`
  margin: 0;
  position: fixed;
  box-shadow: ${boxShadow};
  ${(p) => {
    if (p.isPaperContract) return `bottom: ${baseUnit * 6}px;`;
    return `bottom: ${baseUnit * 1.5}px;`;
  }}
  right: ${baseUnit * 1.5}px;
  max-width: calc(100% - ${baseUnit * 3}px);
  list-style-type: none;
  padding: 0;
  z-index: ${zIndex};
`;

export type Props = {
  notifications: NotificationType[];
  onClearNotification: (id: string) => void;
  onGoToNotices: () => void;
  numUploads: number;
  numNotices: number;
  uploadProgress: number;
  onDimissNoticesNotification: () => void;
  wasNoticesNotificationDismissed: boolean;
  reports: Report[];
  onReportDownloaded: (id: string) => void;
};
const Notifications = ({
  onClearNotification,
  notifications,
  numUploads,
  numNotices,
  uploadProgress,
  onGoToNotices,
  onDimissNoticesNotification,
  wasNoticesNotificationDismissed,
  reports,
  onReportDownloaded,
}: Props) => {
  const { pathname } = useRouter();
  const isPaperContract = (pathname as string).includes("/paper-contract");
  const isTalk = (pathname as string).includes("/talk");
  return (
    <Wrapper isPaperContract={isPaperContract}>
      {notifications.map(({ id, text, type, errorDetails }) => (
        <Notification
          key={id}
          status={type}
          onDismiss={() => onClearNotification(id)}
        >
          <NotificationMessage>{text}</NotificationMessage>
          {errorDetails && (
            <NotificationActions>
              <ErrorDetailsDialog
                {...errorDetails}
                trigger={<NotificationAction>Details</NotificationAction>}
              />
            </NotificationActions>
          )}
        </Notification>
      ))}
      {numUploads > 0 && (
        <Notification status="info">
          <NotificationMessage>
            <Plural
              value={numUploads}
              one="# upload in progress"
              other="# uploads in progress"
            />{" "}
            ({uploadProgress}% done)
          </NotificationMessage>
        </Notification>
      )}
      {numNotices > 0 && !isTalk && (
        <Notification status="info" onDismiss={onDimissNoticesNotification}>
          <NotificationMessage>
            {wasNoticesNotificationDismissed ? (
              <Plural
                value={numNotices}
                one="You have # new notice"
                other="You have # new notices"
              />
            ) : (
              <Plural
                value={numNotices}
                one="You have # unresolved notice"
                other="You have # unresolved notices"
              />
            )}
          </NotificationMessage>
          <NotificationActions>
            <NotificationAction
              onClick={() => {
                onGoToNotices();
                onDimissNoticesNotification();
              }}
            >
              <Trans>Show notices</Trans>
            </NotificationAction>
          </NotificationActions>
        </Notification>
      )}
      {reports.map((report) => (
        <ReportNotification
          key={report.documentId}
          report={report}
          onDownload={() => onReportDownloaded(report.documentId)}
        />
      ))}
    </Wrapper>
  );
};

export default Notifications;
