import { DocumentType } from "~types/lease/document";

const toClient = (typeSmartText?: string | null): DocumentType | null => {
  if (!typeSmartText) {
    return null;
  }

  return typeSmartText.split("key:")[1] as DocumentType;
};

const toService = (type?: DocumentType | null): string | null => {
  return type ? `key:${type}` : null;
};

export default { toClient, toService };
