import styled, { keyframes } from "styled-components";

import { pampas300 } from "~components/copied_from_shared/pui";

const load = keyframes`
    0%, 80%, 100% {
      transform: scale(0);
    } 40% {
      transform: scale(1.0);
    }
`;

const Wrapper = styled.div`
  display: flex;
`;

const Ball = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${pampas300};
  border-radius: 100%;
  animation: ${load} 1.4s infinite ease-in-out both;

  &:not(:last-child) {
    margin-right: 9px;
  }

  &:nth-child(1) {
    animation-delay: -0.32s;
  }

  &:nth-child(2) {
    animation-delay: -0.16s;
  }
`;

const Loader = () => (
  <Wrapper>
    <Ball />
    <Ball />
    <Ball />
  </Wrapper>
);

export default Loader;
