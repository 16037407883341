import { cloneElement } from "react";

import {
  Popover as ReakitPopover,
  PopoverDisclosure as ReakitPopoverDisclosure,
  PopoverState as ReakitPopoverState,
  usePopoverState as useReakitPopoverState,
} from "reakit/Popover";
import styled from "styled-components";

import { white } from "../../tokens";

export const borderRadius = "6px";

const Wrapper = styled(ReakitPopover)`
  background: ${white};
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  border-radius: ${borderRadius};
  z-index: 999;
`;

type Props = {
  trigger: any;
  children: any;
  state?: ReakitPopoverState;
  ariaLabel: string;
};
const Popover = ({ trigger, children, state, ariaLabel, ...rest }: Props) => {
  const popover = state || usePopoverState();
  return (
    <>
      <ReakitPopoverDisclosure
        {...popover}
        ref={trigger.ref}
        {...trigger.props}
      >
        {(triggerProps) => cloneElement(trigger, triggerProps)}
      </ReakitPopoverDisclosure>
      <Wrapper {...popover} {...rest} aria-label={ariaLabel}>
        {popover.visible ? children : null}
      </Wrapper>
    </>
  );
};

export const usePopoverState = useReakitPopoverState;

export default Popover;
