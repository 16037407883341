import { Trans } from "@lingui/macro";

import React, { Fragment } from "react";

import { Dialog as HeadlessDialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import clsx from "clsx";
import { SmileyMeh } from "phosphor-react";

import { Skeleton } from "~components/tailwind/Skeleton";
import { Typography } from "~components/tailwind/Typography";

// -- Modal Title

export type ModalTitleProps = {
  children: any;
  as?: "h2" | "h5";
  textSize?: "xl" | "4xl";
  className?: string;
};
const ModalTitle = ({
  children,
  as = "h5",
  textSize = "xl",
  className,
}: ModalTitleProps) => {
  const titleClassName = clsx(
    "font-bold text-default",
    {
      "text-xl": textSize === "xl",
      "text-4xl": textSize === "4xl",
    },
    className
  );

  return (
    <HeadlessDialog.Title as={as} className={titleClassName}>
      {children}
    </HeadlessDialog.Title>
  );
};

// -- Modal Body

export type ModalBodyProps = {
  children: any;
};
const ModalBody = ({ children }: ModalBodyProps) => {
  return <>{children}</>;
};

// -- Modal Loading Fallback

type ModalLoadingFallbackProps = {
  skeletonCount?: number;
};

const ModalLoadingFallback = ({
  skeletonCount = 4,
}: ModalLoadingFallbackProps) => {
  return (
    <div className="flex flex-col gap-6">
      <ModalTitleLoadingFallback />
      <ModalBodyLoadingFallback skeletonCount={skeletonCount} />
    </div>
  );
};

const ModalTitleLoadingFallback = () => {
  return <Skeleton className="h-8 w-40" />;
};

const ModalBodyLoadingFallback = ({
  skeletonCount = 4,
}: ModalLoadingFallbackProps) => {
  return (
    <div className="flex min-w-80 flex-col gap-4">
      {Array.from(Array(skeletonCount).keys()).map((i) => (
        <Skeleton key={i} className="h-10 w-full" />
      ))}
    </div>
  );
};

// -- Modal Wrapper

export type ModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  closeOnEscape?: boolean;
  children: any;
  maxSize?: "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "fit";
  initialFocusRef?: any;
  className?: string;
  overflow?: "auto" | "hidden" | "visible";
  onClose?: () => void;
  childrenSpacing?: string;
  padding?: string;
  position?: "center" | "top";
};

export const ErrorFallback = () => (
  <div className="flex flex-col items-center justify-center gap-4 p-6">
    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-red-25">
      <SmileyMeh size={24} className="text-red-600" />
    </div>
    <Typography medium color="red" tone={600}>
      <Trans>Something went wrong</Trans>
    </Typography>
    <Typography color="gray" tone={700} alignCenter>
      <Trans>
        Our team has been notified. Brace yourself till we get the error fixed.
        In the meantime, you may refresh the page or try again later.
      </Trans>
    </Typography>
  </div>
);

const Modal = ({
  open = false,
  closeOnEscape = true,
  setOpen,
  children,
  maxSize = "xl",
  initialFocusRef,
  className,
  overflow = "visible",
  onClose,
  childrenSpacing = "gap-10",
  padding = "p-12",
  position = "center",
}: ModalProps) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <HeadlessDialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={initialFocusRef}
        onClose={closeOnEscape ? setOpen : () => null}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <HeadlessDialog.Overlay className="fixed inset-0 bg-black/50 transition-opacity" />
          </Transition.Child>
          {position === "center" && (
            <>
              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:h-screen sm:align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
            </>
          )}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={clsx(
                "relative inline-block rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:w-full sm:align-middle",
                maxSize === "4xl" && "max-w-4xl",
                maxSize === "3xl" && "max-w-3xl",
                maxSize === "2xl" && "max-w-2xl",
                maxSize === "xl" && "max-w-xl",
                maxSize === "lg" && "max-w-lg",
                maxSize === "md" && "max-w-md",
                maxSize === "sm" && "max-w-sm",
                maxSize === "fit" && "max-w-fit",
                position === "top" ? "sm:my-20" : "sm:my-8",
                padding,
                // @ts-ignore
                `overflow-${overflow}`,
                className
              )}
            >
              <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button"
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                  onClick={() => (onClose ? onClose() : setOpen(false))}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className={clsx("flex flex-col", childrenSpacing)}>
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </HeadlessDialog>
    </Transition.Root>
  );
};

Modal.Title = ModalTitle;
Modal.Body = ModalBody;
Modal.LoadingFallback = ModalLoadingFallback;
Modal.TitleLoadingFallback = ModalTitleLoadingFallback;
Modal.BodyLoadingFallback = ModalBodyLoadingFallback;
Modal.ErrorFallback = ErrorFallback;

export default Modal;
