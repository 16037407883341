import { t, Trans } from "@lingui/macro";

import { useEffect, useRef, useState } from "react";

import { gql, useMutation, useQuery } from "@apollo/client";
import clsx from "clsx";
import { Field, useFormikContext } from "formik";
import { Form, Formik, FormikProps } from "formik";

import {
  ArrowLeft,
  ArrowRight,
} from "~components/copied_from_shared/pui/icons";
import { Check } from "~components/copied_from_shared/pui/icons";
import { Button } from "~components/tailwind/Button";
import { Modal } from "~components/tailwind/Modal";
import { useAnalytics, useUser } from "~hooks";
import { reportError } from "~utils";

import { Disappointment as DisappointmentType } from "../../../graphql/generated_types";

import { FormData } from "./types";

import ExternalServiceReview from "~features/customer-feedback/ProactiveFeedback/ExternalServiceReview";
import Satisfaction from "~features/customer-feedback/ProactiveFeedback/Satisfaction";
import Disappointment from "~features/customer-feedback/SurveyFeedback/Disappointment";
import { Satisfaction as SatisfactionType } from "~graphql/generated_types";

const ShouldCustomerTakeSurvey = gql`
  query ShouldCustomerTakeSurvey($customerId: String!) {
    shouldCustomerTakeSurvey(customerId: $customerId) {
      shouldTakeSurvey
    }
  }
`;

const RegisterSurveyFeedback = gql`
  mutation RegisterSurveyFeedback(
    $customerId: String!
    $companyId: String!
    $satisfaction: Satisfaction
    $disappointment: Disappointment
    $primaryBenefit: String
    $nextFeatures: String
  ) {
    registerSurveyFeedback(
      customerId: $customerId
      companyId: $companyId
      satisfaction: $satisfaction
      disappointment: $disappointment
      primaryBenefit: $primaryBenefit
      nextFeatures: $nextFeatures
    ) {
      customerId
    }
  }
`;

const RegisterSurveyDismissed = gql`
  mutation RegisterSurveyDismissed($customerId: String!) {
    registerSurveyDismissed(customerId: $customerId) {
      customerId
    }
  }
`;

type ModalProps = {
  alwaysOpen?: boolean; // this is to allow storybook to show the dialog
};

const SurveyFeedbackModal = ({ alwaysOpen }: ModalProps) => {
  const { authUser, viewAs } = useUser();
  const { track } = useAnalytics();

  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);

  const [surveySubmitted, setSurveySubmitted] = useState(false);

  const { data: verificationResponseData } = useQuery(
    ShouldCustomerTakeSurvey,
    {
      variables: {
        customerId: authUser.id,
      },
    }
  );

  useEffect(() => {
    if (verificationResponseData && !surveySubmitted) {
      const shouldTakeSurvey =
        verificationResponseData.shouldCustomerTakeSurvey.shouldTakeSurvey ??
        false;
      setOpen(shouldTakeSurvey);
    }
  }, [verificationResponseData]);

  const [
    registerSurveyDismissed,
    { data: dismissResponseData, error: dismissError, reset: resetDismiss },
  ] = useMutation(RegisterSurveyDismissed, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (open) {
      track("SurveyFeedbackOpened", {
        authUser: authUser.id,
        viewAs,
      });
    }
  }, [open]);

  useEffect(() => {
    if (dismissResponseData) {
      setOpen(false);
      resetDismiss();
    }
  }, [dismissResponseData]);

  useEffect(() => {
    if (dismissError) {
      reportError(dismissError);
      setOpen(false);
      resetDismiss();
    }
  }, [dismissError]);

  const onDismiss = async () => {
    await registerSurveyDismissed({
      variables: {
        customerId: authUser.id,
      },
    });
    track("SurveyFeedbackDismissed", {
      authUser: authUser.id,
      viewAs,
    });
    setOpen(false);
  };

  return (
    <Modal
      open={alwaysOpen ?? open}
      setOpen={setOpen}
      onClose={async () => {
        setOpen(false);
        if (step !== 5) {
          await onDismiss();
        }
      }}
    >
      <Modal.Body>
        {step <= 4 && (
          <SurveyFeedback
            step={step}
            setStep={setStep}
            setOpen={setOpen}
            setSurveySubmitted={setSurveySubmitted}
          />
        )}
        {step === 5 && <ExternalServiceReview />}
      </Modal.Body>
    </Modal>
  );
};

type Props = {
  setOpen: (open: boolean) => void;
  setStep: (step: number) => void;
  setSurveySubmitted: (submitted: boolean) => void;
  step: number;
};

const SurveyFeedback = ({
  setOpen,
  step,
  setStep,
  setSurveySubmitted,
}: Props) => {
  const { authUser, viewAs } = useUser();
  const { track } = useAnalytics();

  const initialValues = {};

  const [
    registerSurveyFeedback,
    {
      data: submitResponseData,
      loading: submitting,
      error: submitError,
      reset: resetSubmit,
    },
  ] = useMutation(RegisterSurveyFeedback, {
    fetchPolicy: "no-cache",
  });

  const onSubmit = async (values: FormData) => {
    registerSurveyFeedback({
      variables: {
        customerId: authUser.id,
        companyId: viewAs,
        satisfaction: values.satisfaction,
        disappointment: values.disappointment,
        primaryBenefit: values.primaryBenefit,
        nextFeatures: values.nextFeatures,
      },
    })
      .then(() => {
        if (
          values.satisfaction === "High" ||
          values.satisfaction === "VeryHigh"
        ) {
          setStep(5);
        } else {
          setOpen(false);
        }
        setSurveySubmitted(true);
      })
      .catch((error) => {
        reportError(error);
        setOpen(false);
      })
      .finally(() => resetSubmit());
    track("SurveyFeedbackCreated", {
      authUser: authUser.id,
      viewAs,
      satisfaction: values.satisfaction,
      disappointment: values.disappointment,
      primaryBenefit: values.primaryBenefit,
      nextFeatures: values.nextFeatures,
    });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {(form) => (
        <SurveyFeedbackForm
          step={step}
          setStep={setStep}
          form={form}
          submitting={submitting}
        />
      )}
    </Formik>
  );
};

interface FormValues {
  satisfaction?: SatisfactionType;
  disappointment?: DisappointmentType;
  primaryBenefit?: string;
  nextFeatures?: string;
}

type FormProps = {
  form: FormikProps<FormValues>;
  step: number;
  setStep: (step: number) => void;
  submitting: boolean;
};

const SurveyFeedbackForm = ({ step, setStep, form, submitting }: FormProps) => {
  const stepClassName = clsx("flex grow flex-col gap-12 p-6");
  const headingClassName = clsx(
    "h-16 text-center text-2xl font-bold text-default"
  );
  const textareaClassName = clsx(
    "w-full resize-none rounded-lg border border-gray-200 p-4 text-default placeholder:text-gray-400 hover:border-gray-500 focus:border-blue-500"
  );
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const navigateForward = () => {
    setStep(step + 1);
  };

  const navigateBackward = () => {
    setStep(step - 1);
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(
        textareaRef.current.value.length,
        textareaRef.current.value.length
      );
    }
  }, [step]);

  return (
    <Form>
      <div className="flex h-[22rem] min-h-full w-full flex-col justify-start gap-8">
        {step === 1 && (
          <div className={stepClassName}>
            <h6 className={headingClassName}>
              <Trans>How satisfied are you with Proper?</Trans>
            </h6>
            <Satisfaction value={form.values.satisfaction} />
          </div>
        )}
        {step === 2 && (
          <div className={stepClassName}>
            <h6 className={headingClassName}>
              <Trans>How would you feel if Proper didn't exist tomorrow?</Trans>
            </h6>
            <Disappointment value={form.values.disappointment} />
          </div>
        )}
        {step === 3 && (
          <div className={stepClassName}>
            <h6 className={headingClassName}>
              <Trans>What is the primary benefit of using Proper?</Trans>
            </h6>
            <Field
              name="primaryBenefit"
              component="textarea"
              innerRef={textareaRef}
              rows="3"
              placeholder={t`I benefit from...`}
              className={textareaClassName}
            />
          </div>
        )}
        {step === 4 && (
          <div className={stepClassName}>
            <h6 className={headingClassName}>
              <Trans>What would you like us to work on next and why?</Trans>
            </h6>
            <Field
              name="nextFeatures"
              component="textarea"
              innerRef={textareaRef}
              rows="3"
              placeholder={t`I would like to see next...`}
              className={textareaClassName}
            />
          </div>
        )}
        <AutoNavigate navigateForward={navigateForward} />
        <div className="flex flex-row gap-6">
          <div className="flex w-1/2 flex-row justify-end">
            {step >= 2 && (
              <Button
                variant="text"
                onClick={() => navigateBackward()}
                StartIcon={ArrowLeft}
              >
                <Trans>Back</Trans>
              </Button>
            )}
          </div>
          <div className="flex w-1/2 flex-row justify-start">
            {(step === 2 || step === 3) && (
              <Button
                theme="primary"
                onClick={() => navigateForward()}
                EndIcon={ArrowRight}
              >
                <Trans>Next</Trans>
              </Button>
            )}
            {step === 4 && (
              <Button
                type="submit"
                theme="primary"
                disabled={submitting || !form.values.satisfaction}
                EndIcon={Check}
                loading={submitting}
              >
                <Trans>Send</Trans>
              </Button>
            )}
          </div>
        </div>
      </div>
    </Form>
  );
};

type AutoNavigateProps = {
  navigateForward: () => void;
};
const AutoNavigate = ({ navigateForward }: AutoNavigateProps) => {
  const { values } = useFormikContext();
  const { satisfaction, disappointment } = values as FormData;

  useEffect(() => {
    if (satisfaction) {
      navigateForward();
    }
  }, [satisfaction]);

  useEffect(() => {
    if (disappointment) {
      navigateForward();
    }
  }, [disappointment]);

  return null;
};

export default SurveyFeedbackModal;
