import styled, { keyframes } from "styled-components";

import {
  baseUnit,
  support500,
  white,
} from "~components/copied_from_shared/pui";
import {
  AlertCircle,
  Loader,
  Minus,
  Smile,
} from "~components/copied_from_shared/pui/icons";
import { ResourceStatus } from "~hooks/useResource";

const spin = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;

const iconSize = "24px";

const Button = styled.button<{ active: boolean }>`
  background: ${({ active }) => (active ? white : support500)};
  color: ${({ active }) => (active ? support500 : "white")};
  border-radius: 50%;
  border: none;
  width: 38px;
  height: 38px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: ${baseUnit * 2}px;
  right: ${baseUnit * 2}px;
  z-index: 999;

  &:disabled {
    cursor: not-allowed;
  }

  svg {
    width: ${iconSize};
    height: ${iconSize};
  }
`;

const LoaderWrapper = styled.div`
  animation: ${spin} 2s linear infinite;
  width: ${iconSize};
  height: ${iconSize};
`;

type Props = {
  onClick: () => void;
  active: boolean;
  isLoading: boolean;
  error?: string;
};
const Toggle = ({ active, onClick, isLoading, error }: Props) => {
  let icon;
  if (isLoading) {
    icon = (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  } else if (error !== undefined) {
    icon = <AlertCircle />;
  } else if (active) {
    icon = <Minus />;
  } else {
    icon = <Smile />;
  }
  return (
    <Button onClick={onClick} active={active}>
      {icon}
    </Button>
  );
};

export default Toggle;
