import { t } from "@lingui/macro";

import clsx from "clsx";
import { Field } from "formik";

import { Satisfaction as SatisfactionType } from "../../../graphql/generated_types";

type OptionProps = {
  score: 1 | 2 | 3 | 4 | 5;
  satisfaction: SatisfactionType;
  description?: string;
  active?: boolean;
};
const Option = ({ satisfaction, score, active, description }: OptionProps) => {
  const labelClassName = clsx(
    "w-14 cursor-pointer rounded-lg border-2 p-4 text-center text-xl active:bg-blue-600 active:text-white",
    {
      "border-blue-500 bg-blue-500 text-white hover:bg-blue-400": active,
      "border-gray-200 text-default hover:border-blue-500": !active,
    }
  );

  return (
    <div className="flex w-80 flex-col items-center justify-start gap-4">
      <input
        type="radio"
        name="satisfaction"
        id={satisfaction}
        value={satisfaction}
        defaultChecked={active}
        className="fixed w-0 opacity-0"
      />
      <label htmlFor={satisfaction} className={labelClassName}>
        {score}
      </label>
      <span className="flex text-center text-sm text-gray-600">
        {description}
      </span>
    </div>
  );
};

type SatisfactionProps = {
  value?: SatisfactionType;
};
const Satisfaction = ({ value }: SatisfactionProps) => {
  return (
    <Field component="div" name="satisfaction" required>
      <div className="flex w-full flex-row justify-between">
        <Option
          score={1}
          satisfaction={SatisfactionType.VeryLow}
          active={value === SatisfactionType.VeryLow}
          description={t`Very dissatisfied`}
        />
        <Option
          score={2}
          satisfaction={SatisfactionType.Low}
          active={value === SatisfactionType.Low}
        />
        <Option
          score={3}
          satisfaction={SatisfactionType.Medium}
          active={value === SatisfactionType.Medium}
        />
        <Option
          score={4}
          satisfaction={SatisfactionType.High}
          active={value === SatisfactionType.High}
        />
        <Option
          score={5}
          satisfaction={SatisfactionType.VeryHigh}
          active={value === SatisfactionType.VeryHigh}
          description={t`Very satisfied`}
        />
      </div>
    </Field>
  );
};

export default Satisfaction;
