import { RefObject, useEffect, useState } from "react";

function useStickyDetection(ref: RefObject<HTMLElement>): boolean {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        if (entries[0].intersectionRatio === 0) {
          setIsSticky(true);
        } else if (entries[0].intersectionRatio === 1) {
          setIsSticky(false);
        }
      },
      { threshold: [0, 1] }
    );

    if (ref.current) {
      observer.observe(ref.current);

      return function cleanup() {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }
  }, []);

  return isSticky;
}

export default useStickyDetection;
