import { useRouter, useUser } from "~hooks";

import SupportHeader from "./SupportHeader";

const SupportHeaderContainer = () => {
  const {
    isSupport,
    isPaymentsCustomer,
    seeWhatLandlordSees,
    setSeeWhatLandlordSees,
    setViewAs,
  } = useUser();
  const { push } = useRouter();

  const onSearch = (q: string) => {
    push({
      route: `/support/search`,
      query: { q },
    });
  };

  const onViewAsSelected = (id: string | undefined) => {
    push({ route: "/", query: { viewAs: id || "" } });
    // We set the viewAs after a short delay because else it will prevent the page from changing to "/"
    setTimeout(() => {
      setViewAs(id || "");
    }, 500);
  };

  const onGoToOverview = () => {
    push(`/support`);
  };

  const onGoToSearch = () => {
    push("/support/search");
  };

  const onGoToWallE = () => {
    push("/support/wall-e");
  };

  const onGoToReports = () => {
    push("/support/reports");
  };

  const onGoToUsers = () => {
    push("/support/users");
  };

  const onGoToCompanies = () => {
    push("/support/companies");
  };

  if (!isSupport && !seeWhatLandlordSees) {
    return null;
  }

  return (
    <SupportHeader
      onToggleViewAsLandlord={() =>
        setSeeWhatLandlordSees(!seeWhatLandlordSees)
      }
      isPaymentsCustomer={isPaymentsCustomer}
      seeWhatLandlordSees={seeWhatLandlordSees}
      onSearch={onSearch}
      onViewAsSelected={onViewAsSelected}
      onGoToOverview={onGoToOverview}
      onGoToSearch={onGoToSearch}
      onGoToWallE={onGoToWallE}
      onGoToReports={onGoToReports}
      onGoToUsers={onGoToUsers}
      onGoToCompanies={onGoToCompanies}
    />
  );
};

export default SupportHeaderContainer;
