import { Trans } from "@lingui/macro";

import { Tray } from "phosphor-react";

import { Link, Paragraph } from "~components/copied_from_shared/pui";

import { IconWrapper, Wrapper } from "./Phone";

type Props = {
  value?: string;
};
const TalkPrimaryEmail = ({ value }: Props) => (
  <Wrapper>
    <IconWrapper>
      <svg
        width="11"
        height="13"
        viewBox="0 0 11 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.948362 2.04899L0.0276708 1.46785L0.0281105 0.990167L3.52854 0.00323685L4.11482 0.0037765L4.0462 1.65938C4.51605 0.891689 5.43752 0.00499398 6.86087 0.00630411C8.83695 0.00812301 10.242 1.40943 10.2394 4.20887C10.2362 7.70802 8.14123 9.55017 5.49584 9.54773C4.85951 9.54714 4.32394 9.42723 4.03927 9.18813L4.03705 11.5951C4.03649 12.2096 4.33784 12.2441 5.32552 12.3302L5.32491 12.9957L5.4213e-06 12.9908L0.000618528 12.3247C0.670543 12.2743 0.938443 12.2062 0.938946 11.6601L0.947793 2.04899L0.948362 2.04899ZM7.09041 5.16192C7.09248 2.90857 6.30642 1.86668 5.03376 1.86551C4.68233 1.86518 4.34732 1.95009 4.04574 2.15503L4.04132 6.95159C4.0403 8.06116 4.34106 8.74434 5.22874 8.74516C6.43429 8.74569 7.08856 7.17584 7.09041 5.16192Z"
          fill="#939393"
        />
      </svg>
    </IconWrapper>
    {value ? (
      <Link href={`mailto:${value}`} className="break-all">
        {value}
      </Link>
    ) : (
      <Paragraph size="small">
        <Trans>No Talk account</Trans>
      </Paragraph>
    )}
  </Wrapper>
);

export default TalkPrimaryEmail;
