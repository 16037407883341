import { i18n } from "@lingui/core";
import { I18nProvider, useLingui } from "@lingui/react";

import { Fragment, useEffect } from "react";

import { da, de, en, nl } from "make-plural/plurals";

import { useUser } from "~hooks";

i18n.loadLocaleData("en", { plurals: en });
i18n.loadLocaleData("da", { plurals: da });
i18n.loadLocaleData("nl", { plurals: nl });
i18n.loadLocaleData("de", { plurals: de });

export async function activate(locale: "en" | "da" | "nl" | "de") {
  const { messages } = await import(`../../../../locale/${locale}/messages.js`);
  i18n.load(locale, messages);
  i18n.activate(locale);
}

function I18nWatchLocale({ children }: any) {
  const { i18n } = useLingui();

  // Skip render when locale isn't loaded
  if (!i18n.locale) return null;

  // Force re-render when locale changes. Otherwise string translations (e.g.
  // t`Macro`) won't be updated.
  return <Fragment key={i18n.locale}>{children}</Fragment>;
}

const I18n = ({ children }: any) => {
  const { authUser } = useUser();

  useEffect(() => {
    activate(authUser.language || "da");
  }, [authUser.language]);

  return (
    <I18nProvider i18n={i18n}>
      <I18nWatchLocale>{children}</I18nWatchLocale>
    </I18nProvider>
  );
};

export default I18n;
