import { useEffect, useReducer, useState } from "react";

import Uppy from "@uppy/core";
import { useUppy } from "@uppy/react";
import XHRUpload from "@uppy/xhr-upload";
import { v4 as uuidv4 } from "uuid";

import { Provider } from "~contexts/uploads";
import { Document as BEDocument } from "~services/documents";
import { toClient } from "~transformers/document";

import { Relations } from "./types";
import reducer from "./UploadsReducer";

const endpoint = process.env.NEXT_PUBLIC_API_FILES_ENDPOINT as string;

const opts = {
  autoProceed: true,
  allowMultipleUploads: true,
};

const Uploads = ({ children }: any) => {
  const [totalProgress, setTotalProgress] = useState(0);
  const [uploads, dispatch] = useReducer(reducer, []);
  const uppy = useUppy(() => {
    return Uppy(opts).use(XHRUpload, {
      endpoint,
      withCredentials: true,
      fieldName: "the_file",
    });
  });

  const addFile = (file: File, relations: Relations) => {
    uppy.addFile({
      source: "file input",
      name: file.name,
      type: file.type,
      data: file,
      meta: {
        relations: JSON.stringify(relations),
        relativePath: uuidv4(), // Make Uppy think all files are different, to allow multiple uploads of same file
      },
    });
  };

  const add = (
    fileList: FileList,
    relations: Relations,
    options?: Uppy.UppyOptions
  ) => {
    uppy.setOptions({ ...opts, ...options });
    const files = Array.from(fileList);
    files.forEach((file) => addFile(file, relations));
  };

  const remove = (id: string) => {
    uppy.removeFile(id);
  };

  useEffect(() => {
    uppy.on("file-removed", (data) => {
      dispatch({
        type: "remove",
        id: data.id,
      });
    });

    uppy.on("file-added", (data) => {
      const relations = data.meta?.relations
        ? JSON.parse(data.meta.relations)
        : {};
      dispatch({
        type: "add",
        upload: {
          status: "added",
          name: data.name,
          extension: data.extension,
          id: data.id,
          size: data.size,
          progress: data.progress.percentage,
          subject: relations.subject,
        },
      });
    });

    uppy.on("upload-progress", (file, progress) => {
      const percentage = (progress.bytesUploaded / progress.bytesTotal) * 100;
      dispatch({
        type: "update",
        upload: {
          id: file.id,
          progress: parseInt(percentage.toFixed(), 10),
          status: "uploading",
        },
      });
    });

    uppy.on("upload-success", (file, response: any) => {
      const body = JSON.parse(response.body) as BEDocument;
      const documents = toClient([body], endpoint);
      dispatch({
        type: "update",
        upload: {
          id: file.id,
          progress: 100,
          status: "done",
          document: documents[0],
        },
      });
    });

    uppy.on("progress", (progress) => {
      setTotalProgress(progress);
    });
    return () => {
      uppy.close();
    };
  }, []);

  return (
    <Provider value={{ uploads, add, remove, totalProgress }}>
      {children}
    </Provider>
  );
};

export default Uploads;
