import React from "react";

import { Provider } from "./context";
import { Features, UserRole } from "./types";
import useEnabledFeatures from "./useEnabledFeatures";
import { getFeaturesAvailableForUser } from "./utils";

type Props = {
  children: any;
  localStorageKey: string;
  features: Features;
  userRoles: UserRole[];
};
const FeatureTogglerProvider = ({
  children,
  localStorageKey,
  features,
  userRoles,
}: Props) => {
  const [enabledFeatures, setEnabledFeatures] = useEnabledFeatures(
    localStorageKey
  );

  const featuresAvailableForUser = getFeaturesAvailableForUser(
    features,
    userRoles
  );
  return (
    <Provider
      value={{
        localStorageKey,
        userRoles,
        enabledFeatures,
        features,
        featuresAvailableForUser,
        setEnabledFeatures,
      }}
    >
      {children}
    </Provider>
  );
};

export default FeatureTogglerProvider;
