import FieldCore, { Props as FieldCoreProps } from "../FieldCore";

export type Props = Omit<FieldCoreProps, "type">;

const Datalist = ({ children, ...props }: FieldCoreProps) => {
  return (
    <>
      <FieldCore autoComplete="off" type="text" list={props.name} {...props} />
      <datalist id={props.name}>{children}</datalist>
    </>
  );
};

export default Datalist;
