import { useRef } from "react";

import { Provider } from "~contexts/notices";
import { useGet2, useRouter } from "~hooks";
import useDebounce from "~hooks/useDebounce";

import { FeatureProps } from "./types";

const Notices = ({ children }: any) => {
  const router = useRouter();
  const listNotices = useGet2<FeatureProps>("/api/notices");

  const firstMount = useRef(true);

  useDebounce(
    () => {
      if (firstMount.current) {
        firstMount.current = false;
      } else {
        listNotices.update();
      }
    },
    [router.asPath],
    3000
  );

  return (
    <Provider value={{ notices: listNotices.data?.notices ?? [] }}>
      {children}
    </Provider>
  );
};

export default Notices;
