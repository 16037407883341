import { useContext } from "react";

import { Option } from "~components/tailwind/form";
import { Context } from "~contexts/ProductsContext";
import { useUser } from "~hooks/index";
import { PropertiesService } from "~services/properties-service";

const getFallbackProductName = (productName: { [index: string]: string }) => {
  if (productName.en) {
    return productName.en;
  }
  if (productName.da) {
    return productName.da;
  }
  if (productName.de) {
    return productName.de;
  }
  if (productName.nl) {
    return productName.nl;
  }
  return productName[Object.keys(productName)[0]];
};

type ProductKey = string;

export type Productable = {
  productKey: string;
  productName?: { [locale: string]: string };
};

export type CustomProduct = {
  productKey: string;
  productId: string;
  productName: string;
  archived: boolean;
  position: number;
};
const useProducts = (
  context: PropertiesService.Product$Context = "All"
): {
  products: {
    productKey: string;
    productId?: string;
    chargeType?: string;
    productName: string;
    custom: boolean;
    productNameMap: { [locale: string]: string };
  }[];
  staticProducts: {
    productKey: string;
    productName: string;
  }[];
  customProducts: CustomProduct[];
  archivedProducts: CustomProduct[];
  allProductOptions: Option[];
  productOptions: Option[];
  getProductName: (arg: ProductKey | Productable) => string;
} => {
  const { authUser } = useUser();
  const productsContext = useContext(Context);

  const locale = authUser.language;
  const productsInContext =
    productsContext.products[context] ?? productsContext.products["All"];

  const products = productsInContext!.map((product) => ({
    productKey: product.productKey,
    productId: product.productId,
    productName: product.productName[locale]
      ? product.productName[locale]
      : getFallbackProductName(product.productName),
    custom: product.custom,
    position: product.position,
    archived: product.archived,
    productNameMap: product.productName,
    chargeType: !product.custom ? product.productKey : undefined,
  }));

  const staticProducts = products.filter((product) => !product.custom);

  const customProducts = products
    .filter((product) => product.custom)
    .filter((product) => !product.archived);

  const archivedProducts = products
    .filter((product) => product.custom)
    .filter((product) => product.archived);

  const allProductOptions = products.map((product) => {
    return {
      value: product.productKey,
      text: product.productName,
      archived: product.archived,
    };
  });

  const getProductName = (arg: ProductKey | Productable): string => {
    if (typeof arg === "string") {
      const productOption = allProductOptions.find(
        (option) => option.value === arg
      );

      if (productOption) {
        return productOption.text;
      }
      return arg;
    }

    const productName = arg.productName;
    if (productName) {
      if (productName[locale]) {
        return productName[locale];
      }
      return getFallbackProductName(productName);
    }
    return getProductName(arg.productKey);
  };

  return {
    products,
    staticProducts,
    customProducts,
    archivedProducts,
    allProductOptions: allProductOptions,
    productOptions: allProductOptions.filter((option) => !option.archived),
    getProductName,
  };
};

export default useProducts;
