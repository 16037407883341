import clsx from "clsx";
import { CircleNotch, IconProps } from "phosphor-react";

type Props = IconProps & {
  className?: string;
};
const Spinner = ({ className, ...iconProps }: Props) => (
  <CircleNotch className={clsx("animate-spin", className)} {...iconProps} />
);

export default Spinner;
