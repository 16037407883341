import * as React from "react";

import styled from "styled-components";

import { baseUnit, white } from "../../tokens";

export const boxShadow = "0 8px 30px rgba(0, 0, 0, 0.1)";

export const boxShadowHover = "0 10px 35px rgba(0, 0, 0, 0.2)";

export const borderRadius = "6px";

export const Wrapper = styled.div<{ onClick: any }>`
  padding: ${baseUnit * 2}px;
  background: ${white};
  border-radius: ${borderRadius};
  box-shadow: ${boxShadow};
  cursor: ${({ onClick }) => (onClick ? "pointer" : "initial")};
  transition: box-shadow 150ms;

  & + & {
    margin-top: ${baseUnit * 3}px;
  }

  &:hover {
    box-shadow: ${({ onClick }) => (onClick ? boxShadowHover : boxShadow)};
  }
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const CardHeaderActions = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  flex-wrap: wrap;

  > *:not(:first-child) {
    margin-left: ${baseUnit}px;
  }
`;

export const CardActions = styled.div`
  margin-top: ${baseUnit * 3}px;
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: ${baseUnit * 2}px;
  }
`;

export const CardContent = styled.div`
  margin-top: ${baseUnit * 2}px;
`;

type Props = {
  children: React.ReactNode;
  onClick?: () => void;
};

const Card = ({ children, onClick, ...rest }: Props) => {
  return (
    <Wrapper onClick={onClick && onClick} {...rest}>
      {children}
    </Wrapper>
  );
};

export default Card;
