import { t } from "@lingui/macro";

import { useEffect } from "react";

import { Status as FormFieldStatus } from "~components/copied_from_shared/pui/components/form/Form/types";
import useAddressSuggestions, {
  Status as UseAddressSuggestionsStatus,
} from "~hooks/useAddressSuggestions";

import FieldCore, { Props as FieldCoreProps } from "../FieldCore";
import { useFormContext } from "../Form";

const getStatus = (
  cannotVerifyAddress: boolean,
  searchStatus: UseAddressSuggestionsStatus
): FormFieldStatus => {
  if (cannotVerifyAddress) {
    return "warning";
  } else if (searchStatus === "idle") {
    return "default";
  } else if (searchStatus === "error") {
    return "invalid";
  }

  return searchStatus;
};

export type Props = Omit<FieldCoreProps, "type">;

const Address = (props: FieldCoreProps) => {
  const [suggestions, { search, searchStatus }] = useAddressSuggestions();

  const { getValues } = useFormContext();
  const value = getValues(props.name);
  const cannotVerifyAddress = !!value && !suggestions.length;
  const status = getStatus(cannotVerifyAddress, searchStatus);

  useEffect(() => {
    search(value);
  }, []);

  return (
    <FieldCore
      type="autocomplete"
      items={suggestions || []}
      hint={
        cannotVerifyAddress
          ? t`We couldn't verify the address. This might be okay if this is not yet a registered address. Otherwise, ensure that the address is formatted correctly.`
          : null
      }
      status={status}
      // @ts-ignore FIXME
      onChange={search}
      {...props}
    />
  );
};

export default Address;
