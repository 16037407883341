import { createContext } from "react";

import {
  AwaitedReport,
  Report,
} from "~components/connected/app/Providers/Reports";

export type ContextType = {
  reports: Report[];
  onAwaitReport: (report: AwaitedReport) => void;
  onReportDownloaded: (documentId: string) => void;
};

export const Context = createContext<ContextType>({
  reports: [],
  onAwaitReport: () => {
    // do nothing
  },
  onReportDownloaded: () => {
    // do nothing
  },
});

export const Provider = Context.Provider;
