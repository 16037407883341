import { t } from "@lingui/macro";

import { getMonth } from "~utils/translation";

import FieldCore, { Props as FieldCoreProps } from "../FieldCore";

export type Props = Omit<FieldCoreProps, "type">;

const Month = (props: FieldCoreProps) => (
  <FieldCore {...props} type="select" placeholder={t`Choose month`}>
    <option value={1}>{getMonth(1)}</option>
    <option value={2}>{getMonth(2)}</option>
    <option value={3}>{getMonth(3)}</option>
    <option value={4}>{getMonth(4)}</option>
    <option value={5}>{getMonth(5)}</option>
    <option value={6}>{getMonth(6)}</option>
    <option value={7}>{getMonth(7)}</option>
    <option value={8}>{getMonth(8)}</option>
    <option value={9}>{getMonth(9)}</option>
    <option value={10}>{getMonth(10)}</option>
    <option value={11}>{getMonth(11)}</option>
    <option value={12}>{getMonth(12)}</option>
  </FieldCore>
);

export default Month;
