import { useEffect } from "react";

import { darken, lighten } from "polished";
import { Provider as ReakitProvider } from "reakit";
import { createGlobalStyle } from "styled-components";

import { fontSmoothing } from "../../mixins";
import { addFocusControl } from "../../utils";
import ThemeProvider from "../ThemeProvider";
import { Theme } from "../ThemeProvider/ThemeProvider";

const white = "#ffffff";
const black = "#262626";

const gray = lighten(0.5, black);
const gray100 = lighten(0.3, gray);
const gray200 = lighten(0.25, gray);
const gray300 = lighten(0.15, gray);
const gray400 = lighten(0.075, gray);
const gray500 = gray;
const gray600 = darken(0.075, gray);
const gray700 = darken(0.15, gray);
const gray800 = darken(0.225, gray);
const gray900 = darken(0.3, gray);

const pampas = lighten(0.01, "#f7f5f2");
const pampas100 = pampas;
const pampas200 = darken(0.05, pampas);
const pampas300 = darken(0.1, pampas);
const pampas400 = darken(0.15, pampas);
const pampas500 = darken(0.2, pampas);
const pampas600 = darken(0.25, pampas);
const pampas700 = darken(0.3, pampas);
const pampas800 = darken(0.35, pampas);
const pampas900 = darken(0.4, pampas);

const pampasInversed100 = black;
const pampasInversed200 = lighten(0.05, black);
const pampasInversed300 = lighten(0.1, black);
const pampasInversed400 = lighten(0.15, black);
const pampasInversed500 = lighten(0.2, black);
const pampasInversed600 = lighten(0.25, black);
const pampasInversed700 = lighten(0.3, black);
const pampasInversed800 = lighten(0.35, black);
const pampasInversed900 = lighten(0.4, black);

const whiteInversed = pampasInversed200;
const blackInversed = white;

const Styles = createGlobalStyle`
  @font-face {
    font-family: 'Tiempos';
    src:
      url('https://uploads-ssl.webflow.com/5e663bec8b193243e06d4445/5e67baf2dcdb91a493aa2187_TiemposHeadlineWeb-Bold.woff2') format('woff2'),
      url('https://uploads-ssl.webflow.com/5e663bec8b193243e06d4445/5e67baf201cf83081400e383_TiemposHeadlineWeb-Bold.woff') format('woff');
    font-weight: bold;
  }
  @font-face {
    font-family: 'Tiempos';
    src:
      url('https://uploads-ssl.webflow.com/5e663bec8b193243e06d4445/5e67baf3a0420c0c20da3804_TiemposHeadlineWeb-BoldItalic.woff2') format('woff2'),
      url('https://uploads-ssl.webflow.com/5e663bec8b193243e06d4445/5e67baf2dcdb91bdb9aa2188_TiemposHeadlineWeb-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
  }
  @font-face {
    font-family: 'Moderat';
    src:
      url('https://uploads-ssl.webflow.com/5e663bec8b193243e06d4445/5e67bb2a9da113e18ee899ed_Moderat-Regular.woff2') format('woff2'),
      url('https://uploads-ssl.webflow.com/5e663bec8b193243e06d4445/5e67bb2a1e923e048850ab95_Moderat-Regular.woff') format('woff');
  }
  @font-face {
    font-family: 'Moderat';
    src:
      url('https://uploads-ssl.webflow.com/5e663bec8b193243e06d4445/5e67bb29bbc480137263d6c3_Moderat-Light.woff2') format('woff2'),
      url('https://uploads-ssl.webflow.com/5e663bec8b193243e06d4445/5e67bb2901cf8348d900e3e4_Moderat-Light.woff') format('woff');
    font-weight: 300;
  }
  @font-face {
    font-family: 'Moderat';
    src:
      url('https://uploads-ssl.webflow.com/5e663bec8b193243e06d4445/5e67bb29a1907044c8cc9617_Moderat-Bold.woff2') format('woff2'),
      url('https://uploads-ssl.webflow.com/5e663bec8b193243e06d4445/5e67bb29dcdb910882aa22d8_Moderat-Bold.woff') format('woff');
    font-weight: bold;
  }

  body {
    --white: ${white};
    --black: ${black};

    --pampas100: ${pampas100};
    --pampas200: ${pampas200};
    --pampas300: ${pampas300};
    --pampas400: ${pampas400};
    --pampas500: ${pampas500};
    --pampas600: ${pampas600};
    --pampas700: ${pampas700};
    --pampas800: ${pampas800};
    --pampas900: ${pampas900};

    --gray100: ${gray100};
    --gray200: ${gray200};
    --gray300: ${gray300};
    --gray400: ${gray400};
    --gray500: ${gray500};
    --gray600: ${gray600};
    --gray700: ${gray700};
    --gray800: ${gray800};
    --gray900: ${gray900};

    &.dark {
      --white: ${whiteInversed};
      --black: ${blackInversed};

      --pampas100: ${pampasInversed100};
      --pampas200: ${pampasInversed200};
      --pampas300: ${pampasInversed300};
      --pampas400: ${pampasInversed400};
      --pampas500: ${pampasInversed500};
      --pampas600: ${pampasInversed600};
      --pampas700: ${pampasInversed700};
      --pampas800: ${pampasInversed800};
      --pampas900: ${pampasInversed900};

      --gray100: ${pampasInversed200};
      --gray200: ${pampasInversed300};
      --gray300: ${pampasInversed400};
      --gray400: ${pampasInversed500};
      --gray500: ${pampasInversed600};
      --gray600: ${pampasInversed700};
      --gray700: ${pampasInversed800};
      --gray800: ${pampasInversed900};
      --gray900: ${pampasInversed900};
    }

    *,
    *::before,
    *::after {
      box-sizing: border-box
    }

    &.is-mouse-mode *:focus {
        outline: none;
    }

    ${fontSmoothing};
  }
`;

type Props = {
  children: any;
  theme?: Theme;
};
const Provider = ({ children, theme = "default" }: Props) => {
  useEffect(() => {
    addFocusControl();
  });

  return (
    <ReakitProvider>
      <ThemeProvider theme={theme}>
        <Styles />
        {children}
      </ThemeProvider>
    </ReakitProvider>
  );
};

export default Provider;
