import { useState } from "react";

import NumberFormat from "react-number-format";

import { TextField } from "~components/copied_from_shared/pui";

import FieldCore, { Props as FieldCoreProps } from "../FieldCore";

const PercentageField = ({ ...rest }) => {
  return <TextField postfix="%" {...rest} />;
};

const Input = ({ onChange, value, min, max, ...rest }: any) => {
  const [localValue, setLocalValue] = useState<number | undefined>(
    parseFloat(value)
  );

  return (
    <NumberFormat
      {...rest}
      value={localValue}
      customInput={PercentageField}
      onValueChange={(target) => {
        let floatValue = target.floatValue;
        if (max !== undefined && floatValue !== undefined && floatValue > max) {
          floatValue = max;
        } else if (
          min !== undefined &&
          floatValue !== undefined &&
          floatValue < min
        ) {
          floatValue = min;
        }
        setLocalValue(floatValue);
        onChange(floatValue);
      }}
      decimalScale={2}
      decimalSeparator=","
      allowNegative={false}
    />
  );
};

export type Props = Omit<FieldCoreProps, "type">;

const Percentage = (props: Props) => {
  return <FieldCore {...props} as={Input} />;
};

export default Percentage;
