import { createContext } from "react";

import { User } from "~types";

export type ContextType = {
  user: User;
  viewAs: string | undefined | null;
  seeWhatLandlordSees: boolean;
  setSeeWhatLandlordSees: (seeWhatLandlordSees: boolean) => void;
  setViewAs: (viewAs: string | null) => void;
};

export const Context = createContext<ContextType>({
  user: {
    id: "",
    name: "",
    canViewAs: [],
    roles: [],
    language: "da",
    email: "",
    tenantFacingCommunicationDetails: {
      name: "",
    },
    customerType: "PropertiesCustomer",
  },
  viewAs: null,
  seeWhatLandlordSees: false,
  setSeeWhatLandlordSees: () => {
    // do nothing
  },
  setViewAs: () => {
    // do nothing
  },
});

export const Provider = Context.Provider;
