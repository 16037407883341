import styled from "styled-components";

import {
  baseUnit,
  fontStack,
  textColorDiscreet,
} from "~components/copied_from_shared/pui";

const Wrapper = styled.div`
  ${fontStack};
  margin-bottom: ${baseUnit}px;
  font-weight: bold;
  font-size: 15px;
  color: ${textColorDiscreet};

  &:not(:first-child) {
    margin-top: ${baseUnit * 2}px;
  }
`;

type Props = {
  children: React.ReactNode;
};
export const NavigationLabel = ({ children }: Props) => (
  <Wrapper>{children}</Wrapper>
);

export default NavigationLabel;
