import { useEffect, useState } from "react";

type Options = {
  itemsPerPage?: number;
  initialPageNumber?: number;
};
const usePagination = <Item,>(items: Item[], options?: Options) => {
  const initialPageNumber = options?.initialPageNumber || 1;
  const itemsPerPage = options?.itemsPerPage || 10;

  const [currentPageNumber, setCurrentPage] = useState(initialPageNumber);

  const slicedItems = items.slice(
    (currentPageNumber - 1) * itemsPerPage,
    currentPageNumber * itemsPerPage
  );

  const canGoToNextPage = currentPageNumber * itemsPerPage < items.length;
  const canGoToPreviousPage = currentPageNumber > 1;

  const numPages = Math.ceil(items.length / itemsPerPage);

  const onGoToNextPage = () => {
    setCurrentPage(currentPageNumber + 1);
  };

  const onGoToPreviousPage = () => {
    setCurrentPage(currentPageNumber - 1);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [items.length]);

  return {
    items: slicedItems,
    numPages,
    currentPageNumber,
    onGoToNextPage,
    canGoToNextPage,
    onGoToPreviousPage,
    canGoToPreviousPage,
  };
};

export default usePagination;
