import { useEffect, useState } from "react";

export default function useIsWindowActive() {
  const [windowIsActive, setWindowIsActive] = useState(true);

  const callback = (e: Event) => {
    if (e?.type == "focus") {
      setWindowIsActive(true);
    } else if (e?.type == "blur") {
      setWindowIsActive(false);
    } else if (e?.type == "visibilitychange") {
      setWindowIsActive(!document.hidden);
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", callback);
    document.addEventListener("blur", callback);
    window.addEventListener("blur", callback);
    window.addEventListener("focus", callback);
    document.addEventListener("focus", callback);

    return () => {
      window.removeEventListener("blur", callback);
      document.removeEventListener("blur", callback);
      window.removeEventListener("focus", callback);
      document.removeEventListener("focus", callback);
      document.removeEventListener("visibilitychange", callback);
    };
  }, []);

  return windowIsActive;
}
