import { Provider as PuiProvider } from "~components/copied_from_shared/pui";

import GlobalStyle from "./GlobalStyle";
import Notifications from "./Notifications";

const PublicProviders = ({ children }: any) => (
  <PuiProvider>
    <GlobalStyle />
    <Notifications>{children}</Notifications>
  </PuiProvider>
);

export default PublicProviders;
