import { t, Trans } from "@lingui/macro";

import { useEffect, useState } from "react";

import {
  Button,
  DialogActions,
  DialogBody,
  DialogDeprecated,
  DialogHeader,
  DialogHeaderTitle,
  Paragraph,
  useDialogState,
} from "~components/copied_from_shared/pui";
import { PostStatus } from "~hooks/usePost";
import { ResourceStatus } from "~hooks/useResource";

type Status = ResourceStatus | PostStatus;

type Props = {
  status: ResourceStatus | PostStatus;
  onConfirm: () => void;
  children: any;
  dialogTitle?: string;
  dialogBody?: string;
  disabled?: boolean;
  tooltip?: string;
};

const deleteStates: Status[] = ["deleting"];
const errorStates: Status[] = ["delete-failed", "failed", "not-found"];
const doneStates: Status[] = ["idle"];

const DeleteButton = ({
  status,
  children,
  onConfirm,
  dialogTitle,
  dialogBody,
  disabled,
  tooltip,
}: Props) => {
  const dialog = useDialogState();
  const [buttonState, setButtonState] = useState<
    "loading" | "done" | "error" | null
  >();

  const setTemporaryButtonState = (state: "done" | "error") => {
    setButtonState(state);
    setTimeout(() => {
      setButtonState(null);
    }, 2000);
  };

  useEffect(() => {
    const isDeleteting = deleteStates.includes(status);
    const isDone = doneStates.includes(status);
    const isError = errorStates.includes(status);

    if (isDeleteting) {
      setButtonState("loading");
    } else if (buttonState === "loading" && isDone) {
      setTemporaryButtonState("done");
    } else if (buttonState === "loading" && isError) {
      setTemporaryButtonState("error");
    }
  }, [status]);

  return (
    <DialogDeprecated
      state={dialog}
      trigger={
        <Button
          tooltip={tooltip}
          disabled={disabled}
          look="discreet"
          state={buttonState}
          type="button"
        >
          {children}
        </Button>
      }
    >
      <DialogHeader>
        <DialogHeaderTitle>{dialogTitle || t`Are you sure?`}</DialogHeaderTitle>
      </DialogHeader>
      <DialogBody>
        <Paragraph>{dialogBody || t`This action cannot be undone.`}</Paragraph>
      </DialogBody>
      <DialogActions>
        <Button
          onClick={async () => {
            await onConfirm();
            dialog.hide();
          }}
          look="danger"
          state={buttonState}
          type="button"
        >
          {children}
        </Button>
        <Button onClick={dialog.hide} look="discreet" type="button">
          <Trans>Go back</Trans>
        </Button>
      </DialogActions>
    </DialogDeprecated>
  );
};

export default DeleteButton;
