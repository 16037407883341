import { addDays, format } from "date-fns";

import { getDateFromLocalDate } from "~utils/getDateFromLocalDate";

type Interval = {
  start: Date;
  end: Date;
};
const getIntervalFromLocalDateInterval = (
  localDateInterval: string
): Interval => {
  const [start, end] = localDateInterval.split("/");
  return {
    start: getDateFromLocalDate(start),
    end: getDateFromLocalDate(end),
  };
};

const localDateIntervalToLocalDateRange = (
  localDateInterval: string
): string => {
  const interval = getIntervalFromLocalDateInterval(localDateInterval);
  const start = interval.start;
  const end = addDays(interval.end, 1);
  return `${format(start, "yyyy-MM-dd")}/${format(end, "yyyy-MM-dd")}`;
};

export default localDateIntervalToLocalDateRange;
