import React, { createContext } from "react";

import { EnabledFeatures, Features, UserRole } from "./types";

export type ContextType = {
  features: Features;
  enabledFeatures: EnabledFeatures;
  setEnabledFeatures: (enabledFeatures: EnabledFeatures) => void;
  localStorageKey: string;
  userRoles: UserRole[];
  featuresAvailableForUser: Features;
};

export const Context = createContext<ContextType>({
  enabledFeatures: {} as EnabledFeatures,
  features: {},
  setEnabledFeatures: () => {},
  localStorageKey: "",
  userRoles: [],
  featuresAvailableForUser: {},
});

export const Provider = Context.Provider;
