import styled from "styled-components";

import Link, { Anchor } from "~components/connected/Link";
import { proper500 } from "~components/copied_from_shared/pui";
import Svg from "~components/Logo";

const StyledAnchor = styled(Anchor)`
  text-decoration: none;
  cursor: pointer;
  display: block;
  top: 4px;
  position: relative;
`;

const Logo = () => (
  <Link to="/">
    <StyledAnchor>
      <Svg fill={proper500} />
    </StyledAnchor>
  </Link>
);

export default Logo;
