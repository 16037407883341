import styled from "styled-components";

import { baseUnit, Paragraph } from "~components/copied_from_shared/pui";
import CopyButton from "~components/CopyButton";

export const ItemWrapper = styled(Paragraph).attrs({ size: "small" })`
  display: flex;

  &:not(:last-child) {
    margin-bottom: ${baseUnit / 2}px;
  }
`;

const Label = styled.span`
  font-weight: bold;
  padding-right: ${baseUnit / 3}px;
`;

type ItemProps = {
  label: any;
  children: any;
  copyValue?: string;
};
export const Item = ({ label, children, copyValue }: ItemProps) => (
  <ItemWrapper>
    <Label>{label}</Label>
    <CopyButton text={copyValue}>{children}</CopyButton>
  </ItemWrapper>
);

export default Item;
