import { t } from "@lingui/macro";

import {
  Table as PUITable,
  TableActions as PUITableActions,
  TableNextPageButton as PUITableNextPageButton,
  TablePageIndicator as PUITablePageIndicator,
  TablePagination as PUITablePagination,
  TablePreviousPageButton as PUITablePreviousPageButton,
  TBody as PUITBody,
  TdAction as PUITdAction,
  TdActionMenu as PUITdActionMenu,
  TdActionMenuItem as PUITdActionMenuItem,
  TdActionMenuSection as PUITdActionMenuSection,
  TdInfo as PUITdInfo,
  TFoot as PUITFoot,
  Th as PUITh,
  THead as PUITHead,
  Tr as PUITr,
  useTablePagination as PUIuseTablePagination,
} from "~components/copied_from_shared/pui";
import { Minus } from "~components/copied_from_shared/pui/icons";

export const Table = PUITable;
export const THead = PUITHead;
export const TBody = PUITBody;
export const Tr = PUITr;
export const Th = PUITh;
export const TdActionMenuItem = PUITdActionMenuItem;
export const TdActionMenuSection = PUITdActionMenuSection;
export const TablePagination = PUITablePagination;
export const useTablePagination = PUIuseTablePagination;
export const TableNextPageButton = PUITableNextPageButton;
export const TablePageIndicator = PUITablePageIndicator;
export const TablePreviousPageButton = PUITablePreviousPageButton;
export const TableActions = PUITableActions;
export const TFoot = PUITFoot;

export const TdActionMenu = ({
  children,
  state,
}: {
  children: any;
  state?: any;
}) => (
  <PUITdActionMenu tooltip={t`Actions`} state={state}>
    {children}
  </PUITdActionMenu>
);

export const TdInfo = (
  props: React.ComponentProps<typeof PUITdInfo> | { notAvailable: true }
) => {
  if ("notAvailable" in props) {
    return <PUITdAction Icon={Minus} {...props} />;
  }
  return <PUITdInfo {...props} />;
};

export const TdAction = (
  props: React.ComponentProps<typeof PUITdAction> | { notAvailable: true }
) => {
  if ("notAvailable" in props) {
    return <PUITdAction disabled Icon={Minus} />;
  }
  return <PUITdAction {...props} />;
};

export const ThInfo = () => <Th type="info" tooltip={t`Row info`} />;
export const ThActions = () => <Th type="actions" tooltip={t`Row actions`} />;

export default Table;
