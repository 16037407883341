type ObjectKeyType = string | number;

function toObject<ElementType, ObjectValueType>(
  elements: ElementType[],
  key: (element: ElementType) => ObjectKeyType,
  value: ObjectValueType | ((element: ElementType) => ObjectValueType)
): { [key in ObjectKeyType]: ObjectValueType } {
  return elements.reduce(
    (
      out: { [key in ObjectKeyType]: ObjectValueType },
      element: ElementType
    ) => {
      out[key(element)] = value instanceof Function ? value(element) : value;
      return out;
    },
    {}
  );
}

export default toObject;
