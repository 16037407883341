import { t } from "@lingui/macro";

import FieldCore, { Props as FieldCoreProps } from "../FieldCore";

export type Props = Omit<FieldCoreProps, "type">;

const Quarter = (props: Props) => (
  <FieldCore {...props} type="select" placeholder={t`Choose quarter`}>
    <option value={1}>Q1</option>
    <option value={2}>Q2</option>
    <option value={3}>Q3</option>
    <option value={4}>Q4</option>
  </FieldCore>
);

export default Quarter;
