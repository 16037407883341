import { forwardRef } from "react";

import styled from "styled-components";

import {
  borderRadiusInput,
  fontFamilySans,
  fontSmoothing,
  heightInput,
  primary500,
} from "~components/copied_from_shared/pui";

import getLandlordInitials from "../getLandlordInitials";

const Trigger = styled.button`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${fontSmoothing};
  color: white;
  font-size: 17px;
  font-weight: bold;
  text-transform: uppercase;
  background: ${primary500};
  border: none;
  font-family: ${fontFamilySans};
  width: ${heightInput + 2}px;
  flex: 0 0 ${heightInput + 2}px;
  height: ${heightInput + 2}px;
  border-radius: ${borderRadiusInput};
  cursor: pointer;
`;

type Props = {
  userId: string;
  userName?: string;
  onClick?: () => void;
};
const MenuTrigger = ({ userId, userName, ...rest }: Props, ref: any) => (
  <Trigger {...rest} ref={ref}>
    {getLandlordInitials(userId, userName)}
  </Trigger>
);

export default forwardRef(MenuTrigger);
