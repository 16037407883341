import React from "react";

import styled from "styled-components";

import {
  baseUnit,
  Heading,
  Paragraph,
} from "~components/copied_from_shared/pui";

import FeatureTogglesForm, { Props } from "./FeatureTogglesForm";

const Wrapper = styled.div`
  padding: ${baseUnit * 2}px;
`;

const Title = styled(Heading).attrs({ size: "small" })`
  margin-bottom: ${baseUnit}px;
`;

const FeatureToggler = ({
  onApplyChanges,
  featuresAvailableForUser,
  enabledFeatures,
}: Props) => {
  let content;
  if (!Object.keys(featuresAvailableForUser).length) {
    content = <Paragraph>There are currently hidden features.</Paragraph>;
  }
  content = (
    <FeatureTogglesForm
      onApplyChanges={onApplyChanges}
      featuresAvailableForUser={featuresAvailableForUser}
      enabledFeatures={enabledFeatures}
    />
  );

  return (
    <Wrapper>
      <Title>Feature toggles</Title>
      {content}
    </Wrapper>
  );
};

export default FeatureToggler;
