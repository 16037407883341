import { t } from "@lingui/macro";

import {
  FeatureToggles,
  useFeatureToggles,
} from "~components/copied_from_shared/feature-toggler";
import { Popover, usePopoverState } from "~components/copied_from_shared/pui";
import { Truck } from "~components/copied_from_shared/pui/icons";

import Action from "./Action";

const FeatureToggler = () => {
  const popover = usePopoverState();
  const { numFeaturesEnabled } = useFeatureToggles();

  return (
    <Popover
      ariaLabel={t`Feature toggles`}
      state={popover}
      trigger={
        <Action Icon={Truck}>
          {numFeaturesEnabled !== 0 && <span>{numFeaturesEnabled}</span>}
        </Action>
      }
    >
      <FeatureToggles />
    </Popover>
  );
};

export default FeatureToggler;
