import * as React from "react";

import styled from "styled-components";

import { AlertCircle, CheckCircle, Info } from "../../icons";
import {
  baseUnit,
  danger300,
  danger500,
  info300,
  info500,
  warning300,
  warning700,
} from "../../tokens";
import { variants } from "../../utils";
import Paragraph from "../Paragraph";

type Status = "default" | "info" | "warning" | "danger" | "success";

const iconColor = variants("status", {
  default: { default: info500 },
  info: { default: info500 },
  warning: { default: warning700 },
  danger: { default: danger500 },
  success: { default: info500 },
});

const borderColor = variants("status", {
  default: { default: info300 },
  info: { default: info300 },
  warning: { default: warning300 },
  danger: { default: danger300 },
  success: { default: info500 },
});

type WrapperType = {
  status: Status;
};
const Wrapper = styled.div<WrapperType>`
  display: flex;
  padding: ${baseUnit}px;
  border: 1px solid ${borderColor};
  border-radius: 2px;

  svg {
    color: ${iconColor};
    /* a bit hacky as this depends on paragraph line height */
    margin-top: 2px;
    margin-right: ${baseUnit / 1.5}px;
    flex: 0 0 20px;
    height: 20px;
  }
`;

const getIcon = (status: Status) => {
  if (status === "info") {
    return Info;
  } else if (status === "warning") {
    return AlertCircle;
  } else if (status === "success") {
    return CheckCircle;
  }

  return Info;
};

const ActionWrapper = styled.div`
  margin-left: auto;
`;

type Props = {
  children: React.ReactNode;
  status?: Status;
  action?: any;
  style?: any;
};
const InfoBox = ({ children, status = "default", action, ...rest }: Props) => {
  const Icon = getIcon(status);
  return (
    <Wrapper status={status} {...rest}>
      <Icon />
      <Paragraph>{children}</Paragraph>
      {action && <ActionWrapper>{action}</ActionWrapper>}
    </Wrapper>
  );
};

export default InfoBox;
