import CurrencyJs, { Any, Options } from "currency.js";
import {
  currencySymbolMap,
  defaultCurrency,
  localeCurrencyMap,
} from "./constants";
import { Currency, Locale } from "./types";

export class Money {
  money: CurrencyJs;
  currency: Currency;
  currencySymbol: string;
  options: Options;

  constructor(
    input: Any,
    currency: Currency | undefined,
    locale: Locale = "da"
  ) {
    if (input instanceof CurrencyJs) {
      this.currency = currency || defaultCurrency;
      this.money = input;
    } else if (typeof input === "number") {
      this.currency = currency || defaultCurrency;
      this.money = CurrencyJs(input);
    } else if (typeof input === "string") {
      const matchGroups = String(input).match(
        /(?<currency>[A-Z]*) *(?<amount>[+-]?[0-9]*[.,]?[0-9]*)/
      )?.groups;
      this.money = CurrencyJs(matchGroups?.amount || 0);
      this.currency = (matchGroups?.currency as Currency) || defaultCurrency;
      if (
        matchGroups?.currency &&
        currency &&
        matchGroups?.currency !== currency
      ) {
        throw new Error(
          `Currency in input string must match currency defined ${matchGroups?.currency} != ${currency}.`
        );
      }
    } else {
      // TODO: We don't really want to support this case, as but we need to make
      // sure before just throwing as this might break existing functionality
      // throw new Error(`Don't know how to parse input ${input}.`)
      this.currency = currency || defaultCurrency;
      this.money = CurrencyJs(0);
    }

    this.currencySymbol = currencySymbolMap[this.currency];

    if (!this.currencySymbol) {
      throw new Error(`We don't support the currency: ${this.currency}`);
    }

    this.options = {
      ...localeCurrencyMap[locale],
      symbol: this.currencySymbol,
      formatWithSymbol: true,
    };
  }

  get isZero() {
    return this.money.intValue === 0;
  }

  format(options: Options = {}) {
    const newOptions = Object.assign({}, this.options, options);
    return CurrencyJs(this.money, newOptions)
      .format()
      .replace(/ /g, " ")
      .replace("-", "- ");
  }

  add(other: Money) {
    return new Money(this.money.add(other.money), this.getCurrency());
  }

  minus(other: Money) {
    return new Money(this.money.subtract(other.money), this.getCurrency());
  }

  divide(other: number) {
    return new Money(this.money.divide(other), this.getCurrency());
  }

  multiply(other: number) {
    return new Money(this.money.multiply(other), this.getCurrency());
  }

  negate() {
    return new Money(this.money.multiply(-1), this.getCurrency());
  }

  getCurrency(): Currency {
    return this.currency;
  }

  getValue(): number {
    return this.money.value;
  }

  // This method should not return a null, use getIsoFormat instead.
  _deprecated_getIsoFormat(): string | null {
    return this.money.intValue !== 0
      ? `${this.getCurrency()} ${this.money.value}`
      : null;
  }

  getIsoFormat(): string {
    return `${this.getCurrency()} ${this.money.value}`;
  }

  toJSON() {
    return this._deprecated_getIsoFormat();
  }
}

const money = (
  params: Any | Money,
  locale: Locale = "da",
  currencyCode: Currency | undefined = undefined
) => {
  if (params instanceof Money) {
    return params;
  }
  return new Money(params, currencyCode, locale);
};

export default money;
