import { t } from "@lingui/macro";

import { useUser } from "~hooks";

import FieldCore, { Props as FieldCoreProps } from "../FieldCore";

export type Props = Omit<FieldCoreProps, "type">;

const Language = (props: Props) => {
  const { isSupport } = useUser();
  return (
    <FieldCore {...props} type="select">
      <option value="da">{t`Danish`}</option>
      <option value="en">{t`English`}</option>
      <option value="nl" disabled={!isSupport}>
        {t`Dutch`}
      </option>
      <option value="de">{t`German`}</option>
    </FieldCore>
  );
};

export default Language;
