import { Trans } from "@lingui/macro";

import { Suspense } from "react";

import styled from "styled-components";

import { Loader } from "~components";
import { CardFallback } from "~components/Card";
import { appBackgroundColor } from "~components/connected/app/Providers/GlobalStyle";
import {
  baseUnit,
  Card,
  Paragraph,
  textColorDiscreet,
} from "~components/copied_from_shared/pui";
import { AlertCircle } from "~components/copied_from_shared/pui/icons";
import { DialogFallback } from "~components/Dialog";
import TableFallback from "~components/Table/TableFallback";
import { getElementStackOrder } from "~utils";

import ErrorBoundary from "./ErrorBoundary";

const ErrorText = styled(Paragraph).attrs({ look: "discreet" })`
  margin-left: ${baseUnit}px;
`;

const LoadingBox = styled.div<{ minHeight?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${textColorDiscreet};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "40px")};
`;

const zIndexLoadingOverlay = getElementStackOrder("loading-overlay");

const LoadingOverlay = styled.div`
  position: fixed;
  z-index: ${zIndexLoadingOverlay};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${appBackgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${textColorDiscreet};
`;

const StyledCard = styled(Card)<{ minHeight?: string }>`
  height: ${({ minHeight }) => (minHeight ? minHeight : "240px")};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${textColorDiscreet};
`;

const DialogErrorFallback = styled.div<{ minHeight?: string }>`
  height: ${({ minHeight }) => (minHeight ? minHeight : "240px")};
  display: flex;
  padding: ${baseUnit * 4}px ${baseUnit * 8}px;
  align-items: center;
  justify-content: center;
  color: ${textColorDiscreet};
`;

type LoadingCardProps = {
  children: any;
  minHeight?: string;
};
const LoadingCard = ({ children, ...rest }: LoadingCardProps) => (
  <StyledCard {...rest}>{children}</StyledCard>
);

type DefaultErrorFallbackType = {
  card?: boolean;
  dialog?: boolean;
  overlay?: boolean;
};
export const DefaultErrorFallback = ({
  card,
  overlay,
  dialog,
}: DefaultErrorFallbackType) => {
  let Tag;
  if (card) {
    Tag = LoadingCard;
  } else if (dialog) {
    Tag = DialogErrorFallback;
  } else if (overlay) {
    Tag = LoadingOverlay;
  } else {
    Tag = LoadingBox;
  }
  return (
    <Tag>
      <AlertCircle />
      <ErrorText>
        <Trans>Failed to load resource</Trans>
      </ErrorText>
    </Tag>
  );
};

type DefaultLoadingFallbackType = {
  card?: boolean;
  dialog?: boolean;
  overlay?: boolean;
  minHeight?: string;
};
export const DefaultLoadingFallback = ({
  card,
  dialog,
  overlay,
  minHeight,
}: DefaultLoadingFallbackType) => {
  if (card) {
    return (
      <CardFallback>
        <TableFallback rows={5} />
      </CardFallback>
    );
  } else if (dialog) {
    return (
      <DialogFallback>
        <TableFallback rows={5} />
      </DialogFallback>
    );
  } else if (overlay) {
    return (
      <LoadingOverlay>
        <Loader />
      </LoadingOverlay>
    );
  } else {
    return (
      <LoadingBox minHeight={minHeight}>
        <Loader />
      </LoadingBox>
    );
  }
};

type Props = {
  children: any;
  card?: boolean;
  dialog?: boolean;
  overlay?: boolean;
  minHeight?: string;
  LoadingFallback?: React.ComponentType;
  ErrorFallback?: React.ComponentType;
};
const ContentLoader = ({
  children,
  card,
  dialog,
  overlay,
  minHeight,
  LoadingFallback,
  ErrorFallback,
}: Props) => {
  // Suspense doesn't yet work yet for Server-Side Rendering
  if (typeof window === "undefined") {
    return null;
  }

  return (
    <ErrorBoundary
      fallback={
        ErrorFallback ? (
          <ErrorFallback />
        ) : (
          <DefaultErrorFallback card={card} dialog={dialog} />
        )
      }
    >
      <Suspense
        fallback={
          LoadingFallback ? (
            <LoadingFallback />
          ) : (
            <DefaultLoadingFallback
              minHeight={minHeight}
              card={card}
              dialog={dialog}
              overlay={overlay}
            />
          )
        }
      >
        {children}
      </Suspense>
    </ErrorBoundary>
  );
};

export default ContentLoader;
