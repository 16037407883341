import styled, { keyframes } from "styled-components";

import { white } from "~components/copied_from_shared/pui";

import { skeletonColor, skeletonColorDark } from "./tokens";

const gradientLight = `linear-gradient(90deg, ${skeletonColor} 0%, ${skeletonColor} 30%, ${white} 50%, ${skeletonColor} 70%, ${skeletonColor} 100%)`;
const gradientDark = `linear-gradient(90deg, ${skeletonColorDark} 0%, ${skeletonColorDark} 30%, ${skeletonColor} 50%, ${skeletonColorDark} 70%, ${skeletonColorDark} 100%)`;

const shimmer = keyframes`
    100% {
      transform: translateX(100%);
    }
`;

const Wrapper = styled.span<{ dark?: boolean }>`
  background: ${({ dark }) => (dark ? skeletonColorDark : skeletonColor)};
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  opacity: 0.75;
  color: transparent;
  display: inline-flex;
  line-height: 1;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background: ${({ dark }) => (dark ? gradientDark : gradientLight)};
    animation: ${shimmer} 3s infinite 1s;
    content: "";
  }
`;

type Props = {
  width?: any;
  height?: any;
  dark?: boolean;
  style?: any;
  className?: string;
  children?: any;
};
const Skeleton = ({
  width,
  height,
  dark,
  children,
  style = {},
  ...rest
}: Props) => (
  <Wrapper dark={dark} style={{ width, height, ...style }} {...rest}>
    {children || "|"}
  </Wrapper>
);

export default Skeleton;
