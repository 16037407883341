import { useMemo } from "react";

import { useUser } from "~hooks/index";

export default function useStringCompare() {
  const { viewAsUser } = useUser();
  const currentLocale = viewAsUser.localeFromOwnerCountry;

  return useMemo(() => {
    if (typeof Intl === "object" && typeof Intl.Collator === "function") {
      return new Intl.Collator(currentLocale, { sensitivity: "accent" })
        .compare;
    } else {
      return (a: string, b: string) =>
        a.toUpperCase().localeCompare(b.toUpperCase(), currentLocale, {
          sensitivity: "accent",
        });
    }
  }, [currentLocale]);
}
