import { Reducer } from "react";

import { Upload } from "./types";

type AddAction = { type: "add"; upload: Upload };
type UpdateAction = { type: "update"; upload: Partial<Upload> };
type RemoveAction = { type: "remove"; id: string };
type Action = AddAction | UpdateAction | RemoveAction;

const reducer: Reducer<Upload[], Action> = (uploads, action) => {
  switch (action.type) {
    case "add":
      return [...uploads, action.upload];
    case "update": {
      const index = uploads.findIndex(({ id }) => id === action.upload.id);
      const currentUpload = uploads[index];
      const updatedUpload = { ...currentUpload, ...action.upload };
      return [
        ...uploads.slice(0, index),
        updatedUpload,
        ...uploads.slice(index + 1),
      ];
    }
    case "remove": {
      const index = uploads.findIndex(({ id }) => id === action.id);
      return [...uploads.slice(0, index), ...uploads.slice(index + 1)];
    }
    default:
      throw new Error("Unsupported action type");
  }
};

export default reducer;
