import { useEffect } from "react";

const useDebounce = (func: any, dependencies: any[] = [], delay = 300) => {
  let shouldRun = true;
  useEffect(() => {
    const timer = setTimeout(() => {
      if (shouldRun) {
        func();
      }
    }, delay);
    return () => {
      shouldRun = false;
      clearTimeout(timer);
    };
  }, dependencies);
};

export default useDebounce;
