import { FormActions as PUIFormActions } from "~components/copied_from_shared/pui";

import { useFormContext } from "./Form";

const FormActions = ({ children }: any) => {
  const { readOnly } = useFormContext();
  if (readOnly) {
    return null;
  }
  return <PUIFormActions>{children}</PUIFormActions>;
};

export default FormActions;
