import { cloneElement, ReactElement, ReactNode } from "react";

import { X } from "react-feather";
import {
  Dialog as ReakitDialog,
  DialogBackdrop as ReakitDialogBackdrop,
  DialogDisclosure as ReakitDialogDisclosure,
  useDialogState as useReakitDialogState,
} from "reakit/Dialog";
import styled from "styled-components";

import { baseUnit, pampas100, textColor, white } from "../../tokens";
import Heading from "../Heading";

export const borderRadius = "6px";

const backgroundColorOverlay = "rgba(0,0,0,0.5)";
const boxShadowInner = "rgba(0,0,0,0.35)";

const Outer = styled(ReakitDialogBackdrop)`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${backgroundColorOverlay};
`;

const Inner = styled(ReakitDialog)<{
  $maxWidth?: string;
  $minWidth?: string;
  fullScreen?: boolean;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  max-width: calc(100% - ${baseUnit * 2}px);
  max-height: ${({ fullScreen }) =>
    fullScreen ? `100vh` : `calc(100% - ${baseUnit * 6}px)`};
  overflow: auto;
  background-color: ${white};
  box-shadow: 0 0 25px ${boxShadowInner};
  border-radius: ${borderRadius};
  max-width: ${({ $maxWidth, fullScreen }) =>
    fullScreen ? `100vw` : $maxWidth || "800px"};
  min-width: ${({ $minWidth }) => $minWidth || "initial"};
`;

export const DialogHeader = styled.div`
  padding: ${baseUnit * 4}px ${baseUnit * 4}px ${baseUnit * 2}px
    ${baseUnit * 4}px;
`;

const DialogHeaderTitleWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: ${baseUnit / 1.5}px;
  }
`;

type DialogHeaderTitleProps = {
  Icon?: any;
  children: any;
};
export const DialogHeaderTitle = ({
  children,
  Icon,
}: DialogHeaderTitleProps) => (
  <DialogHeaderTitleWrapper>
    {Icon && <Icon size={20} />}
    <Heading size="small">{children}</Heading>
  </DialogHeaderTitleWrapper>
);

export const DialogBody = styled.div`
  padding: 0 ${baseUnit * 4}px ${baseUnit * 4}px ${baseUnit * 4}px;
`;

export const DialogActions = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${baseUnit * 4}px ${baseUnit * 4}px ${baseUnit * 4}px;

  > *:not(:last-child) {
    margin-right: ${baseUnit * 1.5}px;
  }
`;

const CloseButton = styled.button`
  border-radius: 50%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: ${baseUnit * 1.5}px;
  right: ${baseUnit * 1.5}px;
  border: none;
  cursor: pointer;
  color: ${textColor};
  background-color: ${pampas100};
  width: 28px;
  height: 28px;

  &:hover {
    opacity: 0.75;
  }
`;

type DialogCloseButtonProps = {
  onClick: () => void;
};
export const DialogCloseButton = ({ onClick }: DialogCloseButtonProps) => (
  <CloseButton onClick={onClick}>
    <X size={18} />
  </CloseButton>
);

type Props = {
  children: ReactNode;
  trigger?: ReactElement;
  showCloseButton?: boolean;
  state?: any;
  maxWidth?: number | string;
  minWidth?: number | string;
  fullScreen?: boolean;
};
function Dialog({
  trigger,
  children,
  showCloseButton = true,
  state,
  maxWidth,
  minWidth,
  ...rest
}: Props) {
  const dialog = state || useReakitDialogState();
  const parsedMaxWidth =
    typeof maxWidth === "number" ? `${maxWidth}px` : maxWidth;
  const parsedMinWidth =
    typeof minWidth === "number" ? `${minWidth}px` : minWidth;
  return (
    <>
      {trigger && (
        <ReakitDialogDisclosure {...dialog} {...trigger.props}>
          {(disclosureProps) => cloneElement(trigger, disclosureProps)}
        </ReakitDialogDisclosure>
      )}
      {dialog.visible && (
        <Outer
          {...dialog}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Inner
            {...dialog}
            {...rest}
            $maxWidth={parsedMaxWidth}
            $minWidth={parsedMinWidth}
          >
            {children}
            {showCloseButton && <DialogCloseButton onClick={dialog.hide} />}
          </Inner>
        </Outer>
      )}
    </>
  );
}

export const useDialogState = useReakitDialogState;

Dialog.Header = DialogHeader;
Dialog.HeaderTitle = DialogHeaderTitle;
Dialog.Body = DialogBody;
Dialog.Actions = DialogActions;

export default Dialog;
