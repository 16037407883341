import { t } from "@lingui/macro";

import styled from "styled-components";

import { Tooltip } from "~components/copied_from_shared/pui";
import { Copy } from "~components/copied_from_shared/pui/icons";
import { useCopyToClipboard } from "~hooks";

const Wrapper = styled.span`
  position: relative;
`;

const Inner = styled.div`
  position: absolute;
  top: 2px;
  right: -28px;
  display: none;
  padding-left: 28px;

  ${Wrapper}:hover & {
    display: block;
  }
`;

const Button = styled.button`
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

type Props = {
  children: any;
  text?: string;
};
const CopyButton = ({ children, text }: Props) => {
  const [showDidCopy, onCopy] = useCopyToClipboard();
  return (
    <Wrapper>
      {children}
      <Inner>
        <Tooltip title={showDidCopy ? t`Copied!` : t`Copy to clipboard`}>
          <span>
            <Button
              onClick={(e: any) => {
                e.stopPropagation();
                onCopy(text || children);
              }}
            >
              <Copy size={18} />
            </Button>
          </span>
        </Tooltip>
      </Inner>
    </Wrapper>
  );
};

export default CopyButton;
