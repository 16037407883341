import { useContext } from "react";

import { Context } from "~contexts/auth";

const useAuth = () => {
  const auth = useContext(Context);
  return auth;
};

export default useAuth;
