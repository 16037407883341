import { useContext } from "react";

import { Context } from "./context";

const useNotification = () => {
  const notification = useContext(Context);
  return notification;
};

export default useNotification;
