import { Trans } from "@lingui/macro";

import { useEffect, useState } from "react";

import { gql, useMutation } from "@apollo/client";
import { Form, Formik, FormikProps } from "formik";

import { Check } from "~components/copied_from_shared/pui/icons";
import { Button } from "~components/tailwind/Button";
import { Modal } from "~components/tailwind/Modal";
import { useAnalytics, useUser } from "~hooks";
import { reportError } from "~utils";

import { Satisfaction as SatisfactionType } from "../../../graphql/generated_types";

import Satisfaction from "./Satisfaction";
import { FormData } from "./types";

import AddComment from "~features/customer-feedback/ProactiveFeedback/AddComment";
import ExternalServiceReview from "~features/customer-feedback/ProactiveFeedback/ExternalServiceReview";

const RegisterProactiveFeedback = gql`
  mutation RegisterProactiveFeedback(
    $customerId: String!
    $companyId: String!
    $satisfaction: Satisfaction!
    $comment: String
  ) {
    registerProactiveFeedback(
      customerId: $customerId
      companyId: $companyId
      satisfaction: $satisfaction
      comment: $comment
    ) {
      customerId
    }
  }
`;

type Step = "ProactiveFeedbackSubmission" | "ExternalServiceReviewRequest";

export type ModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const ProactiveFeedbackModal = ({ open, setOpen }: ModalProps) => {
  const { authUser, viewAs } = useUser();
  const { track } = useAnalytics();
  const [step, setStep] = useState<Step>("ProactiveFeedbackSubmission");

  useEffect(() => {
    if (open) {
      track("ProactiveFeedbackOpened", {
        authUser: authUser.id,
        viewAs,
      });
    }
  }, [open]);

  return (
    <Modal
      open={open}
      setOpen={(open: boolean) => {
        setOpen(open);
        setTimeout(() => setStep("ProactiveFeedbackSubmission"), 200); // Modal leave animation duration
      }}
    >
      <Modal.Body>
        {step === "ProactiveFeedbackSubmission" && (
          <ProactiveFeedback setOpen={setOpen} setStep={setStep} />
        )}
        {step === "ExternalServiceReviewRequest" && <ExternalServiceReview />}
      </Modal.Body>
    </Modal>
  );
};

export type Props = {
  setOpen: (open: boolean) => void;
  setStep: (step: Step) => void;
};

const ProactiveFeedback = ({ setOpen, setStep }: Props) => {
  const { authUser, viewAs } = useUser();
  const { track } = useAnalytics();

  const initialValues: FormValues = {};

  const [registerProactiveFeedback, { data, loading, error, reset }] =
    useMutation(RegisterProactiveFeedback, {
      fetchPolicy: "no-cache",
    });
  const onSubmit = async (values: FormData) => {
    await registerProactiveFeedback({
      variables: {
        customerId: authUser.id,
        companyId: viewAs,
        satisfaction: values.satisfaction,
        comment: values.comment,
      },
    })
      .then(() => {
        if (
          values.satisfaction === "High" ||
          values.satisfaction === "VeryHigh"
        ) {
          setStep("ExternalServiceReviewRequest");
        } else {
          setOpen(false);
        }
      })
      .catch((error) => {
        reportError(error);
        setOpen(false);
      })
      .finally(() => reset());
    track("ProactiveFeedbackCreated", {
      authUser: authUser.id,
      viewAs,
      satisfaction: values.satisfaction,
      comment: values.comment,
    });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {(form) => <ProactiveFeedbackForm form={form} loading={loading} />}
    </Formik>
  );
};

interface FormValues {
  satisfaction?: SatisfactionType;
  comment?: string;
}

type FormProps = {
  form: FormikProps<FormValues>;
  loading: boolean;
};

const ProactiveFeedbackForm = (props: FormProps) => {
  return (
    <Form>
      <div className="flex flex-col items-center justify-center gap-12">
        <h6 className="text-center text-2xl font-bold text-default">
          <Trans>How satisfied are you with Proper?</Trans>
        </h6>
        <div className="flex max-w-sm flex-col gap-6">
          <Satisfaction value={props.form.values.satisfaction} />

          {props.form.values.satisfaction && (
            <>
              <AddComment satisfaction={props.form.values.satisfaction} />
              <div className="flex flex-row  items-center justify-center gap-6">
                <Button
                  type="submit"
                  theme="primary"
                  disabled={props.loading || !props.form.values.satisfaction}
                  EndIcon={Check}
                  loading={props.loading}
                >
                  <Trans>Send</Trans>
                </Button>
              </div>
            </>
          )}
          {props.form.values.satisfaction && (
            <div className="flex text-center text-sm text-gray-600">
              <span>
                <Trans>
                  If you have a specific issue, please contact our Customer
                  Success team at
                </Trans>{" "}
                <a
                  href="mailto:support@helloproper.com"
                  className="text-blue-500 hover:underline"
                >
                  support@helloproper.com
                </a>
              </span>
            </div>
          )}
        </div>
      </div>
    </Form>
  );
};

export default ProactiveFeedbackModal;
