import { t } from "@lingui/macro";

import FieldCore, { Props as FieldCoreProps } from "../FieldCore";

const validations = {
  DK: {
    pattern: {
      value: /^\d{8}$/,
      message: t`Please provide a valid Danish VAT number (e.g. 12345678)`,
    },
  },
  NL: {
    pattern: {
      value: /^NL[0-9]{9}B[0-9]{2}$/,
      message: t`Please provide a valid Dutch VAT number. (e.g. NL000099998B57)`,
    },
  },
  DE: {
    pattern: {
      value: /^DE[0-9]{9}$/,
      message: t`Please provide a valid German VAT number. (e.g. DE123456789)`,
    },
  },
};

export type Props = Omit<FieldCoreProps, "type" | "validation"> & {
  countryCode: "DK" | "NL" | "DE";
};

const VAT = ({ countryCode, ...rest }: Props) => {
  const validation = validations[countryCode];
  return <FieldCore type="text" validation={validation} {...rest} />;
};

export default VAT;
