import * as core from "./core/colors";

export const backgroundColor = core.pampas100;
export const backgroundColorInput = core.pampas100;
export const backgroundColorInputFilled = core.white;

export const borderColor = core.gray200;
export const borderColorDiscreet = core.gray100;
export const borderColorInput = "transparent";
export const borderColorInputHover = core.gray600;
export const borderColorInputFocus = core.blue500;

export const textColor = core.black;
export const textColorDiscreet = core.gray600;
export const textColorInput = core.black;
export const textColorLink = core.blue500;
export const textColorNegative = core.red500;
export const textColorPlaceholder = core.gray400;
export const textColorPositive = core.green500;
export const textColorWarning = core.yellow500;

export const boxShadowColorLevel1 = "rgba(0, 0, 0, .12)";
export const boxShadowColorLevel2 = "rgba(0, 0, 0, .16)";
export const boxShadowColorLevel3 = "rgba(0, 0, 0, .19)";
export const boxShadowColorSecondary = "rgba(0, 0, 0, .23)";

// Proper colors

export const proper100 = core.green100;
export const proper200 = core.green200;
export const proper300 = core.green300;
export const proper400 = core.green400;
export const proper500 = core.green500;
export const proper600 = core.green600;
export const proper700 = core.green700;
export const proper800 = core.green800;
export const proper900 = core.green900;

// Primary colors

export const primary100 = core.blue100;
export const primary200 = core.blue200;
export const primary300 = core.blue300;
export const primary400 = core.blue400;
export const primary500 = core.blue500;
export const primary600 = core.blue600;
export const primary700 = core.blue700;
export const primary800 = core.blue800;
export const primary900 = core.blue900;

// Customer Success (support) colors

export const support100 = core.orange100;
export const support200 = core.orange200;
export const support300 = core.orange300;
export const support400 = core.orange400;
export const support500 = core.orange500;
export const support600 = core.orange600;
export const support700 = core.orange700;
export const support800 = core.orange800;
export const support900 = core.orange900;

export const textColorSupport = core.blue700;
export const textColorSupportDiscreet = core.blue400;

// Success (positive) colors

export const success100 = core.green100;
export const success200 = core.green200;
export const success300 = core.green300;
export const success400 = core.green400;
export const success500 = core.green500;
export const success600 = core.green600;
export const success700 = core.green700;
export const success800 = core.green800;
export const success900 = core.green900;

// Danger (negative) colors

export const danger50 = core.red50;
export const danger100 = core.red100;
export const danger200 = core.red200;
export const danger300 = core.red300;
export const danger400 = core.red400;
export const danger500 = core.red500;
export const danger600 = core.red600;
export const danger700 = core.red700;
export const danger800 = core.red800;
export const danger900 = core.red900;

// Warning colors

export const warning100 = core.yellow100;
export const warning200 = core.yellow200;
export const warning300 = core.yellow300;
export const warning400 = core.yellow400;
export const warning500 = core.yellow500;
export const warning600 = core.yellow600;
export const warning700 = core.yellow700;
export const warning800 = core.yellow800;
export const warning900 = core.yellow900;

// Info colors

export const info100 = core.blue100;
export const info200 = core.blue200;
export const info300 = core.blue300;
export const info400 = core.blue400;
export const info500 = core.blue500;
export const info600 = core.blue600;
export const info700 = core.blue700;
export const info800 = core.blue800;
export const info900 = core.blue900;
