export const toClient = (fileName: string) => {
  const lastDotIndex = fileName.lastIndexOf(".");

  if (lastDotIndex < 0) {
    return { baseName: fileName, extension: "" };
  }

  const baseName = fileName.substring(0, lastDotIndex);
  const extension = fileName.substring(lastDotIndex + 1, fileName.length);
  return { baseName, extension };
};

export default { toClient };
