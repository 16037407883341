import styled from "styled-components";

import {
  baseUnit,
  borderColor,
  fontStack,
  textColorDiscreet,
  white,
} from "~components/copied_from_shared/pui";

export const SectionWrapper = styled.div`
  border: 1px solid ${borderColor};
  padding: ${baseUnit}px;
  position: relative;
  border-radius: 3px;

  &:not(:last-child) {
    margin-bottom: ${baseUnit * 2}px;
  }
`;

const Title = styled.div`
  ${fontStack};
  font-size: 14px;
  position: absolute;
  top: -${baseUnit}px;
  right: ${baseUnit}px;
  padding: 0 ${baseUnit / 2}px;
  background: ${white};
  color: ${textColorDiscreet};
`;

type SectionProps = {
  title: any;
  children: any;
};
export const Section = ({ title, children }: SectionProps) => (
  <SectionWrapper>
    <Title>{title}</Title>
    {children}
  </SectionWrapper>
);

export default Section;
