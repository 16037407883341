import {
  FormContext,
  FormContextValues,
  useFieldArray as useFieldArrayReactHookForm,
  UseFieldArrayProps,
  useForm,
  useFormContext as useFormContextReactHookForm,
} from "react-hook-form";
import styled from "styled-components";

import { baseUnit } from "../../../tokens";

export const marginBottomItem = `${baseUnit * 2}px`;

const Wrapper = styled.form<{ spaced?: boolean }>`
  > *:not(:last-child) {
    margin-bottom: ${({ spaced }) => (spaced ? marginBottomItem : 0)};
  }
`;

export const useFormContext = () => {
  const context = useFormContextReactHookForm();
  // @ts-ignore FIXME readOnly is our own custom prop.
  // We probably need to make our own context that extends
  // the useFormContext from 'react-hook-form' to allow
  // it for TypeScript. Not sure how to do that, so this is
  // a hacky workaround
  const readOnly = context.readOnly as boolean | undefined;
  return { ...context, readOnly };
};

export const useFieldArray = (props: UseFieldArrayProps) => {
  if (props.control) {
    return useFieldArrayReactHookForm(props);
  }
  const { control } = useFormContext();
  return useFieldArrayReactHookForm({ control, ...props });
};

type Props = FormContextValues & {
  children: any;
  onSubmit: (data: any) => void;
  method?: any;
  className?: string;
  spaced?: boolean;
  readOnly?: boolean;
};
export const Form = ({
  children,
  onSubmit,
  method,
  className,
  spaced,
  readOnly,
  ...methods
}: Props) => {
  return (
    // @ts-ignore FIXME readOnly is our own custom prop.
    // We probably need to make our own context that extends
    // the useFormContext from 'react-hook-form' to allow
    // it for TypeScript
    <FormContext {...methods} readOnly={readOnly}>
      <Wrapper
        spaced={spaced}
        className={className}
        onSubmit={methods.handleSubmit(onSubmit)}
        method={method}
        noValidate
      >
        {children}
      </Wrapper>
    </FormContext>
  );
};

export default useForm;
