import { Trans } from "@lingui/macro";
import { t } from "@lingui/macro";

import styled, { keyframes } from "styled-components";

import {
  baseUnit,
  iconSize,
  Notification,
  NotificationAction,
  NotificationActions,
  NotificationMessage,
  white,
} from "~components/copied_from_shared/pui";
import { Loader } from "~components/copied_from_shared/pui/icons";
import { useAnalytics, useUser } from "~hooks";

import { Report, Type } from "../Providers/Reports/types";

const spin = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;

const LoaderWrapper = styled.div`
  color: ${white};
  animation: ${spin} 2s linear infinite;
  width: ${iconSize}px;
  height: ${iconSize}px;
  margin-right: ${baseUnit / 1.5}px;

  svg {
    width: ${iconSize}px;
    height: ${iconSize}px;
  }
`;

const getPendingMessage = (type: Type) => {
  if (type === "rent-roll") {
    return t`Generating rent roll report`;
  }
  if (type === "utility-export") {
    return t`Generating utility report`;
  }
};

const getTimeoutMessage = (type: Type) => {
  if (type === "rent-roll") {
    return t`Rent roll report timed out`;
  }
  if (type === "utility-export") {
    return t`Utility report timed out`;
  }
};

const getReadyMessage = (type: Type) => {
  if (type === "rent-roll") {
    return t`Rent roll report ready`;
  }
  if (type === "utility-export") {
    return t`Utility report ready`;
  }
};

export type Props = {
  report: Report;
  onDownload: () => void;
};
const ReportNotification = ({ report, onDownload }: Props) => {
  const { authUser } = useUser();

  const { track } = useAnalytics();
  const downloadAndReport = () => {
    if (report.type === "utility-export") {
      track("UtilityReportDownload", {
        authUser: authUser.id,
        documentId: report.documentId,
      });
    }
    onDownload();
  };

  if (report.status === "pending") {
    return (
      <Notification status="info">
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
        <NotificationMessage>
          {getPendingMessage(report.type)}
        </NotificationMessage>
      </Notification>
    );
  }

  if (report.status === "timeout") {
    return (
      <Notification status="error">
        <NotificationMessage>
          {getTimeoutMessage(report.type)}
        </NotificationMessage>
      </Notification>
    );
  }

  return (
    <Notification status="success">
      <NotificationMessage>
        <Trans>{getReadyMessage(report.type)}</Trans>
      </NotificationMessage>
      <NotificationActions>
        <NotificationAction
          href={report.downloadUrl}
          download
          onClick={downloadAndReport}
        >
          <Trans>Download</Trans>
        </NotificationAction>
      </NotificationActions>
    </Notification>
  );
};

export default ReportNotification;
