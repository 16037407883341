const APP_URL =
  process.env.APP_URL ||
  process.env.VERCEL_URL ||
  process.env.NEXT_PUBLIC_VERCEL_URL ||
  "";
const JWT_COOKIE_DOMAIN = process.env.JWT_COOKIE_DOMAIN || APP_URL;

export const isDevelopment = process.env.NODE_ENV === "development";
export const host = isDevelopment ? "localhost" : APP_URL;
export const jwtCookieDomain = isDevelopment ? "localhost" : JWT_COOKIE_DOMAIN;
export const port = isDevelopment ? 3000 : 80;
export const scheme = isDevelopment ? "http" : "https";
export const rootUrl = `${scheme}://${host}${port === 80 ? "" : ":" + port}`;
