import * as React from "react";

import { Controller, FieldElement, FieldError } from "react-hook-form";

import AutoComplete from "../AutoComplete";
import Checkbox from "../Checkbox";
import DatePicker from "../DatePicker";
import Radio from "../Radio/Radio";
import Select from "../Select";
import Textarea from "../Textarea";
import TextField from "../TextField";

import { useFormContext } from "./Form";
import FormElement from "./FormElement";
import { Status } from "./types";

// FIXME: TEST ME!
function getError(name: string, errors: any): FieldError {
  return name.split(".").reduce((object, index) => {
    if (index.match(/\[/)) {
      const [arrayName, i] = index.split("[");
      const position = i.split("]")[0];
      return object?.[arrayName]?.[position];
    } else {
      return object?.[index];
    }
  }, errors);
}

export type FieldType =
  | "text"
  | "search"
  | "number"
  | "email"
  | "select"
  | "checkbox"
  | "date"
  | "month"
  | "radio"
  | "autocomplete"
  | "textarea";
function getFieldComponent(type: FieldType) {
  if (type === "select") {
    return Select;
  } else if (type === "checkbox") {
    return Checkbox;
  } else if (type === "radio") {
    return Radio;
  } else if (type === "date") {
    return DatePicker;
  } else if (type === "autocomplete") {
    return AutoComplete;
  } else if (type === "textarea") {
    return Textarea;
  }

  return TextField;
}

type InputProps = Omit<
  React.HTMLProps<HTMLInputElement>,
  "label" | "name" | "type" | "action" | "as"
>;
type FieldProps = FieldElement &
  InputProps & {
    name: string;
    label?: any;
    type?: FieldType;
    validation?: Record<string, unknown>;
    as?: any;
    help?: string;
    readOnly?: boolean;
    tooltip?: string;
    status?: Status;
    hint?: string;
    action?: any;
    span?: number;
    items?: string[]; // TODO: only for AutoComplete, types needs fixing
  };
const Field = ({
  name,
  label,
  validation = {},
  as,
  type = "text",
  required,
  help,
  tooltip,
  span,
  ...rest
}: FieldProps) => {
  const { errors, register, control, readOnly } = useFormContext();
  const error = getError(name, errors || {});
  const FieldComponent = getFieldComponent(type);
  const id = label ? `form-field-${name}-${label}` : `form-field-${name}`;

  const isReadOnly = rest.readOnly || readOnly;

  const field = as ? (
    // @ts-ignore FIXME ...rest is causing problems
    <Controller
      id={id}
      required={required}
      status={error ? "invalid" : "default"}
      control={control}
      name={name}
      as={as}
      rules={validation}
      hint={error?.message}
      readOnly={isReadOnly}
      {...rest}
    />
  ) : (
    <FieldComponent
      name={name}
      required={required}
      status={error ? "invalid" : "default"}
      id={id}
      // @ts-ignore FIXME not sure how to type this properly
      type={type}
      // @ts-ignore FIXME not sure how to type this properly
      ref={register(validation)}
      hint={error?.message}
      readOnly={isReadOnly}
      {...rest}
    />
  );

  return (
    <FormElement
      span={span}
      layout={
        ["checkbox", "radio"].includes(type) ? "horizontal-inverse" : "vertical"
      }
      isRequired={required}
      status={error ? "invalid" : "default"}
      id={id}
      label={label}
      hint={error?.message}
      help={help}
      readOnly={isReadOnly}
      tooltip={tooltip}
    >
      {field}
    </FormElement>
  );
};

export default Field;
