import { Button as ReakitButton } from "reakit";
import styled from "styled-components";

import { ChevronDown } from "../../../icons";
import { black, iconSize } from "../../../tokens";

const Button = styled(ReakitButton)`
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
`;

const ToggleButton = (props: any) => {
  return (
    <Button {...props}>
      <ChevronDown color={black} size={iconSize} />
    </Button>
  );
};

export default ToggleButton;
