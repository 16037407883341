import * as Sentry from "@sentry/nextjs";

import {
  BadGatewayError,
  InternalServerError,
  NetworkUnreachableError,
  NotFoundError,
  UnauthorizedError,
} from "~errors";

// We only want to report error types that are "interesting" from a
// developers perspective, e.g., errors that indicate bugs.
export const shouldBeCaptured = (error: Error) => {
  if (error instanceof NetworkUnreachableError) {
    // Caused by internet connection issues. See `fetch.ts` for more information.
    return false;
  } else if (error instanceof NotFoundError) {
    return false;
  } else if (error instanceof BadGatewayError) {
    // Often caused by Lambda cold starts.
    return false;
  } else if (error instanceof UnauthorizedError) {
    // Indicates that a user is not logged in.
    return false;
  } else if (error instanceof InternalServerError) {
    // Should already be reported by Java backend
    return false;
  }
  return true;
};

export default function reportError(error: Error) {
  if (shouldBeCaptured(error)) {
    Sentry.captureException(error);
  }
}
