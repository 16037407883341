import { ReactNode } from "react";

import { useDialogState as useReakitDialogState } from "reakit/Dialog";

import { Provider } from "./context";
import Notification from "./NotificationContent";
import NotificationDismissButton from "./NotificationDismissButton";
import { Status } from "./types";

type Props = {
  children: ReactNode;
  onDismiss?: () => void;
  status?: Status;
};
function NotificationContainer({
  children,
  status = "default",
  onDismiss,
  ...rest
}: Props) {
  const dialog = useReakitDialogState({ visible: true });

  const dismiss = () => {
    dialog.hide();
    if (onDismiss) {
      onDismiss();
    }
  };

  return (
    <Provider value={{ status }}>
      <Notification {...rest} status={status}>
        {children}
        {onDismiss && <NotificationDismissButton onClick={dismiss} />}
      </Notification>
    </Provider>
  );
}

export const useDialogState = useReakitDialogState;

export default NotificationContainer;
