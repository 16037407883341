import { TypeSafePage } from "next-type-safe-routes";
import styled from "styled-components";

import Link, { Anchor } from "~components/connected/Link";
import {
  baseUnit,
  fontStack,
  gray500,
  primary500,
  textColor,
} from "~components/copied_from_shared/pui";

const StyledAnchor = styled(Anchor)<{ disabled?: boolean }>`
  ${fontStack};
  text-decoration: none;
  font-size: 15px;
  display: flex;
  align-items: flex-start;
  line-height: 1.5;
  padding: ${baseUnit}px 0;
  cursor: ${({ $isActive, disabled }) =>
    disabled ? "default" : $isActive ? "default" : "pointer"};
  color: ${({ $isActive, $isActiveParent, disabled }) =>
    disabled ? gray500 : $isActive || $isActiveParent ? primary500 : textColor};

  ${({ disabled }) =>
    `&:hover {
    color: ${!disabled ? primary500 : gray500};
  }`}
`;

const IconWrapper = styled.div`
  margin-top: 2px;
  flex: 0 0 18px;
  margin-right: 9px;

  svg {
    width: 18px;
    height: 18px;
  }
`;

const Item = styled.li`
  width: 100%;
  flex: 1;
  min-width: 0;
`;

type NavigationItemProps = {
  children: React.ReactNode;
  linkTo: TypeSafePage;
  Icon?: any;
  doNotShowAsActiveParent?: boolean;
  disabled?: boolean;
};
export const NavigationItem = ({
  children,
  linkTo,
  Icon,
  doNotShowAsActiveParent,
  disabled,
}: NavigationItemProps) => {
  const itemContent = (
    <StyledAnchor disabled={disabled}>
      {Icon && (
        <IconWrapper>
          <Icon />
        </IconWrapper>
      )}
      {children}
    </StyledAnchor>
  );
  return (
    <Item>
      {!disabled && (
        <Link to={linkTo} doNotShowAsActiveParent={doNotShowAsActiveParent}>
          {itemContent}
        </Link>
      )}
      {disabled && itemContent}
    </Item>
  );
};

export default NavigationItem;
