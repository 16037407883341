import { CustomError } from "ts-custom-error";

/**
 * Adopted from: https://github.com/adriengibrat/ts-custom-error/blob/master/src/example/http-error.ts
 */
export class HttpError extends CustomError {
  protected static messages: { [status: number]: string } = {
    400: "Bad Request",
    401: "Unauthorized", // RFC 7235
    402: "Payment Required",
    403: "Forbidden",
    404: "Not Found",
    405: "Method Not Allowed",
    406: "Not Acceptable",
    407: "Proxy Authentication Required", // RFC 7235
    408: "Request Timeout",
    409: "Conflict",
    410: "Gone",
    411: "Length Required",
    412: "Precondition Failed", // RFC 7232
    413: "Payload Too Large", // RFC 7231
    414: "URI Too Long", // RFC 7231
    415: "Unsupported Media Type",
    416: "Range Not Satisfiable", // RFC 7233
    417: "Expectation Failed",
    418: "I'm a teapot", // RFC 2324
    421: "Misdirected Request", // RFC 7540
    426: "Upgrade Required",
    428: "Precondition Required", // RFC 6585
    429: "Too Many Requests", // RFC 6585
    431: "Request Header Fields Too Large", // RFC 6585
    451: "Unavailable For Legal Reasons", // RFC 7725
    500: "Internal Server Error",
    501: "Not Implemented",
    502: "Bad Gateway",
    503: "Service Unavailable",
    504: "Gateway Timeout",
    505: "HTTP Version Not Supported",
    506: "Variant Also Negotiates", // RFC 2295
    510: "Not Extended", // RFC 2774
    511: "Network Authentication Required", // RFC 6585
  };

  // The following properties are included in HTTP responses from the
  // backend when an exception was thrown in a backend service
  public code = ""; // e.g., "properties.owner.unable-to-deduce"
  public reason = ""; // e.g., "Unable to deduce owner"
  public messages: string[] = []; // e.g., ["Unable to deduce owner"]
  public requestId = ""; // e.g., "331c6c17-3458-4459-9fac-7690d6648153"

  public constructor(public httpStatusCode: number, message?: string) {
    super(message);
  }

  public static fromHttpStatusCode(status: number) {
    return new HttpError(status, HttpError.messages[status]);
  }

  public setCode(code: string) {
    this.code = code;
    return this;
  }

  public setReason(reason: string) {
    this.reason = reason;
    return this;
  }

  public setMessages(messages: string[]) {
    this.messages = messages;
    return this;
  }

  public setRequestId(requestId: string) {
    this.requestId = requestId;
    return this;
  }
}

export class HttpClientError extends HttpError {
  public constructor(status: number, message?: string) {
    super(status, message);
  }
}

export class HttpServerError extends HttpError {
  public constructor(status: number, message?: string) {
    super(status, message);
  }
}

export class UnauthorizedError extends HttpError {
  public constructor(message = "Unauthorized") {
    super(401, message);
  }
}

export class NotFoundError extends HttpError {
  public constructor(message = "Not Found") {
    super(404, message);
  }
}

export class BadGatewayError extends HttpError {
  public constructor(message = "Bad Gateway") {
    super(502, message);
  }
}

export class InternalServerError extends HttpError {
  public constructor(message = "Internal Server Error") {
    super(500, message);
  }
}

export class NetworkUnreachableError extends CustomError {
  public constructor(message?: string) {
    super(message);
  }
}
