import { useEffect, useState } from "react";

import { TypeSafeApiRoute } from "next-type-safe-routes";

import {
  useAnalytics,
  useDebounce,
  usePost,
  useQueryParam,
  useResource,
  useRouter,
} from "~hooks";
import { SearchResults } from "~types/search";
import { isSearchTermValid } from "~utils/search";

import Search from "./Search";
import { SearchResult } from "./types";

const getSearchableData = (results: SearchResults): SearchResult[] => {
  const displayResults: SearchResult[] = [];

  results.leaseResults.forEach(({ leaseId, address, tenancyId, tenants }) => {
    const primaryTenant = tenants.find((t) => t.primary);
    displayResults.push({
      propertyId: leaseId || "",
      tenantName: primaryTenant?.name || tenants[0]?.name || null,
      leaseId: leaseId || "",
      tenancyId: tenancyId,
      address: `${address.street}`,
    });
  });

  return displayResults;
};

const SearchContainer = () => {
  const { push, route: nextRoute } = useRouter();
  const { track } = useAnalytics();
  const [route, setRoute] = useState<TypeSafeApiRoute | null>(null);
  const [, search, { postStatus }] = usePost<SearchResults>({
    route: "/api/search",
  });
  const [query] = useQueryParam<string>("q");
  const [term, setTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(term);
  const [results, setResults] = useState<SearchResults | undefined>(undefined);

  useEffect(() => {
    if (!nextRoute.includes("/search")) {
      setTerm("");
    }
  }, [nextRoute]);

  useEffect(() => {
    if (query && !term) {
      setTerm(query);
    }
  }, [query]);

  useDebounce(() => {
    setDebouncedTerm(term);
  }, [term]);

  useEffect(() => {
    let isLatest = true;
    if (isSearchTermValid(debouncedTerm)) {
      track("SearchStarted");
      search({ q: debouncedTerm }).then((response) => {
        if (isLatest) {
          setResults(response);
        }
      });
    } else {
      setResults(undefined);
    }
    return () => {
      isLatest = false;
    };
  }, [debouncedTerm]);

  const onGoToLease = (searchResult: SearchResult) => {
    const { tenancyId, leaseId } = searchResult;
    push({
      route: "/tenancies/[tenancyId]/leases/[leaseId]",
      params: { tenancyId, leaseId },
    });
  };

  const onSearchForResults = (searchTerm: string | null | undefined) => {
    if (isSearchTermValid(searchTerm)) {
      push({
        route: "/search",
        query: {
          q: searchTerm,
        },
      });
    }
  };

  return (
    <Search
      onItemSelected={onGoToLease}
      postStatus={postStatus}
      results={results?.leaseResults ? getSearchableData(results) : null}
      onShowAllResults={onSearchForResults}
      term={term}
      setTerm={setTerm}
    />
  );
};

export default SearchContainer;
