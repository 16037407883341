import { t } from "@lingui/macro";

import styled from "styled-components";

import { TextField } from "~components/copied_from_shared/pui";
import { useUser } from "~hooks";
import { ResourceStatus } from "~hooks/useResource";

import { appBackgroundColor } from "../../Providers/GlobalStyle";

const Wrapper = styled(TextField)`
  border-color: ${appBackgroundColor};
  width: 240px;
`;

const getTextFieldStatus = (resourceStatus: ResourceStatus) => {
  if (resourceStatus === "pending") {
    return "loading";
  } else if (resourceStatus === "get-error") {
    return "invalid"; // FIXME better wording for this state
  }

  return "default";
};

const getTextFieldHint = (resourceStatus: ResourceStatus) => {
  if (resourceStatus === "pending") {
    return t`Loading searchable data`;
  } else if (resourceStatus === "get-error") {
    return t`Failed to load searchable data`;
  }

  return null;
};

type Props = {
  resourceStatus: ResourceStatus;
  onEnterKeyUp: () => void;
};
const SearchField = ({ resourceStatus, onEnterKeyUp, ...rest }: Props) => {
  const { isPaymentsCustomer } = useUser();
  return (
    <Wrapper
      hint={getTextFieldHint(resourceStatus)}
      status={getTextFieldStatus(resourceStatus)}
      placeholder={isPaymentsCustomer ? "Search" : t`Search your portfolio`}
      onKeyUp={(e) => {
        if (e.key === "Enter") {
          onEnterKeyUp();
        }
      }}
      {...rest}
    />
  );
};

export default SearchField;
