import { t } from "@lingui/macro";

import { useState } from "react";

import styled, { css } from "styled-components";

import {
  pampas300,
  Td as PUITd,
  textColor,
  textColorDiscreet,
  Tooltip,
} from "~components/copied_from_shared/pui";
import {
  backgroundColorRowHover,
  TdProps,
} from "~components/copied_from_shared/pui/components/Table";
import { Copy, Minus } from "~components/copied_from_shared/pui/icons";
import { useCopyToClipboard } from "~hooks";

const lineHeight = 24;

const Wrapper = styled.span<{ textOverflowEllipsis?: boolean | undefined }>`
  position: relative;
  display: block;
  line-height: initial;
  line-height: ${lineHeight}px;

  ${({ textOverflowEllipsis }) =>
    textOverflowEllipsis &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

const CopyButton = styled.button`
  padding: 0;
  border: none;
  background: ${backgroundColorRowHover};
  position: absolute;
  right: 0;
  top: -3px;
  color: ${pampas300};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  width: ${lineHeight + 6}px;
  height: ${lineHeight + 6}px;

  &:hover {
    color: ${textColor};
  }
`;

type Props = TdProps & {
  showCopyButton?: boolean;
  title?: any;
  children?: any;
};
const Td = ({ children, showCopyButton, ...rest }: Props) => {
  const isEmpty = !children || children === "";
  const content = isEmpty ? (
    <Minus size={20} color={textColorDiscreet} />
  ) : (
    children
  );
  const [isHovered, setIsHovered] = useState(false);
  const [showDidCopyToClipboard, onCopyToClipboard] = useCopyToClipboard();

  if (isEmpty || !showCopyButton) {
    return <PUITd {...rest}>{content}</PUITd>;
  }

  const onCopyText = async (e: any) => {
    e.stopPropagation();
    onCopyToClipboard(content);
  };

  return (
    <PUITd
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...rest}
    >
      <Wrapper
        title={rest.title}
        textOverflowEllipsis={rest.textOverflowEllipsis}
      >
        {isHovered && (
          <Tooltip
            title={showDidCopyToClipboard ? t`Copied!` : t`Copy to clipboard`}
          >
            <CopyButton onClick={onCopyText} type="button">
              <Copy size={20} />
            </CopyButton>
          </Tooltip>
        )}
        {content}
      </Wrapper>
    </PUITd>
  );
};

export default Td;
