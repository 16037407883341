import { Trans } from "@lingui/macro";

import { useState } from "react";

import { TypeSafePage } from "next-type-safe-routes";
import styled from "styled-components";

import Link, { Anchor } from "~components/connected/Link";
import {
  pageMaxWidth,
  pagePaddingHorizontal,
} from "~components/connected/Page";
import {
  baseUnit,
  borderColor,
  fontFamilySans,
  fontSmoothing,
  fontWeightBold,
  primary500,
  textColor,
  white,
} from "~components/copied_from_shared/pui";
import { Heart, Settings } from "~components/copied_from_shared/pui/icons";
import { useUser } from "~hooks";

import ProactiveFeedbackModal from "~features/customer-feedback/ProactiveFeedback";

const height = "64px";
const itemHeight = "34px";

const Outer = styled.div`
  border-top: solid 1px ${borderColor};
  border-bottom: solid 1px ${borderColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${height};
  background: ${white};
  width: 100%;
`;

const Wrapper = styled.ul`
  margin: 0;
  padding: 0 ${pagePaddingHorizontal}px;
  display: flex;
  list-style-type: none;
  margin: 0 auto;
  max-width: ${pageMaxWidth}px;
  width: ${pageMaxWidth}px;

  > *:not(:last-child) {
    margin-right: ${baseUnit * 4}px;
  }
`;

const NavigationLink = styled(Anchor)`
  ${fontSmoothing};
  line-height: ${itemHeight};
  text-decoration: none;
  font-family: ${fontFamilySans};
  font-weight: ${fontWeightBold};
  font-size: 17px;
  color: ${({ $isActive, $isActiveParent }) =>
    $isActive || $isActiveParent ? primary500 : textColor};
  cursor: pointer;

  &:hover {
    color: ${primary500};
  }
`;

const RightAligned = styled.li`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  gap: ${baseUnit * 2}px;
`;

const StyledAnchor = styled(Anchor)`
  ${fontSmoothing};
  display: flex;
  align-items: center;
  line-height: ${itemHeight};
  text-decoration: none;
  font-family: ${fontFamilySans};
  font-size: 17px;
  color: ${({ $isActive, $isActiveParent }) =>
    $isActive || $isActiveParent ? primary500 : textColor};
  cursor: pointer;

  svg {
    margin-right: ${baseUnit / 1.5}px;
  }

  &:hover {
    color: ${primary500};
  }
`;

type NavigationItemProps = {
  linkTo: TypeSafePage;
  children: any;
  dataTestId?: string;
  stepParentForPaths?: string[];
};
const NavigationItem = ({
  linkTo,
  children,
  dataTestId,
  stepParentForPaths,
}: NavigationItemProps) => (
  <li>
    <Link to={linkTo} stepParentForPaths={stepParentForPaths}>
      <NavigationLink data-testid={dataTestId}>{children}</NavigationLink>
    </Link>
  </li>
);

const Navigation = () => {
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const { viewAsUser } = useUser();

  const isGerman = viewAsUser.ownerCountry === "DE";

  return (
    <Outer>
      <Wrapper>
        <NavigationItem
          linkTo={{
            route: "/",
            query: { viewAs: viewAsUser.id },
          }}
          dataTestId="header-navigation-item-overview"
        >
          <Trans>Overview</Trans>
        </NavigationItem>
        <NavigationItem
          linkTo={{
            route: "/tenancies",
            query: { filter: "all", view: "overview", viewAs: viewAsUser.id },
          }}
          stepParentForPaths={["/tenancies"]}
          dataTestId="header-navigation-item-tenancies"
        >
          <Trans>Tenancies</Trans>
        </NavigationItem>
        <NavigationItem
          stepParentForPaths={["payments"]}
          linkTo={{
            route: "/payments/inbound-payments",
            query: { viewAs: viewAsUser.id },
          }}
          dataTestId="header-navigation-item-billing"
        >
          <Trans id="navigation.payments">Payments</Trans>
        </NavigationItem>
        <NavigationItem
          linkTo={{
            route: "/reports",
            query: { viewAs: viewAsUser.id },
          }}
          dataTestId="header-navigation-item-reports"
        >
          <Trans>Reports</Trans>
        </NavigationItem>
        {!isGerman && (
          <NavigationItem
            linkTo={{
              route: "/apps",
              query: { viewAs: viewAsUser.id },
            }}
          >
            <Trans>Apps</Trans>
          </NavigationItem>
        )}
        <NavigationItem
          linkTo={{
            route: "/[ownerId]/talk/threads",
            params: { ownerId: viewAsUser.id },
          }}
        >
          <Trans>Talk</Trans>
        </NavigationItem>
        <RightAligned>
          <StyledAnchor onClick={() => setFeedbackDialogOpen(true)}>
            <Heart size={18} />
            <span>
              <Trans>Feedback</Trans>
            </span>
          </StyledAnchor>
          <Link to="/settings/subscription" stepParentForPaths={["/settings"]}>
            <StyledAnchor>
              <Settings size={18} />
              <span>
                <Trans>Settings</Trans>
              </span>
            </StyledAnchor>
          </Link>
        </RightAligned>
      </Wrapper>
      <ProactiveFeedbackModal
        open={feedbackDialogOpen}
        setOpen={setFeedbackDialogOpen}
      />
    </Outer>
  );
};

export default Navigation;
