import { css } from "styled-components";

import { fontFamilySans } from "../tokens";

import fontSmoothing from "./fontSmoothing";

export default css`
  ${fontSmoothing};
  font-family: ${fontFamilySans};
`;
