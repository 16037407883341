import * as React from "react";

import styled from "styled-components";

import { baseUnit } from "../../tokens";

type Children = React.ReactNode;
type FlexDirection = "row" | "column" | "row-inverse" | "column-inverse";
type StyledFlexProps = {
  direction: FlexDirection;
  tight?: boolean;
};

const StyledFlex = styled.div<StyledFlexProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction};

  > * {
    flex: ${({ tight }) => (tight ? "none" : 1)};

    &:not(:last-child) {
      margin-right: ${({ direction }) =>
        ["row", "row-inverse"].includes(direction)
          ? `${baseUnit * 1.5}px`
          : "0px"};
      margin-bottom: ${({ direction }) =>
        ["column", "column-inverse"].includes(direction)
          ? `${baseUnit * 1.5}px`
          : "0px"};
    }
  }
`;

type FlexProps = {
  direction?: FlexDirection;
  tight?: boolean;
  children: Children;
};
export const Flex = ({
  children,
  tight,
  direction = "row",
  ...rest
}: FlexProps) => (
  <StyledFlex tight={tight} direction={direction as FlexDirection} {...rest}>
    {children}
  </StyledFlex>
);

export const Grid = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-column-gap: ${baseUnit * 1.5}px;
  grid-row-gap: ${baseUnit * 2}px;

  &:not(:last-child) {
    margin-bottom: ${baseUnit * 2}px;
  }
`;

type Props = {
  flex?: boolean;
  grid?: boolean;
  children: Children;
} & FlexProps;

const Layout = ({ children, ...rest }: Props) => (
  <Flex {...rest}>{children}</Flex>
);

export default Layout;
