import { useEffect, useState } from "react";

import * as Sentry from "@sentry/nextjs";
import Script from "next/script";

import { useRouter, useUser } from "~hooks";

const ZendeskWidget = () => {
  const [loaded, setLoaded] = useState(false);
  const router = useRouter();
  const { authUser } = useUser();

  const handleLoaded = () => {
    setLoaded(true);
    try {
      // @ts-ignore
      window.zE("webWidget", "setLocale", authUser.language);
      // @ts-ignore
      window.zESettings = {
        position: { horizontal: "left", vertical: "bottom" },
      };
      // @ts-ignore
      window.zE("webWidget", "prefill", {
        name: { value: authUser.name },
        email: { value: authUser.email ?? "" },
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (loaded) {
      try {
        // @ts-ignore
        window.zE("webWidget", "helpCenter:setSuggestions", {
          search: router.pathname,
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  }, [router.pathname]);

  return (
    <Script
      id="ze-snippet"
      src="https://static.zdassets.com/ekr/snippet.js?key=bf9696c2-0cfc-4805-8316-e212cf8a65d9"
      onLoad={handleLoaded}
    />
  );
};

export default ZendeskWidget;
