import {
  getPathname,
  getRoute,
  TypeSafeApiRoute,
  TypeSafePage,
} from "next-type-safe-routes";
import { useRouter as useNextRouter } from "next/router";

type ParsedUrlQueryOverride = { [key: string]: string };

const useRouter = () => {
  const router = useNextRouter();

  const push = (typeSafeUrl: TypeSafePage | TypeSafeApiRoute) => {
    const pathname = getPathname(typeSafeUrl);
    const asPath = getRoute(typeSafeUrl);
    router.push(pathname, asPath);
  };

  const replace = (typeSafeUrl: TypeSafePage | TypeSafeApiRoute) => {
    const pathname = getPathname(typeSafeUrl);
    const asPath = getRoute(typeSafeUrl);
    router.replace(pathname, asPath);
  };

  // Note, we never use "catch all routes" (https://nextjs.org/docs/routing/dynamic-routes#catch-all-routes)
  // If we ever choose to do so, we'd probably need to remove this override
  // and manually cast query params to string when used. But this seems
  // very cumbersome for now.
  const query = router.query as ParsedUrlQueryOverride;
  return { ...router, query, push, replace };
};

export default useRouter;
