import { Reducer, useEffect, useReducer } from "react";

import { TypeSafeApiRoute } from "next-type-safe-routes";

import { useIsMounted } from "~hooks/index";
import { getRequestUrl, reportError } from "~utils";
import fetch from "~utils/fetch";

import useIsWindowActive from "./useIsWIndowActive";
import useUser from "./useUser";

type Action<ResponseData> =
  | {
      type: "WILL_REFRESH";
    }
  | {
      type: "WILL_UPDATE";
    }
  | {
      type: "DID_REFRESH";
      data: ResponseData;
    }
  | {
      type: "DID_UPDATE";
      data: ResponseData;
    }
  | {
      type: "DID_LOAD";
      data: ResponseData;
    }
  | {
      type: "DID_ERROR";
      error: string;
    };

type State<ResponseData> =
  | {
      data: undefined;
      isLoading: true;
      isRefreshing: false;
      isUpdating: false;
      error: undefined;
    }
  | {
      data: ResponseData;
      isLoading: false;
      isRefreshing: false;
      isUpdating: false;
      error: undefined;
    }
  | {
      data: ResponseData | undefined;
      isLoading: false;
      isRefreshing: true;
      isUpdating: false;
      error: undefined;
    }
  | {
      data: ResponseData;
      isLoading: false;
      isRefreshing: false;
      isUpdating: true;
      error: undefined;
    }
  | {
      data: ResponseData | undefined;
      isLoading: false;
      isRefreshing: false;
      isUpdating: false;
      error: string;
    };

function reducer<ResponseData>(
  state: State<ResponseData>,
  action: Action<ResponseData>
): State<ResponseData> {
  switch (action.type) {
    case "WILL_REFRESH":
      return {
        ...state,
        isRefreshing: true,
        isLoading: false,
        isUpdating: false,
        error: undefined,
      };
    case "WILL_UPDATE":
      return {
        ...state,
        data: state.data!,
        isRefreshing: false,
        isLoading: false,
        isUpdating: true,
        error: undefined,
      };
    case "DID_REFRESH":
      return {
        ...state,
        isRefreshing: false,
        isLoading: false,
        isUpdating: false,
        data: action.data,
      };
    case "DID_UPDATE":
      return {
        ...state,
        isRefreshing: false,
        isLoading: false,
        isUpdating: false,
        data: action.data,
      };
    case "DID_LOAD":
      return {
        ...state,
        isLoading: false,
        isRefreshing: false,
        isUpdating: false,
        data: action.data,
      };
    case "DID_ERROR":
      return {
        ...state,
        isLoading: false,
        isRefreshing: false,
        isUpdating: false,
        error: action.error,
      };
    default:
      throw new Error();
  }
}

type ReturnArgs<T> = State<T> & {
  load: () => void;
  refresh: () => void;
  update: () => void;
};
function useGet<ResponseData>(
  route: TypeSafeApiRoute
): ReturnArgs<ResponseData> {
  const { authUser, viewAsUser } = useUser();
  const isWindowActive = useIsWindowActive();
  const isMounted = useIsMounted();
  const requestUserId = viewAsUser.id;
  const authorizedUserId = authUser.id;

  const [state, dispatch] = useReducer<
    Reducer<State<ResponseData>, Action<ResponseData>>
  >(reducer, {
    isLoading: true,
    isRefreshing: false,
    isUpdating: false,
    data: undefined,
    error: undefined,
  });
  const requestUrl = getRequestUrl({
    route,
    authorizedUserId,
    requestUserId,
  });
  const load = async () => {
    try {
      const data = await fetch(requestUrl, {
        method: "GET",
      });
      if (isMounted.current) dispatch({ type: "DID_LOAD", data });
    } catch (err: any) {
      if (isMounted.current)
        dispatch({ type: "DID_ERROR", error: err.message });
      reportError(err);
      console.error(`GET to ${requestUrl} failed`);
      console.error(err);
      throw err;
    }
  };

  const refresh = async () => {
    try {
      if (isMounted.current) dispatch({ type: "WILL_REFRESH" });
      const data = await fetch(requestUrl, {
        method: "GET",
      });
      if (isMounted.current) dispatch({ type: "DID_REFRESH", data });
    } catch (err: any) {
      if (isMounted.current)
        dispatch({ type: "DID_ERROR", error: err.message });
      reportError(err);
      console.error(`GET to ${requestUrl} failed`);
      console.error(err);
      throw err;
    }
  };

  const update = async () => {
    try {
      if (isMounted.current) dispatch({ type: "WILL_UPDATE" });
      const data = await fetch(requestUrl, {
        method: "GET",
      });
      if (isMounted.current) dispatch({ type: "DID_UPDATE", data });
    } catch (err: any) {
      if (isMounted.current)
        dispatch({ type: "DID_ERROR", error: err.message });
      reportError(err);
      console.error(`GET to ${requestUrl} failed`);
      console.error(err);
      throw err;
    }
  };

  useEffect(() => {
    let isLatest = true;
    if (isWindowActive && isLatest) {
      load();
    }
    return () => {
      isLatest = false;
    };
  }, [isWindowActive]);

  return { ...state, load, refresh, update };
}

export default useGet;
