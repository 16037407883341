import { Trans } from "@lingui/macro";

import styled from "styled-components";

import {
  backgroundColor,
  baseUnit,
  Link,
  Paragraph,
  textColorDiscreet,
} from "~components/copied_from_shared/pui";
import { Phone as PhoneIcon } from "~components/copied_from_shared/pui/icons";

export const IconWrapper = styled.div`
  margin-right: ${baseUnit / 2}px;
  background: ${backgroundColor};
  border-radius: 50%;
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${textColorDiscreet};

  svg {
    flex: 0 0 14px;
    width: 14px;
    height: 14px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

type Props = {
  value?: string;
};
const Phone = ({ value }: Props) => (
  <Wrapper>
    <IconWrapper>
      <PhoneIcon size={14} />
    </IconWrapper>
    {value ? (
      <Link href={`tel:${value}`}>{value}</Link>
    ) : (
      <Paragraph size="small">
        <Trans>Not specified</Trans>
      </Paragraph>
    )}
  </Wrapper>
);

export default Phone;
