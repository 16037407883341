import { plural, t } from "@lingui/macro";

import {
  differenceInDays,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
  format,
  isToday,
  isYesterday,
} from "date-fns";

const getNaturalTimeAgo = (time: string) => {
  const now = new Date();
  const date = new Date(time);
  const daysBetweenTimestampAndNow = differenceInDays(now, date);
  const weeksBetweenTimestampAndNow = differenceInWeeks(now, date);
  const monthsBetweenTimestampAndNow = differenceInMonths(now, date);
  const yearsBetweenTimestampAndNow = differenceInYears(now, date);

  if (isToday(date)) {
    return format(date, "HH:mm");
  } else if (isYesterday(date)) {
    return t`Yesterday`;
  } else if (monthsBetweenTimestampAndNow < 1) {
    return plural(daysBetweenTimestampAndNow, {
      one: "# day ago",
      other: "# days ago",
    });
  } else if (monthsBetweenTimestampAndNow < 4) {
    return plural(weeksBetweenTimestampAndNow, {
      one: "# week ago",
      other: "# weeks ago",
    });
  } else if (yearsBetweenTimestampAndNow < 1) {
    return plural(monthsBetweenTimestampAndNow, {
      one: "# month ago",
      other: "# months ago",
    });
  }

  return plural(yearsBetweenTimestampAndNow, {
    one: "# year ago",
    other: "# years ago",
  });
};

export default getNaturalTimeAgo;
