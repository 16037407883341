import { t } from "@lingui/macro";

// @ts-ignore
import AutosizeInput from "react-input-autosize";
import styled from "styled-components";

import Link, { Anchor } from "~components/connected/Link";
import { baseUnit, Tooltip } from "~components/copied_from_shared/pui";
import { Edit2 } from "~components/copied_from_shared/pui/icons";
import { useCopyToClipboard, useUser } from "~hooks";
import { Landlord } from "~types";

import AutoComplete from "./AutoComplete";

const SearchField = styled(AutosizeInput)`
  input {
    color: white;
    margin-left: 12px;
    background: none;
    font-size: 16px;
    font-weight: bold;
    outline: none;
    height: 38px;
    border: none;
    padding-right: 36px;

    ::placeholder {
      color: rgba(255, 255, 255, 0.75);
    }

    &:hover {
      opacity: 0.75;
    }
  }
`;

const Id = styled.button`
  cursor: copy;
  color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 3px;
  padding: 0 ${baseUnit / 2}px;
  background: transparent;
  font-size: 14px;
  outline: none;
  height: 24px;
`;

const EditButton = styled(Anchor)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 3px;
  background: transparent;
  font-size: 14px;
  outline: none;
  margin-left: ${baseUnit / 2}px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

type Props = {
  onViewAsSelected: (id: string | undefined) => void;
};
function ViewAs({ onViewAsSelected }: Props) {
  const { authUser, viewAsUser } = useUser();
  const [showDidCopyToClipboard, onCopyToClipboard] = useCopyToClipboard();

  const onSelectOrClear = (item?: { value: string; id: string }) => {
    onViewAsSelected(item?.id);
  };

  const enrichName = ({ name, tenantFacingCommunicationDetails }: Landlord) => {
    if (tenantFacingCommunicationDetails.name !== name) {
      return `${name} [${tenantFacingCommunicationDetails.name}]`;
    } else {
      return name;
    }
  };

  return (
    <Wrapper>
      <AutoComplete
        viewAsName={enrichName(viewAsUser)}
        Input={SearchField}
        data={authUser.canViewAs.map((landlord) => ({
          value: enrichName(landlord),
          id: landlord.id,
        }))}
        onChange={onSelectOrClear}
        data-test-id="input-landlord-autocomplete"
      />
      {!!viewAsUser && (
        <>
          <Tooltip
            title={showDidCopyToClipboard ? t`Copied!` : t`Copy to clipboard`}
          >
            <Id onClick={() => onCopyToClipboard(viewAsUser.id)}>
              {viewAsUser.id}
            </Id>
          </Tooltip>
          <Tooltip title={t`Edit company`}>
            <div>
              <Link
                to={{
                  route: "/support/companies/[companyId]/settings",
                  params: { companyId: viewAsUser.id },
                }}
              >
                <EditButton>
                  <Edit2 size={12} />
                </EditButton>
              </Link>
            </div>
          </Tooltip>
        </>
      )}
    </Wrapper>
  );
}

export default ViewAs;
