import styled from "styled-components";

import { HelpCircle } from "../../icons";
import { blue500 } from "../../tokens";
import Tooltip from "../Tooltip";

const HelpIcon = styled(HelpCircle)`
  position: relative;
  top: 4px;
  width: 20px;
  height: 20px;
  margin-left: 4px;
  color: ${blue500};
  pointer-events: all;
`;

type Props = {
  text: string;
};
const ThHelp = ({ text }: Props) => (
  <Tooltip title={text}>
    <HelpIcon />
  </Tooltip>
);

export default ThHelp;
