import { Skeleton } from "~components/ContentLoader";

import Table, { TBody, Td, Th, THead, Tr } from ".";

type Props = {
  rows?: number;
};
const TableFallback = ({ rows = 7 }: Props) => {
  const rowsArray = Array.from(Array(rows).keys());
  return (
    <Table>
      <THead>
        <Tr>
          <Th>
            <Skeleton width="180px" height="24px" />
          </Th>
          <Th align="right">
            <Skeleton width="140px" height="24px" />
          </Th>
        </Tr>
      </THead>
      <TBody>
        {rowsArray.map((number) => (
          <Tr key={number}>
            <Td>
              <Skeleton width="110px" height="24px" />
            </Td>
            <Td align="right">
              <Skeleton width="40px" height="24px" />
            </Td>
          </Tr>
        ))}
      </TBody>
    </Table>
  );
};
export default TableFallback;
