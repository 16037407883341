import { Trans } from "@lingui/macro";

import Link from "~components/connected/Link";
import { Database } from "~components/copied_from_shared/pui/icons";

import PageError, { Action, Actions, Message, Title } from "./PageError";

const ErrorNotFound = () => (
  <PageError>
    <Title Icon={Database}>
      404 - <Trans>Resource not found</Trans>
    </Title>
    <Message>
      <Trans>
        We couldn’t locate the requested resource in our system. Please verify
        that the url is correct.
      </Trans>
    </Message>
    <Actions>
      <Link to="/">
        <Action>
          <Trans>Go back home</Trans>
        </Action>
      </Link>
    </Actions>
  </PageError>
);

export default ErrorNotFound;
