import { forwardRef } from "react";

import { AlertTriangle, Check } from "react-feather";
import { Button as ReakitButton } from "reakit";
import styled, { css } from "styled-components";

import { fontSmoothing } from "../../mixins";
import {
  baseUnit,
  black,
  borderColor as borderColorToken,
  borderRadiusInput,
  danger400,
  danger500,
  fontFamilySans,
  fontWeightBold,
  heightInput,
  iconSize,
  pampas100,
  pampas200,
  pampas300,
  primary400,
  primary500,
  primary600,
  support100,
  support400,
  support500,
  support600,
  textColorSupport,
  transitionDuration,
  white,
} from "../../tokens";
import { variants } from "../../utils";
import Tooltip from "../Tooltip";

import Loader from "./Loader";
import { Look, State } from "./types";

export const height = heightInput + 2;
export const borderWidth = 1;

const textColor = variants("look", {
  default: { default: black, support: white },
  primary: { default: "white", support: "white" },
  danger: { default: "white" },
  discreet: { default: black, support: textColorSupport },
});

const backgroundColor = variants("look", {
  default: { default: white, support: support500 },
  primary: { default: primary500, support: support500 },
  danger: { default: danger500 },
  discreet: { default: "transparent" },
});

const borderColor = variants("look", {
  default: { default: borderColorToken, support: support500 },
  primary: { default: primary500, support: support500 },
  danger: { default: danger500 },
  discreet: { default: "transparent" },
});

const backgroundColorHover = variants("look", {
  default: { default: pampas100, support: support400 },
  primary: { default: primary400, support: support400 },
  danger: { default: danger400 },
  discreet: { default: "transparent" },
});

const backgroundColorActive = variants("look", {
  default: { default: pampas300, support: support600 },
  primary: { default: primary600, support: support600 },
  danger: { default: support600 },
  discreet: { default: pampas200, support: support100 },
});

type WrapperType = {
  $hasChildren: boolean;
  state?: State;
  disabled?: boolean;
  look: Look;
};

const Wrapper = styled(ReakitButton)<WrapperType>`
  ${fontSmoothing};
  display: flex;
  align-items: center;
  white-space: nowrap;
  pointer-events: ${({ state }) => (state ? "none" : "all")};
  user-select: none;
  position: relative;
  font-size: 15px;
  font-weight: ${fontWeightBold};
  height: ${height}px;
  /* avoid been sqeezed if appearing in a flex container */
  flex: initial !important;
  padding: ${({ $hasChildren, look }) => {
    if (look === "discreet") {
      return 0;
    } else if ($hasChildren) {
      return `0 ${baseUnit * 1.5}px`;
    }

    return 0;
  }};
  width: ${({ $hasChildren }) => ($hasChildren ? "auto" : `${height}px`)};
  flex: ${({ $hasChildren }) => ($hasChildren ? "inherit" : `0 0 ${height}px`)};
  font-family: ${fontFamilySans};
  color: ${textColor};
  border: ${borderWidth}px solid ${borderColor};
  background-color: ${backgroundColor};
  border-radius: ${borderRadiusInput};
  letter-spacing: 0.025rem;
  cursor: pointer;
  transition: background-color ${transitionDuration},
    opacity ${transitionDuration};

  ${({ state }) =>
    !!state &&
    css`
      color: transparent;
    `}

  &:hover {
    background-color: ${backgroundColorHover};
    opacity: ${({ look }) => (look === "discreet" ? 0.75 : "initial")};
  }

  &:active {
    background-color: ${backgroundColorActive};
    opacity: ${({ look }) => (look === "discreet" ? 1 : "initial")};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      background-color: ${backgroundColor};
    }
  }

  svg {
    vertical-align: middle;
    position: relative;
    margin-left: -3px;
  }

  span {
    vertical-align: middle;
    bottom: 1px;
    position: relative;
    line-height: 20px;
  }
`;

// @ts-ignore this works, but the Reakit Button component complains cause of types
const LinkWrapper = styled(Wrapper).attrs({ as: "a" })`
  display: inline-flex;
  text-decoration: none;
  align-items: center;
`;

const StateWrapper = styled.div<{ look: Look }>`
  color: ${textColor};
  position: absolute;
  top: 0;
  left: 0;
  height: ${height - borderWidth * 2}px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type GetLookArgs = {
  primary?: boolean;
  danger?: boolean;
  discreet?: boolean;
  look?: Look;
  state?: State;
};
const getLook = ({
  primary,
  danger,
  discreet,
  state,
  look = "default",
}: GetLookArgs): Look => {
  if (state === "error") {
    return "danger";
  } else if (primary) {
    return "primary";
  } else if (danger) {
    return "danger";
  } else if (discreet) {
    return "discreet";
  }

  return look;
};

export type Props = {
  children?: any;
  look?: Look;
  onClick?: () => void;
  Icon?: any;
  state?: State;
  disabled?: boolean;
  primary?: boolean;
  danger?: boolean;
  discreet?: boolean;
  type?: "submit" | "button" | "reset";
  tooltip?: string;
  href?: string;
};

/**
 * The `Button` component should be used to help users find and
 * complete actions. Buttons should be used sparringly and
 * should not be used instead of links.
 */
const Button = (
  {
    children,
    look = "default",
    Icon,
    onClick,
    disabled,
    state,
    type,
    primary,
    danger,
    discreet,
    tooltip,
    ...rest
  }: Props,
  ref: any
) => {
  const actualLook = getLook({ state, primary, danger, discreet, look });
  const Component = rest.href ? LinkWrapper : Wrapper;
  const button = (
    <Component
      look={actualLook}
      disabled={disabled}
      $hasChildren={!!children}
      onClick={onClick}
      state={state}
      ref={ref}
      type={type}
      {...rest}
    >
      {!!state && (
        <StateWrapper look={actualLook}>
          {state === "loading" && <Loader look={actualLook} />}
          {state === "done" && <Check size={iconSize} />}
          {state === "error" && <AlertTriangle size={iconSize} />}
        </StateWrapper>
      )}
      {Icon && (
        <Icon size={iconSize} style={{ marginRight: children ? "8px" : 0 }} />
      )}
      {children && <span>{children}</span>}
    </Component>
  );

  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        {/* The span is to allow tooltips even for disabled buttons */}
        <span>{button}</span>
      </Tooltip>
    );
  }

  return button;
};

export default forwardRef(Button);
