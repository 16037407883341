import * as React from "react";

import { Message } from "react-hook-form";
import styled from "styled-components";

import { baseUnit } from "../../../tokens";
import Paragraph from "../../Paragraph";
import { Look } from "../../Paragraph";
import Tooltip from "../../Tooltip";

import {
  layoutToAlignItems,
  layoutToFlexDirection,
  layoutToJustifyContent,
  LayoutType,
} from "./FormElement.utils";
import FormElementLabel from "./FormElementLabel";
import { Status } from "./types";

const getParagraphLook = (status: Status): Look => {
  status === "invalid" ? "negative" : "discreet";
  if (status === "loading") {
    return "positive";
  } else if (status === "valid") {
    return "positive";
  } else if (status === "invalid") {
    return "negative";
  }

  return status;
};

type WrapperProps = {
  layout: LayoutType;
  span?: number;
};
const Wrapper = styled.div<WrapperProps>`
  flex: 1;
  display: flex;
  flex-direction: ${({ layout }) => layoutToFlexDirection(layout)};
  align-items: ${({ layout }) => layoutToAlignItems(layout)};
  justify-content: ${({ layout }) => layoutToJustifyContent(layout)};

  td & {
    display: initial;
  }
`;

const Hint = styled(Paragraph)`
  margin-top: ${baseUnit / 2}px;

  td & {
    display: none;
  }
`;

export type Props = {
  id: string;
  label?: any;
  children: React.ReactNode;
  isRequired?: boolean;
  status?: Status;
  layout?: LayoutType;
  hint?: Message;
  help?: string;
  className?: string;
  readOnly?: boolean;
  tooltip?: string;
  span?: number;
};

/**
 * NOTE! The `FormElement` component should rarely be used
 * directly as it is already used under-the-hood by input
 * elements (such as `TextField` and `Radio`).
 */
const FormElement = ({
  id,
  label,
  isRequired,
  status = "default",
  layout = "vertical",
  hint,
  help,
  children,
  className,
  readOnly,
  tooltip,
  span,
}: Props) => (
  <Tooltip title={tooltip}>
    <Wrapper span={span} layout={layout} className={className}>
      {label && (
        <FormElementLabel
          id={id}
          isRequired={isRequired}
          status={status}
          layout={layout}
          help={help}
          readOnly={readOnly}
        >
          {label}
        </FormElementLabel>
      )}
      {children}
      {hint && (
        <Hint look={getParagraphLook(status)} size="small">
          {hint}
        </Hint>
      )}
    </Wrapper>
  </Tooltip>
);

export default FormElement;
