import { createContext } from "react";

import { PropertiesService } from "~services/properties-service";

export type ContextType = {
  products: {
    [P in PropertiesService.Product$Context]?: PropertiesService.FetchAllProductsForOwnerQuery$ResponseProduct[];
  };
};

export const Context = createContext<ContextType>({ products: {} });

export const Provider = Context.Provider;
