import * as React from "react";

import styled from "styled-components";

import { fontSmoothing } from "../../mixins";
import {
  baseUnit,
  danger200,
  fontFamilySans,
  gray400,
  info200,
  success200,
  textColor,
  warning200,
} from "../../tokens";
import { variants } from "../../utils";
import Tooltip from "../Tooltip";

export type Status = "info" | "success" | "danger" | "warning" | "neutral";
export type Look = "filled" | "outlined";

export const height = "22px";

const backgroundColor = variants("status", {
  default: { default: info200 },
  success: { default: success200 },
  danger: { default: danger200 },
  warning: { default: warning200 },
  neutral: { default: gray400 },
});

const Wrapper = styled.span<{ status: Status; look: Look }>`
  ${(p) =>
    p.look === "filled" ? `background-color: ${backgroundColor(p)};` : ""}
  display: inline-flex;
  align-items: center;
  height: ${height};
  user-select: none;
  padding: 0 ${baseUnit / 2}px;
  border: ${(p) => (p.look === "outlined" ? 1 : 0)}px solid ${backgroundColor};
  border-radius: 6px;
  cursor: default;
`;

const Text = styled.span`
  ${fontSmoothing};
  text-transform: lowercase;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: ${height};
  font-family: ${fontFamilySans};
  color: ${textColor};
`;

type Props = {
  children?: React.ReactNode;
  status?: Status;
  tooltip: string | null;
  look?: Look;
};

/**
 * Badges are designed as short, color-coded indicators that
 * help users identify critical information quickly.
 */
const Badge = ({
  children,
  tooltip,
  status = "info",
  look = "filled",
  ...rest
}: Props) => (
  <Tooltip title={tooltip}>
    <Wrapper status={status} look={look} {...rest}>
      <Text>{children}</Text>
    </Wrapper>
  </Tooltip>
);

export default Badge;
