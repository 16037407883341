import { useEffect } from "react";

import eventBus from "../eventBus";

export type Event =
  | "LeaseDepositCollected"
  | "LeaseDepositPeriodCountUpdated"
  | "LeaseCollectedDepositRegistered"
  | "LeaseCollectedDepositDeleted"
  | "LeasePrepaidRentCollected"
  | "LeasePrepaidRentPeriodCountUpdated"
  | "LeaseCollectedPrepaidRentRegistered"
  | "LeaseCollectedPrepaidRentDeleted"
  | "LeaseDepositCorrespondsToUpdated"
  | "LeasePrepaidRentCorrespondsToUpdated"
  | "RecurringBillItemAdded"
  | "RecurringBillItemAmountUpdated"
  | "RecurringBillItemVatUpdated"
  | "RecurringBillItemQuantityUpdated"
  | "RecurringBillItemDescriptionUpdated"
  | "RecurringBillItemDueDateRuleUpdated"
  | "RecurringBillItemStartedOnUpdated"
  | "RecurringBillItemDiscountUpdated"
  | "RecurringBillItemDiscountCreated"
  | "RecurringBillItemDiscountRemoved"
  | "RecurringBillItemCancelled"
  | "OneTimeBillItemDeleted"
  | "OneTimeBillItemAdded"
  | "DidUndoRecurringBillItemAmountChange"
  | "DidUndoRecurringBillItemDescriptionChange"
  | "DidUndoRecurringBillItemDueDateRuleChange"
  | "DidUndoRecurringBillItemVatChange"
  | "DidUndoRecurringBillItemQuantityChange"
  | "DidUndoRecurringBillItemCancellation"
  | "LeaseBillCreated"
  | "LeaseBillAnnulled"
  | "LeaseBillAnnulledAndRecreated"
  | "RentAdjustmentPlanCreated"
  | "RentAdjustmentRuleAdded"
  | "RentAdjustmentRuleDeleted"
  | "RentAdjustmentPlanDeleted"
  | "RentAdjustmentRolledBack"
  | "RentAdjustmentRuleLastYearUpdated"
  | "DidEndLease"
  | "DidUnendLease"
  | "DidUpdateLeaseContractStart"
  | "DidUpdateLeaseLeaseStart"
  | "LeaseBillingSettingsUpdated"
  | "LeaseTenantUpdated"
  | "LeasePrimaryTenantUpdated"
  | "LeaseTenantRemoved"
  | "LeaseTenantAdded"
  | "MoveOutStatementCreated"
  | "MoveOutStatementCancelled"
  | "LeaseTenantScreeningStatusUpdated"
  | "LeaseBillingContactUpdated"
  | "LeaseAssociatedContactAdded"
  | "LeaseAssociatedContactCreated"
  | "LeaseAssociatedContactUpdated"
  | "LeaseAssociatedContactRemoved"
  | "MessageCreated"
  | "MessageThreadCreated"
  | "LeasePurposeUpdated"
  | "LeaseGeneralInformationUpdated"
  | "LeaseAccessAndInventoryUpdated"
  | "TenancyGeneralInformationUpdated"
  | "TenancyAddressUpdated"
  | "LeaseCopied"
  | "LeaseContractSpecialTermsUpdated"
  | "LeaseContractTemplateUpdated"
  | "LeaseDraftContractDocumentGenerated"
  | "LeaseReadyToBeSignedContractDocumentGeneratedForWetSignature"
  | "LeaseReadyToBeSignedContractDocumentSentForDigitalSignature"
  | "LeaseContractDigitalSignatureRevoked"
  | "LeaseSignedContractDocumentAdded"
  | "TenantRegistrationInformationAdded"
  | "SignedEwiiPoaDocumentAdded"
  | "SignedEwiiPoaRemoved"
  | "DownloadedEwiiPoa"
  | "LeaseSignedContractDocumentRemoved"
  | "LeaseAutomationEnabled"
  | "LeaseAutomationDisabled"
  | "LeaseHasStartedOnboarding"
  | "LeaseShouldUseContractTemplateChanged"
  | "LeasePaperContractCreated"
  | "LeasePaperContractRemoved"
  | "LeasePaperContractMarkedAsSigned"
  | "ProductCreated"
  | "ProductArchived"
  | "ProductUnarchived"
  | "ProductNameUpdated"
  | "SettingUpTenancyAreaSkipped"
  | "SettingUpTenancyRoomsSkipped"
  | "SettingUpLeaseAccessAndInventorySkipped"
  | "SettingUpLeaseDepositSkipped"
  | "SettingUpLeasePrepaidRentSkipped"
  | "SettingUpLeaseRentAdjustmentSkipped"
  | "SettingUpLeaseA10ContractSpecificDataSkipped"
  | "SettingUpLeaseContractSpecialTermsSkipped"
  | "SettingUpLeaseContractAttachmentsSkipped"
  | "LeaseApproved"
  | "LeasePaymentCreated"
  | "LeaseArchived"
  | "TenancyArchived"
  | "LeaseServicesUpdated"
  | "BillSkipDefaultNotice"
  | "BillSendDefaultNotice"
  | "LeaseAlwaysSkipDefaultNotice"
  | "HeatAdded"
  | "CoolingAdded"
  | "InternetAdded"
  | "ElectricityAdded"
  | "TvAdded"
  | "WaterAdded"
  | "LeaseAlwaysSendDefaultNotice"
  | "LeaseContractAttachmentsUpdated"
  | "LeaseA10ContractSpecificDataUpdated"
  | "ApiKeyCreated"
  | "ApiKeyUpdated"
  | "ApiKeyRevoked"
  | "WebhookCreated"
  | "CategoryCreated"
  | "GroupCreated"
  | "GroupUpdated"
  | "GroupDeleted"
  | "LeaseSignUpForElectricityPartner"
  | "LeaseDoNotSignUpForElectricityPartner";

function useEventBus(
  types: Event | Event[],
  callback: (payload: any) => void,
  dependencies?: any
) {
  useEffect(() => {
    const typesAsArray: string[] = Array.isArray(types) ? types : [types];
    typesAsArray.forEach((type) => {
      eventBus.addListener(type, callback);
    });
    return () => {
      typesAsArray.forEach((type) => {
        eventBus.removeListener(type, callback);
      });
    };
  }, dependencies);
}

export default useEventBus;
