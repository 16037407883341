import { t } from "@lingui/macro";

import styled from "styled-components";

import {
  Menu as PUIMenu,
  MenuSection,
} from "~components/copied_from_shared/pui";
import {
  Key,
  LogOut,
  Settings,
} from "~components/copied_from_shared/pui/icons";
import { useAnalytics } from "~hooks";

import MenuItem from "./MenuItem";
import MenuTrigger from "./MenuTrigger";
import MenuUserInfo from "./MenuUserInfo";
import { PartialUser } from "./types";

const Content = styled.div`
  width: 300px;
`;

type Props = {
  user: PartialUser;
};
const Menu = ({ user }: Props) => {
  const { id, name, email } = user;
  const { track } = useAnalytics();

  const trackProfileIconClicked = () => {
    track("ProfileIconClicked");
  };

  const trackChangePasswordClicked = () => {
    track("ChangePasswordClicked");
  };

  const trackLogOutClicked = () => {
    track("LogOutClicked");
  };

  return (
    <PUIMenu
      ariaLabel={t`User menu`}
      trigger={
        <MenuTrigger
          userId={id}
          userName={name}
          onClick={trackProfileIconClicked}
        />
      }
    >
      <Content>
        <MenuUserInfo userId={id} userName={name} userEmail={email} />
        <MenuSection>
          <MenuItem
            Icon={Settings}
            text={t`User settings`}
            linkTo="/user/settings"
          />
        </MenuSection>
        <MenuSection>
          <MenuItem
            Icon={Key}
            text={t`Change password`}
            linkTo="/api/auth/change-password"
            onClick={trackChangePasswordClicked}
          />
          <MenuItem
            Icon={LogOut}
            text={t`Logout`}
            linkTo="/api/auth/logout"
            onClick={trackLogOutClicked}
          />
        </MenuSection>
      </Content>
    </PUIMenu>
  );
};

export default Menu;
