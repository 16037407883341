import { forwardRef } from "react";

import { MenuItem as ReakitMenuItem } from "reakit/Menu";
import styled from "styled-components";

import {
  baseUnit,
  fontFamilySans,
  pampas100,
  textColor,
  textColorDiscreet,
  textColorLink,
  white,
} from "../../tokens";

import useMenu from "./useMenu";

export const menuItemPaddingHorizontal = baseUnit * 1.5;
export const menuItemPaddingVertical = baseUnit / 1.5;

type WrapperTypes = {
  $isActive?: boolean;
  disabled?: boolean;
};
const Wrapper = styled(ReakitMenuItem)<WrapperTypes>`
  display: flex;
  align-items: center;
  font-size: 15px;
  padding: ${menuItemPaddingVertical}px ${menuItemPaddingHorizontal}px;
  font-family: ${fontFamilySans};
  border: 0;
  text-align: left;
  width: 100%;
  color: ${({ disabled }) => (disabled ? textColorDiscreet : textColor)};
  background-color: ${({ $isActive }) => ($isActive ? pampas100 : white)};
  cursor: ${({ $isActive, disabled }) =>
    $isActive || disabled ? "default" : "pointer"};
  pointer-events: ${({ $isActive }) => ($isActive ? "none" : "all !important")};
  text-decoration: none;
  line-height: 1.5;

  &:hover {
    color: ${({ disabled }) => (disabled ? textColorDiscreet : textColorLink)};
  }

  &:first-child {
    margin-top: ${baseUnit / 1.5}px;
  }

  &:last-child {
    margin-bottom: ${baseUnit / 1.5}px;
  }

  svg {
    margin-right: ${baseUnit}px;
    width: 16px;
    flex: 0 0 16px;
    height: 16px;
  }
`;

type BaseProps = {
  children: any;
  onClick?: (e: any) => void;
  Icon?: any;
  isActive?: boolean;
};

type ButtonProps = BaseProps & {
  disabled?: boolean;
};

type LinkProps = BaseProps & {
  href: string;
  download?: boolean;
};

type Props = ButtonProps | LinkProps;

const MenuItem = forwardRef<HTMLAnchorElement, Props>((props, ref) => {
  const onClick = (e: any) => {
    e.stopPropagation();
    if (props.onClick) {
      props.onClick(e);
    }
  };
  const menu = useMenu();
  if ("href" in props) {
    const { children, Icon, ...rest } = props;
    return (
      <Wrapper as="a" {...rest} onClick={onClick} ref={ref}>
        {!!Icon && <Icon />}
        {children}
      </Wrapper>
    );
  } else {
    const { isActive, children, Icon, ...rest } = props;

    return (
      <Wrapper
        $isActive={isActive}
        {...menu}
        {...rest}
        onClick={onClick}
        // @ts-ignore
        ref={ref}
      >
        {!!Icon && <Icon />}
        {children}
      </Wrapper>
    );
  }
});
MenuItem.displayName = "MenuItem";

export default MenuItem;
