import styled from "styled-components";

import {
  baseUnit,
  fontFamilySans,
  fontSmoothing,
  primary500,
} from "~components/copied_from_shared/pui";

import getLandlordInitials from "../getLandlordInitials";

type Size = "small" | "regular";

export const sizeSmall = 24;
export const sizeRegular = 28;

const styles = `
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${fontSmoothing};
  background: ${primary500};
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  font-family: ${fontFamilySans};
  border-radius: 6px;
  margin-right: ${baseUnit}px;
`;

const Small = styled.div`
  ${styles};
  font-size: 12px;
  flex: 0 0 ${sizeSmall}px;
  width: ${sizeSmall}px;
  height: ${sizeSmall}px;
`;

const Regular = styled.div`
  ${styles};
  font-size: 14px;
  flex: 0 0 ${sizeRegular}px;
  width: ${sizeRegular}px;
  height: ${sizeRegular}px;
`;

type Props = {
  name: string;
  size?: Size;
  style?: Record<string, unknown>;
};
const InitialIcon = ({ name, style, size = "regular" }: Props) => {
  const initials = getLandlordInitials(name);
  return size === "small" ? (
    <Small style={style}>{initials}</Small>
  ) : (
    <Regular style={style}>{initials}</Regular>
  );
};

export default InitialIcon;
