import styled from "styled-components";

import Link from "~components/connected/Link";
import {
  pageMaxWidth,
  pagePaddingHorizontal,
} from "~components/connected/Page";
import {
  baseUnit,
  Button,
  pampas100,
} from "~components/copied_from_shared/pui";
import { getElementStackOrder } from "~utils";

import Logo from "./Logo";

const appBackgroundColor = pampas100;
const zIndex = getElementStackOrder("header");

const Outer = styled.div`
  position: sticky;
  z-index: ${zIndex};
  top: 0;
  background-color: ${appBackgroundColor};
`;

const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 64px;
  padding: 0 ${pagePaddingHorizontal}px;
  max-width: ${pageMaxWidth}px;
  width: 100%;
`;

const LogoWrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 64px;
  pointer-events: none;

  > * {
    pointer-events: all;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;

  > *:not(:first-child) {
    margin-left: ${baseUnit}px;
  }
`;

function Header() {
  return (
    <Outer>
      <Wrapper>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <Actions>
          <Link to="/api/auth/login">
            <Button>Login</Button>
          </Link>
        </Actions>
      </Wrapper>
    </Outer>
  );
}

export default Header;
