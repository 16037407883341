import { createContext } from "react";

export type ContextType = {
  sub?: string;
};

export const Context = createContext<ContextType>({
  sub: undefined,
});

export const Provider = Context.Provider;
