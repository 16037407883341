import styled from "styled-components";

import { baseUnit } from "~components/copied_from_shared/pui";

import ThemeSelector from "../ThemeSelector";

import FeatureToggler from "./FeatureToggler";
import Menu, { Props as MenuProps } from "./Menu";

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: ${baseUnit * 1.5}px;
  }
`;

export type Props = MenuProps;
const Actions = (props: Props) => (
  <Wrapper>
    {/*<ThemeSelector />*/}
    <FeatureToggler />
    <Menu {...props} />
  </Wrapper>
);

export default Actions;
