import * as React from "react";

import styled from "styled-components";

import { fontSmoothing } from "../../mixins";
import {
  fontFamilySans,
  fontFamilySerif,
  fontWeightBold,
  textColor,
  textColorDiscreet,
  textColorSupport,
} from "../../tokens";
import { variant } from "../../utils";

const color = variant(textColor, { support: textColorSupport });

const variations = {
  tiny: {
    defaultTag: "h6",
    fontSize: "16px",
    fontFamily: fontFamilySans,
  },
  small: {
    defaultTag: "h5",
    fontSize: "19px",
    fontFamily: fontFamilySans,
  },
  normal: {
    defaultTag: "h4",
    fontSize: "24px",
    fontFamily: fontFamilySans,
  },
  large: {
    defaultTag: "h3",
    fontSize: "30px",
    fontFamily: fontFamilySerif,
  },
  huge: {
    defaultTag: "h2",
    fontSize: "36px",
    fontFamily: fontFamilySerif,
  },
} as any;

type WrapperType = {
  fontSize: string;
  fontFamily: string;
  fontWeight: number;
  as?: string;
};

const Wrapper = styled.div<WrapperType>`
  ${fontSmoothing};
  color: ${color};
  font-size: ${({ fontSize }) => fontSize};
  font-family: ${({ fontFamily }) => fontFamily};
  font-weight: ${fontWeightBold};
  line-height: 1.5;
  margin: 0;

  small {
    font-size: ${({ fontSize }) => fontSize};
    color: ${textColorDiscreet};
  }

  em {
    font-style: italic;
  }
`;

type Props = {
  children: React.ReactNode;
  size?: "normal" | "large" | "huge" | "small" | "tiny";
  tag?: string;
  style?: any;
};

/**
 * The `Heading` component should be used for the titles of
 * each major section of a page in the interface. For example
 * card components generally use headings as their title.
 */
const Heading = ({ children, size = "normal", tag, style, ...rest }: Props) => {
  const { fontSize, fontFamily, fontWeight, defaultTag } = variations[size];
  return (
    <Wrapper
      {...rest}
      style={style}
      as={tag || defaultTag}
      fontSize={fontSize}
      fontFamily={fontFamily}
      fontWeight={fontWeight}
    >
      {children}
    </Wrapper>
  );
};

export default Heading;
