import { useEffect } from "react";

import { NextQueryParamProvider } from "next-query-params";
import NextApp from "next/app";
import Head from "next/head";
import { withRouter } from "next/router";

import {
  Header,
  Notifications,
  Providers,
  PublicProviders,
  SupportHeader,
} from "~components/connected/app";
import Navigation from "~components/connected/app/Navigation";

import "~styles/tailwind.css";

import { Header as PublicHeader } from "../components/public";

import Clippy from "~features/support/Clippy";

class App extends NextApp {
  render() {
    const { Component, pageProps, router } = this.props;

    if (router.asPath.startsWith("/public")) {
      return (
        <PublicProviders>
          <PublicHeader />
          <Component {...pageProps} />
        </PublicProviders>
      );
    }

    const showHeaderAndNavigation =
      (!router.asPath.includes("/talk") ||
        router.asPath.includes("/settings")) &&
      !router.pathname.startsWith("/support") &&
      !router.pathname.includes("/paper-contract") &&
      !router.pathname.includes("/contract/contract-pdf-loader");
    const isProperTalk =
      router.asPath.includes("/talk") &&
      !router.asPath.includes("/support/companies") &&
      !router.asPath.includes("/settings");
    const showSupportHeader =
      !isProperTalk &&
      !router.pathname.includes("/paper-contract") &&
      !router.pathname.includes("/contract/contract-pdf-loader");
    return (
      <>
        <Head>
          <title>Proper</title>
          <meta property="og:title" content="Proper" key="title" />
        </Head>
        <Providers>
          {showSupportHeader && <SupportHeader />}
          {showHeaderAndNavigation && (
            <>
              <Header />
              <Navigation />
            </>
          )}
          <Notifications />
          <NextQueryParamProvider>
            <Component {...pageProps} />
          </NextQueryParamProvider>
          {showSupportHeader && <Clippy query={router.query} />}
          <DocumentClass isProperTalk={isProperTalk} />
        </Providers>
      </>
    );
  }
}

const DocumentClass = (props: any) => {
  useEffect(() => {
    document.documentElement.className = props.isProperTalk
      ? "h-full bg-white"
      : "";
    document.body.className = props.isProperTalk
      ? "h-full overflow-hidden"
      : "";
  });
  return null;
};

export default withRouter(App);
