import { createContext } from "react";

import {
  Notification,
  PushNotification,
} from "~components/connected/app/Providers/Notifications";

export type ContextType = {
  notifications: Notification[];
  clear: (id: string) => void;
  push: (notification: PushNotification) => void;
};

export const Context = createContext<ContextType>({
  push: () => {
    // do nothing
  },
  clear: () => {
    // do nothing
  },
  notifications: [],
});

export const Provider = Context.Provider;
