import React, { SVGAttributes } from "react";

import clsx from "clsx";

import Spinner from "~components/tailwind/Spinner";

type Theme = "default" | "primary" | "danger" | "support";
type Variant = "contained" | "text";
type Size = "small" | "regular" | "large";

export type Props = {
  children?: string | React.ReactNode | React.ReactNode[];
  childrenContainerClassName?: string;
  className?: string;
  theme?: Theme;
  variant?: Variant;
  size?: Size;
  loading?: boolean;
  disabled?: boolean;
  formNoValidate?: boolean;
  type?: "submit" | "button" | "reset";
  ref?: any;
  StartIcon?: React.ComponentType<SVGAttributes<SVGElement>>;
  EndIcon?: React.ComponentType<SVGAttributes<SVGElement>>;
  title?: string;
  onClick?: () => void;
  newDesign?: boolean;
};

const SVGSizeProps = {
  width: "100%",
  height: "100%",
};

const Button = ({
  children,
  childrenContainerClassName,
  className,
  theme = "default",
  variant = "contained",
  size = "regular",
  StartIcon,
  EndIcon,
  loading,
  disabled,
  formNoValidate = false,
  onClick,
  newDesign = false,
  ...rest
}: Props) => {
  if (newDesign) {
    return (
      <button
        {...rest}
        formNoValidate={formNoValidate}
        disabled={disabled}
        className={clsx(
          className,
          "relative inline-flex flex-initial flex-row items-center justify-center rounded-full disabled:cursor-default disabled:opacity-50",
          // theme === "default" &&
          //   variant === "contained" && [
          //     "border border-gray-200 bg-white text-default",
          //     !disabled && "hover:bg-gray-50 active:bg-gray-100",
          //   ],
          theme === "primary" &&
            variant === "contained" && [
              "bg-newgreen-500 text-default",
              !disabled &&
                "transition-colors hover:bg-newgreen-300 active:bg-newgreen-600",
            ],
          // theme === "danger" &&
          //   variant === "contained" && [
          //     "bg-red-500 text-white",
          //     !disabled && "hover:bg-red-300 active:bg-red-600",
          //   ],
          theme === "support" &&
            variant === "contained" && [
              "bg-purple-200 text-white",
              !disabled && "hover:bg-purple-100 active:bg-purple-300",
            ],
          // theme === "default" &&
          //   variant === "text" && [
          //     "text-default shadow-none",
          //     !disabled && "hover:bg-gray-100 active:bg-gray-200",
          //   ],
          // theme === "primary" &&
          //   variant === "text" && [
          //     "text-blue-500 shadow-none",
          //     !disabled && "hover:bg-blue-25 active:bg-blue-50",
          //   ],
          // theme === "danger" &&
          //   variant === "text" && [
          //     "text-red-500 shadow-none",
          //     !disabled && "hover:bg-red-25 active:bg-red-50",
          //   ],
          // theme === "support" &&
          //   variant === "text" && [
          //     "text-purple-300 shadow-none",
          //     !disabled && "hover:bg-purple-100/20 active:bg-purple-100/40",
          //   ],
          loading && "pointer-events-none cursor-default text-transparent",
          size === "small" && "rounded-md px-2.5 py-1 text-sm font-medium",
          size === "regular" &&
            "rounded-md px-4 py-2 text-base font-bold shadow-sm",
          size === "large" &&
            "rounded-md px-6 py-2.5 text-lg font-bold shadow-sm"
        )}
        onClick={(e) => {
          if (onClick) {
            e.preventDefault();
            e.stopPropagation();
            onClick();
          }
        }}
      >
        {/*{loading && (*/}
        {/*  <div*/}
        {/*    className={clsx(*/}
        {/*      "absolute top-0 left-0 flex h-full w-full items-center justify-center",*/}
        {/*      theme === "primary" && variant === "contained" && "text-white",*/}
        {/*      theme === "danger" && variant === "contained" && "text-white",*/}
        {/*      theme === "support" && variant === "contained" && "text-white",*/}
        {/*      theme === "primary" && variant === "text" && "text-blue-500",*/}
        {/*      theme === "danger" && variant === "text" && "text-red-500",*/}
        {/*      theme === "default" && "text-default"*/}
        {/*    )}*/}
        {/*  >*/}
        {/*    <div className="h-5">*/}
        {/*      <Spinner {...SVGSizeProps} />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
        <div className="flex flex-initial flex-row items-center justify-center space-x-2">
          {StartIcon && (
            <div
              className={clsx(
                "-ml-1",
                size === "small" && "h-4 w-4",
                size === "regular" && "h-5 w-5",
                size === "large" && "h-6 w-6"
              )}
            >
              <StartIcon {...SVGSizeProps} />
            </div>
          )}
          <div
            className={clsx(
              "flex flex-initial flex-row items-center justify-center",
              loading && "opacity-0",
              childrenContainerClassName
            )}
          >
            {children}
          </div>
          {EndIcon && (
            <div
              className={clsx(
                "-mr-1",
                size === "small" && "h-4 w-4",
                size === "regular" && "h-5 w-5",
                size === "large" && "h-6 w-6"
              )}
            >
              <EndIcon {...SVGSizeProps} />
            </div>
          )}
        </div>
      </button>
    );
  } else {
    return (
      <button
        {...rest}
        formNoValidate={formNoValidate}
        disabled={disabled}
        className={clsx(
          className,
          "relative inline-flex flex-initial flex-row items-center justify-center rounded-md disabled:cursor-default disabled:opacity-50",
          theme === "default" &&
            variant === "contained" && [
              "border border-gray-200 bg-white text-default",
              !disabled && "hover:bg-gray-50 active:bg-gray-100",
            ],
          theme === "primary" &&
            variant === "contained" && [
              "bg-blue-500 text-white",
              !disabled && "hover:bg-blue-300 active:bg-blue-600",
            ],
          theme === "danger" &&
            variant === "contained" && [
              "bg-red-500 text-white",
              !disabled && "hover:bg-red-300 active:bg-red-600",
            ],
          theme === "support" &&
            variant === "contained" && [
              "bg-purple-200 text-white",
              !disabled && "hover:bg-purple-100 active:bg-purple-300",
            ],
          theme === "default" &&
            variant === "text" && [
              "text-default shadow-none",
              !disabled && "hover:bg-gray-100 active:bg-gray-200",
            ],
          theme === "primary" &&
            variant === "text" && [
              "text-blue-500 shadow-none",
              !disabled && "hover:bg-blue-25 active:bg-blue-50",
            ],
          theme === "danger" &&
            variant === "text" && [
              "text-red-500 shadow-none",
              !disabled && "hover:bg-red-25 active:bg-red-50",
            ],
          theme === "support" &&
            variant === "text" && [
              "text-purple-300 shadow-none",
              !disabled && "hover:bg-purple-100/20 active:bg-purple-100/40",
            ],
          loading && "pointer-events-none cursor-default text-transparent",
          size === "small" && "rounded-md px-2.5 py-1 text-sm font-medium",
          size === "regular" && "rounded-md px-4 py-2 text-base font-bold",
          size === "large" && "rounded-md px-6 py-2.5 text-lg font-bold",
          size === "regular" && variant != "text" && "shadow-sm",
          size === "large" && variant != "text" && "shadow-sm"
        )}
        onClick={(e) => {
          if (onClick) {
            e.preventDefault();
            e.stopPropagation();
            onClick();
          }
        }}
      >
        {loading && (
          <div
            className={clsx(
              "absolute left-0 top-0 flex h-full w-full items-center justify-center",
              theme === "primary" && variant === "contained" && "text-white",
              theme === "danger" && variant === "contained" && "text-white",
              theme === "support" && variant === "contained" && "text-white",
              theme === "primary" && variant === "text" && "text-blue-500",
              theme === "danger" && variant === "text" && "text-red-500",
              theme === "default" && "text-default"
            )}
          >
            <div className="h-5">
              <Spinner {...SVGSizeProps} />
            </div>
          </div>
        )}
        <div className="flex flex-initial flex-row items-center justify-center space-x-2">
          {StartIcon && (
            <div
              className={clsx(
                "-ml-1",
                size === "small" && "h-4 w-4",
                size === "regular" && "h-5 w-5",
                size === "large" && "h-6 w-6"
              )}
            >
              <StartIcon {...SVGSizeProps} />
            </div>
          )}
          <div
            className={clsx(
              "flex flex-initial flex-row items-center justify-center",
              loading && "opacity-0",
              childrenContainerClassName
            )}
          >
            {children}
          </div>
          {EndIcon && (
            <div
              className={clsx(
                "-mr-1",
                size === "small" && "h-4 w-4",
                size === "regular" && "h-5 w-5",
                size === "large" && "h-6 w-6"
              )}
            >
              <EndIcon {...SVGSizeProps} />
            </div>
          )}
        </div>
      </button>
    );
  }
};

export default Button;
