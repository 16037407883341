import { useRef } from "react";

import styled from "styled-components";

import {
  pageMaxWidth,
  pagePaddingHorizontal,
} from "~components/connected/Page";
import { baseUnit, white } from "~components/copied_from_shared/pui";
import { useStickyDetection } from "~hooks";
import { getElementStackOrder } from "~utils";

import CompanySelector from "./CompanySelector";
import Logo from "./Logo";
import Menu from "./Menu";
import SearchContainer from "./Search";

export const height = "64px";

const zIndex = getElementStackOrder("header");
const boxShadowSticky = "0 8px 15px rgba(0, 0, 0, 0.05)";

const Outer = styled.div<{ isSticky: boolean }>`
  position: sticky;
  z-index: ${zIndex};
  top: 0;
  background-color: ${white};
  transition: 500ms;
  box-shadow: ${({ isSticky }) => (isSticky ? boxShadowSticky : "none")};
`;

const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${height};
  padding: 0 ${pagePaddingHorizontal}px;
  max-width: ${pageMaxWidth}px;
  width: 100%;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;

  > *:not(:first-child) {
    margin-left: ${baseUnit}px;
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: ${height};
  pointer-events: none;

  > * {
    pointer-events: all;
  }
`;

const StickyDetector = styled.div`
  min-height: 0px;
  min-width: 0px;
`;

function Header() {
  const stickyDetectorRef = useRef<HTMLDivElement>(null);
  const isSticky = useStickyDetection(stickyDetectorRef);
  return (
    <>
      <StickyDetector ref={stickyDetectorRef} />
      <Outer isSticky={isSticky}>
        <Wrapper>
          <CompanySelector />
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
          <Actions>
            <SearchContainer />
            <Menu />
          </Actions>
        </Wrapper>
      </Outer>
    </>
  );
}

export default Header;
