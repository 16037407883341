import { useEffect } from "react";

import { useAnalytics, useRouter } from "~hooks";

const RouteTracking = () => {
  const { track } = useAnalytics();
  const router = useRouter();

  useEffect(() => {
    track("Navigation");
  }, [router.pathname]);

  return null;
};

export default RouteTracking;
