import unfetch from "isomorphic-unfetch";

import { useRouter, useUser } from "~hooks";
import { reportError } from "~utils";

type EventName =
  | "Navigation"
  | "SearchStarted"
  | "SearchResultSelected"
  | "SearchResultSeeAll"
  | "TenancyListFilterClicked"
  | "EconomicIntegrationActivated"
  | "ExportFinancialTransactionsReport"
  | "ExportSumOfChargesReport"
  | "ExportPayoutReconciliationReport"
  | "ExportRentRollReport"
  | "ExportUtilityReport"
  | "UtilityReportDownload"
  | "NoticesClicked"
  | "ProfileIconClicked"
  | "LeaseNavigationClicked"
  | "NewPaymentCreated"
  | "NewTransactionCreated"
  | "ChangePasswordClicked"
  | "LogOutClicked"
  | "NewChargeCreated"
  | "ViewConversation"
  | "OpenNewConversationView"
  | "CreateNewConversation"
  | "ReplyToConversation"
  | "DownloadAttachment"
  | "UploadAttachments"
  | "BillItemRemovalRequest"
  | "SendWelcomeEmail"
  | "GenerateBill"
  | "SendLeaseBillNow"
  | "UpdateProducts"
  | "UnliveLease"
  | "ChangeLeaseDates"
  | "AnnulBill"
  | "MovePayment"
  | "AddProductInProductPlan"
  | "UpdateProductPriceInProductPlan"
  | "ChangeRent"
  | "BuildNewContractClicked"
  | "TenancyCreated"
  | "BuildContractOnLease"
  | "ContractPreview"
  | "ContractDownload"
  | "EditContract"
  | "OpenContract"
  | "RemoveContractFromLease"
  | "StartSignedContractFlow"
  | "StartReadyToSignContractFlow"
  | "FromContractBuilderToLeaseView"
  | "AddNewTenantOnContract"
  | "RemoveTenantOnContract"
  | "AddCustomContributionOnContract"
  | "RemoveCustomContributionOnContract"
  | "AddOneTimeExpenseOnContract"
  | "RemoveOneTimeExpenseOnContract"
  | "AddP11ClauseOnContract"
  | "RemoveP11ClauseOnContract"
  | "UploadAttachmentOnContract"
  | "RemoveAttachmentOnContract"
  | "SurveyFeedbackCreated"
  | "SurveyFeedbackDismissed"
  | "SurveyFeedbackOpened"
  | "ProactiveFeedbackOpened"
  | "ProactiveFeedbackCreated"
  | "UpdateThreadStatus"
  | "SearchConversations"
  | "ViewAllConversations"
  | "FilterByParticipant"
  | "AddRecurringBillItem"
  | "UpdateRecurringBillItemAmount"
  | "UpdateRecurringBillItemVat"
  | "UpdateRecurringBillItemQuantity"
  | "UpdateRecurringBillItemDescription"
  | "UpdateRecurringBillItemDueDateRule"
  | "UpdateRecurringBillItemStartedOn"
  | "UpdateRecurringBillItemDiscount"
  | "CreateRecurringBillItemDiscount"
  | "RemoveRecurringBillItemDiscount"
  | "CancelRecurringBillItem"
  | "AddOneTimeBillItem"
  | "AddNegativeOneTimeBillItem"
  | "DeleteOneTimeBillItem"
  | "UndoRecurringBillItemAmountChange"
  | "UndoRecurringBillItemDescriptionChange"
  | "UndoRecurringBillItemDueDateRuleChange"
  | "UndoRecurringBillItemVatChange"
  | "UndoRecurringBillItemQuantityChange"
  | "UndoRecurringBillItemCancellation"
  | "UpdatePeriodCount"
  | "CreateUtilityGroup"
  | "UpdateUtilityGroup"
  | "DeleteUtilityGroup"
  | "EndLease"
  | "UnendLease"
  | "RegisterCollectedDeposit"
  | "RegisterCollectedPrepaidRent"
  | "UpdateLeasePrepaidRentPeriodCount" // deprecated
  | "UpdateLeasePrepaidRentCorrespondsTo"
  | "UpdateLeaseDepositPeriodCount" // deprecated
  | "UpdateLeaseDepositCorrespondsTo"
  | "CreateRentAdjustmentPlan"
  | "AddRentAdjustmentRule"
  | "DeleteRentAdjustmentRule"
  | "RollbackRentAdjustment"
  | "UpdateRentAdjustmentRuleLastYear"
  | "UpdateLeaseContractStart"
  | "UpdateLeaseLeaseStart"
  | "UpdateLeaseBillingSettings"
  | "EnableLeaseEInvoicing"
  | "AddContactEan"
  | "ChangeContactEan"
  | "RemoveContactEan"
  | "DisableLeaseEInvoicing"
  | "UpdateTenant"
  | "SetTenantAsPrimary"
  | "RemoveTenant"
  | "RemoveFuturePayment"
  | "CreateNewTenantWithContact"
  | "CreateMoveOutStatement"
  | "CancelMoveOutStatement"
  | "CreateMOSSentToTenant"
  | "CreateMOSFinaliseAsPDF"
  | "FuturePaymentRegistered"
  | "AddReceivedByLandlordPaymentModalOpened"
  | "AddPaidOutByLandlordPaymentModalOpened"
  | "AddFuturePaymentModalNextClicked"
  | "MoveOutStatementFormNext"
  | "AddContactAsTenant"
  | "UpdateContactScreeningStatus"
  | "UpdateLeaseBillingContact"
  | "AddLeaseAssociatedContact"
  | "CreateLeaseAssociatedContact"
  | "UpdateLeaseAssociatedContact"
  | "RemoveLeaseAssociatedContact"
  | "AnnulLeasePaymentTransactionModalOpened"
  | "LeasePaymentTransactionAnnulled"
  | "CreateCustomProduct"
  | "UpdateTenancyGeneralInformation"
  | "UpdateLeaseGeneralInformation"
  | "UpdateLeaseAccessAndInventory"
  | "UpdateTenancyAddress"
  | "OpenCreateLease"
  | "CreateLease"
  | "CopyLease"
  | "StartLeaseOnboarding"
  | "UpdateLeasePurpose"
  | "UpdateTenancyTotalArea"
  | "UpdateTenancyRoomCounts"
  | "AddDepositOneTimeBillItem"
  | "AddPrepaidRentOneTimeBillItem"
  | "CompleteLeaseOnboarding"
  | "UpdateLeaseOnboardingContractMode"
  | "UpdateLeaseContractSpecialTerms"
  | "UpdateLeaseContractTemplate"
  | "ReviewLeaseContract"
  | "DownloadLeaseReadyToBeSignedContractDocument"
  | "DownloadLeaseContractForWetSignature"
  | "RevokeLeaseContractDigitalSignature"
  | "SendLeaseContractForDigitalSignature"
  | "UploadSignedLeaseContract"
  | "RemoveSignedLeaseContract"
  | "ArchiveProduct"
  | "ResendBill"
  | "ResendBillModalOpened"
  | "UnarchiveProduct"
  | "UpdateProductName"
  | "ApproveLease"
  | "DeleteLease"
  | "DisableLeaseAutomation"
  | "EnableLeaseAutomation"
  | "TenancyOverview"
  | "UpdateLeaseContractAttachments"
  | "UpdateLeaseA10ContractSpecificData"
  | "CreateApiKey"
  | "UpdateApiKey"
  | "RevokeApiKey"
  | "CreateWebhook"
  | "UploadSignedEwiiPoa"
  | "DownloadEwiiPowerOfAttorney"
  | "RemoveSignedEwiiPoa"
  | "AddTenantRegistrationInformation"
  | "SignUpForElectricityPartner"
  | "DoNotSignUpForElectricityPartner"
  | "DigitalEwiiRegistrationRevoked"
  | "SkipSettingUpTenancyArea"
  | "SkipSettingUpTenancyRooms"
  | "SkipSettingUpLeaseAccessAndInventory"
  | "SkipSettingUpLeaseDeposit"
  | "SkipSettingUpLeasePrepaidRent"
  | "SkipSettingUpLeaseRentAdjustment"
  | "SkipSettingUpLeaseA10ContractSpecificData"
  | "SkipSettingUpLeaseContractSpecialTerms"
  | "SkipSettingUpLeaseContractAttachments"
  | "LeaseShouldUseContractTemplate"
  | "LeaseShouldNotUseContractTemplate"
  | "EditLeasePurposeFromOnboardingPage"
  | "EditTenantsFromOnboardingPage"
  | "EditLeaseContractStartDateFromOnboardingPage"
  | "EditLeaseBillingSettingsFromOnboardingPage"
  | "EditRecurringBillItemsFromOnboardingPage"
  | "UpdateTenantFacingCommunicationDetails"
  | "AddRentRecurringBillItemFromOnboardingPage"
  | "EditLeaseAccessAndInventoryFromOnboardingPage";

export type EventProperties = { [key: string]: any };

const useAnalytics = () => {
  const { viewAsUser } = useUser();
  const router = useRouter();

  const track = (eventName: EventName, properties: EventProperties = {}) => {
    const _properties = {
      path: router.asPath,
      pathname: router.pathname,
      ...properties,
    };

    try {
      unfetch("/api/analytics", {
        method: "POST",
        body: JSON.stringify({
          ownerId: viewAsUser.id,
          event: {
            eventName,
            properties: JSON.stringify(_properties),
          },
        }),
      }).catch(reportError);
    } catch (error: any) {
      reportError(error);
    }
  };

  return { track };
};

export default useAnalytics;
