import styled from "styled-components";

const Menu = styled.ul`
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: absolute;
  background: white;
  list-style-type: none;
  top: calc(100% + 3px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  z-index: 9999;
  right: 0;
  width: 100%;
  max-height: 220px;
  overflow: scroll;
  -webkit-overflow-scrolling: auto;
`;

export default Menu;
