import { t } from "@lingui/macro";

import {
  Features,
  Provider,
} from "~components/copied_from_shared/feature-toggler";
import { useUser } from "~hooks";

export const features: Features = {
  preventRouteChangeWhenHavingUnsavedChanges: {
    displayName: t`Prevent page change when having unsaved changes`,
    description: t`It can be very frustrating if you accidentally leave a page that has unsaved changes. Then you need to type everything in again. We've seen this happen to users sometimes. This is an experimental feature that will warn you before leaving a page when having unsaved changes. It's experimental, as it hasn't been tested out properly. So you might experience unnecessary warnings.`,
    enabledForUserRoles: ["proper.support"],
  },
  removeBillItems: {
    displayName: "Remove bill items (WIP)",
    description: "Remove bill items from a customer bill",
    enabledForUserRoles: ["proper.support"],
  },
  showUnpostedLeaseBills: {
    displayName: "Unposted lease bills",
    description: "Show unposted lease bills on the lease overview page",
    enabledForUserRoles: ["proper.support"],
  },
  addCustomTransactions: {
    displayName: "Add custom transactions",
    description:
      "Add custom transactions to reduce manual tasks. This is an export tool and requires that you know exactly what you're doing.",
    enabledForUserRoles: ["proper.support"],
  },
  ownerAccount: {
    displayName: "Owner balance account",
    description: "Allow owners to create a balance account with Proper.",
    enabledForUserRoles: ["proper.support"],
  },
  properApiFeature: {
    displayName: "Proper API",
    description: "Proper API",
    enabledForUserRoles: ["proper.support"],
  },
  categories: {
    displayName: "Categories",
    description: "Categories",
    enabledForUserRoles: ["proper.support"],
  },
  utilityStatementsFeature: {
    displayName: "Utility statements",
    description: "Utility statements",
    enabledForUserRoles: ["proper.support"],
  },
};

const FeatureToggle = ({ children }: any) => {
  const { authUser } = useUser();
  return (
    <Provider
      localStorageKey="self-service-to-point-o"
      features={features}
      userRoles={authUser.roles}
    >
      {children}
    </Provider>
  );
};

export default FeatureToggle;
