import { t } from "@lingui/macro";

import FieldCore, { Props as FieldCoreProps } from "../FieldCore";

const validation = {
  pattern: {
    value:
      /^DE\d{20}|ES\d{22}|DK\d{16}|IT\d{2}[A-Z]\d{10}[A-Za-z0-9]{12}|FR\d{12}[A-Za-z0-9]{11}\d{2}|BE\d{14}|NL\d{2}[A-Z]{4}\d{10}$/,
    message: t`Please provide a valid IBAN number, with no spaces`,
  },
};

export type Props = Omit<FieldCoreProps, "type" | "validation"> & {
  countryCode: "DK" | "NL";
};
const IBAN = (props: Props) => {
  return <FieldCore type="text" validation={validation} {...props} />;
};

export default IBAN;
