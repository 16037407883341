import { useCallback } from "react";

import { useStringCompare } from "~hooks/index";

export default function useOrderByString<T>(
  collection: T[],
  iteratee: (element: T) => string | undefined
) {
  const stringCompare = useStringCompare();

  const orderBy = useCallback(
    (collection: T[], iteratee: (element: T) => string | undefined): T[] => {
      return [...collection].sort((elementA, elementB) => {
        let valueA, valueB;
        if (iteratee === undefined) {
          valueA = String(elementA);
          valueB = String(elementB);
        } else if (typeof iteratee === "function") {
          valueA = iteratee(elementA);
          valueB = iteratee(elementB);
        } else {
          valueA = elementA[iteratee];
          valueB = elementB[iteratee];
        }
        if (
          (valueA === undefined || valueA === null) &&
          (valueB === undefined || valueB === null)
        ) {
          return 0;
        } else if (valueA === undefined || valueA === null) {
          return -1;
        } else if (valueB === undefined || valueB === null) {
          return 1;
        } else {
          return stringCompare(valueA, valueB);
        }
      });
    },
    [stringCompare]
  );

  return orderBy(collection, iteratee);
}
