import styled from "styled-components";

import { baseUnit } from "~components/copied_from_shared/pui";

const Wrapper = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: ${baseUnit / 2}px;
`;

type NavigationSubmenuProps = {
  children: React.ReactNode;
};
export const NavigationSubmenu = ({ children }: NavigationSubmenuProps) => (
  <Wrapper>{children}</Wrapper>
);

export default NavigationSubmenu;
