import { useCallback, useState } from "react";

import { useNotifications } from "~hooks";
import { getRequestUrl, reportError } from "~utils";
import fetch from "~utils/fetch";

import useUser from "./useUser";

export type PostStatus = "creating" | "failed" | "idle";

import { t } from "@lingui/macro";

import { TypeSafeApiRoute } from "next-type-safe-routes";

export type Messages =
  | { error: string; success: string | null }
  | { error: string; info: string | null };

export type Callbacks = {
  onSuccess: (res: any) => boolean;
};

export type Options = {
  notifyOnError?: boolean;
};

const defaultOptions: Options = { notifyOnError: true };

function usePost<ResponseData, RequestData = any>(
  route: TypeSafeApiRoute,
  options: Options = defaultOptions
) {
  const notifications = useNotifications();
  const { authUser, viewAsUser } = useUser();

  const requestUserId = viewAsUser.id;
  const authorizedUserId = authUser.id;
  const [res, setRes] = useState<ResponseData>();
  const [status, setStatus] = useState<PostStatus>("idle");
  const requestUrl = getRequestUrl({
    route,
    authorizedUserId,
    requestUserId,
  });

  const showMessages = (messages: Messages) => {
    if ("success" in messages && !!messages.success) {
      notifications.push({ type: "success", text: messages.success });
    } else if ("info" in messages && !!messages.info) {
      notifications.push({ type: "info", text: messages.info });
    }
  };

  const doPostData = useCallback(
    async (
      data: RequestData,
      messages?: Messages,
      callbacks?: Callbacks
    ): Promise<ResponseData> => {
      try {
        setStatus("creating");
        const result = await fetch(requestUrl, {
          method: "POST",
          body: JSON.stringify(data),
        });
        setRes(result);
        setStatus("idle");
        if (callbacks) {
          if (callbacks.onSuccess(result)) {
            messages && showMessages(messages);
          }
        } else {
          messages && showMessages(messages);
        }
        return result;
      } catch (err: any) {
        reportError(err);
        setStatus("failed");
        console.error(`Post to ${requestUrl} failed`);
        console.error(data);
        console.error(err);
        if (options.notifyOnError) {
          notifications.push({
            type: "error",
            text: messages?.error ?? t`Something went wrong`,
            errorDetails: {
              code: err.code,
              requestId: err.requestId,
              payload: data,
              endpoint: requestUrl,
            },
          });
        }
        throw err;
      }
    },
    []
  );

  return [res, doPostData, { postStatus: status }] as const;
}

export default usePost;
