import { t } from "@lingui/macro";

import { Controller } from "react-hook-form";
import styled from "styled-components";

import {
  formatDate,
  formatMonth,
  formatMonthAndYear,
} from "~components/copied_from_shared/date";
import {
  fontSizeInput,
  fontStack,
  FormField as PUIFormField,
  heightInput,
  Link,
  textColor,
} from "~components/copied_from_shared/pui";
import FormElement, {
  Props as FormElementProps,
} from "~components/copied_from_shared/pui/components/form/Form/FormElement";
import money from "~utils/money";

import { useFormContext } from "./Form";
import { ValidationType } from "./validationSchema";

export type FieldCoreProps = React.ComponentProps<typeof PUIFormField> & {
  validationType?: ValidationType;
};

const paddingVertically = 8;
const lineHeight = heightInput + 2 - paddingVertically * 2;

const Hidden = styled.input`
  display: none;
`;

export const Value = styled.div`
  ${fontStack};
  line-height: ${lineHeight}px;
  padding: ${paddingVertically}px 0;
  font-size: ${fontSizeInput};
  color: ${textColor};
`;

type ReadOnlyFieldType =
  | "year"
  | "month"
  | "quarter"
  | "percentage"
  | "money"
  | "deprecated-money"
  | "address"
  | "text"
  | "number"
  | "email"
  | "select"
  | "checkbox"
  | "date"
  | "autocomplete"
  | "datalist"
  | "area"
  | "search"
  | "vat"
  | "phone"
  | "postal-code"
  | "country-code"
  | "radio"
  | "iban"
  | "bban"
  | "language"
  | "month-year"
  | "textarea"
  | "floor-door"
  | "purpose";

type Props = Omit<FormElementProps, "children" | "id"> & {
  type?: ReadOnlyFieldType;
  defaultValue?: any;
  defaultChecked?: boolean;
  name: string;
};
export const FieldReadOnly = ({
  type,
  defaultValue,
  defaultChecked,
  name,
  ...rest
}: Props) => {
  const { register, control, watch } = useFormContext();
  let value = watch(name) || defaultValue || defaultChecked;

  if (value === undefined || value === null || value === "") {
    value = t`Not specified`;
  } else if (type === "money" || type === "deprecated-money") {
    value = money(value as string).format();
  } else if (type === "date") {
    value = formatDate(value as string);
  } else if (type === "month-year") {
    value = formatMonthAndYear(value as string);
  } else if (type === "percentage") {
    value = `${value as string}%`;
  } else if (type === "checkbox") {
    value = value ? t`Yes` : t`No`;
  } else if (type === "email") {
    value = <Link href={`mailto:${value}`}>{value}</Link>;
  } else if (type === "phone") {
    value = <Link href={`tel:${value}`}>{value}</Link>;
  } else if (type === "month") {
    value = formatMonth(value - 1);
  }

  return (
    <FormElement {...rest} id={`form-element-${name}`} readOnly>
      <Controller
        disabled
        name={name}
        as={Hidden}
        defaultValue={defaultValue || undefined} //Warning: `value` prop on `input` should not be null. Consider using an empty string to clear the component or `undefined` for uncontrolled components.
        control={control}
        ref={register}
      />
      <Value>{value}</Value>
    </FormElement>
  );
};

export default FieldReadOnly;
