import { Trans } from "@lingui/macro";

import styled from "styled-components";

import Link, { Anchor } from "~components/connected/Link";
import {
  baseUnit,
  Heading,
  menuItemPaddingHorizontal,
  Paragraph,
} from "~components/copied_from_shared/pui";
import { Settings } from "~components/copied_from_shared/pui/icons";

const Wrapper = styled.div`
  width: 100%;
  padding: ${baseUnit * 1.5}px ${menuItemPaddingHorizontal}px ${baseUnit}px
    ${menuItemPaddingHorizontal}px;
`;

const StyledAnchor = styled(Anchor)`
  text-decoration: none;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
`;

const Name = styled(Heading).attrs({ size: "small" })`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Email = styled(Paragraph).attrs({ size: "small", look: "discreet" })`
  margin-top: ${baseUnit / 4}px;
`;

type Props = {
  userId: string;
  userName?: string;
  userEmail?: string;
};
const UserInfo = ({ userId, userName, userEmail }: Props) => (
  <Wrapper>
    <Name>
      {userName || userId}
      <Link to="/user/settings">
        <StyledAnchor>
          <Settings size={20} />
        </StyledAnchor>
      </Link>
    </Name>
    <Email>{userEmail || <Trans>No email address</Trans>}</Email>
  </Wrapper>
);

export default UserInfo;
