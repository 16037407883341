import { useContext } from "react";

import { Context } from "~contexts/uploads";

const useStatefulUpload = () => {
  const uploads = useContext(Context);
  return uploads;
};

export default useStatefulUpload;
