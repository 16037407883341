import { useEffect, useReducer, useRef } from "react";

import { useInterval, useIsMounted, useIsWindowFocused } from "~hooks";

function reducer(state, action) {
  switch (action.type) {
    case "RETRY":
      return { ...state, error: null };
    case "UPDATE":
      return {
        ...state,
        shouldUpdate: true,
        shouldUpdateWithoutErrorHandling: false,
        error: null,
      };
    case "UPDATE_WITHOUT_ERROR_HANDLING":
      return { ...state, shouldUpdateWithoutErrorHandling: true };
    case "REFRESH":
      return { ...state, shouldRefresh: true, error: null };
    case "WILL_LOAD":
      return { ...state, loading: true };
    case "DID_LOAD":
      return { ...state, loading: false };
    case "WILL_UPDATE":
      return {
        ...state,
        shouldUpdate: false,
        shouldUpdateWithoutErrorHandling: false,
        updating: true,
      };
    case "DID_UPDATE":
      return { ...state, updating: false };
    case "WILL_REFRESH":
      return { ...state, shouldRefresh: false, refreshing: true };
    case "DID_REFRESH":
      return { ...state, refreshing: false };
    case "DID_ERROR":
      return {
        ...state,
        loading: false,
        updating: false,
        refreshing: false,
        error: action.error,
      };
    default:
      throw new Error(action);
  }
}

const useSceneData = (props) => {
  const [state, dispatch] = useReducer(reducer, {
    shouldUpdate: props.shouldUpdate ?? false,
    shouldUpdateWithoutErrorHandling: false,
    shouldRefresh: false,
    willLoad: false,
    willUpdate: false,
    willRefresh: false,
    loading: false,
    updating: false,
    refreshing: false,
    error: null,
  });

  const isMounted = useIsMounted();

  const isWindowFocused = useIsWindowFocused();

  let firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (isWindowFocused && !state.error) dispatch({ type: "UPDATE" });
  }, [isWindowFocused, state.error]);

  const poll = () => {
    if (state.loading || state.updating || state.refreshing) return;
    if (state.shouldRefresh && props.refresh) {
      refresh();
    } else if (state.shouldUpdate && props.update) {
      update();
    } else if (state.error) {
      // do nothing
    } else if (state.shouldUpdateWithoutErrorHandling && props.update) {
      updateWithoutErrorHandling();
    } else if (props.shouldLoad && props.load) {
      if (props.getScrollProperties) {
        const scrollProperties = props.getScrollProperties();
        if (!scrollProperties) return;
        const { visibleLength, offset, contentLength } = scrollProperties;
        if (visibleLength === 0) return;
        if (
          visibleLength + offset >
          contentLength - (props.loadingIndicatorHeight ?? 0) - 800
        ) {
          load();
        }
      } else {
        load();
      }
    }
  };

  useInterval(poll, 100);

  const load = async () => {
    try {
      dispatch({ type: "WILL_LOAD" });
      await props.load();
      if (isMounted.current) dispatch({ type: "DID_LOAD" });
    } catch (error) {
      if (isMounted.current) {
        dispatch({ type: "DID_ERROR", error });
        throw error;
      }
    }
  };

  const update = async () => {
    try {
      dispatch({ type: "WILL_UPDATE" });
      await props.update();
      if (isMounted.current) dispatch({ type: "DID_UPDATE" });
    } catch (error) {
      if (isMounted.current) {
        dispatch({ type: "DID_ERROR", error });
        throw error;
      }
    }
  };

  const updateWithoutErrorHandling = async () => {
    try {
      dispatch({ type: "WILL_UPDATE" });
      await props.update();
      if (isMounted.current) dispatch({ type: "DID_UPDATE" });
    } catch (error) {
      if (isMounted.current) {
        dispatch({ type: "DID_ERROR", error });
      }
    }
  };

  const refresh = async () => {
    try {
      dispatch({ type: "WILL_REFRESH" });
      await props.refresh();
      if (isMounted.current) dispatch({ type: "DID_REFRESH" });
    } catch (error) {
      if (isMounted.current) {
        dispatch({ type: "DID_ERROR", error });
        throw error;
      }
    }
  };

  return { state, dispatch };
};

export default useSceneData;
