import styled from "styled-components";

import { baseUnit } from "../../../tokens";

const DefaultRow = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-column-gap: ${baseUnit * 1.5}px;
  grid-row-gap: ${baseUnit * 2}px;
`;

const TightRow = styled.div`
  display: flex;

  > * {
    flex: none;

    &:not(:last-child) {
      margin-right: ${baseUnit * 2}px;
    }
  }
`;

type Props = {
  tight?: boolean;
  children: any;
};
const Row = ({ children, tight }: Props) => {
  if (tight) {
    return <TightRow>{children}</TightRow>;
  } else {
    return <DefaultRow>{children}</DefaultRow>;
  }
};

export default Row;
