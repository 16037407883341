import { createGlobalStyle } from "styled-components";

import { pampas100 } from "~components/copied_from_shared/pui";

export const appBackgroundColor = pampas100;

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    background-color: ${appBackgroundColor};
  }
`;

export default GlobalStyle;
