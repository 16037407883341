import styled from "styled-components";

import { baseUnit } from "~components/copied_from_shared/pui";

const navigationWidth = 250;
const navigationMarginRight = baseUnit * 3;

const Wrapper = styled.ul`
  list-style-type: none;
  margin: 0 ${navigationMarginRight}px 0 0;
  padding: 0;
  width: ${navigationWidth}px;
`;

type Props = {
  children: React.ReactNode;
};
const Navigation = ({ children }: Props) => <Wrapper>{children}</Wrapper>;

export default Navigation;
