import { TypeSafeApiRoute, TypeSafePage } from "next-type-safe-routes";
import styled from "styled-components";

import Link, { Anchor } from "~components/connected/Link";
import { MenuItem as PUIMenuItem } from "~components/copied_from_shared/pui";

const StyledAnchor = styled(Anchor)`
  text-decoration: none;
  width: 100%;
  display: block;
`;

type Props = {
  text: string;
  linkTo: TypeSafePage | TypeSafeApiRoute;
  Icon?: any;
  onClick?: () => void;
};
const MenuItem = ({ text, linkTo, Icon, onClick }: Props) => {
  const item = (
    <PUIMenuItem Icon={Icon} onClick={onClick}>
      <Link to={linkTo}>
        <StyledAnchor>{text}</StyledAnchor>
      </Link>
    </PUIMenuItem>
  );

  return item;
};

export default MenuItem;
