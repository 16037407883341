import { t } from "@lingui/macro";

import { useUser } from "~hooks";

import FieldCore, { Props as FieldCoreProps } from "../FieldCore";

export type Props = Omit<FieldCoreProps, "type" | "placeholder">;

const CountryCode = (props: Props) => {
  const { isSupport } = useUser();
  return (
    <FieldCore type="select" placeholder={t`Choose country`} {...props}>
      <option value="DK">DK</option>
      <option disabled={!isSupport} value="NL">
        NL
      </option>
      <option disabled={!isSupport} value="DE">
        DE
      </option>
    </FieldCore>
  );
};

export default CountryCode;
