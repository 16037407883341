import { t } from "@lingui/macro";

import { useEffect, useRef } from "react";

import { Field } from "formik";

import { blue500 } from "~components/copied_from_shared/pui";

import { Satisfaction } from "../../../graphql/generated_types";

import { getIcon, getReaction } from "~features/customer-feedback/utils";

type Props = {
  satisfaction: Satisfaction;
};
const AddComment = ({ satisfaction }: Props) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const Icon = getIcon(satisfaction);
  const reaction = getReaction(satisfaction);

  useEffect(() => {
    textareaRef.current && textareaRef.current.focus();
  }, [satisfaction]);

  return (
    <div className="flex flex-col items-center justify-center gap-4">
      <Icon size="48px" color={blue500} />
      <h6 className="h-12 min-h-full text-center text-default">{reaction}</h6>
      <Field
        name="comment"
        component="textarea"
        innerRef={textareaRef}
        rows="3"
        placeholder={t`I like... I wish...`}
        className="w-full resize-none rounded-lg border border-gray-200 p-4 text-default placeholder:text-gray-400 hover:border-gray-500 focus:border-blue-500"
      />
    </div>
  );
};

export default AddComment;
