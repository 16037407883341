import { forwardRef } from "react";

import styled from "styled-components";

import { black, blue500 } from "../../tokens";
import Tooltip from "../Tooltip";

import { heightRow } from "./Table";

const StyledTd = styled.td`
  width: ${heightRow}px;
  opacity: 1 !important;
  text-align: center;
  position: relative;
`;

const Button = styled.button<{ isClickable?: boolean }>`
  padding: 0;
  background: none;
  border: none;
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "default")};
  opacity: 1;
  position: absolute;
  top: 11px;
  left: 11px;
  height: 20px;

  &:hover {
    opacity: 0.5;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.25;
  }
`;

type Props = {
  Icon: any;
  tooltip?: string;
  onClick?: (e: any) => void;
  disabled?: boolean;
};
const TdAction = ({ Icon, onClick, tooltip, disabled }: Props, ref: any) => (
  <StyledTd ref={ref}>
    <Button
      disabled={disabled}
      type="button"
      isClickable={!!onClick}
      onClick={(e: any) => {
        e && e.stopPropagation();
        if (onClick) {
          onClick(e);
        }
      }}
    >
      <Tooltip title={tooltip}>
        <Icon color={!!onClick || disabled ? black : blue500} size={20} />
      </Tooltip>
    </Button>
  </StyledTd>
);

export default forwardRef(TdAction);
