import { t } from "@lingui/macro";

import styled from "styled-components";

import {
  baseUnit,
  DialogDeprecated,
  Paragraph,
  support500,
  textColorDiscreet,
  Tooltip,
} from "~components/copied_from_shared/pui";
import { Smile } from "~components/copied_from_shared/pui/icons";
import { useUser } from "~hooks";

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;
const ListItem = styled.li`
  padding-left: ${baseUnit * 1.5}px;
  position: relative;

  &::before {
    position: absolute;
    top: 9px;
    left: 0;
    content: "";
    display: block;
    width: 5px;
    height: 5px;
    background-color: ${textColorDiscreet};
    border-radius: 50%;
  }
`;
const IconButton = styled.button`
  cursor: pointer;
  padding: 0;
  background: none;
  border: none;
  display: flex;
  margin-left: ${baseUnit / 2}px;
`;

type SupportGuidelineText = {
  children: any;
};
export const SupportGuideline = ({ children }: SupportGuidelineText) => {
  return (
    <ListItem>
      <Paragraph>{children}</Paragraph>
    </ListItem>
  );
};

type Props = {
  children: any;
};
const SupportGuidelines = ({ children }: Props) => {
  const { isSupport } = useUser();

  if (!isSupport) {
    return null;
  }
  return (
    <DialogDeprecated
      maxWidth={560}
      trigger={
        <IconButton>
          <Tooltip title={t`Show support guidelines`}>
            <Smile size={20} color={support500} />
          </Tooltip>
        </IconButton>
      }
    >
      <DialogDeprecated.Header>
        <DialogDeprecated.HeaderTitle Icon={Smile}>
          Support guidelines
        </DialogDeprecated.HeaderTitle>
      </DialogDeprecated.Header>
      <DialogDeprecated.Body>
        <List>{children}</List>
      </DialogDeprecated.Body>
    </DialogDeprecated>
  );
};

SupportGuidelines.Item = SupportGuideline;

export default SupportGuidelines;
