import styled from "styled-components";

import {
  baseUnit,
  fontStack,
  info100,
  pampas100,
  textColor,
  white,
} from "~components/copied_from_shared/pui";
import { borderRadius } from "~components/copied_from_shared/pui/components/Popover";
import { X as Close } from "~components/copied_from_shared/pui/icons";

export const Wrapper = styled.div`
  position: relative;
`;

export const SearchFieldWrapper = styled.div`
  position: relative;
`;

export const Ul = styled.ul`
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: absolute;
  background: ${white};
  list-style-type: none;
  min-width: 240px;
  top: calc(100% + 6px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  border-radius: ${borderRadius};
  z-index: 9999;
  right: 0;
`;

type LiProps = {
  isHighlighted?: boolean;
  isSelected?: boolean;
};
export const Li = styled.li<LiProps>`
  ${fontStack};
  background-color: ${({ isHighlighted, isSelected }: any) => {
    if (isHighlighted) {
      return pampas100;
    } else if (isSelected) {
      return info100;
    }
    return "initial";
  }};
  padding: ${baseUnit / 1.5}px ${baseUnit}px;
  white-space: nowrap;
  align-items: space-between;
  width: 100%;
  cursor: pointer;
`;

export const Address = styled.span`
  color: ${textColor};
  font-size: 15px;
  font-weight: bold;
  display: block;
  margin-bottom: ${baseUnit / 3}px;
  max-width: 40ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Meta = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TenantName = styled.span`
  font-size: 14px;
  color: ${textColor};
  max-width: 40ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ClearSelectionButton = styled(Close)`
  position: absolute;
  top: calc(50% - 9px);
  right: 12px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  opacity: 0.4;
  border-radius: 50%;
  width: 18px !important;
  height: 18px !important;
  padding: 1px;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;
