import styled from "styled-components";

import { baseUnit, borderColor } from "~components/copied_from_shared/pui";

const NavigationSeparator = styled.div`
  margin-bottom: ${baseUnit * 0.5}px;
  padding-bottom: ${baseUnit * 0.5}px;
  border-bottom: 1px solid ${borderColor};
`;

export default NavigationSeparator;
