import { Trans } from "@lingui/macro";

import clsx from "clsx";
import { Field } from "formik";

import { Disappointment as DisappointmentType } from "../../../graphql/generated_types";

type OptionProps = {
  disappointment: DisappointmentType;
  active?: boolean;
  children: any;
};
const Option = ({ disappointment, active, children }: OptionProps) => {
  const labelClassName = clsx(
    "text-md cursor-pointer rounded-lg border-2 p-4 text-center active:bg-blue-600 active:text-white",
    {
      "border-blue-500 bg-blue-500 text-white hover:bg-blue-400": active,
      "border-gray-200 text-default hover:border-blue-500": !active,
    }
  );

  return (
    <>
      <input
        type="radio"
        name="disappointment"
        id={disappointment}
        value={disappointment}
        defaultChecked={active}
        className="fixed w-0 opacity-0"
      />
      <label htmlFor={disappointment} className={labelClassName}>
        {children}
      </label>
    </>
  );
};

type Props = {
  value?: DisappointmentType;
};
const Disappointment = ({ value }: Props) => {
  return (
    <Field component="div" name="disappointment" required>
      <div className="flex flex-row items-center justify-center gap-4">
        <Option
          disappointment={DisappointmentType.NotSad}
          active={value === DisappointmentType.NotSad}
        >
          <Trans>Don't care</Trans>
        </Option>
        <Option
          disappointment={DisappointmentType.LittleSad}
          active={value === DisappointmentType.LittleSad}
        >
          <Trans>A little sad</Trans>
        </Option>
        <Option
          disappointment={DisappointmentType.VerySad}
          active={value === DisappointmentType.VerySad}
        >
          <Trans>Very sad</Trans>
        </Option>
      </div>
    </Field>
  );
};

export default Disappointment;
