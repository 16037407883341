import { useContext } from "react";

import { Context } from "~contexts/notices";

const useNotices = () => {
  const { notices } = useContext(Context);
  return [notices] as const;
};

export default useNotices;
