import { Trans } from "@lingui/macro";

import styled from "styled-components";

import {
  baseUnit,
  fontStack,
  Heading,
} from "~components/copied_from_shared/pui";
import { useUser } from "~hooks";
import { PostStatus } from "~hooks/usePost";
import { ResourceStatus } from "~hooks/useResource";

import Loader from "../../Loader";

import ErrorNotFound from "./ErrorNotFound";
import GetError from "./GetError";
import PageBackLink from "./PageBackLink";

import CustomerSurveyModal from "~features/customer-feedback/SurveyFeedback/SurveyFeedback";

export const pageMaxWidth = 1100;
export const pagePaddingHorizontal = baseUnit * 2;

const Wrapper = styled.div`
  max-width: ${pageMaxWidth}px;
  margin: 0 auto;
  padding: ${baseUnit * 4}px ${pagePaddingHorizontal}px;
`;
const Inner = styled.div`
  display: flex;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  margin-bottom: ${baseUnit * 2}px;
`;

const Content = styled.div`
  flex: 1;
`;

const Children = styled.div``;

const Indicator = styled.div`
  ${fontStack};
  font-size: 15px;
  font-weight: bold;
  padding: 5px 10px;
  position: fixed;
  bottom: ${baseUnit * 2}px;
  left: ${baseUnit * 2}px;
  background: lightgoldenrodyellow;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  font-weight: bold;
`;

export type PageContentProps = {
  status?: ResourceStatus | PostStatus;
  children: any;
  title?: string;
};
const PageContent = ({ children, title, status }: PageContentProps) => {
  if (status === "get-error") {
    return <GetError />;
  } else if (status === "not-found") {
    return <ErrorNotFound />;
  }

  return (
    <>
      {status === "validating" && (
        <Indicator>
          <Trans>Validating</Trans>...
        </Indicator>
      )}
      {title && (
        <Title>
          <Heading size="large">{title}</Heading>
        </Title>
      )}
      {status === "pending" ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <Children>{children}</Children>
      )}
    </>
  );
};

export type Props = PageContentProps & {
  navigation?: React.ReactNode;
  title?: string | React.ReactNode;
  backText?: string | null;
  wide?: boolean;
};
const PageDeprecated = ({ navigation, backText, ...rest }: Props) => {
  const { viewAsUser } = useUser();
  const isDemoUser =
    viewAsUser.roles.includes("proper.demo") ||
    viewAsUser.roles.includes("demo");

  return (
    <Wrapper>
      <Inner>
        {navigation}
        <Content>
          {!!backText && <PageBackLink>{backText}</PageBackLink>}
          <PageContent {...rest} />
        </Content>
      </Inner>
      {!isDemoUser && <CustomerSurveyModal />}
    </Wrapper>
  );
};

export default PageDeprecated;
