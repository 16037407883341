import styled from "styled-components";

import { pageMaxWidth, pagePaddingHorizontal } from "~components/connected";
import {
  baseUnit,
  support500,
  teal800,
} from "~components/copied_from_shared/pui";

import Actions, { Props as ActionsProps } from "./Actions";
import Search from "./Search";
import SupportAreaToggle from "./SupportAreaToggle";
import ViewAs from "./ViewAs";

const Wrapper = styled.div<{
  isPaymentsCustomer: boolean;
}>`
  background: ${(props) => (props.isPaymentsCustomer ? teal800 : support500)};
`;

const Inner = styled.div`
  align-items: center;
  display: flex;
  height: 56px;
  max-width: ${pageMaxWidth}px;
  margin: 0 auto;
  padding: 0 ${pagePaddingHorizontal}px;

  > *:nth-child(2) {
    margin-right: auto;
  }
`;

const ActionsWrapper = styled.div`
  margin-left: ${baseUnit}px;
`;

type Props = ActionsProps & {
  onViewAsSelected: (id: string | undefined) => void;
  onSearch: (query: string) => void;
  isPaymentsCustomer: boolean;
};
const SupportHeader = ({
  onViewAsSelected,
  onSearch,
  isPaymentsCustomer,
  ...rest
}: Props) => {
  return (
    <Wrapper isPaymentsCustomer={isPaymentsCustomer}>
      <Inner>
        <SupportAreaToggle isPaymentsCustomer={isPaymentsCustomer} />
        <ViewAs onViewAsSelected={onViewAsSelected} />
        <Search isPaymentsCustomer={isPaymentsCustomer} onSearch={onSearch} />
        <ActionsWrapper>
          <Actions {...rest} />
        </ActionsWrapper>
      </Inner>
    </Wrapper>
  );
};

export default SupportHeader;
