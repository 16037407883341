import { t } from "@lingui/macro";

import FieldCore, { Props as FieldCoreProps } from "../FieldCore";

export type Props = Omit<FieldCoreProps, "type">;

const Purpose = (props: Props) => {
  return (
    <FieldCore {...props} type="select">
      <option value="key:residential">{t`Residential`}</option>
      <option value="key:commercial">{t`Commercial`}</option>
    </FieldCore>
  );
};

export default Purpose;
