import { Address, Li, Meta, TenantName } from "./StyledComponents";
import { SearchResult } from "./types";

type ItemProps = {
  item: SearchResult;
  index: number;
  highlightedIndex: number | null;
  selectedItem: SearchResult;
  getItemProps: any;
  inputValue: string;
};
const SearchItem = ({
  item,
  index,
  highlightedIndex,
  selectedItem,
  getItemProps,
}: ItemProps) => {
  const props = getItemProps({ key: item.leaseId, index, item });
  const isSelected = selectedItem && selectedItem.leaseId === item.leaseId;
  return (
    <Li
      isHighlighted={highlightedIndex === index}
      isSelected={isSelected}
      {...props}
    >
      <Address>{item.address}</Address>
      <Meta>
        <TenantName>{item.tenantName}</TenantName>
      </Meta>
    </Li>
  );
};

type ItemsProps = {
  items: any;
  inputValue: string | null;
  highlightedIndex: number | null;
  selectedItem: any;
  getItemProps: any;
};
const SearchItems = ({
  items,
  inputValue,
  getItemProps,
  selectedItem,
  highlightedIndex,
}: ItemsProps) => {
  return items.map((item: any, index: any) => (
    <SearchItem
      key={item.leaseId}
      item={item}
      index={index}
      inputValue={inputValue || ""}
      highlightedIndex={highlightedIndex}
      selectedItem={selectedItem}
      getItemProps={getItemProps}
    />
  ));
};

export default SearchItems;
