import { createContext } from "react";

import Uppy from "@uppy/core";

import { Relations, Upload } from "~components/connected/app/Providers/Uploads";

export type ContextType = {
  uploads: Upload[];
  add: (
    file: FileList,
    relations: Relations,
    options?: Uppy.UppyOptions
  ) => void;
  remove: (id: string) => void;
  totalProgress: number;
};

export const Context = createContext<ContextType>({
  uploads: [],
  totalProgress: 0,
  add: () => {
    // do nothing
  },
  remove: () => {
    // do nothing
  },
});

export const Provider = Context.Provider;
