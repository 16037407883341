import { pickBy } from "lodash";

function isTruthyObject(object?: Record<string, unknown> | null) {
  if (!object) {
    return false;
  }
  return Object.keys(pickBy(object)).length > 0;
}

export default isTruthyObject;
