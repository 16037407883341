import { TypeSafePage } from "next-type-safe-routes";
import styled from "styled-components";

import Link from "~components/connected/Link";
import { baseUnit, Button } from "~components/copied_from_shared/pui";

const Wrapper = styled.div`
  &:not(:first-child) {
    margin-top: ${baseUnit * 2}px;
  }

  &:not(:last-child) {
    margin-bottom: ${baseUnit * 2}px;
  }
`;

type Props = {
  linkTo: TypeSafePage;
  Icon?: any;
  children: any;
};
const NavigationButton = ({ Icon, children, linkTo }: Props) => (
  <Wrapper>
    <Link to={linkTo}>
      <Button Icon={Icon}>{children}</Button>
    </Link>
  </Wrapper>
);

export default NavigationButton;
