import Link from "next/link";
import { useRouter } from "next/router";
import styled from "styled-components";

import {
  baseUnit,
  fontStack,
  textColor,
} from "~components/copied_from_shared/pui";
import { ChevronLeft } from "~components/copied_from_shared/pui/icons";

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const StyledAnchor = styled.a`
  display: flex;
  align-items: center;
  font-size: 16px;
  ${fontStack};
  font-weight: bold;
  cursor: pointer;
  margin-bottom: ${baseUnit * 3}px;
  color: ${textColor};
  text-decoration: none;

  svg {
    margin-right: ${baseUnit / 2}px;
    width: 20px;
    height: 20px;
  }

  &:hover {
    text-decoration: underline;
  }
`;

type Props = {
  children: string;
};
const PageBackLink = ({ children }: Props) => {
  const { pathname, asPath } = useRouter();
  const as = asPath.slice(0, asPath.lastIndexOf("/"));
  const href = pathname.slice(0, pathname.lastIndexOf("/"));
  return (
    <Wrapper>
      <Link href={href} as={as}>
        <StyledAnchor>
          <ChevronLeft />
          {children}
        </StyledAnchor>
      </Link>
    </Wrapper>
  );
};

export default PageBackLink;
