import { useRef, useState } from "react";

import { Provider } from "~contexts/notifications";

export type Type = "info" | "error" | "success";

export type ErrorCode =
  | "email.in-use"
  | "properties.lease.one-primary-tenant"
  | "entities.person.has-credentials";
export type ErrorDetails = {
  code: ErrorCode;
  requestId: string;
  endpoint: string;
  payload: any;
  reason?: string;
};
export type Notification = {
  id: string;
  type: Type;
  text: string;
  errorDetails?: ErrorDetails;
};

export type PushNotification =
  | { type: Type; text: string; errorDetails?: ErrorDetails }
  | string;

type PrivateNotification = Notification & {
  timeout: any;
};

const autoClearMs = 4000;

function getNotificationObject(
  notificationStringOrObject: PushNotification,
  id: string
): Notification {
  if (typeof notificationStringOrObject === "string") {
    return {
      type: "info" as Type,
      text: notificationStringOrObject,
      id,
    };
  } else {
    return { ...notificationStringOrObject, id };
  }
}

const Notifications = ({ children }: any) => {
  const [notifications = [], setNotifications] =
    useState<PrivateNotification[]>();
  const notificationsRef = useRef(notifications);
  notificationsRef.current = notifications;

  const clear = (id: string, notifications: PrivateNotification[]) => {
    const notification = notifications.find((f) => f.id === id);
    if (notification) {
      clearTimeout(notification.timeout);
      setNotifications(notifications.filter((f) => f.id !== id));
    }
  };

  const push = (notificationStringOrObject: PushNotification) => {
    const id = `notification-${notifications.length}`;
    const notification = getNotificationObject(notificationStringOrObject, id);
    const timeout = ["info", "success"].includes(notification.type)
      ? setTimeout(() => {
          // Using a state property inside of a setTimeout does not
          // use the current value of that state property. setTimeout
          // is a closure, therefore, when setTimeout is scheduled
          // it uses the value of notifications at that exact moment
          // in time, which is the initial value of [].
          // Details: https://upmostly.com/tutorials/settimeout-in-react-components-using-hooks
          clear(id, notificationsRef.current);
        }, autoClearMs)
      : null;
    const updatedNotifications = [
      ...notifications,
      { ...notification, timeout },
    ];
    setNotifications(updatedNotifications);
  };

  return (
    <Provider
      value={{
        // this is just to exclude the private "timeout" property
        notifications: notifications.map((notification) => {
          const { id, type, text, errorDetails } = notification;
          return { id, type, text, errorDetails };
        }),
        push,
        clear: (id: string) => clear(id, notifications),
      }}
    >
      {children}
    </Provider>
  );
};

export default Notifications;
