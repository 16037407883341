import { Trans } from "@lingui/macro";

import React from "react";

import Image from "next/image";

import { Button } from "~components/tailwind/Button";

const Trustpilot = () => (
  <Image
    src={`/images/feedback-services/Trustpilot.png`}
    alt="Trustpilot"
    layout="fill"
    objectFit="contain"
  />
);

const Google = () => (
  <Image
    src={`/images/feedback-services/GoogleReview.png`}
    alt="Google Review"
    layout="fill"
    objectFit="contain"
  />
);

const ExternalServiceReview = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-8">
      <h6 className="text-center text-2xl font-bold text-default">
        <Trans>Thank you for sharing your opinion!</Trans>
      </h6>
      <div className="content-center text-center text-lg text-gray-600">
        <p>
          <Trans>
            Positive customer experiences are the foundation of our business.
            Would you be willing to share your thoughts on Trustpilot or Google
            to help us spread the word?
          </Trans>
        </p>
      </div>
      <div className="text-gray-600">
        <p>
          <Trans>
            (click below to type your review - it takes two minutes)
          </Trans>
        </p>
      </div>
      <div className="flex flex-row items-baseline gap-2">
        <a
          href="https://www.trustpilot.com/evaluate/helloproper.com"
          target="_blank"
          rel="noreferrer"
        >
          <Button className="h-12 w-36 shadow-xl">
            <Trustpilot />
          </Button>
        </a>
        <a
          href="https://g.page/r/CbeRult4zQ6gEBM/review"
          target="_blank"
          rel="noreferrer"
        >
          <Button className="h-12 w-36 shadow-xl">
            <Google />
          </Button>
        </a>
      </div>
    </div>
  );
};

export default ExternalServiceReview;
