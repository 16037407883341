import * as React from "react";

import {
  getPathname,
  getRoute,
  TypeSafeApiRoute,
  TypeSafePage,
} from "next-type-safe-routes";
import LinkComponent from "next/link";
import styled from "styled-components";

import { useRouter } from "~hooks";

export const Anchor = styled.a<{
  $isActive?: boolean;
  $isActiveParent?: boolean;
}>`
  color: currentColor;
`;

type GetIsActiveProps = {
  pathname: string;
  href: any;
  asPath?: any;
  as?: any;
};
const getIsActive = ({ pathname, asPath, href, as }: GetIsActiveProps) => {
  if (as) {
    return pathname === href && asPath.split("?")[0] === as.split("?")[0];
  }

  return pathname === href;
};

type GetIsActiveParent = {
  doNotShowAsActiveParent: boolean;
  href: string;
  pathname: string;
  stepParentForPaths?: string[];
};
const getIsActiveParent = ({
  doNotShowAsActiveParent,
  stepParentForPaths,
  href,
  pathname,
}: GetIsActiveParent) => {
  if (doNotShowAsActiveParent) {
    return false;
  } else if (href === "/") {
    return pathname === href;
  }

  const isStepParent = stepParentForPaths?.find(
    (path) => pathname.indexOf(`${path}/`) !== -1
  );

  return isStepParent || pathname.indexOf(`${href}/` as string) !== -1;
};

type Props = {
  to: TypeSafePage | TypeSafeApiRoute;
  children: React.ReactElement;
  // Better name welcomed. The challenge is, that some items
  // "don't want" to be shown as parent even though they are
  // pages that on a urls basis are their children
  doNotShowAsActiveParent?: boolean;
  // Used to specify that a link should be considered
  // as an active parent for one or more unrelated paths
  // e.g., we want the "Properties" link in the header to
  // be active parent when viewing /tenancies/... pages
  stepParentForPaths?: string[];
};

const Link = ({
  to,
  children,
  doNotShowAsActiveParent = false,
  stepParentForPaths,
  ...rest
}: Props) => {
  const href = getPathname(to);
  const as = getRoute(to);
  const { pathname, asPath } = useRouter();
  const isActive = getIsActive({ pathname, href, as, asPath });
  const isActiveParent = getIsActiveParent({
    href,
    pathname,
    doNotShowAsActiveParent,
    stepParentForPaths,
  });

  return (
    <LinkComponent href={href} as={as} passHref {...rest}>
      {React.cloneElement(children, {
        $isActive: isActive,
        $isActiveParent: isActiveParent,
      })}
    </LinkComponent>
  );
};

export default Link;
