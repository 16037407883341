import { Theme } from "../components/ThemeProvider/ThemeProvider";

type ThemeValues = { [key in Theme]?: string };

type Mode = "default" | "support";
type Themes = {
  default: ThemeValues;
  [key: string]: ThemeValues;
};

type Props = {
  theme: {
    mode: Mode;
  };
};
export const variant = function (value: string, themes: ThemeValues) {
  return function (props: Props) {
    return themes[props.theme.mode] || value;
  };
};

const variants = function (prop: any, themes: Themes) {
  return function (props: any) {
    const variant = (themes[props[prop]] || themes["default"]) as any;
    return variant[props.theme.mode] || variant["default"];
  };
};

export default variants;
