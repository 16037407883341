import * as React from "react";

import { AlertTriangle, CheckCircle, Loader } from "react-feather";
import styled, { css } from "styled-components";

import { rotate } from "../../../mixins";
import {
  baseUnit,
  borderColorInput,
  danger500,
  heightInput,
  iconSize,
  success500,
  textColorInput,
  warning500,
} from "../../../tokens";
import { variants } from "../../../utils";
import { heightRow as heightTableRow } from "../../Table";
import Tooltip from "../../Tooltip";

import { Status } from "./types";

const iconColor = variants("status", {
  default: { default: borderColorInput },
  valid: { default: success500 },
  invalid: { default: danger500 },
  warning: { default: warning500 },
  loading: { default: textColorInput },
});

const getStatusIcon = (status: Status) => {
  if (status === "valid") {
    return CheckCircle;
  } else if (status === "invalid") {
    return AlertTriangle;
  } else if (status === "warning") {
    return AlertTriangle;
  } else if (status === "loading") {
    return Loader;
  }
  return null;
};

const StatusWrapper = styled.span<{ status: Status }>`
  height: ${heightInput}px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${iconColor};
  pointer-events: all;
  padding-right: ${baseUnit}px;

  td &:last-child {
    padding-right: 0;
  }

  td & {
    padding-right: 0;
    line-height: ${heightTableRow - 4}px;
    height: ${heightTableRow - 4}px;
  }

  svg {
    animation: ${({ status }) =>
      status === "loading"
        ? css`
            ${rotate} 2s linear infinite
          `
        : "none"};
  }
`;

export type Props = {
  status?: Status;
  hint?: string | React.ReactElement | null;
};

/**
 * The `TextField` component is used when we need a user to
 * provide some sort of info or to perform searches.
 */
const TextFieldStatus = ({ status = "default", hint }: Props) => {
  const StatusIcon = getStatusIcon(status);

  if (!StatusIcon) {
    return null;
  }

  return (
    <Tooltip title={hint}>
      <StatusWrapper status={status}>
        <StatusIcon size={iconSize} />
      </StatusWrapper>
    </Tooltip>
  );
};

export default TextFieldStatus;
