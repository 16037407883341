function highlightLetters(value: string, inputValue = "") {
  const letters = value.split("");
  let markup = "";
  letters.forEach((l) => {
    if (inputValue.toLowerCase().indexOf(l.toLowerCase()) !== -1) {
      markup += l;
    } else {
      markup += `<strong>${l}</strong>`;
    }
  });

  return <span dangerouslySetInnerHTML={{ __html: markup }} />;
}

export default highlightLetters;
