import { Skeleton } from "~components/ContentLoader";
import { height } from "~components/copied_from_shared/pui/components/Button";

import {
  DialogActions,
  DialogBody,
  DialogHeader,
  DialogHeaderTitle,
} from "./Dialog";

type Props = {
  children: any;
};
const DialogFallback = ({ children }: Props) => (
  <>
    <DialogHeader>
      <DialogHeaderTitle>
        <Skeleton width="260px" />
      </DialogHeaderTitle>
    </DialogHeader>
    <DialogBody>{children}</DialogBody>
    <DialogActions>
      <Skeleton width="180px" height={`${height}px`} />
    </DialogActions>
  </>
);

export default DialogFallback;
