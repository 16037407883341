import { Dispatch, useState } from "react";

import { EnabledFeatures } from "./types";

export default function useEnabledFeatures(
  key: string
): [EnabledFeatures, Dispatch<EnabledFeatures>] {
  if (typeof window === "undefined") {
    return [{}, () => {}];
  }

  const item = window.localStorage.getItem(key);
  const [enabledFeatures, setEnabledFeatures] = useState<EnabledFeatures | {}>(
    item ? JSON.parse(item) : {}
  );

  const updateEnabledFeatures = (enabledFeatures: EnabledFeatures) => {
    setEnabledFeatures(enabledFeatures);
    window.localStorage.setItem(key, JSON.stringify(enabledFeatures));
  };

  return [enabledFeatures, updateEnabledFeatures];
}
