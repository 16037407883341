import { useEffect } from "react";

import { createGlobalStyle } from "styled-components";

import { fontSmoothing } from "../../mixins";

const MOUSE_MODE_CLASS = "is-mouse-mode";
const TAB_KEY = 9;
const noFocusEvents = ["mouseup", "mousedown", "mousemove"];

const onMouseEvent = function () {
  document.body.classList.add(MOUSE_MODE_CLASS);
};

const addFocusControl = () => {
  document.body.classList.add(MOUSE_MODE_CLASS);
  document.addEventListener(
    "keydown",
    (e) => {
      if (e.keyCode === TAB_KEY) {
        document.body.classList.remove(MOUSE_MODE_CLASS);
      }
    },
    false
  );

  [noFocusEvents].forEach(function (e: any) {
    document.addEventListener(e, onMouseEvent, false);
  });
};

const Styles = createGlobalStyle`
  body {
    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }

    &.is-mouse-mode * {
        outline: none;
    }

    ${fontSmoothing};
  }
`;

const GlobalStyle = () => {
  useEffect(() => {
    addFocusControl();
  });

  return <Styles />;
};

export default GlobalStyle;
