import styled from "styled-components";

import {
  baseUnit,
  Button,
  Heading,
  Paragraph,
  white,
} from "~components/copied_from_shared/pui";

const Wrapper = styled.div`
  padding: ${baseUnit * 3}px;
  background: ${white};
`;

const StyledHeading = styled(Heading).attrs({ size: "small" })`
  margin-bottom: ${baseUnit}px;
  display: flex;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
    margin-right: ${baseUnit}px;
  }
`;

type Props = {
  children: any;
  Icon: any;
};
export const Title = ({ children, Icon }: Props) => (
  <StyledHeading>
    <Icon /> {children}
  </StyledHeading>
);

export const Message = styled(Paragraph)`
  margin-bottom: ${baseUnit * 2}px;
`;

export const Actions = styled.div`
  display: flex;

  > *:not(:last-child) {
    margin-right: ${baseUnit * 2}px;
  }
`;

export const Action = Button;

export default Wrapper;
