import { ComponentProps } from "react";

import styled from "styled-components";

import { MenuItem } from "~components/copied_from_shared/pui";

import InitialIcon from "./InitialsIcon";
import { Company as CompanyType } from "./types";

// Note, seems that styled components doesn't pass props through properly :/
const Wrapper = styled(MenuItem)<ComponentProps<typeof MenuItem>>`
  display: flex;
  align-items: center;
`;

const Name = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

import { Link } from "~components/connected";

type Props = {
  onChangeCompany: (id: string) => void;
  company: CompanyType;
  isActive: boolean;
};
const Company = ({ company, isActive, onChangeCompany }: Props) => {
  const { name, id } = company;

  return (
    <Link
      to={{
        route: `/`,
        query: { viewAs: id },
      }}
    >
      <Wrapper onClick={() => onChangeCompany(id)} isActive={isActive}>
        <InitialIcon name={name} />
        <Name>{name}</Name>
      </Wrapper>
    </Link>
  );
};

export default Company;
