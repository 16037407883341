import clsx from "clsx";

export type Theme = "light" | "dark";

type Props = {
  theme?: Theme;
  className?: string;
};
const Skeleton = ({ theme = "light", className }: Props) => (
  <span
    className={clsx(
      "relative inline-flex overflow-hidden rounded-md text-transparent opacity-75 after:absolute after:inset-0 after:translate-x-[-100%] after:animate-shimmer after:content-['']",
      theme === "light" &&
        "bg-pampas-100 after:bg-gradient-to-r after:from-pampas-100 after:via-white after:to-pampas-100",
      theme === "dark" &&
        "bg-pampas-200 after:bg-gradient-to-r after:from-pampas-200 after:via-pampas-100 after:to-pampas-200",
      className
    )}
  >
    |
  </span>
);

export default Skeleton;
