import { useEffect, useState } from "react";

import { useFuzzySearch } from "~hooks";

import AutoCompleteDownshift from "./AutoCompleteDownshift";

type Props = {
  onChange: (item: any) => void;
  viewAsName?: string;
  Input: any;
  data: {
    value?: string;
    id: string;
  }[];
};

function AutoComplete({ data = [], onChange, viewAsName, Input }: Props) {
  const { items, search } = useFuzzySearch(data, ["value", "id"]);
  const [inputValue, setInputValue] = useState<string>(viewAsName || "");

  useEffect(() => {
    setInputValue(viewAsName || "");
  }, [viewAsName]);

  const _onStateChange = (changes: any) => {
    const hasInputValue = Object.prototype.hasOwnProperty.call(
      changes,
      "inputValue"
    );
    if (hasInputValue) {
      setInputValue(changes.inputValue);
      search(changes.inputValue);
    }
  };

  return (
    <AutoCompleteDownshift
      inputValue={inputValue}
      Input={Input}
      onChange={onChange}
      items={items.slice(0, 6)}
      onStateChange={_onStateChange}
    />
  );
}

export default AutoComplete;
