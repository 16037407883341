import * as React from "react";

import styled from "styled-components";

import { fontSmoothing } from "../../mixins";
import { fontFamilySans, fontWeightBold, textColor } from "../../tokens";
import { variants } from "../../utils";

import { Status } from "./types";
import useNotification from "./useNotification";

const color = variants("status", {
  default: { default: textColor },
  info: { default: "white" },
  error: { default: "white" },
  success: { default: "white" },
});

type WrapperType = {
  status: Status;
};

const Wrapper = styled.p<WrapperType>`
  ${fontSmoothing};
  color: ${color};
  font-size: 15px;
  font-family: ${fontFamilySans};
  font-weight: ${fontWeightBold};
  line-height: 1.5;
  margin: 0 auto 0 0;
`;

type Props = {
  children: React.ReactNode;
};

const NotificationMessage = ({ children, ...rest }: Props) => {
  const { status } = useNotification();
  return (
    <Wrapper status={status} {...rest}>
      {children}
    </Wrapper>
  );
};

export default NotificationMessage;
