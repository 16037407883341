import { useGet2, useResource, useRouter, useUser } from "~hooks";

import Clippy from "./Clippy";
import { FeatureProps } from "./types";

type Query = {
  leaseId?: string;
};

type Props = {
  query: Query;
};

const ClippyContainer = ({ query }: Props) => {
  const { push } = useRouter();

  const fetchClippy = useGet2<FeatureProps>({
    route: "/api/support/clippy",
    query,
  });

  const onGoToCompany = (companyId: string) => {
    push({
      route: "/support/companies/[companyId]/settings",
      params: { companyId },
    });
  };

  return (
    <Clippy
      isLoading={fetchClippy.isLoading}
      error={fetchClippy.error}
      data={fetchClippy.data ?? null}
      onGoToCompany={onGoToCompany}
    />
  );
};

const ClippyContainerWrapper = (props: Props) => {
  const { isSupport } = useUser();

  if (isSupport) {
    return <ClippyContainer {...props} />;
  }

  return null;
};

export default ClippyContainerWrapper;
