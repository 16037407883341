import { useEffect, useRef, useState } from "react";

import { useRouter as useNextRouter } from "next/router";

import { Provider } from "~contexts/auth";
import { UnauthorizedError } from "~errors";
import { useGet2, useRouter } from "~hooks";
import useLocalStorage from "~hooks/useLocalStorage";

import AppLoader from "../AppLoader/AppLoader";

type Auth = {
  sub: string;
};

const options = {
  headers: { pragma: "no-cache", "cache-control": "no-cache" },
};

const headers = new Headers({
  pragma: "no-cache",
  "cache-control": "no-cache",
});

const Authenticated = ({ children }: any) => {
  const router = useRouter();
  const nextRouter = useNextRouter();
  const [redirectUrl, setRedirectUrl] = useLocalStorage<string | null>(
    "proper:self-service:redirect-url",
    null
  );

  const shouldRedirect = useRef(true);

  useEffect(() => {
    if (redirectUrl !== null && shouldRedirect.current) {
      setRedirectUrl(null);
      nextRouter.push(redirectUrl);
    }
  }, [redirectUrl]);

  const [userData, setUserData] = useState<Auth | null>(null);
  const fetchUser = useGet2<Auth>(
    { route: "/api/auth/user" },
    options,
    headers
  );

  const authenticatedUserData = fetchUser.data;

  useEffect(() => {
    if (authenticatedUserData) {
      setUserData(authenticatedUserData);
    }
  }, [authenticatedUserData]);

  useEffect(() => {
    if (fetchUser.error && fetchUser.error instanceof UnauthorizedError) {
      shouldRedirect.current = false;
      setRedirectUrl(router.asPath);
      router.push({
        route: "/api/auth/login",
        query: { redirectTo: router.asPath },
      });
    }
  }, [fetchUser.error]);

  if (!userData) {
    return <AppLoader />;
  }

  return (
    <Provider
      value={{
        sub: userData.sub,
      }}
    >
      {children}
    </Provider>
  );
};

export default Authenticated;
