// Note, this is an experimental hook to warn users when leaving a
// page that has unsaved changes. The solution is adopted from:
// https://github.com/vercel/next.js/issues/2476#issuecomment-563190607
import { t } from "@lingui/macro";

import { useEffect } from "react";

import Router from "next/router";

import useFeatureToggler from "~components/copied_from_shared/feature-toggler/useFeatureToggles";

const useExperimentalWarnIfUnsavedChanges = (hasUnsavedChanges: boolean) => {
  const { enabledFeatures } = useFeatureToggler();
  const { preventRouteChangeWhenHavingUnsavedChanges } = enabledFeatures;

  const message = t`You have unsaved changes. Do you want to leave?`;

  useEffect(() => {
    if (!preventRouteChangeWhenHavingUnsavedChanges) {
      return;
    }
    const routeChangeStart = (url: string) => {
      if (Router.asPath !== url && hasUnsavedChanges && !confirm(message)) {
        Router.events.emit("routeChangeError");
        Router.replace(Router, Router.asPath);
        throw "The user chose to abort the route change. This error can safely be ignored.";
      }
    };

    const beforeUnload = (e: any) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", beforeUnload);
    Router.events.on("routeChangeStart", routeChangeStart);

    return () => {
      window.removeEventListener("beforeunload", beforeUnload);
      Router.events.off("routeChangeStart", routeChangeStart);
    };
  }, [preventRouteChangeWhenHavingUnsavedChanges, hasUnsavedChanges]);
};

export default useExperimentalWarnIfUnsavedChanges;
