import { getRoute, TypeSafeApiRoute } from "next-type-safe-routes";

type GetPathArgs = {
  route: TypeSafeApiRoute;
  authorizedUserId: string;
  requestUserId?: string;
};

const getRequestUrl = ({
  route,
  requestUserId,
  authorizedUserId,
}: GetPathArgs) => {
  const url = getRoute(route);
  const query = new URLSearchParams();
  query.set("authorizedUserId", authorizedUserId);
  if (requestUserId) {
    query.set("requestUserId", requestUserId);
  }
  const queryString = query.toString();
  return url.match(/\?/) ? `${url}&${queryString}` : `${url}?${queryString}`;
};

export default getRequestUrl;
