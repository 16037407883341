import { forwardRef } from "react";

import styled from "styled-components";

import {
  baseUnit,
  fontStack,
  support700,
} from "~components/copied_from_shared/pui";

const Wrapper = styled.button`
  position: relative;
  cursor: pointer;
  margin: 0;
  color: white;
  padding: 0;
  border: none;
  background: none;
  display: flex;
  align-items: center;

  span {
    position: absolute;
    top: -9px;
    left: 8px;
    background-color: ${support700};
    ${fontStack};
    border-radius: 10px;
    font-weight: bold;
    font-size: 12px;
    padding: 3px;
    min-width: 20px;
    height: 20px;
    margin-left: ${baseUnit / 2}px;
    line-height: 1;
  }

  &:hover {
    opacity: 0.5;
  }
`;

type Props = {
  Icon: any;
  children?: any;
};
const Action = ({ Icon, children, ...rest }: Props, ref: any) => (
  <Wrapper {...rest} ref={ref}>
    <Icon />
    {children}
  </Wrapper>
);

export default forwardRef(Action);
