import styled from "styled-components";

import {
  baseUnit,
  black,
  fontFamilySans,
  fontFamilySerif,
  fontStack,
  info500,
  Tooltip,
  white,
} from "~components/copied_from_shared/pui";
import {
  borderRadius,
  boxShadow,
} from "~components/copied_from_shared/pui/components/Card/Card";
import { Info } from "~components/copied_from_shared/pui/icons";

export type Size = "normal" | "small";

const iconSizeSmall = "20px";
const iconSizeLarge = "24px";

export const Wrapper = styled.div<{ size: Size }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ size }) =>
    size === "small" ? `${baseUnit * 2}px` : `${baseUnit * 2.5}px`};
  border-radius: ${borderRadius};
  background-color: ${white};
  box-shadow: ${boxShadow};
`;

export const Value = styled.div<{ size: Size }>`
  ${fontStack};
  color: ${black};
  font-family: ${fontFamilySerif};
  /* line-height: 1; */
  font-size: ${({ size }) => (size === "small" ? "21px" : "24px")};
  margin-top: ${({ size }) =>
    size === "small" ? `${baseUnit / 1.5}px` : `${baseUnit}px`};
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: ${baseUnit / 4}px;
    flex: 0 0 18px;
  }
`;

export const Label = styled.div<{ size: Size }>`
  ${fontStack};
  color: ${black};
  font-family: ${fontFamilySans};
  width: 100%;
  font-size: 17px;
  line-height: ${({ size }) =>
    size === "small" ? iconSizeSmall : iconSizeLarge};
`;

export const IconWrapper = styled.div<{ size: Size }>`
  margin-right: ${({ size }) =>
    size === "small" ? `${baseUnit / 2}px` : `${baseUnit}px`};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: ${({ size }) => (size === "small" ? iconSizeSmall : iconSizeLarge)};
    width: ${({ size }) => (size === "small" ? iconSizeSmall : iconSizeLarge)};
  }
`;

export const LabelAndIcon = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
`;

export type Props = {
  label: string;
  value: string | number;
  icon?: any;
  size?: Size;
  help?: string;
};
const StatsBox = ({ label, value, icon, size = "normal", help }: Props) => (
  <Wrapper size={size}>
    <LabelAndIcon>
      {icon && <IconWrapper size={size}>{icon}</IconWrapper>}
      <LabelWrapper>
        <Label size={size}>{label}</Label>
        {help && (
          <Tooltip title={help}>
            <Info size={20} color={info500} />
          </Tooltip>
        )}
      </LabelWrapper>
    </LabelAndIcon>
    <Value size={size}>{value}</Value>
  </Wrapper>
);

export default StatsBox;
