import styled, { css } from "styled-components";

import { fontStack } from "../../../mixins";
import {
  baseUnit,
  borderColor,
  fontWeightBold,
  textColorDiscreet,
  textColorSupport,
} from "../../../tokens";
import { variant } from "../../../utils";
import Paragraph from "../../Paragraph";

import FormElementHelp from "./FormElementHelp";

const color = variant(textColorDiscreet, { support: textColorSupport });

const Wrapper = styled.fieldset<{ spaced?: boolean }>`
  border: 1px solid ${borderColor};
  margin: 0;
  padding: ${baseUnit * 1.5}px;

  ${({ spaced }) =>
    spaced &&
    css`
      > *:not(:last-child) {
        margin-bottom: ${baseUnit * 2}px;
      }
    `}
`;

const Legend = styled.legend`
  margin: 0 0 0 -${baseUnit / 3}px !important;
  padding: 0 ${baseUnit / 3}px;
`;

// flexbox align-items: center doesn't seem to work for legend in firefox
const LegendInner = styled.div`
  ${fontStack};
  display: flex;
  align-items: center;
  color: ${color};
  font-size: 15px;
  font-weight: ${fontWeightBold};
  line-height: 1.5;
`;

const ErrorMessage = styled(Paragraph).attrs({
  look: "negative",
  size: "small",
})`
  margin-bottom: ${baseUnit}px;
`;

type FieldSetProps = {
  name: string;
  legend: any;
  children: any;
  errorMessage?: string | null;
  spaced?: boolean;
  help?: string;
};
export const FieldSet = ({
  legend,
  children,
  errorMessage,
  spaced,
  help,
  ...rest
}: FieldSetProps) => (
  <Wrapper {...rest} spaced={spaced}>
    <Legend>
      <LegendInner>
        {legend}
        {help && <FormElementHelp text={help} />}
      </LegendInner>
    </Legend>
    {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    {children}
  </Wrapper>
);

export default FieldSet;
