import styled from "styled-components";

import {
  baseUnit,
  borderColor,
  Heading,
  Paragraph,
  white,
} from "~components/copied_from_shared/pui";

export const Wrapper = styled.div`
  background: ${white};
  width: 100%;
  padding: 0 ${baseUnit * 1.5}px;
  border-radius: 6px;
  border: 1px solid ${borderColor};
  height: 76px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 0 ${baseUnit / 2}px 0;
  text-align: left;
`;

const WrapperButton = styled(Wrapper).attrs({ as: "button" })`
  cursor: pointer;
`;

type Props = {
  onClick?: () => void;
  title: string;
  subtitle: string;
};
const NavigationHeader = ({ onClick, title, subtitle }: Props) => {
  const Tag = onClick ? WrapperButton : Wrapper;
  return (
    <Tag onClick={onClick}>
      <Heading size="tiny">{title}</Heading>
      <Paragraph size="small">{subtitle}</Paragraph>
    </Tag>
  );
};

export default NavigationHeader;
