import * as React from "react";

import styled, { css } from "styled-components";

import { fontFamilySans, textColorLink } from "../../tokens";

export const linkCss = css`
  color: ${textColorLink};
  font-family: ${fontFamilySans};
`;

const Wrapper = styled.a`
  ${linkCss};
`;

type Props = {
  children: React.ReactNode;
  href: string;
  target?: string;
  download?: boolean;
  onClick?: (e: any) => void;
  className?: string;
};

/**
 * Links are used to embed actions or pathways to more
 * information in a sentence.
 */
const Link = ({
  children,
  href,
  download,
  onClick,
  className,
  ...rest
}: Props) => (
  <Wrapper
    {...rest}
    href={href}
    download={download}
    onClick={onClick}
    className={className}
  >
    {children}
  </Wrapper>
);

export default Link;
