import { t } from "@lingui/macro";

import { useState } from "react";

import copyToClipboard from "clipboard-copy";

import useNotifications from "~hooks/useNotifications";
import { reportError } from "~utils";

const useCopyToClipboard = () => {
  const [showIdCopied, setShowIdCopied] = useState(false);
  const notifications = useNotifications();

  const onCopyId = async (id: string) => {
    try {
      await copyToClipboard(id);
      setShowIdCopied(true);
      setTimeout(() => setShowIdCopied(false), 1000);
    } catch (err: any) {
      reportError(err);
      notifications.push({
        text: t`Failed to copy to clipboard`,
        type: "error",
      });
    }
  };

  return [showIdCopied, onCopyId] as const;
};

export default useCopyToClipboard;
