import { t } from "@lingui/macro";

import { EntitiesService } from "~services/entities-service";
import { PropertiesService } from "~services/properties-service";
import {
  EconomicVatZoneKey,
  LeasePaymentStatus,
  Month,
  PaymentInterval,
  PaymentMethod,
} from "~types";
import { BillDocumentType } from "~types/billing";
import { DocumentType } from "~types/lease/document";

import reportError from "./reportError";

export const getPeriodCount = (
  period?: PropertiesService.PeriodRule$PeriodLength,
  count?: number
) => {
  if (count === 1) {
    if (period === "Week") {
      return t`week`;
    } else if (period === "Month") {
      return t`month`;
    } else if (period === "Quarter") {
      return t`quarter`;
    } else if (period === "Year") {
      return t`year`;
    }
  } else {
    if (period === "Week") {
      return t`weeks`;
    } else if (period === "Month") {
      return t`months`;
    } else if (period === "Quarter") {
      return t`quarters`;
    } else if (period === "Year") {
      return t`years`;
    }
  }
};

export const getPaymentDescription = (
  paymentMethod: PaymentMethod,
  customerType: EntitiesService.CustomerType
) => {
  switch (paymentMethod) {
    case "key:bank_transfer":
      return t`Bank transfer`;
    case "key:prepaid":
      return t`Prepaid rent`;
    case "key:deposit":
      return t`Deposit`;
    case "key:received_by_landlord":
      return customerType === "PaymentsCustomer"
        ? t`Payment received`
        : t`Received by landlord`;
    case "key:paid_out_by_landlord":
      return customerType === "PaymentsCustomer"
        ? t`Money to customer`
        : t`Paid out by landlord`;
    case "key:direct_debit:betalingsservice":
      return t`Betalingsservice`;
    case "key:other":
      return t`Other`;
    default: {
      reportError(new Error(`Unknown payment method ${paymentMethod}`));
      return paymentMethod;
    }
  }
};

export const getTransactionType = (
  type: PropertiesService.Transaction$TransactionType
) => {
  switch (type) {
    case "Charge":
      return t`Charge`;
    case "Payment":
      return t`Payment`;
    default: {
      reportError(new Error(`Unknown transaction type ${type}`));
      return type;
    }
  }
};

export const getLeasePaymentStatus = (paymentStatus: LeasePaymentStatus) => {
  if (paymentStatus === "neutral") {
    return t`neutral`;
  } else if (paymentStatus === "late") {
    return t`late`;
  } else if (paymentStatus === "delinquent") {
    return t`delinquent`;
  }

  return t`Unknown`;
};

export const getLeasePurpose = (purpose: string) => {
  if (purpose === "key:commercial") {
    return t`Commercial`;
  } else if (purpose === "key:residential") {
    return t`Residential`;
  }

  return t`No purpose specified`;
};

export const getPaymentInterval = (
  paymentInterval: PaymentInterval | undefined
) => {
  if (paymentInterval === "monthly") {
    return t`Monthly`;
  } else if (paymentInterval === "quarterly") {
    return t`Quarterly`;
  } else if (paymentInterval === "yearly") {
    return t`Yearly`;
  }

  return t`Not defined`;
};

export const getMonth = (monthNumber: Month) => {
  if (monthNumber === 1) {
    return t`January`;
  } else if (monthNumber === 2) {
    return t`February`;
  } else if (monthNumber === 3) {
    return t`March`;
  } else if (monthNumber === 4) {
    return t`April`;
  } else if (monthNumber === 5) {
    return t`May`;
  } else if (monthNumber === 6) {
    return t`June`;
  } else if (monthNumber === 7) {
    return t`July`;
  } else if (monthNumber === 8) {
    return t`August`;
  } else if (monthNumber === 9) {
    return t`September`;
  } else if (monthNumber === 10) {
    return t`October`;
  } else if (monthNumber === 11) {
    return t`November`;
  } else if (monthNumber === 12) {
    return t`December`;
  }
};

export const getMonthsWithIndex = () =>
  Array.from({ length: 12 }, (_, i) => i + 1).map((index) => ({
    index: index as Month,
    name: getMonth(index as Month),
  }));

export const getChargeDay = (
  chargeDateOffset?: number,
  paymentInterval?: PaymentInterval
) => {
  let period;
  if (paymentInterval === "monthly") {
    if (chargeDateOffset && chargeDateOffset > 0) {
      period = t`the month`;
    } else {
      period = t`month`;
    }
  } else if (paymentInterval === "quarterly") {
    if (chargeDateOffset && chargeDateOffset > 0) {
      period = t`the quarter`;
    } else {
      period = t`quarter`;
    }
  } else {
    if (chargeDateOffset && chargeDateOffset > 0) {
      period = t`the year`;
    } else {
      period = t`year`;
    }
  }
  if (chargeDateOffset === 1) {
    return t`1st day of ${period}`;
  } else if (chargeDateOffset === 2) {
    return t`2nd day of ${period}`;
  } else if (chargeDateOffset === 3) {
    return t`3rd day of ${period}`;
  } else if (chargeDateOffset === 4) {
    return t`4th day of ${period}`;
  } else if (chargeDateOffset === 5) {
    return t`5th day of ${period}`;
  } else if (chargeDateOffset === 6) {
    return t`6th day of ${period}`;
  } else if (chargeDateOffset === 7) {
    return t`7th day of ${period}`;
  } else if (chargeDateOffset === 8) {
    return t`8th day of ${period}`;
  } else if (chargeDateOffset === 9) {
    return t`9th day of ${period}`;
  } else if (chargeDateOffset === 10) {
    return t`10th day of ${period}`;
  } else if (chargeDateOffset === 11) {
    return t`11th day of ${period}`;
  } else if (chargeDateOffset === 12) {
    return t`12th day of ${period}`;
  } else if (chargeDateOffset === 13) {
    return t`13th day of ${period}`;
  } else if (chargeDateOffset === 14) {
    return t`14th day of ${period}`;
  } else if (chargeDateOffset === 15) {
    return t`15th day of ${period}`;
  } else if (chargeDateOffset === 16) {
    return t`16th day of ${period}`;
  } else if (chargeDateOffset === 17) {
    return t`17th day of ${period}`;
  } else if (chargeDateOffset === 18) {
    return t`18th day of ${period}`;
  } else if (chargeDateOffset === 19) {
    return t`19th day of ${period}`;
  } else if (chargeDateOffset === 20) {
    return t`20th day of ${period}`;
  } else if (chargeDateOffset === -1) {
    return t`1 day before new ${period}`;
  } else if (chargeDateOffset === -2) {
    return t`2 days before new ${period}`;
  } else if (chargeDateOffset === -3) {
    return t`3 days before new ${period}`;
  } else if (chargeDateOffset === -4) {
    return t`4 days before new ${period}`;
  } else if (chargeDateOffset === -5) {
    return t`5 days before new ${period}`;
  } else if (chargeDateOffset === -6) {
    return t`6 days before new ${period}`;
  } else if (chargeDateOffset === -7) {
    return t`7 days before new ${period}`;
  } else if (chargeDateOffset === -8) {
    return t`8 days before new ${period}`;
  } else if (chargeDateOffset === -9) {
    return t`9 days before new ${period}`;
  } else if (chargeDateOffset === -10) {
    return t`10 days before new ${period}`;
  } else if (chargeDateOffset === -11) {
    return t`11 days before new ${period}`;
  } else if (chargeDateOffset === -12) {
    return t`12 days before new ${period}`;
  } else if (chargeDateOffset === -13) {
    return t`13 days before new ${period}`;
  } else if (chargeDateOffset === -14) {
    return t`14 days before new ${period}`;
  } else if (chargeDateOffset === -15) {
    return t`15 days before new ${period}`;
  } else if (chargeDateOffset === -16) {
    return t`16 days before new ${period}`;
  } else if (chargeDateOffset === -17) {
    return t`17 days before new ${period}`;
  } else if (chargeDateOffset === -18) {
    return t`18 days before new ${period}`;
  } else if (chargeDateOffset === -19) {
    return t`19 days before new ${period}`;
  } else if (chargeDateOffset === -20) {
    return t`20 days before new ${period}`;
  }
};

export const getDocumentType = (type: DocumentType) => {
  if (type === "allonge") {
    return t`Allonge`;
  } else if (type === "documentation") {
    return t`Documentation`;
  } else if (type === "move_out_statement") {
    return t`Move-out statement`;
  } else if (type === "move_out_report") {
    return t`Move-out report`;
  } else if (type === "move_in_report") {
    return t`Move-in report`;
  } else if (type === "default_notice") {
    return t`Default notice letter`;
  } else if (type === "default_notice_commercial") {
    return t`Default notice letter`;
  } else if (type === "house_rules") {
    return t`House rules`;
  } else if (type === "lease_contract") {
    return t`Lease contract`;
  } else if (type === "privacy_policy") {
    return t`Privacy policy`;
  } else if (type === "utility_accounts") {
    return t`Utility accounts`;
  } else if (type === "invoice") {
    return t`Invoice`;
  } else if (type === "report") {
    return t`Report`;
  } else if (type === "utility_statement") {
    return t`Utility statement`;
  }

  reportError(new Error(`Unknown document type ${type}`));
  return type;
};

export const getEconomicVatZone = (zone: EconomicVatZoneKey) => {
  if (zone === "Domestic") {
    return t`Domestic`;
  } else if (zone === "EU") {
    return t`EU (rubric B)`;
  } else if (zone === "Abroad") {
    return t`Abroad (rubric C)`;
  } else if (zone === "Domestic exempt VAT") {
    return t`Domestic without VAT`;
  }

  return zone;
};

export const getBillDocumentType = (type: BillDocumentType) => {
  if (type === "invoice") {
    return t`Invoice`;
  } else if (type === "credit-note") {
    return t`Credit note`;
  }

  reportError(new Error(`Unknown bill document type: ${type}`));
  return type;
};
