import { t } from "@lingui/macro";
import { Trans } from "@lingui/macro";

import styled from "styled-components";

import {
  Menu as PUIMenu,
  MenuItem,
  menuItemPaddingHorizontal,
  menuItemPaddingVertical,
  MenuSection,
} from "~components/copied_from_shared/pui";
import Checkbox from "~components/copied_from_shared/pui/components/form/Checkbox";
import FormElement from "~components/copied_from_shared/pui/components/form/Form/FormElement";
import { MoreHorizontal } from "~components/copied_from_shared/pui/icons";

import Action from "./Action";

const Settings = styled.div`
  padding: ${menuItemPaddingVertical}px ${menuItemPaddingHorizontal}px;
`;

export type Props = {
  onToggleViewAsLandlord: () => void;
  seeWhatLandlordSees: boolean;
  onGoToOverview: () => void;
  onGoToSearch: () => void;
  onGoToWallE: () => void;
  onGoToReports: () => void;
  onGoToUsers: () => void;
  onGoToCompanies: () => void;
};
const Menu = ({
  seeWhatLandlordSees,
  onToggleViewAsLandlord,
  onGoToOverview,
  onGoToSearch,
  onGoToWallE,
  onGoToReports,
  onGoToUsers,
  onGoToCompanies,
}: Props) => (
  <PUIMenu
    ariaLabel={t`Support actions`}
    trigger={<Action Icon={MoreHorizontal} />}
  >
    <MenuSection>
      <MenuItem onClick={onGoToOverview}>
        <Trans>Overview</Trans>
      </MenuItem>
      <MenuItem onClick={onGoToSearch}>
        <Trans>Search leases</Trans>
      </MenuItem>
      <MenuItem onClick={onGoToWallE}>
        <Trans>Wall·E</Trans>
      </MenuItem>
      <MenuItem onClick={onGoToReports}>
        <Trans>Reports</Trans>
      </MenuItem>
    </MenuSection>
    <MenuSection>
      <MenuItem onClick={onGoToUsers}>
        <Trans>Users</Trans>
      </MenuItem>
      <MenuItem onClick={onGoToCompanies}>
        <Trans>Companies</Trans>
      </MenuItem>
    </MenuSection>
    <MenuSection label={t`Settings`}>
      <Settings>
        <FormElement
          id="test"
          label="See what landlords see"
          layout="horizontal-inverse"
        >
          <Checkbox
            checked={seeWhatLandlordSees}
            onChange={onToggleViewAsLandlord}
          />
        </FormElement>
      </Settings>
    </MenuSection>
  </PUIMenu>
);

export default Menu;
