import { t } from "@lingui/macro";
import { Trans } from "@lingui/macro";

import styled from "styled-components";

import {
  DialogBody,
  DialogDeprecated,
  DialogHeader,
  DialogHeaderTitle,
} from "~components/copied_from_shared/pui";
import {
  baseUnit,
  borderColorDiscreet,
  fontFamilySans,
  fontSmoothing,
  fontWeightBold,
  Heading,
  pampas100,
  Paragraph,
} from "~components/copied_from_shared/pui";

import {
  ErrorCode,
  ErrorDetails as ErrorDetailsType,
} from "../Providers/Notifications";

const getErrorMessage = (code: ErrorCode, reason?: string) => {
  if (code === "email.in-use") {
    return t`The email provided is already in use`;
  } else if (code === "properties.lease.one-primary-tenant") {
    return t`A lease must have exactly one primary tenant`;
  } else if (code === "entities.person.has-credentials") {
    return t`It is not current possible to change emails for users that can login on the platform`;
  } else if (code === "payments.authentication.password-too-weak") {
    return t`The password is too weak`;
    // FIXME: The backend sometimes return a reason (in english) instead of
    // an error code. In this case, we cannot translate it, so we just
    // display whatever is returned. We should update the backend to always
    // return an error code I would say, so that the frontend can determine
    // the display text instead.
  } else if (reason) {
    return reason;
  } else if (code) {
    return code;
  }

  return t`An unrecognized error occured`;
};

const Details = styled.details`
  background-color: ${pampas100};
  padding: ${baseUnit}px;
  margin-top: ${baseUnit}px;
`;

const Summary = styled.summary`
  ${fontSmoothing};
  cursor: pointer;
  font-family: ${fontFamilySans};
  font-size: 15px;
  font-weight: ${fontWeightBold};
`;

const DetailsContent = styled.div`
  border-top: 1px solid ${borderColorDiscreet};
  margin-top: 12px;
  padding-top: 12px;

  > *:not(:last-child) {
    margin-bottom: ${baseUnit / 2}px;
  }
`;

type Props = ErrorDetailsType & {
  trigger: any;
};
const ErrorDetails = ({
  code,
  requestId,
  endpoint,
  trigger,
  reason,
  payload,
}: Props) => {
  const errorMessage = getErrorMessage(code, reason);
  return (
    <DialogDeprecated maxWidth={600} trigger={trigger}>
      <DialogHeader>
        <DialogHeaderTitle>{errorMessage}</DialogHeaderTitle>
      </DialogHeader>
      <DialogBody>
        <Paragraph>
          <Trans>
            If you would like to report this problem, please get in touch and
            provide the error details below.
          </Trans>
        </Paragraph>
        <Details>
          <Summary>
            <Trans>View error details</Trans>
          </Summary>
          <DetailsContent>
            <Heading size="tiny">
              <Trans>Error code</Trans>
            </Heading>
            <Paragraph>{code}</Paragraph>
            <Heading size="tiny">
              <Trans>Endpoint</Trans>
            </Heading>
            <Paragraph>{endpoint}</Paragraph>
            <Heading size="tiny">
              <Trans>Payload</Trans>
            </Heading>
            <Paragraph>{JSON.stringify(payload, null, 2)}</Paragraph>
            <Heading size="tiny">
              <Trans>Request ID</Trans>
            </Heading>
            <Paragraph>
              <Trans>Request id: {requestId}</Trans>
            </Paragraph>
          </DetailsContent>
        </Details>
      </DialogBody>
    </DialogDeprecated>
  );
};

export default ErrorDetails;
