import styled from "styled-components";

import { X } from "../../icons";

import { height, NotificationAction } from "./NotificationActions";

const StyledNotificationAction = styled(NotificationAction)`
  width: ${height};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0;
`;

type Props = {
  onClick: () => void;
};
const NotificationDismissButton = ({ onClick }: Props) => (
  <StyledNotificationAction onClick={onClick}>
    <X size={18} />
  </StyledNotificationAction>
);

export default NotificationDismissButton;
