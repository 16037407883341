import React from "react";

import useFeatureToggles from "../useFeatureToggles";

import FeatureToggles from "./FeatureToggles";

const FeatureTogglesContainer = () => {
  const {
    featuresAvailableForUser,
    enabledFeatures,
    setEnabledFeatures,
  } = useFeatureToggles();

  return (
    <FeatureToggles
      onApplyChanges={setEnabledFeatures}
      enabledFeatures={enabledFeatures}
      featuresAvailableForUser={featuresAvailableForUser}
    />
  );
};

export default FeatureTogglesContainer;
