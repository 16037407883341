import styled from "styled-components";

import { baseUnit, Paragraph } from "~components/copied_from_shared/pui";

const Items = styled.div``;

const Label = styled.div`
  width: 250px;
  font-weight: bold;
  padding-right: ${baseUnit}px;
`;

export const ItemWrapper = styled(Paragraph)`
  display: flex;

  &:not(:last-child) {
    margin-bottom: ${baseUnit}px;
  }
`;

type ItemProps = {
  label: any;
  children: any;
};
export const Item = ({ label, children }: ItemProps) => (
  <ItemWrapper>
    <Label>{label}</Label>
    {children}
  </ItemWrapper>
);

export default Items;
