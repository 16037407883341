import React from "react";

import {
  Form,
  FormActions,
  FormField,
  FormResetButton,
  FormSubmitButton,
  useForm,
} from "~components/copied_from_shared/pui";

import { EnabledFeatures, Features } from "../types";

export type Props = {
  featuresAvailableForUser: Features;
  enabledFeatures: EnabledFeatures | Record<string, unknown>;
  onApplyChanges: (enabledFeatures: EnabledFeatures) => void;
};
const FeatureToggler = ({
  onApplyChanges,
  featuresAvailableForUser,
  enabledFeatures,
}: Props) => {
  const methods = useForm({ defaultValues: enabledFeatures });
  const onSubmit = (enabledFeatures: EnabledFeatures) => {
    onApplyChanges(enabledFeatures);
  };

  return (
    <Form {...methods} onSubmit={onSubmit} spaced>
      {Object.keys(featuresAvailableForUser).map((feature, index) => (
        <div key={index}>
          <FormField
            label={featuresAvailableForUser[feature].displayName}
            name={feature}
            type="checkbox"
          />
          <details>{featuresAvailableForUser[feature].description}</details>
        </div>
      ))}
      <FormActions>
        <FormSubmitButton>Apply</FormSubmitButton>
        <FormResetButton>Reset</FormResetButton>
      </FormActions>
    </Form>
  );
};

export default FeatureToggler;
