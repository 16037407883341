import styled from "styled-components";

import { ChevronLeft, ChevronRight } from "../../../icons";
import { baseUnit } from "../../../tokens";
import Action from "../../abstract/Action";
import Paragraph from "../../Paragraph";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  > *:not(:last-child) {
    margin-right: ${baseUnit}px;
  }
`;

type NextPageButtonProps = {
  onClick: () => void;
  disabled: boolean;
};
export const NextPageButton = ({ onClick, disabled }: NextPageButtonProps) => (
  <Action Icon={ChevronRight} disabled={disabled} onClick={onClick} />
);

type PreviousPageButtonProps = {
  onClick: () => void;
  disabled: boolean;
};
export const PreviousPageButton = ({
  onClick,
  disabled,
}: PreviousPageButtonProps) => (
  <Action Icon={ChevronLeft} disabled={disabled} onClick={onClick} />
);

export const PageIndicator = styled(Paragraph)`
  user-select: none;
`;

export default Wrapper;
