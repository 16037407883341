import { useAnalytics, useNotifications, useReports, useRouter } from "~hooks";
import useStatefulUpload from "~hooks/useStatefulUpload";

import Notifications from "./Notifications";
import useNoticesNotification from "./useNoticesNotification";

function NotificationsContainer() {
  const { push } = useRouter();
  const { uploads, totalProgress } = useStatefulUpload();
  const [notices, { onDismiss, wasDismissed }] = useNoticesNotification();
  const { reports, onReportDownloaded } = useReports();
  const incompleteUploads = uploads.filter(({ status }) => status !== "done");
  const { notifications, clear } = useNotifications();
  const { track } = useAnalytics();

  const onGoToNotices = () => {
    push("/apps/e-conomic/notices");
    track("NoticesClicked");
  };

  return (
    <Notifications
      onClearNotification={clear}
      notifications={notifications}
      numUploads={incompleteUploads.length}
      uploadProgress={totalProgress === 100 ? 99 : totalProgress}
      onGoToNotices={onGoToNotices}
      numNotices={notices.length}
      wasNoticesNotificationDismissed={wasDismissed}
      onDimissNoticesNotification={onDismiss}
      reports={reports}
      onReportDownloaded={onReportDownloaded}
    />
  );
}

export default NotificationsContainer;
