import addDays from "date-fns/addDays";
import format from "date-fns/format";

const getLocalDateRangeFromClosedPeriod = (
  periodStart: string,
  periodEnd: string
): string => {
  const start = format(new Date(periodStart), "yyyy-MM-dd");
  const end = format(addDays(new Date(periodEnd), 1), "yyyy-MM-dd");
  return `${start}/${end}`;
};

export default getLocalDateRangeFromClosedPeriod;
