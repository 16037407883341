import { Message } from "react-hook-form";
import styled, { css } from "styled-components";

import {
  fontFamilySans,
  red500,
  textColor,
  textColorDiscreet,
  textColorNegative,
  textColorPositive,
  textColorSupport,
  textColorWarning,
} from "../../../tokens";
import { variants } from "../../../utils";
import Tooltip from "../../Tooltip";

import { LayoutType } from "./FormElement.utils";
import FormElementHelp from "./FormElementHelp";
import { Status } from "./types";

const color = variants("status", {
  default: { default: textColor, support: textColorSupport },
  valid: { default: textColorPositive },
  invalid: { default: textColorNegative },
  warning: { default: textColorWarning },
});

type LabelProps = {
  status?: Status;
  readOnly?: boolean;
  layout: LayoutType;
};

export const Wrapper = styled.label<LabelProps>`
  display: flex;
  align-items: center;
  color: ${color};
  font-family: ${fontFamilySans};
  font-weight: normal;
  font-size: 15px;
  padding: ${({ layout }) => {
    const right = layout === "horizontal" ? "7px" : "0";
    const bottom = layout === "vertical" ? "7px" : "0";
    const left = layout === "horizontal-inverse" ? "7px" : "0";
    return `0 ${right} ${bottom} ${left}`;
  }};
  border-bottom: 1px solid transparent;

  ${({ readOnly }) =>
    readOnly &&
    css`
      pointer-events: none;
      color: ${textColorDiscreet};
      border-color: transparent !important;
      border-bottom: 1px solid transparent;
    `}
`;

const Required = styled.span`
  margin-left: 3px;
  color: ${red500};
`;

type Props = {
  id: string;
  children: string;
  isRequired?: boolean;
  status?: Status;
  layout?: LayoutType;
  hint?: Message;
  help?: string;
  readOnly?: boolean;
  requiredText?: string;
};

const FormElementLabel = ({
  id,
  children,
  isRequired,
  requiredText,
  status = "default",
  layout = "vertical",
  help,
  readOnly,
}: Props) => (
  <Wrapper
    status={status}
    htmlFor={readOnly ? undefined : id}
    layout={layout}
    readOnly={readOnly}
  >
    {children}
    {isRequired && !readOnly && (
      <Tooltip title={requiredText}>
        <Required>*</Required>
      </Tooltip>
    )}
    {help && <FormElementHelp text={help} />}
  </Wrapper>
);

export default FormElementLabel;
