import styled from "styled-components";

const Tabs = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  list-style-type: none;
`;

export default Tabs;
