type Props = {
  fill: string;
};
const Logo = ({ fill }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="32px"
    viewBox="0 0 518 116"
    fill="none"
  >
    <path
      d="M8.32738 19.0057L0.238484 13.9L0.242347 9.7032L31.001 1.03234L36.1519 1.03709L35.549 15.5827C39.6719 8.838 47.7677 1.04778 60.2728 1.05929C77.634 1.07527 89.9779 13.3867 89.9553 37.9817C89.927 68.7241 71.5216 84.9086 48.2801 84.8872C42.6895 84.8821 37.9842 83.8286 35.4831 81.7279L35.4636 102.875C35.4587 108.274 38.1063 108.576 46.7837 109.333L46.7783 115.18L0.000414201 115.137L0.00580077 109.285C5.89155 108.842 8.24524 108.243 8.24965 103.446L8.32738 19.0057ZM62.2945 46.3549C62.3127 26.5577 55.4066 17.4039 44.2254 17.3937C41.1379 17.3908 38.1945 18.1368 35.545 19.9374L35.5062 62.0784C35.4972 71.8267 38.1396 77.8289 45.9384 77.8361C56.525 77.8408 62.2782 64.0486 62.2945 46.3549Z"
      fill="#232323"
    />
    <path
      d="M129.951 69.5046C129.945 75.9526 133.327 76.5567 141.714 77.6136L141.709 83.4606L94.6312 83.4173L94.6366 77.5703C100.522 77.1275 102.876 76.5287 102.88 71.7309L102.929 19.0877L94.84 13.982L94.8439 9.78523L125.603 1.11438L130.604 1.11898L129.993 24.216C136.044 6.67047 141.195 1.12873 150.463 1.13726C153.845 1.14037 156.347 2.19187 157.815 3.69571L157.795 24.5421L154.263 24.5389C144.996 24.5303 136.757 24.8233 129.983 29.9153L129.946 69.5046L129.951 69.5046Z"
      fill="#232323"
    />
    <path
      d="M159.956 44.6431C159.979 19.4471 177.063 1.16805 201.188 1.19026C225.464 1.2126 242.216 16.9762 242.194 41.5712C242.171 66.7672 225.087 85.0462 200.961 85.024C176.691 85.0016 159.933 69.2381 159.956 44.6431ZM213.356 48.1454C213.378 23.5504 210.011 7.49866 200.448 7.48985C191.62 7.48173 188.817 19.0254 188.799 38.0739C188.777 62.6689 192.294 78.7208 201.707 78.7295C210.53 78.7325 213.338 67.1888 213.356 48.1454Z"
      fill="#232323"
    />
    <path
      d="M254.129 19.2245L246.04 14.1187L246.044 9.92196L276.798 1.25109L281.949 1.25584L281.346 15.8014C285.474 9.05675 293.57 1.26653 306.075 1.27804C323.436 1.29402 335.78 13.6054 335.757 38.2004C335.729 68.9429 317.324 85.1274 294.082 85.106C288.491 85.1009 283.786 84.0473 281.285 81.9466L281.265 103.094C281.261 108.492 283.908 108.795 292.586 109.552L292.58 115.399L245.797 115.356L245.803 109.504C251.688 109.061 254.042 108.462 254.047 103.664L254.124 19.2245L254.129 19.2245ZM308.091 46.5736C308.11 26.7764 301.203 17.6227 290.022 17.6124C286.935 17.6096 283.991 18.3556 281.342 20.1561L281.303 62.2971C281.294 72.0455 283.936 78.0477 291.735 78.0549C302.327 78.0595 308.075 64.2674 308.091 46.5736Z"
      fill="#232323"
    />
    <path
      d="M416.262 34.2321L416.259 37.9807L369.331 37.9375C369.02 55.7837 378.722 66.8907 392.106 66.903C401.524 66.9117 408.436 63.47 414.034 55.9729L416.825 57.7733C412.838 74.2665 401.502 85.2064 382.962 85.1894C361.189 85.1693 344.726 70.3075 344.749 45.1115C344.783 18.4181 362.89 1.33697 384.073 1.35647C405.406 1.37611 416.28 15.1836 416.262 34.2321ZM369.482 31.0363L393.018 31.058C393.032 16.5119 390.247 7.65734 382.299 7.65002C374.215 7.64767 370.234 17.0919 369.482 31.0363Z"
      fill="#232323"
    />
    <path
      d="M456.103 69.8093C456.098 76.2573 459.479 76.8614 467.867 77.9183L467.861 83.7653L420.784 83.722L420.789 77.875C426.675 77.4322 429.028 76.8334 429.033 72.0356L429.081 19.3924L420.992 14.2867L420.996 10.0899L451.755 1.41906L456.756 1.42367L456.145 24.5207C462.192 6.97515 467.348 1.43342 476.615 1.44195C479.998 1.44506 482.5 2.49656 483.967 4.0004L483.948 24.8468L480.416 24.8436C471.148 24.835 462.909 25.1279 456.135 30.22L456.098 69.8093L456.103 69.8093Z"
      fill="#232323"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M486.971 74.7133L483.944 74.7105L483.935 83.7916L481.917 83.7898L481.925 74.7086L478.893 74.7058L478.895 72.8672L486.973 72.8746L486.971 74.7133Z"
      fill="#232323"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M499.927 83.8093L497.953 83.8075L497.96 76.5548L497.835 76.5547L495.167 82.5571L493.133 82.5553L490.461 76.5479L490.341 76.5478L490.334 83.8005L488.361 83.7987L488.371 72.8789L490.849 72.8812L494.1 80.3712L494.22 80.3713L497.469 72.8873L499.932 72.8895L499.922 83.8093L499.927 83.8093Z"
      fill="#232323"
    />
  </svg>
);

export default Logo;
