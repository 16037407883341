import { useState } from "react";

export type ModalState = {
  open: boolean;
  hide: () => void;
  show: () => void;
  setOpen: (value: boolean) => void;
};
export default function useModalState(initialValue: boolean): ModalState {
  const [open, setOpen] = useState(initialValue);
  const hide = () => setOpen(false);
  const show = () => setOpen(true);
  return { open, setOpen, hide, show };
}
