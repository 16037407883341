import styled from "styled-components";

import { fontStack } from "../../mixins";
import {
  baseUnit,
  heightInput,
  primary500,
  textColor,
  textColorDiscreet,
  transitionDuration,
} from "../../tokens";

const Li = styled.li``;

export const Button = styled.button<{ $isActive: boolean }>`
  ${fontStack};
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  color: ${textColor};
  letter-spacing: 0.025rem;
  cursor: pointer;
  background: none;
  padding: 0;
  border: none;
  border-bottom: ${({ $isActive }) =>
    $isActive ? `2px solid ${primary500}` : `2px solid transparent`};
  transition: color ${transitionDuration};
  margin-right: ${baseUnit * 3}px;
  line-height: ${heightInput}px;
  cursor: ${({ $isActive }) => ($isActive ? "default" : "pointer")};

  &:hover:not(:disabled) {
    border-bottom: 2px solid ${primary500};
  }

  &:disabled {
    cursor: default;
    color: ${textColorDiscreet};
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: ${baseUnit}px;
  }
`;

type Props = {
  children: any;
  disabled?: boolean;
  Icon?: any;
  isActive?: boolean;
  onClick: () => void;
};
export const Tab = ({
  disabled,
  Icon,
  children,
  isActive,
  onClick,
  ...rest
}: Props) => (
  <Li {...(rest as any)}>
    <Button disabled={disabled} $isActive={!!isActive} onClick={onClick}>
      {Icon && <Icon />}
      {children}
    </Button>
  </Li>
);

export default Tab;
