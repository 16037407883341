import { forwardRef } from "react";

import styled from "styled-components";

import { blue500 } from "../../tokens";
import Tooltip from "../Tooltip";

import { heightRow } from "./Table";

const StyledTd = styled.td`
  width: ${heightRow}px;
  opacity: 1 !important;
  text-align: center;
  position: relative;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 11px;
  left: 11px;
  height: 20px;
  display: flex;
`;

type Props = {
  Icon: any;
  tooltip: string;
};
const TdInfo = ({ Icon, tooltip }: Props, ref: any) => (
  <StyledTd ref={ref}>
    <IconWrapper>
      <Tooltip title={tooltip}>
        <Icon color={blue500} size={20} />
      </Tooltip>
    </IconWrapper>
  </StyledTd>
);

export default forwardRef(TdInfo);
