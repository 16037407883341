import { forwardRef } from "react";

import styled from "styled-components";

import {
  backgroundColorInput,
  backgroundColorInputFilled,
  borderColorInput,
  borderColorInputFocus,
  borderColorInputHover,
  primary500,
  sizeCheckboxAndRadio,
  support500,
  textColorPlaceholder,
} from "../../../tokens";
import { variant, variants } from "../../../utils";

const backgroundColor = variants("filled", {
  default: { default: backgroundColorInput },
  filled: { default: backgroundColorInputFilled },
});

const backgroundColorEnabled = variant(primary500, { support: support500 });

const Input = styled.input<{ filled: "filled" | "default" }>`
  cursor: pointer;
  appearance: none;
  margin: 0;
  position: relative;
  box-sizing: border-box;
  color: transparent;
  background-color: ${backgroundColor};
  border: 1px solid ${borderColorInput};
  border-radius: ${sizeCheckboxAndRadio / 2}px;
  height: ${sizeCheckboxAndRadio}px;
  width: ${sizeCheckboxAndRadio}px;

  &:focus {
    border-color: ${borderColorInputFocus};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover:enabled {
    color: ${textColorPlaceholder};
    border-color: ${borderColorInputHover};
  }

  &:active:enabled,
  &:checked:enabled {
    cursor: default;
    background-color: ${backgroundColorEnabled};
    border-color: ${backgroundColorEnabled};
  }

  &:checked::after {
    color: white;
    background-color: currentColor;
    border-radius: 50%;
    content: "";
    height: 6px;
    left: 6px;
    position: absolute;
    top: 6px;
    width: 6px;
  }
`;

export type Props = {
  required?: boolean;
  value?: string | number;
  filled?: boolean;
};

/**
 * Use the `Radio` component together with the `RadioGroup`
 * component to present multiple items and require users to
 * pick only one thing from a list.
 */
const Radio = ({ filled, ...rest }: Props, ref: any) => {
  return (
    <Input
      {...rest}
      type="radio"
      filled={filled ? "filled" : "default"}
      ref={ref}
    />
  );
};

export default forwardRef(Radio);
