import styled from "styled-components";

import { Info } from "../../icons";
import { baseUnit, textColorDiscreet } from "../../tokens";
import Button from "../Button";
import Paragraph from "../Paragraph";

const CardEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${baseUnit * 4}px 0;
`;

export const StyledDescription = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 450px;

  svg {
    margin-bottom: ${baseUnit / 2}px;
    width: 24px;
    height: 24px;
    color: ${textColorDiscreet};
  }
`;
type CardEmptyDescriptionProps = {
  children: any;
};
export const CardEmptyDescription = ({
  children,
}: CardEmptyDescriptionProps) => (
  <StyledDescription>
    <Info />
    <Paragraph look="discreet">{children}</Paragraph>
  </StyledDescription>
);

export const CardEmptyAction = styled(Button)`
  margin-top: ${baseUnit * 2}px;
`;

export default CardEmpty;
