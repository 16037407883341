export type LayoutType = "vertical" | "horizontal" | "horizontal-inverse";
export function layoutToFlexDirection(layout: LayoutType) {
  if (layout === "vertical") {
    return "column";
  } else if (layout === "horizontal") {
    return "row";
  } else if (layout === "horizontal-inverse") {
    return "row-reverse";
  }
}

export function layoutToJustifyContent(layout: LayoutType) {
  if (layout === "horizontal") {
    return "flex-start";
  } else if (layout === "horizontal-inverse") {
    return "flex-end";
  } else if (layout === "vertical") {
    return "initial";
  }
}

export function layoutToAlignItems(layout: LayoutType) {
  if (layout === "vertical") {
    return "flex-start";
  } else {
    return "center";
  }
}
