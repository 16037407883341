import properMoney from "~components/copied_from_shared/money";

export const money = (value: string) => {
  return properMoney(value);
};

export const moneyFromNumber = (value: number, currencyCode: "DKK" | "EUR") => {
  return properMoney(value, undefined, currencyCode);
};

export const add = (a: string, b: string) =>
  money(a).add(money(b)).getIsoFormat();

export const multiply = (a: string, b: number) =>
  money(a).multiply(b).getIsoFormat();

export const subtract = (a: string, b: string) =>
  money(a).minus(money(b)).getIsoFormat();

export const divide = (a: string, b: number) =>
  money(a).divide(b).getIsoFormat();

export const negate = (a: string) => money(a).multiply(-1).getIsoFormat();

export const toZero = (amount: string) => {
  const currency = money(amount).getCurrency();
  return moneyFromNumber(0, currency).getIsoFormat();
};

export default money;
