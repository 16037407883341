import { useContext } from "react";

import { Context } from "./context";

const useMenu = () => {
  const menu = useContext(Context);
  return menu;
};

export default useMenu;
