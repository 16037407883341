import { t } from "@lingui/macro";

import { Frown, Meh, Smile } from "~components/copied_from_shared/pui/icons";
import reportError from "~utils/reportError";

import { Satisfaction } from "../../graphql/generated_types";

export const getReaction = (satisfaction: Satisfaction) => {
  switch (satisfaction) {
    case Satisfaction.VeryLow:
      return t`Yikes! We would appreciate if you told us what's bothering you.`;
    case Satisfaction.Low:
      return t`What do we need to improve as soon as possible?`;
    case Satisfaction.Medium:
      return t`Meh. What can we improve?`;
    case Satisfaction.High:
      return t`Was is it that you like in particular?`;
    case Satisfaction.VeryHigh:
      return t`Nailed it! We would appreciate if you told us what your primary benefit is.`;
    default: {
      reportError(new Error(`Unknown satisfaction score: ${satisfaction}`));
      return satisfaction;
    }
  }
};

export const getIcon = (satisfaction: Satisfaction) => {
  switch (satisfaction) {
    case Satisfaction.VeryLow:
      return Frown;
    case Satisfaction.Low:
      return Frown;
    case Satisfaction.Medium:
      return Meh;
    case Satisfaction.High:
      return Smile;
    case Satisfaction.VeryHigh:
      return Smile;
    default: {
      reportError(new Error(`Unknown satisfaction score: ${satisfaction}`));
      return satisfaction;
    }
  }
};
