import { DeepPartial, OmitResetState } from "react-hook-form";

import Button, { Props as ButtonProps } from "../../Button";

import { useFormContext } from "./Form";

type ResetButton = ButtonProps & {
  values?: DeepPartial<any>; // FIXME: use proper types
  omitResetState?: OmitResetState;
};
const ResetButton = ({
  children,
  values,
  omitResetState,
  ...rest
}: ResetButton) => {
  const { reset } = useFormContext();

  if (values || omitResetState) {
    return (
      <Button
        {...rest}
        look="discreet"
        type="button"
        onClick={() => reset(values, omitResetState)}
      >
        {children}
      </Button>
    );
  } else {
    return (
      <Button {...rest} look="discreet" type="reset">
        {children}
      </Button>
    );
  }
};

export default ResetButton;
