import { useEffect, useState } from "react";

import * as Sentry from "@sentry/nextjs";

import { Provider } from "~contexts/user";
import { useGet2 } from "~hooks";
import useAuth from "~hooks/useAuth";
import { User as UserType } from "~types";

import AppLoader from "../../AppLoader/AppLoader";

import useViewAs from "./useViewAs";

const User = ({ children }: any) => {
  const { sub } = useAuth();

  const [userData, setUserData] = useState<UserType | null>(null);
  const fetchUser = useGet2<UserType>({
    route: `/api/users/[auth0Sub]`,
    params: { auth0Sub: sub as string },
  });
  const user = fetchUser.data;
  const [viewAs, setViewAs] = useViewAs(user);
  const [seeWhatLandlordSees, setSeeWhatLandlordSees] = useState(false);

  useEffect(() => {
    if (user) {
      setUserData(user);
      Sentry.setUser({ id: user.id });
    }
  }, [user]);

  if (!userData) {
    return <AppLoader isError={!!fetchUser.error} />;
  }

  return (
    <Provider
      value={{
        viewAs,
        user: userData,
        setViewAs,
        seeWhatLandlordSees,
        setSeeWhatLandlordSees,
      }}
    >
      {children}
    </Provider>
  );
};

export default User;
