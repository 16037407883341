import { Trans } from "@lingui/macro";

import Downshift from "downshift";
import styled from "styled-components";

import {
  baseUnit,
  blue600,
  pampas100,
  Paragraph,
} from "~components/copied_from_shared/pui";
import { useAnalytics } from "~hooks";
import { PostStatus } from "~hooks/usePost";
import { isSearchTermValid } from "~utils/search";

import SearchField from "./SearchField";
import SearchItems from "./SearchItems";
import {
  ClearSelectionButton,
  SearchFieldWrapper,
  Ul,
  Wrapper,
} from "./StyledComponents";
import { SearchResult } from "./types";

const handleFocus = (e: any) => e.target.select();

const SeeMoreWrapper = styled.div`
  color: ${blue600};
  padding: ${baseUnit / 2}px ${baseUnit}px;
  cursor: pointer;

  :hover {
    background-color: ${pampas100};
  }
`;

type Props = {
  results: SearchResult[] | null;
  onItemSelected: (item: SearchResult) => void;
  postStatus: PostStatus;
  onShowAllResults: (searchTerm: string) => void;
  term: string;
  setTerm: (term: string) => void;
};
const Search = ({
  onItemSelected,
  onShowAllResults,
  results,
  postStatus,
  term,
  setTerm,
}: Props) => {
  const { track } = useAnalytics();

  const reset = () => {
    setTerm("");
  };

  return (
    <Downshift
      onStateChange={({ inputValue }) => {
        if (inputValue !== undefined && inputValue !== null) {
          setTerm(inputValue);
        }
      }}
      onChange={(selectedItem) => {
        if (selectedItem) {
          onItemSelected(selectedItem);
          track("SearchResultSelected");
        }
      }}
      itemToString={(item) => (item ? item.address : "")}
      inputValue={term}
      stateReducer={(state, changed) => {
        if (changed.type === Downshift.stateChangeTypes.mouseUp) {
          return { ...changed, isOpen: false, inputValue: state.inputValue };
        }
        return changed;
      }}
    >
      {(downshiftProps) => {
        const {
          getInputProps,
          getMenuProps,
          getRootProps,
          isOpen,
          openMenu,
          clearSelection,
          closeMenu,
          ...itemsProps
        } = downshiftProps;
        const { inputValue } = itemsProps;
        const menuProps = getMenuProps();
        const rootProps = getRootProps({} as any, { suppressRefError: true });
        const inputProps = getInputProps({
          onFocus: (e: any) => {
            handleFocus(e);
            !!inputValue && openMenu();
          },
          onBlur: () => {
            // setSearching(false);
          },
        });

        const onClear = () => {
          reset();
          clearSelection();
        };

        return (
          <Wrapper>
            <SearchFieldWrapper {...rootProps}>
              <SearchField
                {...inputProps}
                // @ts-ignore
                resourceStatus={
                  postStatus === "creating"
                    ? "pending"
                    : postStatus === "failed"
                    ? "get-error"
                    : "ready"
                }
                onEnterKeyUp={() => {
                  track("SearchResultSeeAll");
                  onShowAllResults(term);
                  closeMenu();
                }}
              />
              {!!inputValue && <ClearSelectionButton onClick={onClear} />}
            </SearchFieldWrapper>
            <Ul {...menuProps}>
              {isOpen && isSearchTermValid(term) ? (
                <>
                  <SearchItems
                    items={(results || []).slice(0, 5)}
                    {...itemsProps}
                  />
                  <SeeMoreWrapper
                    key={"see-more-item"}
                    onClick={() => {
                      track("SearchResultSeeAll");
                      onShowAllResults(term);
                      closeMenu();
                    }}
                    onMouseEnter={() => itemsProps.setHighlightedIndex(-1)}
                  >
                    <Paragraph>
                      <Trans>See all results...</Trans>
                    </Paragraph>
                  </SeeMoreWrapper>
                </>
              ) : null}
            </Ul>
          </Wrapper>
        );
      }}
    </Downshift>
  );
};

export default Search;
