import { t } from "@lingui/macro";

import styled from "styled-components";

import Link from "~components/connected/Link";
import {
  support400,
  teal700,
  Tooltip,
} from "~components/copied_from_shared/pui";
import { ArrowLeft, Smile } from "~components/copied_from_shared/pui/icons";
import { useRouter } from "~hooks";

const Anchor = styled.a<{
  isPaymentsCustomer: boolean;
}>`
  background: ${(props) => (props.isPaymentsCustomer ? teal700 : support400)};
  border-radius: 50%;
  border: none;
  width: 38px;
  height: 38px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  }
`;

function SupportAreaToggle({
  isPaymentsCustomer,
}: {
  isPaymentsCustomer: boolean;
}) {
  const { asPath } = useRouter();
  const isSupportPath = asPath.startsWith("/support");

  if (isSupportPath) {
    return (
      <Tooltip title={t`Go to platform`}>
        <div>
          <Link to="/">
            <Anchor isPaymentsCustomer={isPaymentsCustomer}>
              <ArrowLeft />
            </Anchor>
          </Link>
        </div>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={t`Go to support area`}>
        <div>
          <Link to="/support">
            <Anchor isPaymentsCustomer={isPaymentsCustomer}>
              <Smile />
            </Anchor>
          </Link>
        </div>
      </Tooltip>
    );
  }
}

export default SupportAreaToggle;
