// FIXME: This is directly ported from the old self service app
// should probably be turned into small util methods instead of
// a class to follow rest of the codebase
class Address {
  street: string;
  number: string;
  postalCode: string;
  city: string;
  country: "DK";
  unit: string;
  tenancy: string;

  constructor(json: any = {}) {
    json = { street: "", ...json };

    if (!json.unit) {
      if (json.street.includes(",")) {
        const [street = "", unit = "", tenancy = ""] = json.street.split(",");
        json.street = street.trim();
        json.unit = unit.trim();
        json.tenancy = tenancy.trim();
      }
    }

    if (json.street.match(/.* \d+/)) {
      const parts = json.street.split(" ");
      json.street = parts.slice(0, -1).join(" ");
      json.number = parts[parts.length - 1];
    }

    this.street = json.street || "";
    this.number = json.number || "";
    this.postalCode = json.postalCode || "";
    this.city = json.city || "";
    this.country = json.country || "DK";
    this.unit = json.unit || "";
    this.tenancy = json.tenancy || "";
  }

  static fromDAWA(json: any) {
    const {
      adresse: { vejnavn, husnr, postnr, postnrnavn, dør, etage },
    } = json;
    return new Address({
      street: vejnavn,
      postalCode: postnr,
      city: postnrnavn,
      country: "DK",
      unit: dør ? `${etage}. ${dør}` : etage,
      number: husnr,
    });
  }

  formatStreet() {
    if (!this.street || !this.number || !this.city || !this.postalCode) {
      return null;
    }
    return `${this.street} ${this.formatUnit()}`;
  }

  formatUnit(): string | null {
    if (!this.number) {
      return null;
    }
    let base: string = this.number;
    if (this.unit) {
      base += `, ${this.unit}`;
    }
    if (this.unit && this.tenancy) {
      base += `, ${this.tenancy}`;
    }
    if (!this.unit && this.tenancy) {
      base += `,, ${this.tenancy}`;
    }
    return base;
  }

  formatAddress() {
    const street = this.formatStreet();
    if (street === null) {
      return null;
    }
    return `${street}, ${this.postalCode} ${this.city}`;
  }

  getSaveableAddress() {
    const street = this.formatStreet();
    if (street === null) {
      return null;
    }
    return `${street}, ${this.postalCode} ${this.city}, ${this.country}`;
  }
}

export default Address;
