import clsx from "clsx";

type Color = "red" | "blue" | "green" | "yellow" | "pampas" | "gray" | "white";
type Tone =
  | 25
  | 50
  | 75
  | 100
  | 200
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 900
  | 1000;

const getFontWeight = (props: Props) => {
  if (props.black) return "font-black";
  if (props.bold) return "font-bold";
  if (props.regular) return "font-regular";
  if (props.medium) return "font-medium";
  if (props.light) return "font-light";
  return null; // inherit
};

const getFontStyle = (props: Props) => {
  if (props.italic) return "italic";
  if (props.notItalic) return "not-italic";
  return null; // inherit
};

const getFontFamily = (props: Props) => {
  if (props.sansSerif) return "font-sans";
  if (props.mono) return "font-mono";
  if (props.serif) return "font-serif";
  return null; // inherit
};

const getAlignment = (props: Props) => {
  if (props.alignLeft) return "text-left";
  if (props.alignCenter) return "text-center";
  if (props.alignJustify) return "text-justify";
  if (props.alignRight) return "text-right";
  return null; // inherit
};

const getColor = (props: Props) => {
  if (props.color) {
    if (props.color === "white") return "text-white";
    const tone = props.tone ?? 500;
    return `text-${props.color}-${tone}`;
  }
  return "text-gray-1000"; // default
};

const getSize = (props: Props) => {
  if (props.smaller) return "text-xs";
  if (props.small) return "text-sm";
  if (props.base) return "text-base";
  if (props.large) return "text-lg";
  if (props.larger) return "text-xl";
  if (props.huge) return "text-2xl";
  return null; // inherit
};

const getTextDecoration = (props: Props) => {
  if (props.lineThrough) return "line-through";
  if (props.underline) return "underline";
  return null; // inherit
};

type Props = {
  children: string | React.ReactNode;
  light?: boolean;
  regular?: boolean;
  medium?: boolean;
  bold?: boolean;
  black?: boolean;
  italic?: boolean;
  notItalic?: boolean;
  lineThrough?: boolean;
  underline?: boolean;
  sansSerif?: boolean;
  mono?: boolean;
  serif?: boolean;
  noWrap?: boolean;
  alignLeft?: boolean;
  alignRight?: boolean;
  alignCenter?: boolean;
  alignJustify?: boolean;
  paragraph?: boolean;
  smaller?: boolean;
  small?: boolean;
  base?: boolean;
  large?: boolean;
  larger?: boolean;
  huge?: boolean;
  color?: Color;
  tone?: Tone;
  as?: "span" | "div" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
  truncate?: boolean;
  className?: string;
};
const Typography = (props: Props) => {
  const fontWeight = getFontWeight(props);
  const fontStyle = getFontStyle(props);
  const fontFamily = getFontFamily(props);
  const alignment = getAlignment(props);
  const color = getColor(props);
  const size = getSize(props);
  const Tag = props.as ?? "span";
  const textDecoration = getTextDecoration(props);

  const content = (
    <Tag
      className={clsx(
        fontWeight,
        fontStyle,
        fontFamily,
        alignment,
        color,
        size,
        textDecoration,
        props.truncate && "truncate",
        props.noWrap && "whitespace-nowrap",
        props.className
      )}
    >
      {props.children}
    </Tag>
  );

  if (props.paragraph) return <p>{content}</p>;

  return content;
};

export default Typography;
