import { Skeleton } from "~components/ContentLoader";

import {
  IconWrapper,
  Label,
  LabelAndIcon,
  Size,
  Value,
  Wrapper,
} from "./StatsBox";

export type Props = {
  icon?: any;
  label?: string;
  size?: Size;
};

const StatsBoxFallback = ({ icon, label, size = "normal" }: Props) => (
  <Wrapper size={size}>
    <LabelAndIcon>
      {icon && <IconWrapper size={size}>{icon}</IconWrapper>}
      <Label size={size}>{label || <Skeleton width="75%" />}</Label>
    </LabelAndIcon>
    <Value size={size}>
      <Skeleton width="60%">x</Skeleton>
    </Value>
  </Wrapper>
);

export default StatsBoxFallback;
