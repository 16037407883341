import { darken, lighten } from "polished";

export const white = "var(--white)";
export const black = "var(--black)";

export const gray100 = "var(--gray100)";
export const gray200 = "var(--gray200)";
export const gray300 = "var(--gray300)";
export const gray400 = "var(--gray400)";
export const gray500 = "var(--gray500)";
export const gray600 = "var(--gray600)";
export const gray700 = "var(--gray700)";
export const gray800 = "var(--gray800)";
export const gray900 = "var(--gray900)";

const green = "#54bf7d";
export const green100 = lighten(0.3, green);
export const green200 = lighten(0.225, green);
export const green300 = lighten(0.15, green);
export const green400 = lighten(0.075, green);
export const green500 = green;
export const green600 = darken(0.05, green);
export const green700 = darken(0.1, green);
export const green800 = darken(0.15, green);
export const green900 = darken(0.2, green);

export const blue = "#6b85e3";
export const blue100 = lighten(0.3, blue);
export const blue200 = lighten(0.225, blue);
export const blue300 = lighten(0.15, blue);
export const blue400 = lighten(0.075, blue);
export const blue500 = blue;
export const blue600 = darken(0.05, blue);
export const blue700 = darken(0.1, blue);
export const blue800 = darken(0.15, blue);
export const blue900 = darken(0.2, blue);

export const yellow = "#fad661";
export const yellow100 = lighten(0.225, yellow);
export const yellow200 = lighten(0.15, yellow);
export const yellow300 = lighten(0.075, yellow);
export const yellow400 = yellow;
export const yellow500 = darken(0.05, yellow);
export const yellow600 = darken(0.1, yellow);
export const yellow700 = darken(0.15, yellow);
export const yellow800 = darken(0.2, yellow);
export const yellow900 = darken(0.25, yellow);

export const red = "#e55757";
export const red50 = lighten(0.35, red);
export const red100 = lighten(0.3, red);
export const red200 = lighten(0.225, red);
export const red300 = lighten(0.15, red);
export const red400 = lighten(0.075, red);
export const red500 = red;
export const red600 = darken(0.05, red);
export const red700 = darken(0.1, red);
export const red800 = darken(0.15, red);
export const red900 = darken(0.2, red);

export const pampas100 = "var(--pampas100)";
export const pampas200 = "var(--pampas200)";
export const pampas300 = "var(--pampas300)";
export const pampas400 = "var(--pampas400)";
export const pampas500 = "var(--pampas500)";
export const pampas600 = "var(--pampas600)";
export const pampas700 = "var(--pampas700)";
export const pampas800 = "var(--pampas800)";
export const pampas900 = "var(--pampas900)";

export const orange = "#734B5E";
export const orange100 = lighten(0.3, orange);
export const orange200 = lighten(0.225, orange);
export const orange300 = lighten(0.15, orange);
export const orange400 = lighten(0.075, orange);
export const orange500 = orange;
export const orange600 = darken(0.05, orange);
export const orange700 = darken(0.1, orange);
export const orange800 = darken(0.15, orange);
export const orange900 = darken(0.2, orange);

export const teal700 = "#205C58";
export const teal800 = "#2B7A75";
