import { useState } from "react";
import * as React from "react";

import styled, { css } from "styled-components";

import {
  baseUnit,
  black,
  borderColor as defaultBorderColor,
  fontFamilySans,
  pampas100,
  textColor,
  textColorSupport,
  white,
} from "../../tokens";
import { variant } from "../../utils";

import { Provider, SortDirection } from "./context";

export const borderColor = defaultBorderColor;
export const heightRow = 43;
export const backgroundColorRowHover = pampas100;

export const color = variant(textColor, { support: textColorSupport });

export const Wrapper = styled.table`
  border: none;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 100%;
  table-layout: fixed;
  font-family: ${fontFamilySans};
  font-size: 15px;
`;

export const THead = styled.thead`
  border-bottom: 1px solid ${borderColor};
  ${Wrapper}.vertical & {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`;

export const TBody = styled.tbody``;
export const TFoot = styled.tfoot`
  border-top: 1px solid ${borderColor};
`;
export const TotalsTableWrapper = styled.div`
  width: 75%;
  margin-left: auto;
  border-bottom: 1px solid ${black};
`;

export const Separator = styled.div`
  border-bottom: 1px solid ${black};
`;

export const Tr = styled.tr<{ onClick?: any; lineThrough?: boolean }>`
  line-height: ${heightRow}px;
  background-color: ${white};
  cursor: ${({ onClick }) => (onClick ? "pointer" : "initial")};

  &:not(:first-child) {
    border-top: 1px solid ${borderColor};
  }

  td {
    ${({ lineThrough }) =>
      lineThrough &&
      `
      text-decoration: line-through;
      opacity: 0.5;
    `}
  }

  ${Wrapper}.vertical & {
    line-height: initial;
    text-align: right;
    display: block;
  }

  &:hover {
    background-color: ${({ onClick }) =>
      onClick ? backgroundColorRowHover : "current"};
  }
`;

type Align = "left" | "right" | "center";
export type TdProps = {
  textOverflowEllipsis?: boolean;
  align?: Align;
  span?: string;
  lineThrough?: boolean;
  colSpan?: number;
};
export const Td = styled.td<TdProps>`
  color: ${color};
  max-width: ${({ span }) => (span ? span : "initial")};
  width: ${({ span }) => (span ? span : "initial")};
  text-align: ${({ align }) => align ?? "left"};
  padding: 0;
  line-height: 0;
  height: ${heightRow + 1}px;
  text-decoration: ${({ lineThrough }) =>
    lineThrough ? "line-through !important" : "initial"};
  opacity: ${({ lineThrough }) => (lineThrough ? "0.5 !important" : "initial")};

  ${({ textOverflowEllipsis }) =>
    textOverflowEllipsis &&
    css`
      text-overflow: ellipsis;
      max-width: 0;
      white-space: nowrap;
      overflow: hidden;
    `}

  &:not(:last-child) {
    padding-right: ${baseUnit}px;
  }

  ${Wrapper}.vertical & {
    text-align: left;
    padding: ${baseUnit / 1.5}px 0;
    border-bottom: 1px solid ${borderColor};
    display: flex;
    justify-content: space-between;

    &::before {
      content: attr(data-label);
      font-weight: bold;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  ${TFoot} & {
    font-weight: bold;

    * {
      font-weight: bold;
    }
  }
`;

export const Actions = styled.div`
  margin-top: ${baseUnit * 1.5}px;
  align-items: space-between;
  display: flex;
`;

type Props = {
  children: React.ReactNode;
  onSortByChanged?: (value: string, direction: "asc" | "desc") => void;
  style?: any;
};

/**
 * Use tables to logically structure content in a grid to make it easier
 * to see relationships and to facilitate understanding. Tables are
 * structured in HTML, so the component only provides structure and
 * content alignment.
 */
const Table = ({ children, onSortByChanged, ...rest }: Props) => {
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState<SortDirection>("desc");

  const onSortBy = (value: string) => {
    let direction: SortDirection;
    if (!sortBy) {
      direction = "asc";
    } else if (sortBy === value) {
      direction = sortDirection === "asc" ? "desc" : "asc";
    } else {
      direction = "asc";
    }

    setSortBy(value);
    setSortDirection(direction);

    onSortByChanged && onSortByChanged(value, direction);
  };

  return (
    <Provider value={{ sortBy, sortDirection, onSortBy }}>
      <Wrapper className="horizontal" {...rest}>
        {children}
      </Wrapper>
    </Provider>
  );
};

export default Table;
