import styled from "styled-components";

import { ChevronDown, ChevronUp, Info, Tool } from "../../icons";
import { baseUnit, textColorDiscreet, textColorSupport } from "../../tokens";
import { variant } from "../../utils";
import Tooltip from "../Tooltip";

import { heightRow, Wrapper as TableWrapper } from "./Table";
import ThHelp from "./ThHelp";
import useTable from "./useTable";

export const color = variant(textColorDiscreet, { support: textColorSupport });

type WrapperProps = {
  textOverflowEllipsis?: boolean;
  isIcon?: boolean;
  isClickable?: boolean;
  align?: "left" | "right";
  span?: string;
};
const Wrapper = styled.th<WrapperProps>`
  position: relative;
  white-space: nowrap;
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "default")};
  color: ${color};
  text-overflow: ${({ textOverflowEllipsis }) =>
    textOverflowEllipsis ? "ellipsis" : "initial"};
  text-align: ${({ align }: any) => (align === "right" ? "right" : "left")};
  max-width: ${({ span }) => (span ? span : "initial")};
  width: ${({ span, isIcon }) => {
    if (span) {
      return span;
    } else if (isIcon) {
      return `${heightRow}px`;
    }
    return "initial";
  }};

  &:not(:last-child) {
    padding-right: ${({ isIcon }) => (isIcon ? "0" : `${baseUnit}px`)};
  }

  ${TableWrapper}.vertical & {
    padding: ${baseUnit / 1.5}px 0;
  }
`;

const IconWrapper = styled.span`
  position: relative;
  top: 5px;

  svg {
    flex: 0 0 20px;
    height: 20px;
  }
`;

const ActionIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = WrapperProps & {
  children?: any;
  sortName?: string;
  type?: "text" | "actions" | "info" | "custom-icon";
  Icon?: any;
  tooltip?: any;
  help?: any;
};
const Th = ({
  children,
  sortName,
  type = "text",
  tooltip,
  help,
  Icon,
  ...rest
}: Props) => {
  const { sortBy, sortDirection, onSortBy } = useTable();
  const isSortByAsc = sortBy === sortName && sortDirection === "asc";
  const isSortByDesc = sortBy === sortName && sortDirection === "desc";
  return (
    <Tooltip title={tooltip}>
      <Wrapper
        isClickable={type === "text" && !!sortName}
        isIcon={type !== "text"}
        onClick={() => !!sortName && onSortBy(sortName)}
        {...rest}
      >
        {type !== "text" ? (
          <ActionIconWrapper>
            {type === "custom-icon" && <Icon size={20} />}
            {type === "actions" && <Tool size={20} />}
            {type === "info" && <Info size={20} />}
          </ActionIconWrapper>
        ) : (
          children
        )}
        {help && <ThHelp text={help} />}
        {(isSortByAsc || isSortByDesc) && (
          <IconWrapper>
            {isSortByAsc && <ChevronDown />}
            {isSortByDesc && <ChevronUp />}
          </IconWrapper>
        )}
      </Wrapper>
    </Tooltip>
  );
};

export default Th;
