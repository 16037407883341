import { format as dateFnsFormat, getQuarter, parseISO } from "date-fns";
import { da, enGB, nl, de } from "date-fns/locale";

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function format(date: Date, formatString: string, options?: any) {
  return dateFnsFormat(date, formatString, { locale: da, ...options });
}

export function formatDate(date: Date | string) {
  if (typeof date === "string") {
    date = parseISO(date);
  }
  return date ? format(date, "dd.MM.yyyy") : null;
}

export function formatMonth(month: number | string, locale?: string) {
  if (typeof month === "string") {
    month = parseInt(month);
  }
  let date=new Date(1970, month, 1)
  if (locale) {
    if (locale === "nl") {
      return capitalizeFirstLetter(dateFnsFormat(date, "MMMM", { locale: nl }));
    } else if (locale === "en") {
      return capitalizeFirstLetter(dateFnsFormat(date, "MMMM", { locale: enGB }));
    } else if (locale === "de"){
      return capitalizeFirstLetter(dateFnsFormat(date, "MMMM", { locale: de }));
    }
  }
  return capitalizeFirstLetter(dateFnsFormat(date, "MMMM", { locale: da }));

}

export function formatMonthDay(month: number, day: number, locale?: string) {
  let date=new Date(1970, month, day)
  if (locale) {
    if (locale === "nl") {
      return capitalizeFirstLetter(dateFnsFormat(date, "d MMMM", { locale: nl }));
    } else if (locale === "en") {
      return capitalizeFirstLetter(dateFnsFormat(date, "MMMM do", { locale: enGB }));
    } else if (locale === "de"){
      return capitalizeFirstLetter(dateFnsFormat(date, "d. MMMM", { locale: de }));
    }
  }
  return dateFnsFormat(date, "d. MMMM", { locale: da });

}

export function formatMonthAndYear(date: Date | string, locale?: string) {
  if (typeof date === "string") {
    date = parseISO(date);
  }
  if (locale) {
    if (locale === "nl") {
      return dateFnsFormat(date, "MMMM yyyy", { locale: nl });
    } else if (locale === "en") {
      return dateFnsFormat(date, "MMMM yyyy", { locale: enGB });
    } else if (locale === "de"){
      return dateFnsFormat(date, "MMMM yyyy", { locale: de });
    }
  }
  return dateFnsFormat(date, "MMMM yyyy", { locale: da });
}

export function serializeDate(date: Date) {
  return format(date, "yyyy-MM-dd");
}

export function serializeYearMonth(date: Date) {
  return format(date, "yyyy-MM");
}

export function formatQuarter(date: Date | string) {
  if (typeof date === "string") {
    date = parseISO(date);
  }
  return `Q${getQuarter(date)}`;
}

export function formatYear(date: Date | string) {
  if (typeof date === "string") {
    date = parseISO(date);
  }
  return format(date, "yyyy");
}
