import { t } from "@lingui/macro";

import styled from "styled-components";

import {
  baseUnit,
  borderColorDiscreet,
  borderColorInputHover,
  borderRadiusInput,
  fontFamilySans,
  fontSmoothing,
  heightInput,
  Menu as PUIMenu,
  menuItemPaddingHorizontal,
  textColor,
  TextField,
  transitionDuration,
  useMenuState,
} from "~components/copied_from_shared/pui";
import { defaultOptions } from "~components/copied_from_shared/pui/components/Menu";
import { ChevronDown } from "~components/copied_from_shared/pui/icons";

const heightName = heightInput + 2;
const paddingHorizontally = (heightName - sizeSmall) / 2;

import { pageMaxWidth } from "~components/connected/Page";
import { useFuzzySearch, useUser } from "~hooks";

import Companies from "./Companies";
import InitialIcon, { sizeSmall } from "./InitialsIcon";
import { Company } from "./types";

const Content = styled.div`
  width: 300px;
`;

const CompanyName = styled.div`
  ${fontSmoothing};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${textColor};
  font-size: 17px;
  font-weight: bold;
  font-family: ${fontFamilySans};
  flex: 0 0 ${heightInput + 2}px;
  height: ${heightInput + 2}px;
  white-space: nowrap;
  padding: 0 ${paddingHorizontally}px;
  cursor: default;
  max-width: 420px;

  @media (max-width: ${pageMaxWidth}px) {
    max-width: 270px;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
`;

const Trigger = styled(CompanyName).attrs({ as: "button" })`
  background: transparent;
  border-radius: ${borderRadiusInput};
  cursor: pointer;
  transition: ${transitionDuration};
  border: 1px solid ${borderColorDiscreet};

  &:hover {
    border: 1px solid ${borderColorInputHover};
  }
`;

const Icon = styled(ChevronDown)`
  margin-left: ${baseUnit / 1.5}px;
  top: 1px;
  position: relative;
  flex: 0 0 22px;
  width: 22px;
  height: 22px;
`;

const SearchFieldWrapper = styled.div`
  padding: ${menuItemPaddingHorizontal}px;
  border-bottom: 1px solid ${borderColorDiscreet};
`;

export const numCompaniesToShowSearch = 6;

export type Props = {
  companies: Company[];
  selectedCompany: Company;
};
const CompanySelector = ({ companies, selectedCompany }: Props) => {
  const { items, search, term } = useFuzzySearch(companies, ["name"], {
    threshold: 0.2,
  });
  const { setViewAs } = useUser();
  const menu = useMenuState(defaultOptions);

  const _onChangeCompany = (companyId: string) => {
    setViewAs(companyId || "");
    menu.hide();
  };

  if (companies.length < 2) {
    return (
      <CompanyName>
        <InitialIcon size="small" name={selectedCompany.name} />
        <span>{selectedCompany.name}</span>
      </CompanyName>
    );
  }

  return (
    <PUIMenu
      state={menu}
      ariaLabel={t`Company selector`}
      trigger={
        <Trigger>
          <InitialIcon size="small" name={selectedCompany.name} />
          <span>{selectedCompany.name}</span>
          <Icon />
        </Trigger>
      }
    >
      <Content>
        {companies.length >= numCompaniesToShowSearch && (
          <SearchFieldWrapper>
            <TextField
              value={term}
              onChange={(e) => search(e.target.value)}
              placeholder={t`Search your companies...`}
            />
          </SearchFieldWrapper>
        )}
        <Companies
          companies={items.slice(0, 30)}
          selectedCompanyId={selectedCompany?.id}
          onChangeCompany={_onChangeCompany}
        />
      </Content>
    </PUIMenu>
  );
};

export default CompanySelector;
