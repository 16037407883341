import { useState } from "react";

import styled from "styled-components";

import { baseUnit, white } from "~components/copied_from_shared/pui";
import { ResourceStatus } from "~hooks/useResource";
import { getElementStackOrder } from "~utils";

import ClippyContent from "./ClippyContent";
import Toggle from "./Toggle";
import { FeatureProps } from "./types";

const Wrapper = styled.div<{ expanded: boolean }>`
  position: fixed;
  z-index: ${getElementStackOrder("clippy")};
  bottom: ${baseUnit * 2}px;
  right: ${baseUnit * 2}px;
  width: ${({ expanded }) => (expanded ? "360px" : "38px")};
  height: ${({ expanded }) => (expanded ? "auto" : "38px")};
  border-radius: 19px;
  background: ${white};
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  overflow: ${({ expanded }) => (expanded ? "scroll" : "hidden")};
`;

export type Props = {
  data: FeatureProps | null;
  isLoading: boolean;
  error?: string;
  onGoToCompany: (companyId: string) => void;
};

const Clippy = ({ data, isLoading, error, onGoToCompany }: Props) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Wrapper expanded={expanded}>
      {expanded && (
        <ClippyContent
          data={data}
          isLoading={isLoading}
          error={error}
          onGoToCompany={onGoToCompany}
        />
      )}
      <Toggle
        onClick={() => setExpanded(!expanded)}
        active={expanded}
        isLoading={isLoading}
        error={error}
      />
    </Wrapper>
  );
};

export default Clippy;
