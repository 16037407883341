import { t } from "@lingui/macro";

import FieldCore, { Props as FieldCoreProps } from "../FieldCore";

export type Props = Omit<FieldCoreProps, "type">;

const validation = {
  pattern: {
    value: /^[0-9][0-9][0-9][0-9]-[0-9][0-9]$/,
    message: t`Please provide year and month in the format: YYYY-MM`,
  },
};

const MonthYear = (props: Props) => (
  <FieldCore {...props} type="month" validation={validation} />
);

export default MonthYear;
