import styled, { keyframes } from "styled-components";

import { pampas400, textColor, white } from "../../tokens";

import { Look } from "./types";

const load = (look: Look) => {
  let backgroundA;
  let backgroundB;
  if (look === "danger" || look === "primary") {
    backgroundA = "rgba(255,255,255,0.2)";
    backgroundB = white;
  } else {
    backgroundA = pampas400;
    backgroundB = textColor;
  }
  return keyframes`
    0%, 80%, 100% { 
        background-color: ${backgroundA};
    } 40% { 
        background-color: ${backgroundB};
    }
`;
};

const Wrapper = styled.div`
  display: flex;
`;

const animationStyle = "1.4s infinite ease-in-out both";

const Ball = styled.div<{ look: Look }>`
  width: 6px;
  height: 6px;
  border-radius: 100%;
  animation: ${({ look }) => load(look)} ${animationStyle};

  &:not(:last-child) {
    margin-right: 3px;
  }

  &:nth-child(1) {
    animation-delay: -0.32s;
  }

  &:nth-child(2) {
    animation-delay: -0.16s;
  }
`;

type Props = {
  look: Look;
};
const Loader = ({ look }: Props) => (
  <Wrapper>
    <Ball look={look} />
    <Ball look={look} />
    <Ball look={look} />
  </Wrapper>
);

export default Loader;
