import { t } from "@lingui/macro";

import * as React from "react";

import { useNotifications } from "~hooks";
import { reportError } from "~utils";

const withNotifications = (Component: any) => {
  // eslint-disable-next-line react/display-name
  return (props: any) => {
    const notifications = useNotifications();
    return <Component notifications={notifications} {...props} />;
  };
};

type Props = {
  fallback: any;
  notifications: any;
};
type State = {
  error: any;
  hasError: boolean;
};

// Error boundaries currently have to be classes.
class ErrorBoundary extends React.Component<Props, State> {
  state = { hasError: false, error: null };
  static getDerivedStateFromError(error: any) {
    return {
      hasError: true,
      error,
    };
  }
  componentDidCatch(error: any) {
    reportError(error);
    this.props.notifications.push({
      type: "error",
      text: t`An unexpected error occurred`,
    });
  }
  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}

export default withNotifications(ErrorBoundary);
