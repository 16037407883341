import * as React from "react";

import styled from "styled-components";

import { fontStack } from "../../../mixins";
import {
  backgroundColorInput,
  backgroundColorInputFilled,
  baseUnit,
  borderColorInput,
  borderColorInputFocus,
  borderRadiusInput,
  fontFamilySans,
  textColor,
  textColorDiscreet,
} from "../../../tokens";
import { variants } from "../../../utils";

const backgroundColor = variants("filled", {
  default: { default: backgroundColorInput },
  filled: { default: backgroundColorInputFilled },
});

type WrapperProps = {
  filled: "filled" | "default";
};

const Wrapper = styled.textarea<WrapperProps>`
  ${fontStack}
  font-family: ${fontFamilySans};
  background: ${backgroundColor};
  width: 100%;
  border: 1px solid ${borderColorInput};
  padding: ${baseUnit}px;
  border-radius: ${borderRadiusInput};
  font-size: 16px;
  color: ${textColor};

  &:active,
  &:focus {
    border-color: ${borderColorInputFocus};
  }

  ::placeholder {
    color: ${textColorDiscreet};
  }
`;

type Props = {
  filled?: boolean;
  placeholder?: string;
};

/**
 * The `Textarea` component is used when we want to allow users to
 * provide multiline text...
 */
const Textarea = ({ filled, ...rest }: Props, ref: any) => {
  return <Wrapper filled={filled ? "filled" : "default"} ref={ref} {...rest} />;
};

export default React.forwardRef(Textarea);
