import styled from "styled-components";

import { fontStack } from "../../mixins";
import {
  baseUnit,
  danger400,
  pampas100,
  primary400,
  success400,
  textColor,
} from "../../tokens";
import { variants } from "../../utils";

import { Status } from "./types";
import useNotification from "./useNotification";

export const height = "28px";

type WrapperProps = {
  status: Status;
  href?: string;
};

const backgroundColor = variants("status", {
  default: { default: pampas100 },
  info: { default: primary400 },
  error: { default: danger400 },
  success: { default: success400 },
});

const color = variants("status", {
  default: { default: textColor },
  info: { default: "white" },
  error: { default: "white" },
  success: { default: "white" },
});

const Wrapper = styled.button<WrapperProps>`
  ${fontStack};
  padding: 0;
  border: none;
  cursor: pointer;
  color: ${color};
  background-color: ${backgroundColor};
  height: ${height};
  line-height: ${height};
  font-size: 15px;
  padding: 0 9px;
  border-radius: 6px;
  margin-left: ${baseUnit}px;
  text-decoration: none;

  &:hover {
    opacity: 0.75;
  }
`;

const WrapperLink = styled(Wrapper).attrs({ as: "a" })``;

type LinkProps = {
  href: string;
  children: any;
  download?: boolean;
  onClick?: () => void;
};

type ButtonProps = {
  onClick?: () => void;
  children: any;
};

type Props = LinkProps | ButtonProps;
export const NotificationAction = (props: Props) => {
  const { status } = useNotification();

  if ("href" in props) {
    return (
      <WrapperLink status={status} {...props}>
        {props.children}
      </WrapperLink>
    );
  } else {
    return (
      <Wrapper status={status} {...props}>
        {props.children}
      </Wrapper>
    );
  }
};

const NotificationActions = styled.div`
  display: flex;
`;

export default NotificationActions;
