import { useEffect, useState } from "react";

import { FormSubmitButton } from "~components/copied_from_shared/pui";
import {
  Look,
  State as PUIButtonState,
} from "~components/copied_from_shared/pui/components/Button";
import { PostStatus } from "~hooks/usePost";
import { ResourceStatus } from "~hooks/useResource";

type Status = ResourceStatus | PostStatus | null;

type Props = {
  status: Status;
  children: string | React.ReactNode;
  disabled?: boolean;
  tooltip?: any;
  subtle?: boolean;
  dataTestId?: string;
  look?: Look;
  Icon?: any;
};

const submitStates: Status[] = ["updating", "creating", "deleting"];
const errorStates: Status[] = ["update-failed", "failed", "not-found"];
const doneStates: Status[] = ["idle", "validating"];

const SubmitButton = ({
  status,
  children,
  disabled,
  tooltip,
  dataTestId,
  look,
  Icon,
}: Props) => {
  const [buttonState, setButtonState] = useState<PUIButtonState>();

  const setTemporaryButtonState = (state: PUIButtonState) => {
    setButtonState(state);
    setTimeout(() => {
      setButtonState(null);
    }, 2000);
  };

  useEffect(() => {
    const isSubmitting = submitStates.includes(status);
    const isDone = doneStates.includes(status);
    const isError = errorStates.includes(status);

    if (isSubmitting) {
      setButtonState("loading");
    } else if (buttonState === "loading" && isDone) {
      setTemporaryButtonState("done");
    } else if (buttonState === "loading" && isError) {
      setTemporaryButtonState("error");
    }
  }, [status]);

  return (
    <FormSubmitButton
      Icon={Icon}
      look={look}
      tooltip={tooltip}
      disabled={disabled}
      state={buttonState}
      data-test-id={dataTestId}
    >
      {children}
    </FormSubmitButton>
  );
};

export default SubmitButton;
