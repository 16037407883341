import { Trans } from "@lingui/macro";

import styled from "styled-components";

import { baseUnit, Paragraph } from "~components/copied_from_shared/pui";
import { ResourceStatus } from "~hooks/useResource";

import ClippyContentData from "./ClippyContentData";
import { FeatureProps } from "./types";

export type Props = {
  onGoToCompany: (companyId: string) => void;
  data: FeatureProps | null;
  isLoading: boolean;
  error?: string;
};

const Wrapper = styled.div`
  padding: ${baseUnit * 1.5}px;
`;

const ClippyContent = ({ data, isLoading, error, onGoToCompany }: Props) => {
  let content;
  if (error !== undefined) {
    content = (
      <Paragraph>
        <Trans>Failed to load...</Trans>
      </Paragraph>
    );
  } else if (data) {
    content = <ClippyContentData {...data} onGoToCompany={onGoToCompany} />;
  } else {
    content = (
      <Paragraph>
        <Trans>Loading...</Trans>
      </Paragraph>
    );
  }

  return <Wrapper>{content}</Wrapper>;
};

export default ClippyContent;
