import { Trans } from "@lingui/macro";

import Link from "~components/connected/Link";
import { AlertOctagon } from "~components/copied_from_shared/pui/icons";

import PageError, { Action, Actions, Message, Title } from "./PageError";

const ErrorNotFound = () => (
  <PageError>
    <Title Icon={AlertOctagon}>
      <Trans>An unexpected error occurred</Trans>
    </Title>
    <Message>
      <Trans>
        Please try reloading the page. If you keep experiencing problems, we
        would like to hear from you.
      </Trans>
    </Message>
    <Actions>
      <Action onClick={() => window.location.reload()}>
        <Trans>Reload page</Trans>
      </Action>
      <Link to="/">
        <Action look="discreet">
          <Trans>Go back home</Trans>
        </Action>
      </Link>
    </Actions>
  </PageError>
);

export default ErrorNotFound;
