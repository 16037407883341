import { useState } from "react";
import * as React from "react";

import { Search } from "react-feather";
import styled, { css } from "styled-components";

import { fontStack } from "../../../mixins";
import {
  backgroundColorInput,
  backgroundColorInputFilled,
  baseUnit,
  borderColorInputFocus,
  borderColorInputHover,
  borderRadiusInput,
  danger500,
  fontFamilySans,
  heightInput,
  success500,
  textColorDiscreet,
  textColorInput,
  textColorPlaceholder,
  textColorSupport,
  transitionDuration,
} from "../../../tokens";
import { fontSizeInput } from "../../../tokens/typography";
import { variant, variants } from "../../../utils";
import {
  borderColor as borderColorTable,
  heightRow as heightTableRow,
  Td,
} from "../../Table";

import TextFieldStatus from "./TextFieldStatus";
import { Status } from "./types";

const color = variant(textColorInput, {
  support: textColorSupport,
});

const borderColor = variants("status", {
  default: { default: "transparent" },
  valid: { default: success500 },
  invalid: { default: danger500 },
});

const borderColorFocus = variants("status", {
  default: { default: borderColorInputFocus },
  valid: { default: success500 },
  invalid: { default: danger500 },
});

const borderColorHover = variants("status", {
  default: { default: borderColorInputHover },
  valid: { default: success500 },
  invalid: { default: danger500 },
});

const backgroundColor = variants("filled", {
  default: { default: backgroundColorInput },
  filled: { default: backgroundColorInputFilled },
});

type WrapperProps = {
  isInputFocused: boolean;
  isInputDisabled: boolean;
  filled: "filled" | "default";
  readOnly?: boolean;
};
const Wrapper = styled.div<WrapperProps>`
  position: relative;
  background-color: ${backgroundColor};
  width: 100%;
  display: flex;
  border: 1px solid ${borderColor};
  border-color: ${({ isInputFocused }) =>
    isInputFocused ? borderColorFocus : borderColor};
  border-radius: ${borderRadiusInput};
  transition: border-color ${transitionDuration};
  overflow: hidden;
  opacity: ${({ isInputDisabled }) => (isInputDisabled ? 0.5 : 1)};
  height: ${heightInput + 2}px;

  &:hover {
    border-color: ${({ isInputFocused, isInputDisabled }) => {
      if (isInputDisabled) {
        return "none";
      } else if (isInputFocused) {
        return borderColorFocus;
      }

      return borderColorHover;
    }};
  }

  ${Td} & {
    top: 1px;
    border-radius: initial;
    border: none;
    background-color: transparent;
    padding-left: 0;
    transition: none;
    height: ${heightTableRow}px;
    border-top: 1px solid transparent;
    border-bottom: 2px solid transparent;
    outline: none;

    &:hover {
      border-bottom-color: ${borderColorTable};
    }

    &.is-focused {
      border-bottom-color: ${borderColorInputFocus} !important;
    }
  }

  ${({ readOnly }) =>
    readOnly &&
    css`
      pointer-events: none;
      background: transparent;
      padding: 0;
    `};
`;

const Input = styled.input<{ status: Status; readOnly?: boolean }>`
  -webkit-appearance: textfield;
  box-sizing: border-box;
  font-size: ${fontSizeInput};
  font-family: ${fontFamilySans};
  outline: none;
  background: none;
  border: none;
  color: ${color};
  height: 100%;
  line-height: ${heightInput}px;
  width: 100%;
  padding: 0 ${baseUnit}px;

  &:not(:first-child) {
    padding-left: 0;
  }

  ::placeholder {
    color: ${textColorPlaceholder};
  }

  ${Td} & {
    padding: 0;
    line-height: ${heightTableRow - 4}px;
    height: ${heightTableRow - 4}px;
  }

  ${({ readOnly }) =>
    readOnly &&
    css`
      padding: 0;
    `};
`;

const FixWrapper = styled.div<{ isInputFocused: boolean; readOnly?: boolean }>`
  ${fontStack};
  font-size: ${fontSizeInput};
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${heightInput}px;
  cursor: default;
  color: ${({ isInputFocused }) =>
    isInputFocused ? borderColorFocus : textColorDiscreet};
  padding-right: ${baseUnit}px;
  font-weight: normal !important;

  ${Td} &:last-child {
    padding-right: 0;
  }

  &:first-child {
    padding-right: ${baseUnit / 2}px;
    padding-left: ${({ readOnly }) => (readOnly ? 0 : `${baseUnit}px`)};

    ${Td} & {
      padding-left: 0;
    }
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

export type Props = {
  id?: string;
  value?: string | number;
  placeholder?: string;
  onChange?: (e: any) => void;
  onFocus?: (e: any) => void;
  onBlur?: (e: any) => void;
  onKeyUp?: (e: any) => void;
  autoFocus?: boolean;
  status?: Status;
  type?: "text" | "number" | "search" | "email" | "date";
  filled?: boolean;
  hint?: string | React.ReactElement | null;
  name?: string;
  required?: boolean;
  prefix?: any;
  postfix?: any;
  action?: any;
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  autoComplete?: "off" | "on";
  list?: string;
  defaultValue?: any;
  showAction?: boolean;
};

/**
 * The `TextField` component is used when we need a user to
 * provide some sort of info or to perform searches.
 */
const TextField = (
  {
    id,
    value,
    placeholder,
    onChange,
    onFocus,
    onBlur,
    onKeyUp,
    status = "default",
    type = "text",
    filled = false,
    hint,
    name,
    required,
    prefix,
    postfix,
    className,
    disabled,
    readOnly,
    action,
    showAction,
    ...rest
  }: Props,
  ref: any
) => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const classes = isInputFocused ? `${className} is-focused` : className;
  return (
    <Wrapper
      id={id}
      filled={filled ? "filled" : "default"}
      isInputDisabled={!!disabled}
      isInputFocused={isInputFocused}
      readOnly={readOnly}
      className={classes}
    >
      {(prefix || type === "search") && (
        <FixWrapper isInputFocused={isInputFocused} readOnly={readOnly}>
          {type === "search" ? <Search /> : prefix}
        </FixWrapper>
      )}
      <Input
        onFocus={(e: any) => {
          setIsInputFocused(true);
          onFocus && onFocus(e);
        }}
        onBlur={(e: any) => {
          setIsInputFocused(false);
          onBlur && onBlur(e);
        }}
        onKeyUp={onKeyUp}
        status={status}
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        ref={ref}
        name={name}
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        {...rest}
      />
      {postfix && (
        <FixWrapper isInputFocused={isInputFocused} readOnly={readOnly}>
          {postfix}
        </FixWrapper>
      )}
      {(!readOnly || showAction) && action && (
        <FixWrapper isInputFocused={isInputFocused} readOnly={readOnly}>
          {action}
        </FixWrapper>
      )}
      <TextFieldStatus status={status} hint={hint} />
    </Wrapper>
  );
};

export default React.forwardRef(TextField);
