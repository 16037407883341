import { Skeleton } from "~components/ContentLoader";
import { height } from "~components/copied_from_shared/pui/components/abstract/Action";

import Card, {
  CardContent,
  CardHeader,
  CardHeaderActions,
  CardHeaderTitle,
} from "./Card";

type Props = {
  children: any;
};
const CardFallback = ({ children }: Props) => (
  <Card
    onClick={() => {
      // do nothing
    }}
  >
    <CardHeader>
      <CardHeaderTitle>
        <Skeleton width="260px" />
      </CardHeaderTitle>
      <CardHeaderActions>
        <Skeleton width="100px" height={height} />
      </CardHeaderActions>
    </CardHeader>
    <CardContent>{children}</CardContent>
  </Card>
);

export default CardFallback;
