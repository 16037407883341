import * as React from "react";

import { AlertTriangle, Check, Loader } from "react-feather";
import { Button as ReakitButton } from "reakit";
import styled, { css } from "styled-components";

import { rotate } from "../../../mixins";
import { fontSmoothing } from "../../../mixins";
import {
  baseUnit,
  borderColor as borderColorToken,
  fontFamilySans,
  fontWeightNormal,
  primary500,
  primary600,
  textColor,
  textColorSupport,
  transitionDuration,
  white,
} from "../../../tokens";
import { variants } from "../../../utils";
import Tooltip from "../../Tooltip";

export type State = "loading" | "done" | "error" | null | "";

export const height = 30;
export const iconSize = 18;
export const borderWidth = 1;
export const paddingHorizontal = baseUnit / 1.5;

const color = variants("look", {
  default: { default: textColor, support: textColorSupport },
  primary: { default: white },
});

const backgroundColor = variants("look", {
  default: { default: white },
  primary: { default: primary500 },
});

const borderColor = variants("look", {
  default: { default: borderColorToken },
  primary: { default: primary600 },
});

type WrapperType = {
  $hasChildren: boolean;
  state?: State;
  disabled?: boolean;
  look: "default" | "primary";
};

const Wrapper = styled(ReakitButton)<WrapperType>`
  ${fontSmoothing};
  background-color: ${backgroundColor};
  color: ${color};
  border: 1px solid ${borderColor};
  align-items: center;
  display: inline-flex;
  justify-content: center;
  border-radius: 3px;
  font-weight: ${fontWeightNormal};
  user-select: none;
  position: relative;
  font-size: 14px;
  height: ${height}px;
  font-family: ${fontFamilySans};
  letter-spacing: 0.025rem;
  cursor: pointer;
  transition: box-shadow ${transitionDuration};
  /* avoid been sqeezed if appearing in a flex container */
  flex: initial !important;
  text-decoration: none;
  white-space: nowrap;
  padding: ${({ $hasChildren }) => {
    if ($hasChildren) {
      return `0 ${paddingHorizontal}px`;
    }

    return 0;
  }};
  width: ${({ $hasChildren }) => ($hasChildren ? "auto" : `${height}px`)};
  flex: ${({ $hasChildren }) => ($hasChildren ? "inherit" : `0 0 ${height}px`)};
  // Note! Actions may be wrapped in containers that have pointer-events: none
  // so we need to make sure it's still clickable
  pointer-events: ${({ state }) => (state ? "none" : "all")};

  ${({ state }) =>
    !!state &&
    css`
      color: transparent;
    `}

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  &:active {
    opacity: initial;
  }

  &:disabled {
    opacity: 0.5;
  }

  svg {
    stroke: ${color};
    vertical-align: middle;
    width: ${iconSize}px;
    height: ${iconSize}px;

    &:not(:only-child) {
      margin-right: ${baseUnit / 2}px;
    }
  }

  span {
    vertical-align: middle;
  }
`;

const StateWrapper = styled.span`
  color: ${color};
  position: absolute;
  top: 0;
  left: 0;
  height: ${height - 2 * borderWidth}px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Spinner = styled(Loader)`
  animation: ${rotate} 2s linear infinite;
`;

const Children = styled.span`
  line-height: 20px;
`;

type BaseProps = {
  Icon?: any;
  children?: React.ReactNode;
  tooltip?: any;
  onClick?: (e: any) => void;
  primary?: boolean;
  look?: "default" | "primary";
  type?: "submit" | "button" | "reset";
};

type ButtonProps = BaseProps & {
  state?: State;
  disabled?: boolean;
};

type LinkProps = BaseProps & {
  href: string;
  target?: string;
  download?: boolean;
};

export type Props = ButtonProps | LinkProps;

const Action = (props: Props, ref: any) => {
  const {
    children,
    Icon,
    tooltip,
    onClick,
    look = "default",
    primary,
    ...rest
  } = props;
  const hasChildren = !!children || children === 0;
  const actualLook = primary ? "primary" : look;

  const _onClick = (e: any) => {
    // We don't want the event bubbling up if the action is used
    // inside something else that is clickable. We've seen this
    // be a problem a few times
    e.stopPropagation();
    if (onClick) {
      onClick(e);
    }
  };

  if ("href" in props) {
    return (
      <Tooltip title={tooltip}>
        <Wrapper
          as="a"
          $hasChildren={hasChildren}
          ref={ref}
          onClick={_onClick}
          look={actualLook}
          {...rest}
        >
          {Icon && <Icon color={textColor} />}
          {hasChildren && <Children>{children}</Children>}
        </Wrapper>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={tooltip}>
        {/* The span is to allow tooltips for disabled buttons */}
        <span>
          <Wrapper
            type="button"
            $hasChildren={hasChildren}
            state={props.state}
            ref={ref}
            onClick={_onClick}
            look={actualLook}
            {...rest}
          >
            {!!props.state && (
              <StateWrapper>
                {props.state === "loading" && <Spinner size={iconSize} />}
                {props.state === "done" && <Check size={iconSize} />}
                {props.state === "error" && <AlertTriangle size={iconSize} />}
              </StateWrapper>
            )}
            {Icon && (
              <Icon
                style={{ visibility: props.state ? "hidden" : "visible" }}
                color={textColor}
              />
            )}
            {hasChildren && <Children>{children}</Children>}
          </Wrapper>
        </span>
      </Tooltip>
    );
  }
};

export default React.forwardRef(Action);
