import {
  boxShadowColorLevel1,
  boxShadowColorLevel2,
  boxShadowColorLevel3,
  boxShadowColorSecondary,
} from "./colors";

export const boxShadow = `0 1px 3px ${boxShadowColorLevel1}, 0 1px 2px ${boxShadowColorSecondary}`;
export const boxShadowLevel2 = `0 3px 6px ${boxShadowColorLevel2}, 0 3px 6px ${boxShadowColorSecondary}`;
export const boxShadowLevel3 = `0 10px 20px ${boxShadowColorLevel3}, 0 6px 6px ${boxShadowColorSecondary}`;
