import { Document as BEDocument } from "~services/documents";

export type PartialDocument = Pick<BEDocument, "documentId">;

export const toClient = (
  document: PartialDocument,
  filesApiEndpoint: string,
  isDownload?: boolean
): string => {
  const { documentId } = document;
  const url = encodeURI(`${filesApiEndpoint}/${documentId}`);
  return isDownload ? `${url}?download` : url;
};

export default { toClient };
