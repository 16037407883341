import {
  Card,
  CardContent as PUICardContent,
  CardEmpty as PUICardEmpty,
  CardEmptyDescription as PUICardEmptyDescription,
  CardHeader as PUICardHeader,
  CardHeaderAction as PUICardHeaderAction,
  CardHeaderActions as PUICardHeaderActions,
  CardHeaderTitle as PUICardHeaderTitle,
} from "~components/copied_from_shared/pui";

export const CardContent = PUICardContent;
export const CardEmpty = PUICardEmpty;
export const CardEmptyDescription = PUICardEmptyDescription;
export const CardHeader = PUICardHeader;
export const CardHeaderAction = PUICardHeaderAction;
export const CardHeaderActions = PUICardHeaderActions;
export const CardHeaderTitle = PUICardHeaderTitle;

export default Card;
