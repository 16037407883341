import { createContext } from "react";

import { Status } from "./types";

export type ContextType = {
  status: Status;
};

export const Context = createContext<ContextType>({
  status: "default",
});

export const Provider = Context.Provider;
