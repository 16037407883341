import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components";

export type Theme = "default" | "support";

type Props = {
  children: any;
  theme: Theme;
};
const Provider = ({ children, theme }: Props) => (
  <StyledComponentsThemeProvider theme={{ mode: theme }}>
    {children}
  </StyledComponentsThemeProvider>
);

export default Provider;
