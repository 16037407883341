import { forwardRef } from "react";

import AutoComplete from "./AutoComplete";
import useFuzzySearch from "./useFuzzySearch";

export type Props = {
  items: string[];
  onChange?: (value: string) => void;
  required?: boolean;
  placeholder?: string;
  defaultValue?: string;
};
const AutoCompleteContainer = (
  { items, onChange, ...rest }: Props,
  ref: any
) => {
  const { result, search } = useFuzzySearch(items.map((o) => ({ value: o })));
  return (
    <AutoComplete
      {...rest}
      ref={ref}
      suggestions={result.map((r) => r.value)}
      onChange={(value) => {
        search(value || "");
        onChange && onChange(value || "");
      }}
    />
  );
};

export default forwardRef(AutoCompleteContainer);
