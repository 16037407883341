import { t, Trans } from "@lingui/macro";

import { isEmpty } from "lodash";
import Image from "next/image";
import styled from "styled-components";

import { Identifier } from "~components";
import { formatDate } from "~components/copied_from_shared/date";
import {
  Action,
  baseUnit,
  borderColor,
  Paragraph,
} from "~components/copied_from_shared/pui";
import { Settings } from "~components/copied_from_shared/pui/icons";
import { Button } from "~components/tailwind/Button";
import { usePost } from "~hooks";
import { PropertiesService } from "~services/properties-service";
import { AddressLegacy } from "~utils";
import { getLeasePurpose } from "~utils/translation";

import Email from "./components/Email";
import Phone from "./components/Phone";
import { Item, Section } from "./components";
import { FeatureProps } from "./types";

import TalkPrimaryEmail from "~features/support/Clippy/components/TalkPrimaryEmail";

const Wrapper = styled.div`
  margin-bottom: ${baseUnit * 2}px;
`;

const Contact = styled.div`
  > *:not(:last-child) {
    margin-bottom: ${baseUnit / 2}px;
  }
`;

const NameAndID = styled.div`
  display: flex;
  margin-bottom: ${baseUnit / 2}px;

  > * {
    margin-left: ${baseUnit}px;
  }
`;

const Name = styled(Paragraph)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
`;

const BetalingsserviceActivatedBadgeWrapper = styled.div`
  margin-top: ${baseUnit}px;
  border: solid 1px ${borderColor};
  padding: ${baseUnit / 2}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
`;

export const ImageWrapper = styled.div`
  flex-shrink: 0;
  margin-right: 12px;
  line-height: 0;
`;

const BetalingsserviceActivatedBadge = ({
  validFrom,
}: {
  validFrom: string;
}) => {
  return (
    <BetalingsserviceActivatedBadgeWrapper>
      <ImageWrapper>
        <Image
          src="/images/betalingsservice_pos_RGB.png"
          alt="Betalingsservice"
          width={30}
          height={15}
        />
      </ImageWrapper>
      <Paragraph size="small">
        <Trans>Mandate is valid from {formatDate(validFrom)}</Trans>
      </Paragraph>
    </BetalingsserviceActivatedBadgeWrapper>
  );
};

export type Props = FeatureProps & {
  onGoToCompany: (companyId: string) => void;
};

const ClippyContentData = ({
  landlord,
  tenancy,
  lease,
  onGoToCompany,
  talkAccount,
}: Props) => {
  const address = tenancy
    ? new AddressLegacy(tenancy.address).formatAddress()
    : null;

  const [
    ,
    generateLeasePaymentDetails,
    { postStatus: generateLeasePaymentDetailsPostStatus },
  ] = usePost<
    PropertiesService.GenerateLeaseContractForPreviewCommand$Response,
    PropertiesService.GenerateLeaseContractForPreviewCommand$Request
  >({
    route: "/api/services/properties/leases/generate-lease-payment-details",
  });
  const isGeneratingLeasePaymentDetails =
    generateLeasePaymentDetailsPostStatus === "creating";

  const onGenerateLeasePaymentDetails = async (leaseId: string) => {
    await generateLeasePaymentDetails({ leaseId });
  };

  return (
    <div className="max-h-[90vh]">
      <Wrapper>
        <NameAndID>
          <Name>{landlord.name}</Name>
          <Identifier fontSize="13px">{landlord.id}</Identifier>
          <Action
            Icon={Settings}
            tooltip={t`Edit company`}
            onClick={() => onGoToCompany(landlord.id)}
          />
        </NameAndID>
        <Contact>
          <Phone value={landlord.phone} />
          <Email value={landlord.email} />
          <TalkPrimaryEmail value={talkAccount?.primaryEmail} />
        </Contact>
      </Wrapper>
      {lease && (
        <Section title={t`Primary tenant`}>
          <Item label={t`Name`}>
            {lease.primaryTenant?.name || t`Not specified`}
          </Item>
          <Item label={t`Email`}>
            {lease.primaryTenant?.email || t`Not specified`}
          </Item>
          <Item label={t`Phone`}>
            {lease.primaryTenant?.phone || t`Not specified`}
          </Item>
          <Item label={t`Language`}>
            {lease.primaryTenant?.language || t`Not specified`}
          </Item>
        </Section>
      )}
      {tenancy && (
        <Section title={t`Tenancy`}>
          <Item label={t`Address`}>{address}</Item>
        </Section>
      )}
      {lease && (
        <Section title={t`Bank account`}>
          <Item label={t`Registration number`}>
            {lease.bankAccount.regNumber}
          </Item>
          <Item label={t`Account number`}>
            {lease.bankAccount.accountNumber}
          </Item>
          <Item label={t`IBAN number`}>{lease.bankAccount.iban}</Item>
          <Item label={t`SWIFT/BIC`}>DABADKKK</Item>
          {/*Lack of payment details on a lease is indicated by setting bankAccount to {} according to src/transformers/lease/bankAccount.ts */}
          {isEmpty(lease.bankAccount) && (
            <Button
              theme="default"
              size="small"
              onClick={() => onGenerateLeasePaymentDetails(lease.id)}
              loading={isGeneratingLeasePaymentDetails}
              disabled={isGeneratingLeasePaymentDetails}
            >
              <Trans>Generate payment details</Trans>
            </Button>
          )}
        </Section>
      )}
      {lease?.betalingsserviceDetails && (
        <Section title={t`Betalingsservice`}>
          <Item label={t`PBS number`}>
            {lease.betalingsserviceDetails.pbsNumber}
          </Item>
          <Item label={t`Debtor group number`}>
            {lease.betalingsserviceDetails.debtorGroupNumber}
          </Item>
          <Item label={t`Customer number`}>
            {lease.betalingsserviceDetails.customerNumber}
          </Item>
          <Item
            label={t`Signup`}
            copyValue={lease.betalingsserviceDetails.customerSignUpUrl}
          >
            <a
              href={lease.betalingsserviceDetails.customerSignUpUrl}
              target="_blank"
              rel="noreferrer"
            >
              <Trans>Link to sign up form</Trans>
            </a>
          </Item>
          {lease.betalingsserviceDetails.mandateValidFrom && (
            <BetalingsserviceActivatedBadge
              validFrom={lease.betalingsserviceDetails.mandateValidFrom}
            />
          )}
        </Section>
      )}
      {lease && (
        <Section title={t`Lease details`}>
          <Item label={t`Alias`}>{lease.alias}</Item>
          <Item label={t`Id`}>{lease.id}</Item>
          <Item label={t`Purpose`}>{getLeasePurpose(lease.purpose)}</Item>
        </Section>
      )}
    </div>
  );
};

export default ClippyContentData;
