import { t } from "@lingui/macro";

export const email = {
  pattern: {
    value:
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/,
    message: t`Please provide a valid email address`,
  },
};
export const emailLocal = {
  pattern: {
    value: /^[a-zA-Z0-9][a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+$/,
    message: t`Please provide a valid email address`,
  },
};
export const street = {
  pattern: {
    value:
      /^(?:[a-zA-ZæøåÆØÅ]+[ '.-]*)+\s\d+[a-zA-Z]?(,?(\s(\d+|[a-zA-Z]+).?)*)*$/,
    message: t`Please provide a valid street address`,
  },
};
export const streetNameDK = {
  pattern: {
    value: /^([a-zA-ZæøåÆØÅ]+[ '.-]*)+$/,
    message: t`Please provide a valid street address`,
  },
};
export const streetNameNL = {
  pattern: {
    value: /^[^,]+$/,
    message: t`Please provide a valid street address`,
  },
};
export const streetNumberDK = {
  pattern: {
    value: /^\d+[a-zA-ZæøåÆØÅ]?(-\d+)?$/,
    message: t`Please provide a valid street number`,
  },
};
export const streetNumberNL = {
  pattern: {
    value: /^[^,]+$/,
    message: t`Please provide a valid street number`,
  },
};
export const noComma = {
  pattern: {
    value: /^[^,]+$/,
    message: t`Please provide a valid door designation`,
  },
};
export const doorDK = {
  pattern: {
    value: /^(mf|tv|th|\d+)\.?$/,
    message: t`Please provide a valid door designation`,
  },
};
export const doorNL = {
  pattern: {
    value: /^[^,]+$/,
    message: t`Please provide a valid door designation`,
  },
};

export const doorDE = {
  pattern: {
    value: /^[^,]+$/,
    message: t`Please provide a valid door designation`,
  },
};

export const floorDK = {
  pattern: {
    value: /^(baghuset|mezz|mezzanin|parterre|st|kl|\d+)\.?$/,
    message: t`Please provide a valid floor designation`,
  },
};
export const floorNL = {
  pattern: {
    value: /^[^,]+$/,
    message: t`Please provide a valid floor designation`,
  },
};

export const floorDE = {
  pattern: {
    value: /^[^,]+$/,
    message: t`Please provide a valid floor designation`,
  },
};

export const suite = {
  pattern: {
    value: /^[^,]+$/,
    message: t`Please provide a valid designation`,
  },
};

export const postalDk = {
  pattern: {
    value: /^\d+$/,
    message: t`Please provide a valid postal code`,
  },
};
export const postalNL = {
  pattern: {
    value: /^\d{4}\s?[a-zA-Z]{2}$/,
    message: t`Please provide a valid postal code`,
  },
};
export const postalDE = {
  pattern: {
    value: /^\d{5}$/,
    message: t`Please provide a valid postal code`,
  },
};

export const city = {
  pattern: {
    value: /^[^,]+$/,
    message: t`Please provide a valid city`,
  },
};

export const phone = {
  pattern: {
    value: /^\+(?:[0-9]●?){6,17}[0-9]$/,
    message: t`Please provide an international phone number (e.g. +4512345678)`,
  },
};

export const country = {
  pattern: {
    value: "DK",
    message: t`A valid Danish VAT is exactly 8 digits`,
  },
};

export const percentage = {
  min: {
    value: 0,
    message: t`Please specify a number between 0 and 100`,
  },
  max: {
    value: 100,
    message: t`Please specify a number between 0 and 100`,
  },
};

export const year = {
  min: {
    value: 0,
    message: t`Please specify a valid year`,
  },
  max: {
    value: 9999,
    message: t`Please specify a valid year`,
  },
};

export const excludeComma = {
  pattern: {
    value: /^[^,]+$/,
    message: t`Must not contain comma`,
  },
};

export type ValidationType =
  | "email"
  | "phone"
  | "postalCode"
  | "country"
  | "percentage"
  | "floorDK"
  | "doorDK"
  | "floorNL"
  | "doorNL"
  | "floorDE"
  | "doorDE"
  | "suite"
  | "year";

const validationSchema = {
  email,
  phone,
  country,
  percentage,
  floorDK,
  doorDK,
  floorNL,
  doorNL,
  floorDE,
  doorDE,
  suite,
  year,
} as {
  [key: string]: Record<string, unknown>;
};

export default validationSchema;
