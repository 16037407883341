import * as React from "react";

import { ChevronDown } from "react-feather";
import styled from "styled-components";

import { fontSmoothing } from "../../mixins";
import {
  borderColor,
  fontFamilySans,
  fontWeightNormal,
  textColor,
  transitionDuration,
  white,
} from "../../tokens";
import { height, iconSize, paddingHorizontal } from "../abstract/Action";

const Wrapper = styled.div`
  position: relative;
`;

const StyledSelect = styled.select`
  ${fontSmoothing};
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  font-weight: ${fontWeightNormal};
  user-select: none;
  position: relative;
  font-size: 14px;
  height: ${height}px;
  font-family: ${fontFamilySans};
  background-color: ${white};
  border: 1px solid ${borderColor};
  color: ${textColor};
  letter-spacing: 0.025rem;
  cursor: pointer;
  transition: box-shadow ${transitionDuration};
  /* avoid been sqeezed if appearing in a flex container */
  flex: initial !important;
  display: block;
  padding: 0 ${paddingHorizontal + iconSize}px 0 ${paddingHorizontal}px;
  width: 100%;
  max-width: 100%;
  margin: 0;
  box-sizing: border-box;

  option {
    font-weight: normal;
  }

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  &:active {
    opacity: initial;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &::-ms-expand {
    display: none;
  }
`;

const ArrowWrapper = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  height: ${height}px;
  width: ${height}px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

type Props = {
  defaultValue?: string;
  children: React.ReactNode;
  value?: string | number;
  onChange?: (e: any) => void;
  id?: string;
};

/**
 * The `Select` component displays a long list of options in
 * a drop down menu that users can scroll through before they
 * make a selection.
 */
const Select = (
  { children, value, onChange, id, defaultValue, ...rest }: Props,
  ref: any
) => (
  <Wrapper>
    <StyledSelect
      id={id}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
      ref={ref}
      {...rest}
    >
      {children}
    </StyledSelect>
    <ArrowWrapper>
      <ChevronDown color={textColor} size={iconSize} />
    </ArrowWrapper>
  </Wrapper>
);

export default React.forwardRef(Select);
