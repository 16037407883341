import { Trans } from "@lingui/macro";

import { Link, Paragraph } from "~components/copied_from_shared/pui";
import { Mail } from "~components/copied_from_shared/pui/icons";

import { IconWrapper, Wrapper } from "./Phone";

type Props = {
  value?: string;
};
const Email = ({ value }: Props) => (
  <Wrapper>
    <IconWrapper>
      <Mail />
    </IconWrapper>
    {value ? (
      <Link href={`mailto:${value}`}>{value}</Link>
    ) : (
      <Paragraph size="small">
        <Trans>Not specified</Trans>
      </Paragraph>
    )}
  </Wrapper>
);

export default Email;
