import styled from "styled-components";

import {
  baseUnit,
  fontSizeInput,
  fontStack,
  gray300,
  primary500,
} from "~components/copied_from_shared/pui";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Inner = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  cursor: pointer;
`;

const Checkbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const Circle = styled.span<{ checked: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ checked }) => (checked ? primary500 : gray300)};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &::before {
    border-radius: 50%;
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 7px;
    bottom: 5px;
    background-color: white;
    transition: 0.4s;
  }

  input:checked + &::before {
    transform: translateX(25px);
  }
`;

const Text = styled.div<{ selected: boolean }>`
  ${fontStack};
  color: ${({ selected }) => (selected ? primary500 : gray300)};
  font-weight: bold;
  font-size: ${fontSizeInput};
  margin-right: ${baseUnit}px;
`;

type Props = {
  checked: boolean;
  onChange: () => void;
  label: string;
};
const Toggle = ({ checked, onChange, label }: Props) => (
  <Wrapper>
    <Text selected={checked}>{label}</Text>
    <Inner>
      <Checkbox type="checkbox" checked={checked} onChange={onChange} />
      <Circle checked={checked} />
    </Inner>
  </Wrapper>
);

export default Toggle;
