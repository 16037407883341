import { Document as BEDocument } from "~services/documents";
import { DocumentSummary } from "~types/lease/document";

import baseNameAndExtension from "./baseNameAndExtension";
import documentType from "./documentType";
import url from "./url";

export const toClient = (
  documents: BEDocument[],
  filesApiEndpoint: string
): DocumentSummary[] => {
  return documents.map((document) => {
    const { extension } = baseNameAndExtension.toClient(document.fileName);
    return {
      documentId: document.documentId,
      documentType: documentType.toClient(document.documentType),
      sizeInBytes: document.sizeInBytes,
      fileName: document.fileName,
      createdAt: document.createdAt,
      url: url.toClient(document, filesApiEndpoint),
      downloadUrl: url.toClient(document, filesApiEndpoint, true),
      visibility: document.visibility,
      extension,
    };
  });
};

export default { toClient };
