import * as React from "react";

import {
  Tooltip as ReakitTooltip,
  TooltipReference,
  useTooltipState,
} from "reakit/Tooltip";
import styled from "styled-components";

import { baseUnit, fontFamilySans } from "../../tokens";

const PuiTooltip = styled(ReakitTooltip)`
  background-color: rgba(0, 0, 0, 0.85);
  color: white;
  font-size: 13px;
  padding: ${baseUnit / 3}px ${baseUnit}px;
  border-radius: 3px;
  transform-origin: center center;
  z-index: 100000;
  font-family: ${fontFamilySans};
  max-width: 320px;
  text-align: center;
`;

type Props = {
  title?: string | React.ReactElement | null;
  children: any;
};
function Tooltip({ children, title, ...props }: Props) {
  if (!title) {
    return children;
  }

  const tooltip = useTooltipState({ gutter: baseUnit / 2 });
  return (
    <>
      <TooltipReference {...tooltip} {...children.props}>
        {(referenceProps) => React.cloneElement(children, referenceProps)}
      </TooltipReference>
      <PuiTooltip {...tooltip} {...props}>
        {title}
      </PuiTooltip>
    </>
  );
}

export default Tooltip;
