import { useContext } from "react";

import { Context } from "./context";

const useFeatureToggler = () => {
  const props = useContext(Context);
  const featureKeys = Object.keys(props.features);
  const numFeaturesEnabled = Object.keys(props.enabledFeatures).filter(
    (key) => {
      const featureExists = featureKeys.find((k) => k === key);
      const isEnabled = props.enabledFeatures[key];
      return isEnabled && featureExists;
    }
  ).length;
  return { ...props, numFeaturesEnabled };
};

export default useFeatureToggler;
