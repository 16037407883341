import { t } from "@lingui/macro";

import { FormField as PUIFormField } from "~components/copied_from_shared/pui";

import { useFormContext } from "./Form";
import validationSchema, { ValidationType } from "./validationSchema";

export type Props = React.ComponentProps<typeof PUIFormField> & {
  validationType?: ValidationType;
  // FIXME: I wanted to allow `null` here, but cannot understand
  // that TypeScript wants...
  defaultValue?: any;
};

const FieldCore = ({
  required,
  validationType,
  validation,
  placeholder,
  defaultValue,
  type,
  ...rest
}: Props) => {
  const { readOnly } = useFormContext();

  let validationObject;
  if (validationType) {
    validationObject = validationSchema[validationType] || {};
  } else {
    validationObject = validation;
  }

  return (
    <PUIFormField
      validation={{
        required: required ? t`This field is required` : null,
        ...validationObject,
      }}
      required={required}
      placeholder={readOnly ? t`Not specified` : placeholder}
      defaultValue={defaultValue}
      type={type}
      {...rest}
    />
  );
};

export default FieldCore;
