import da from "date-fns/locale/da";
import de from "date-fns/locale/de";
import enGB from "date-fns/locale/en-GB";
import nl from "date-fns/locale/nl";

const getLocaleFromLanguage = (language: "da" | "nl" | "en" | "de"): Locale => {
  if (language === "da") return da;
  if (language === "nl") return nl;
  if (language === "de") return de;
  return enGB;
};

export default getLocaleFromLanguage;
