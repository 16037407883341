import styled from "styled-components";

import { fontSmoothing } from "../../mixins";
import {
  fontFamilySans,
  textColor,
  textColorDiscreet,
  textColorLink,
  textColorNegative,
  textColorPositive,
  textColorSupport,
  textColorSupportDiscreet,
  textColorWarning,
} from "../../tokens";
import { variants } from "../../utils";
import { linkCss } from "../Link/Link";

export type Look = "default" | "discreet" | "negative" | "positive" | "warning";

const look = variants("look", {
  default: { default: textColor, support: textColorSupport },
  discreet: { default: textColorDiscreet, support: textColorSupportDiscreet },
  negative: { default: textColorNegative },
  positive: { default: textColorPositive },
  warning: { default: textColorWarning },
});

const fontSize = variants("size", {
  tiny: { default: "12px" },
  small: { default: "14px" },
  default: { default: "16px" },
  large: { default: "18px" },
  huge: { default: "20px" },
});

type WrapperType = {
  size: string;
  look: string;
};

const Wrapper = styled.p<WrapperType>`
  ${fontSmoothing};
  color: ${look};
  font-size: ${fontSize};
  font-family: ${fontFamilySans};
  font-weight: 400;
  line-height: 1.5;
  margin: 0;

  em {
    font-style: italic;
  }

  a {
    ${linkCss};
  }

  small {
    font-size: ${fontSize};
    color: ${textColorDiscreet};
  }

  button {
    display: contents;
    font-size: inherit;
    font-family: ${fontFamilySans};
    padding: 0;
    border: none;
    background: none;
    text-decoration: underline;
    cursor: pointer;
    color: ${textColorLink};
  }
`;

type Props = {
  children: any;
  size?: "default" | "large" | "huge" | "small" | "tiny";
  tag?: string;
  look?: "default" | "discreet" | "negative" | "positive" | "warning";
};

/**
 * Use the `Paragraph` component for a distinct section of a piece
 * of writing. Usually dealing with a single theme and indicated
 * by a new line, indentation, or numbering.
 */
const Paragraph = ({
  children,
  size = "default",
  tag,
  look = "default",
  ...rest
}: Props) => (
  <Wrapper as={tag as any} size={size} look={look} {...rest}>
    {children}
  </Wrapper>
);

export default Paragraph;
