import NumberFormat, { NumberFormatProps } from "react-number-format";

import { TextField } from "~components/copied_from_shared/pui";
import { useUser } from "~hooks";
import { moneyFromNumber } from "~utils/money";

import FieldCore, { Props as FieldCoreProps } from "../FieldCore";

type SupportedCurrency = "DKK" | "EUR";

type CurrencyFieldProps = {
  currency: SupportedCurrency;
};
const CurrencyField = ({ currency, ...rest }: CurrencyFieldProps) => {
  return <TextField placeholder="0.00" prefix={currency} {...rest} />;
};

type NumberFormatFieldProps = Omit<NumberFormatProps, "placeholder"> & {
  currency: SupportedCurrency;
};
export const NumberFormatField = ({
  currency,
  ...rest
}: NumberFormatFieldProps) => (
  <NumberFormat
    {...rest}
    currency={currency}
    customInput={CurrencyField}
    isNumericString
    thousandSeparator={"."}
    decimalSeparator={","}
    decimalScale={2}
    fixedDecimalScale
    placeholder="0,00"
  />
);

type InputProps = FieldCoreProps & {
  onChange: (amount: string) => void;
  value?: number;
  currency?: SupportedCurrency;
};
export const Input = ({ onChange, value, currency, ...rest }: InputProps) => {
  const { viewAsUser } = useUser();
  const currencyOrOwnerCurrency: SupportedCurrency =
    currency ?? viewAsUser.ownerCurrency;
  const numberFormatValue = value
    ? moneyFromNumber(value, currencyOrOwnerCurrency).getValue()
    : undefined;
  return (
    <NumberFormatField
      currency={currencyOrOwnerCurrency}
      value={numberFormatValue}
      onValueChange={(target: any) => {
        const value = target.floatValue || 0;
        const amount = moneyFromNumber(
          value,
          currencyOrOwnerCurrency
        ).getIsoFormat() as string;
        onChange(amount);
      }}
      {...rest}
    />
  );
};

const DeprecatedInput = ({
  onChange,
  value,
  currency,
  ...rest
}: InputProps) => {
  const { viewAsUser } = useUser();
  const currencyOrOwnerCurrency: SupportedCurrency =
    currency ?? viewAsUser.ownerCurrency;
  const numberFormatValue = value
    ? moneyFromNumber(value, currencyOrOwnerCurrency).getValue()
    : undefined;
  return (
    <NumberFormatField
      currency={currencyOrOwnerCurrency}
      value={numberFormatValue}
      onValueChange={(target: any) => {
        const value = target.floatValue || 0;
        const amount = moneyFromNumber(
          value,
          currencyOrOwnerCurrency
        )._deprecated_getIsoFormat() as string;
        onChange(amount);
      }}
      {...rest}
    />
  );
};

export type Props = Omit<FieldCoreProps, "type" | "as"> & {
  currency?: SupportedCurrency;
};

const Money = (props: Props) => {
  return <FieldCore {...props} as={Input} />;
};

/**
 * @deprecated use <Money/> component instead
 */
export const DeprecatedMoney = (props: Props) => {
  return <FieldCore {...props} as={DeprecatedInput} />;
};

export default Money;
