import { forwardRef } from "react";

import TextField from "../TextField";

export type Props = {
  required?: boolean;
  onChange?: (date: string) => void;
};

const DatePicker = ({ onChange, ...rest }: Props, ref: any) => (
  <TextField
    {...rest}
    onChange={(e: any) => {
      onChange && onChange(e.target.value);
    }}
    placeholder="yyyy-mm-dd"
    type="date"
    ref={ref}
  />
);

export default forwardRef(DatePicker);
