import { t } from "@lingui/macro";

import styled from "styled-components";

import {
  support400,
  teal700,
  TextField,
} from "~components/copied_from_shared/pui";

const SupportTextfield = styled(TextField)<{
  isPaymentsCustomer: boolean;
}>`
  background: ${(props) => (props.isPaymentsCustomer ? teal700 : support400)};
  width: 240px;
  border: none;

  * {
    color: white;
    stroke: white;

    &::placeholder {
      color: white;
    }
  }
`;

function Search({
  onSearch,
  isPaymentsCustomer,
}: {
  onSearch: (query: string) => void;
  isPaymentsCustomer: boolean;
}) {
  const onKeyUp = (e: any) => {
    if (e.keyCode === 13) {
      onSearch(e.target.value.trim());
    }
  };

  return (
    <SupportTextfield
      type="search"
      placeholder={`${t`Search all leases`}...`}
      onKeyUp={onKeyUp}
      isPaymentsCustomer={isPaymentsCustomer}
    />
  );
}

export default Search;
