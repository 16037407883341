import styled from "styled-components";

import {
  Action,
  baseUnit,
  Card,
  CardContent,
  CardHeader,
  CardHeaderTitle,
  Paragraph,
} from "~components/copied_from_shared/pui";
import { Info } from "~components/copied_from_shared/pui/icons";

export const ActionsRequiredDescription = styled(Paragraph)`
  max-width: 540px;
`;

export const ActionsRequiredList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: ${baseUnit * 1.5}px 0 0 0;
`;

const Li = styled.li`
  &:not(:last-child) {
    margin-bottom: ${baseUnit}px;
  }
`;

type ActionItemProps = {
  children: any;
  onClick: () => void;
  Icon: any;
};
export const ActionsRequiredListItem = ({
  children,
  onClick,
  Icon,
}: ActionItemProps) => (
  <Li>
    <Action Icon={Icon} onClick={onClick}>
      {children}
    </Action>
  </Li>
);

type Props = {
  children: any;
  title: string;
};

const ActionsRequired = ({ children, title }: Props) => {
  return (
    <Card>
      <CardHeader>
        <CardHeaderTitle Icon={Info}>{title}</CardHeaderTitle>
      </CardHeader>
      <CardContent>{children}</CardContent>
    </Card>
  );
};

ActionsRequired.List = ActionsRequiredList;
ActionsRequired.ListItem = ActionsRequiredListItem;
ActionsRequired.Description = ActionsRequiredDescription;

export default ActionsRequired;
