import { useContext } from "react";

import { Context } from "~contexts/user";
import { Landlord, User } from "~types";

const getLocaleFromOwnerCountry = (countryCode: string) => {
  if (countryCode === "DK") {
    return "da";
  }
  if (countryCode === "DE") {
    return "de";
  }
  if (countryCode === "NL") {
    return "nl";
  }

  throw new Error("Unknown country code");
};

// For easy of use, we extract the details about a company that the
// user is "viewing as" here. This, as it's common to use this
// information throughout the codebase.
const getViewAsUser = (
  user: User,
  viewAs: string | undefined | null
): Landlord => {
  const userAsLandlord: Landlord = {
    id: user.id,
    name: user.name,
    phone: user.phone,
    email: user.email,
    language: user.language,
    roles: user.roles,
    // Note, this will never happen, as a user who
    // can "view as themselves" (are owners)
    // always have these properties. This is made
    // complicated as users and companies are currently
    // the same in our domain model
    ownerCountry: user.ownerCountry || "DK",
    ownerCurrency: user.ownerCurrency || "DKK",
    localeFromOwnerCountry: getLocaleFromOwnerCountry(
      user.ownerCountry || "DK"
    ),
    tenantFacingCommunicationDetails: user.tenantFacingCommunicationDetails,
    customerType: user.customerType,
  };

  if (viewAs) {
    const company = user.canViewAs.find((landlord) => landlord.id === viewAs);
    // In the odd case that a user can no longer view as a company, we just
    // return the user.
    if (!company) {
      return userAsLandlord;
    }

    return {
      ...company,
      localeFromOwnerCountry: getLocaleFromOwnerCountry(company.ownerCountry),
    };
  }

  return userAsLandlord;
};

type FeatureName = "default-notices" | "e-conomic" | "termination-of-tenancy";
const hasFeature = (viewAsUser: Landlord, featureName: FeatureName) => {
  if (featureName === "default-notices") {
    return viewAsUser.ownerCountry === "DK" || viewAsUser.ownerCountry === "DE";
  } else if (featureName === "e-conomic") {
    return (
      viewAsUser.ownerCountry === "DK" || viewAsUser.id === "3du2rlhfvyfktmjm"
    );
  } else if (featureName === "termination-of-tenancy") {
    return viewAsUser.ownerCountry === "DK";
  }

  return true;
};

// the `seeWhatLandlordSees` flag can be toggled on and off by
// support users to allow getting the same experience as the
// landlord would. This is only a display setting, which is
// why we don't keep this in the Provider.
const getRoles = (user: User, seeWhatLandlordSees: boolean) => {
  if (seeWhatLandlordSees) {
    return user.roles.filter((rol) => !rol.match("proper."));
  }

  return user.roles;
};

const useUser = () => {
  const {
    user,
    viewAs,
    seeWhatLandlordSees,
    setSeeWhatLandlordSees,
    setViewAs,
  } = useContext(Context);

  const roles = getRoles(user, seeWhatLandlordSees);
  const authUser = { ...user, roles };
  const viewAsUser = getViewAsUser(user, viewAs);

  return {
    setViewAs,
    setSeeWhatLandlordSees,
    seeWhatLandlordSees,
    viewAs,
    viewAsUser: {
      ...viewAsUser,
      hasFeature: (featureName: FeatureName) =>
        hasFeature(viewAsUser, featureName),
    },
    customerType: viewAsUser.customerType,
    isPaymentsCustomer: viewAsUser.customerType === "PaymentsCustomer",
    isPropertiesCustomer: viewAsUser.customerType === "PropertiesCustomer",
    authUser,
    isSupport: roles.includes("proper.support"),
    isLandlord: roles.includes("landlord"),
    isBeta: roles.includes("proper.beta"),
    isBilling: roles.includes("proper.billing"),
    isOwner: roles.includes("owner"),
  };
};

export default useUser;
