import styled from "styled-components";

import {
  Form as PUIForm,
  useForm as usePUIForm,
  useFormContext as usePUIFormContext,
  useFormFieldArray as usePUIFormFieldArray,
} from "~components/copied_from_shared/pui";
import { useExperimentalWarnIfUnsavedChanges } from "~hooks";

const Form = (props: any) => {
  useExperimentalWarnIfUnsavedChanges(props.formState.dirty);
  return <PUIForm spaced {...props} />;
};

export const FormActions = styled.div`
  margin-top: 20px;
  display: flex;

  > *:not(:last-child) {
    margin-right: 20px;
  }
`;

export const useFieldArray = usePUIFormFieldArray;
export const useFormContext = usePUIFormContext;
export const useForm = usePUIForm;
export default Form;
