import { t } from "@lingui/macro";

import { useEffect, useState } from "react";

import { PostStatus } from "~hooks/usePost";
import { fetch, reportError } from "~utils";

import useNotifications from "./useNotifications";

const dawaEndpoint = "https://dawa.aws.dk/adresser/autocomplete";

type NewTenancy = {
  address: string;
};

export type Props = {
  onCreate: (address: NewTenancy) => void;
  status: PostStatus;
};

const getAddressSuggestions = async (query: string) => {
  const res = await fetch(`${dawaEndpoint}?q=${query}&per_side=8`);
  return res.map((r: any) => r.tekst);
};

export type Status = "idle" | "loading" | "error";
const useAddressSuggestions = () => {
  const notifications = useNotifications();
  const [status, setStatus] = useState<Status>("idle");
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (query === "") {
      return;
    }

    setStatus("loading");
    const getSuggestions = async (searchQuery: string) => {
      try {
        const newSuggestions = await getAddressSuggestions(searchQuery);
        setSuggestions(newSuggestions);
        setStatus("idle");
      } catch (err: any) {
        reportError(err);
        setStatus("error");
        notifications.push({
          text: t`Failed to get address suggestions`,
          type: "error",
        });
        console.error("Failed to get address suggestions");
      }
    };

    getSuggestions(query);
  }, [query]);

  return [suggestions, { search: setQuery, searchStatus: status }] as const;
};

export default useAddressSuggestions;
