type ElementName =
  | "popout"
  | "dashboard-filters"
  | "notifications"
  | "overlay"
  | "header"
  | "clippy"
  | "loading-overlay";

const elementNames = [
  "popout",
  "dashboard-filters",
  "header",
  "overlay",
  "loading-overlay",
  "clippy",
  "notifications",
];

const getElementStackOrder = (elementName: ElementName): number => {
  return elementNames.indexOf(elementName) + 1;
};

export default getElementStackOrder;
