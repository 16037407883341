import styled from "styled-components";

import { HelpCircle } from "../../../icons";
import { info500 } from "../../../tokens";
import Tooltip from "../../Tooltip";

const HelpIcon = styled(HelpCircle)`
  width: 18px;
  height: 18px;
  margin-left: 4px;
  color: ${info500};
  pointer-events: all;
`;

type Props = {
  text: string;
};
const FormElementHelp = ({ text }: Props) => (
  <Tooltip title={text}>
    <HelpIcon />
  </Tooltip>
);

export default FormElementHelp;
