import { useUser } from "~hooks";
import { Role } from "~types";

type Props = {
  value: Role;
  children: any;
};
const RoleRequired = ({ value, children }: Props) => {
  const { authUser } = useUser();

  if (!authUser?.roles.includes(value)) {
    return null;
  }

  return children;
};

export default RoleRequired;
