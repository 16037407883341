import styled from "styled-components";

import {
  baseUnit,
  blue100,
  fontStack,
  fontWeightBold,
  pampas100,
  support500,
  textColor,
  white,
} from "~components/copied_from_shared/pui";
import { X as Close } from "~components/copied_from_shared/pui/icons";
import { Wrapper as Identifier } from "~components/Identifier";

export const Wrapper = styled.div`
  position: relative;
`;

export const Ul = styled.ul`
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: absolute;
  background: ${white};
  list-style-type: none;
  min-width: 240px;
  top: calc(100% + 3px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  z-index: 9999;
  left: 0;
`;

type LiProps = {
  isHighlighted: boolean;
  isSelected: boolean;
};
export const Li = styled.li<LiProps>`
  background-color: ${({ isHighlighted, isSelected }: any) => {
    if (isSelected) {
      return blue100;
    } else if (isHighlighted) {
      return pampas100;
    }
    return "initial";
  }};
  color: ${textColor};
  padding: 10px 12px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;

  strong {
    color: ${support500};
  }
`;

export const Text = styled.span`
  ${fontStack};
  font-size: 15px;
  font-weight: ${fontWeightBold};
  margin-right: ${baseUnit}px;
`;

export const Id = styled(Identifier)`
  margin-left: auto;
`;

export const ClearSelectionButton = styled(Close as any)`
  position: absolute;
  top: calc(50% - 9px);
  right: 12px;
  border: 1px solid white;
  color: white;
  opacity: 0.4;
  border-radius: 50%;
  width: 18px !important;
  height: 18px !important;
  padding: 1px;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;
