import { createContext } from "react";

export type SortDirection = "asc" | "desc";
export type ContextType = {
  sortBy?: string;
  onSortBy: (value: string) => void;
  sortDirection?: string;
};

export const Context = createContext<ContextType>({
  onSortBy: () => {
    // do nothing
  },
});

export const Provider = Context.Provider;
