import { IconContext } from "phosphor-react";

import Apollo from "~components/connected/app/Providers/Apollo";
import ProductsProvider from "~components/connected/app/Providers/ProductsProvider";
import ZendeskWidget from "~components/connected/app/Providers/ZendeskWidget";
import { Provider as PuiProvider } from "~components/copied_from_shared/pui";

import Authenticated from "./Authenticated";
import FeatureToggle from "./FeatureToggle";
import GlobalStyle from "./GlobalStyle";
import Hotjar from "./Hotjar";
import I18n from "./I18n";
import Notices from "./Notices";
import Notifications from "./Notifications";
import Reports from "./Reports";
import RouteTracking from "./RouteTracking";
import Uploads from "./Uploads";
import User from "./User";

const Providers = ({ children }: any) => (
  <Apollo>
    <PuiProvider>
      <IconContext.Provider
        value={{
          weight: "regular",
        }}
      >
        <GlobalStyle />
        <Authenticated>
          <User>
            <Hotjar />
            <RouteTracking />
            <I18n>
              <FeatureToggle>
                <ZendeskWidget />
                <Notifications>
                  <Reports>
                    <Notices>
                      <Uploads>
                        <ProductsProvider>{children}</ProductsProvider>
                      </Uploads>
                    </Notices>
                  </Reports>
                </Notifications>
              </FeatureToggle>
            </I18n>
          </User>
        </Authenticated>
      </IconContext.Provider>
    </PuiProvider>
  </Apollo>
);

export default Providers;
