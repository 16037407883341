import { useState } from "react";

import { useNotices } from "~hooks";

function useNoticesNotification() {
  const [notices] = useNotices();
  const [dismissedIds, setDimissedIds] = useState<string[]>([]);
  const [wasDismissed, setWasDismissed] = useState(false);
  const noticesToShow = notices.filter(({ id }) => !dismissedIds.includes(id));

  const onDismiss = () => {
    const ids = noticesToShow.map(({ id }) => id);
    setDimissedIds([...dismissedIds, ...ids]);
    setWasDismissed(true);
  };

  return [noticesToShow, { onDismiss, wasDismissed }] as const;
}

export default useNoticesNotification;
