import { getDateFromLocalDate } from "~utils/getDateFromLocalDate";

export type Interval = {
  start: Date;
  end: Date;
};
const getIntervalFromLocalDateRange = (localDateRange: string): Interval => {
  const [start, end] = localDateRange.split("/");
  return {
    start: getDateFromLocalDate(start),
    end: getDateFromLocalDate(end),
  };
};

export default getIntervalFromLocalDateRange;
