import * as React from "react";

import { ChevronDown } from "react-feather";
import styled, { css } from "styled-components";

import {
  backgroundColorInput,
  baseUnit,
  black,
  borderColorInput,
  borderColorInputFocus,
  borderColorInputHover,
  borderRadiusInput,
  fontFamilySans,
  heightInput,
  iconSize,
  textColor,
  textColorPlaceholder,
  white,
} from "../../../tokens";
import { fontSizeInput } from "../../../tokens/typography";
import {
  borderColor as borderColorTable,
  heightRow as heightTableRow,
  Td,
} from "../../Table";
import { Status } from "../TextField";
import TextFieldStatus from "../TextField/TextFieldStatus";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledSelect = styled.select<{ filled: boolean; readOnly?: boolean }>`
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  display: block;
  font-size: ${fontSizeInput};
  font-family: ${fontFamilySans};
  font-weight: 400;
  color: ${textColor};
  line-height: ${heightInput + 2}px;
  height: ${heightInput + 2}px;
  padding: 0 ${heightInput + 2}px 0 ${baseUnit}px;
  width: 100%;
  max-width: 100%;
  margin: 0;
  border: 1px solid ${borderColorInput};
  border-radius: ${borderRadiusInput};
  appearance: none;
  background-color: ${({ filled }) => (filled ? white : backgroundColorInput)};
  box-sizing: border-box;

  &:invalid {
    color: ${textColorPlaceholder};
  }

  &:hover {
    border-color: ${borderColorInputHover};
  }

  &::-ms-expand {
    display: none;
  }

  &:focus {
    border-color: ${borderColorInputFocus};
  }

  &:disabled {
    pointer-events: none;
  }

  option {
    font-weight: normal;
  }

  ${Td} & {
    top: 1px;
    position: relative;
    border-radius: initial;
    border: none;
    background-color: transparent;
    padding: 0;
    transition: none;
    height: ${heightTableRow}px;
    border-top: 1px solid transparent;
    border-bottom: 2px solid transparent;

    &:hover {
      border-bottom: 2px solid ${borderColorTable};
    }

    &:focus,
    &:active {
      outline: none;
      border-bottom: 2px solid ${borderColorInputFocus};
    }
  }

  ${({ readOnly }) =>
    readOnly &&
    css`
      pointer-events: none;
      background: transparent;
      padding: 0;
      border: none;
      border-radius: 0;
    `};
`;

const ArrowWrapper = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  height: ${heightInput + 2}px;
  padding: 0 ${baseUnit}px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  > *:not(:first-child) {
    margin-left: ${baseUnit / 1.5}px;
  }

  ${Td} & {
    top: 1px;
    padding-right: 0;
  }
`;

export type Props = {
  children?: React.ReactNode;
  value?: string | number;
  onChange?: (e: any) => void;
  name?: string;
  className?: string;
  placeholder?: string;
  required?: boolean;
  defaultValue?: string;
  postfix?: any;
  action?: any;
  filled?: boolean;
  readOnly?: boolean;
  hint?: string | React.ReactElement | null;
  status?: Status;
};

/**
 * The `Select` component displays a long list of options in
 * a drop down menu that users can scroll through before they
 * make a selection.
 */
const Select = (
  {
    children,
    value,
    onChange,
    className,
    placeholder,
    required,
    defaultValue = "",
    postfix,
    filled,
    readOnly,
    action,
    hint,
    status,
    name,
    ...rest
  }: Props,
  ref: any
) => (
  <Wrapper className={className}>
    <StyledSelect
      name={name}
      value={value}
      onChange={onChange}
      ref={ref}
      defaultValue={defaultValue}
      required={required}
      filled={!!filled}
      readOnly={readOnly}
      {...rest}
    >
      {placeholder && (
        <option value="" disabled={required}>
          {placeholder}
        </option>
      )}
      {children}
    </StyledSelect>
    <ArrowWrapper>
      {!readOnly && <ChevronDown color={black} size={iconSize} />}
      {!readOnly && action}
      {postfix}
      <TextFieldStatus status={status} hint={hint} />
    </ArrowWrapper>
  </Wrapper>
);

export default React.forwardRef(Select);
