import { forwardRef } from "react";

import { useCombobox } from "downshift";
import styled from "styled-components";

import TextField, { Status } from "../TextField";

import highlightLetters from "./highlightLetters";
import Menu from "./Menu";
import MenuItem from "./MenuItem";
import ToggleButton from "./ToggleButton";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const TextFieldWrapper = styled.div``;

export type Props = {
  suggestions: string[];
  onChange?: (value?: string) => void;
  status?: Status;
  defaultValue?: string;
};
function Autocomplete(
  { suggestions, onChange, status, defaultValue, ...rest }: Props,
  ref: any
) {
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    selectedItem,
    getItemProps,
    inputValue,
  } = useCombobox({
    items: suggestions,
    initialInputValue: defaultValue,
    onInputValueChange: (changes) => onChange && onChange(changes.inputValue),
  });

  const inputProps = getInputProps({
    onFocus: () => {
      onChange && onChange(defaultValue);
    },
  });

  return (
    <Wrapper>
      <TextFieldWrapper {...getComboboxProps()}>
        <TextField
          {...inputProps}
          autocomplete="off"
          status={status}
          ref={ref}
          action={
            <ToggleButton
              {...getToggleButtonProps()}
              aria-label="toggle menu"
            />
          }
          {...rest}
        />
      </TextFieldWrapper>
      <Menu {...getMenuProps()}>
        {isOpen &&
          suggestions.map((suggestion, index) => (
            <MenuItem
              isHighlighted={highlightedIndex === index}
              isSelected={selectedItem && selectedItem === suggestion}
              key={`${suggestion}${index}`}
              {...getItemProps({ item: suggestion, index })}
            >
              {highlightLetters(suggestion, inputValue)}
            </MenuItem>
          ))}
      </Menu>
    </Wrapper>
  );
}

export default forwardRef(Autocomplete);
