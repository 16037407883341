import { TypeSafePage } from "next-type-safe-routes";
import styled from "styled-components";

import Link, { Anchor } from "~components/connected/Link";
import {
  baseUnit,
  fontStack,
  primary500,
  textColor,
} from "~components/copied_from_shared/pui";

const Wrapper = styled.li`
  padding-left: 20px;
  padding-bottom: ${baseUnit}px;
`;

const Inner = styled.div<{
  $isActive?: boolean;
  $isActiveParent?: boolean;
}>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 3px;
  max-width: fit-content;
  border: ${({ $isActive, $isActiveParent }) =>
    $isActive || $isActiveParent
      ? `1px solid ${primary500}`
      : "1px solid transparent"};

  &:hover {
    border: 1px solid ${primary500};
  }
`;

const StyledAnchor = styled(Anchor)`
  ${fontStack};
  text-decoration: none;
  font-size: 15px;
  padding: 3px 7px;
  cursor: pointer;
  color: ${textColor};
`;

type NavigationSubmenuItemProps = {
  children: React.ReactNode;
  linkTo: TypeSafePage;
};
export const NavigationSubmenuItem = ({
  children,
  linkTo,
}: NavigationSubmenuItemProps) => (
  <Wrapper>
    <Link to={linkTo}>
      <Inner>
        <StyledAnchor>{children}</StyledAnchor>
      </Inner>
    </Link>
  </Wrapper>
);

export default NavigationSubmenuItem;
