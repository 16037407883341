import Address, { Props as AddressProps } from "./fields/Address";
import Area, { Props as AreaProps } from "./fields/Area";
import BBAN, { Props as BBANProps } from "./fields/BBAN";
import CountryCode, { Props as CountryCodeProps } from "./fields/CountryCode";
import Datalist, { Props as DatalistProps } from "./fields/Datalist";
import Email, { Props as EmailProps } from "./fields/Email";
import FloorDoor, { Props as FloorDoorProps } from "./fields/FloorDoor";
import IBAN, { Props as IBANProps } from "./fields/IBAN";
import Language, { Props as LanguageProps } from "./fields/Language";
import Money, { DeprecatedMoney, Props as MoneyProps } from "./fields/Money";
import Month, { Props as MonthProps } from "./fields/Month";
import MonthYear, { Props as MonthYearProps } from "./fields/MonthYear";
import Percentage, { Props as PercentageProps } from "./fields/Percentage";
import Phone, { Props as PhoneProps } from "./fields/Phone";
import PostalCode, { Props as PostalCodeProps } from "./fields/PostalCode";
import Purpose, { Props as PurposeProps } from "./fields/Purpose";
import Quarter, { Props as QuarterProps } from "./fields/Quarter";
import VAT, { Props as VATProps } from "./fields/VAT";
import Year, { Props as YearProps } from "./fields/Year";
import FieldCore, { Props as FieldCoreProps } from "./FieldCore";
import FieldReadOnly from "./FieldReadOnly";
import { useFormContext } from "./Form";

type Props =
  | (YearProps & { type: "year" })
  | (MonthProps & { type: "month" })
  | (QuarterProps & { type: "quarter" })
  | (PercentageProps & { type: "percentage" })
  | (PostalCodeProps & { type: "postal-code" })
  | (AddressProps & { type: "address" })
  | (MoneyProps & { type: "money" })
  | (MoneyProps & { type: "deprecated-money" })
  | (AreaProps & { type: "area" })
  | (DatalistProps & { type: "datalist" })
  | (EmailProps & { type: "email" })
  | (VATProps & { type: "vat" })
  | (PhoneProps & { type: "phone" })
  | (BBANProps & { type: "bban" })
  | (IBANProps & { type: "iban" })
  | (CountryCodeProps & { type: "country-code" })
  | (LanguageProps & { type: "language" })
  | (MonthYearProps & { type: "month-year" })
  | (FloorDoorProps & { type: "floor-door" })
  | (PurposeProps & { type: "purpose" })
  | FieldCoreProps;

export const Field = (props: Props) => {
  const { readOnly } = useFormContext();

  // NOTE: It's tricky to make selects readonly due to
  // the value not being the same as the display text
  if ((readOnly || props.readOnly) && props.type !== "select") {
    const { type, ...rest } = props;
    return <FieldReadOnly type={type} {...rest} />;
  }

  // Note, `type` will be unused. Not sure how else to get the ...rest
  // extracted without confusing the TypeScript compiler...
  if (props.type === "year") {
    const { type, ...rest } = props; // eslint-disable-line
    return <Year {...rest} />;
  } else if (props.type === "month") {
    const { type, ...rest } = props; // eslint-disable-line
    return <Month {...rest} />;
  } else if (props.type === "quarter") {
    const { type, ...rest } = props; // eslint-disable-line
    return <Quarter {...rest} />;
  } else if (props.type === "percentage") {
    const { type, ...rest } = props; // eslint-disable-line
    return <Percentage {...rest} />;
  } else if (props.type === "address") {
    const { type, ...rest } = props; // eslint-disable-line
    return <Address {...rest} />;
  } else if (props.type === "money") {
    const { type, ...rest } = props; // eslint-disable-line
    return <Money {...rest} />;
  } else if (props.type === "deprecated-money") {
    const { type, ...rest } = props; // eslint-disable-line
    return <DeprecatedMoney {...rest} />;
  } else if (props.type === "area") {
    const { type, ...rest } = props; // eslint-disable-line
    return <Area {...rest} />;
  } else if (props.type === "datalist") {
    const { type, ...rest } = props; // eslint-disable-line
    return <Datalist {...rest} />;
  } else if (props.type === "email") {
    const { type, ...rest } = props; // eslint-disable-line
    return <Email {...rest} />;
  } else if (props.type === "vat") {
    const { type, ...rest } = props; // eslint-disable-line
    return <VAT {...rest} />;
  } else if (props.type === "phone") {
    const { type, ...rest } = props; // eslint-disable-line
    return <Phone {...rest} />;
  } else if (props.type === "postal-code") {
    const { type, ...rest } = props; // eslint-disable-line
    return <PostalCode {...rest} />;
  } else if (props.type === "country-code") {
    const { type, ...rest } = props; // eslint-disable-line
    return <CountryCode {...rest} />;
  } else if (props.type === "iban") {
    const { type, ...rest } = props; // eslint-disable-line
    return <IBAN {...rest} />;
  } else if (props.type === "bban") {
    const { type, ...rest } = props; // eslint-disable-line
    return <BBAN {...rest} />;
  } else if (props.type === "language") {
    const { type, ...rest } = props; // eslint-disable-line
    return <Language {...rest} />;
  } else if (props.type === "month-year") {
    const { type, ...rest } = props; // eslint-disable-line
    return <MonthYear {...rest} />;
  } else if (props.type === "floor-door") {
    const { type, ...rest } = props; // eslint-disable-line
    return <FloorDoor {...rest} />;
  } else if (props.type === "purpose") {
    const { type, ...rest } = props; // eslint-disable-line
    return <Purpose {...rest} />;
  } else {
    return <FieldCore {...props} />;
  }
};

export default Field;
