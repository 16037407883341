import { useEffect } from "react";

import { useQueryParam } from "~hooks";
import useLocalStorage from "~hooks/useLocalStorage";
import { User as UserType } from "~types";

export type GetInitialViewAsArgs = {
  viewAsLocalStorage: string | null;
  viewAsQuery: string | undefined;
  userCanViewAsIds: string[];
};
export const getInitialViewAs = ({
  userCanViewAsIds,
  viewAsLocalStorage,
  viewAsQuery,
}: GetInitialViewAsArgs) => {
  if (viewAsQuery) {
    return viewAsQuery;
  }

  if (userCanViewAsIds.includes(viewAsLocalStorage || "")) {
    return viewAsLocalStorage;
  }

  return userCanViewAsIds[0];
};

const useViewAs = (user: UserType | undefined) => {
  const [viewAs, setViewAs] = useLocalStorage<string | null>(
    "proper:self-service:view-as",
    null
  );
  const [viewAsQuery, setViewAsQuery] = useQueryParam<string>("viewAs", {
    persistWhileMounted: true,
  });

  const onSetViewAs = (value: string | null) => {
    setViewAs(value);
    setViewAsQuery(value || undefined);
  };

  useEffect(() => {
    if (user) {
      const value = getInitialViewAs({
        viewAsQuery,
        viewAsLocalStorage: viewAs,
        userCanViewAsIds: user.canViewAs.map(({ id }) => id),
      });
      onSetViewAs(value);
    }
  }, [user]);

  return [viewAs, onSetViewAs] as const;
};

export default useViewAs;
