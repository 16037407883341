import Head from "next/head";

import { useUser } from "~hooks";

const Hotjar = () => {
  const { isSupport, authUser } = useUser();

  const hotjarId = process.env.NEXT_PUBLIC_HOTJAR_ID;

  if (!hotjarId) {
    return null;
  }

  // We don't want to track our test user hallandsen (9ji10jlhajtfot3r)
  if (isSupport || authUser.id === "9ji10jlhajtfot3r") {
    return null;
  }

  return (
    <Head>
      <script
        dangerouslySetInnerHTML={{
          __html: `(function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:${hotjarId},hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
        }}
      />
    </Head>
  );
};

export default Hotjar;
