import { useContext } from "react";

import { Context } from "./context";

const useTable = () => {
  const table = useContext(Context);
  return table;
};

export default useTable;
