import { useEffect } from "react";

import { Provider } from "~contexts/ProductsContext";
import { useGet, useRouter, useUser } from "~hooks";
import useEventBus from "~hooks/useEventBus";
import { PropertiesService } from "~services/properties-service";

import AppLoader from "../AppLoader";

const ProductsProvider = ({ children }: any) => {
  const { viewAsUser } = useUser();
  const router = useRouter();

  const fetchProductsForOwner =
    useGet<PropertiesService.FetchAllProductsForOwnerQuery$Response>({
      route: "/api/services/properties/products/fetch-all-products-for-owner",
      query: {
        ownerId: viewAsUser.id,
      },
    });

  useEffect(() => {
    if (
      !fetchProductsForOwner.isLoading &&
      !fetchProductsForOwner.isRefreshing &&
      !fetchProductsForOwner.isUpdating
    ) {
      fetchProductsForOwner.update();
    }
  }, [router.asPath]);

  useEventBus(
    [
      "ProductCreated",
      "ProductArchived",
      "ProductUnarchived",
      "ProductNameUpdated",
    ],
    () => {
      fetchProductsForOwner.update();
    }
  );

  if (fetchProductsForOwner.isLoading || fetchProductsForOwner.isRefreshing) {
    return <AppLoader />;
  }

  if (fetchProductsForOwner.error !== undefined) {
    return null;
  }

  return (
    <Provider value={{ products: fetchProductsForOwner.data.contextProducts }}>
      {children}
    </Provider>
  );
};

export default ProductsProvider;
