import styled from "styled-components";

import { baseUnit } from "../../../tokens";

const Actions = styled.div`
  margin-top: ${baseUnit * 3}px;
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: ${baseUnit * 2}px;
  }
`;

export default Actions;
